import {checkExistsField} from "./auxValidators";

export const softValidateCashRegister = (data, setErrors) => {
    const errors = {}

    if (checkExistsField(data, "paid") && data.paid !== 0) {
        if (!checkExistsField(data, "discount"))
            errors['discount'] = "El descuento es obligatorio"
        else if (parseFloat(data.discount) < 0)
            errors['discount'] = "El descuento debe ser mayor o igual que el 0%"
        else if (parseFloat(data.discount) > 100)
            errors['discount'] = "El descuento debe ser menor o igual que el 100%"

        if (parseFloat(data.paid) <= 0)
            errors['paid'] = "La cantidad abonada debe ser un número positivo"
        else if (parseFloat(data.paid) > parseFloat(data.unpaid))
            errors['paid'] = "La cantidad abonada no puede ser superior a la deuda restante"

        if (parseFloat(data.paid) > 0 && !checkExistsField(data, "payment_method"))
            errors['payment_method'] = "Seleccione el metodo de pago"
    }

    setErrors(errors)
    return Object.keys(errors).length === 0;
}


export const softValidateGroupSessionCashRegister = (data, setErrors) => {
    const errors = {}
     if (!checkExistsField(data, "discount"))
        errors['discount'] = "El descuento es obligatorio"
    else if (parseFloat(data.discount) < 0)
        errors['discount'] = "El descuento debe ser mayor o igual que el 0%"
    else if (parseFloat(data.discount) > 100)
        errors['discount'] = "El descuento debe ser menor o igual que el 100%"

    if (parseFloat(data.final_price) !== 0){
        if (!checkExistsField(data, "paid") || data.paid === 0)
            errors['paid'] = "La cantidad abonada es obligatoria"
        else if (parseFloat(data.paid) <= 0)
            errors['paid'] = "La cantidad abonada debe ser un número positivo"
        else if (parseFloat(data.paid) > parseFloat(data.unpaid))
            errors['paid'] = "La cantidad abonada no puede ser superior a la deuda restante"

        if (parseFloat(data.paid) > 0 && !checkExistsField(data, "payment_method"))
            errors['payment_method'] = "Seleccione el metodo de pago"
    }

    setErrors(errors)
    return Object.keys(errors).length === 0;
}

