import React from "react";


const ColorSelectorComponent = ({
                                    value = '',
                                    onChange = () => {},
                                    disabled = false,
                                    label = "Color en el calendario"
                                }) => {

    return <>
        <label className="form-label">{label}</label>
        <select value={value} className="form-select" onChange={onChange} name={"color"} disabled={disabled}>
            <option value="blue">Azul</option>
            <option value="turquoise">Turquesa</option>
            <option value="green">Verde</option>
            <option value="red">Rojo</option>
            <option value="orange">Naranja</option>
            <option value="yellow">Amarillo</option>
            <option value="violet">Violeta</option>
        </select>
    </>
}

export default ColorSelectorComponent
