import React, {useState} from "react";

import CheckBoxComponent from "../../../general/input/CheckBoxComponent";
import InputComponent from "../../../general/input/InputComponent";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";


const PaymentSessionComponent = ({
                                     sessionData,
                                     sessionType,
                                     paymentType,
                                     setPaymentType,
                                     cashRegisterData,
                                     setCashRegisterData,
                                     voucherData,
                                     setVoucherData,
                                     acquiredVouchers,
                                     errors
                                 }) => {
        const [showAllVouchers, setShowAllVouchers] = useState(!sessionData.acquired_voucher_id || sessionData.acquired_voucher_id === "")

        const handleChangePaymentType = (e) => handleChangeAux(e, paymentType, setPaymentType)

        const handleChangeCashRegisterData = (e) => handleChangeAux(e, cashRegisterData, setCashRegisterData)

        const handleChangeVouchersShow = (e) => setShowAllVouchers(e.target.checked)

        const handleChangeVoucherData = (e) => {
            if (e.target.value !== "")
                setVoucherData(acquiredVouchers[e.target.value])
            else
                setVoucherData({acquired_voucher_id: ""})
        }

        if (paymentType && paymentType.voucher === true && sessionType && sessionData)
            return <div className="container">
                <h2>Pago</h2>
                <MessageSpan messagesDict={errors}/>

                <div className="row g-3">
                    <div className="col-6">
                        <CheckBoxComponent
                            label={"Pago con bono previamente adquirido"}
                            onChange={handleChangePaymentType}
                            value={paymentType.voucher}
                            name={"voucher"}
                            disabled={sessionData.acquired_voucher_id && sessionData.acquired_voucher_id !== ""}
                        />
                    </div>

                    <div className="col-6">
                        <CheckBoxComponent
                            label={"Mostrar solo los bonos que coincidan con el tipo de sesión"}
                            onChange={handleChangeVouchersShow}
                            value={showAllVouchers}
                            name={"display"}
                            disabled={sessionData.acquired_voucher_id && sessionData.acquired_voucher_id !== ""}
                        />
                    </div>

                    <div className="col-12">
                        <select className="form-select" onChange={handleChangeVoucherData} name={"acquired_voucher_id"}
                                value={voucherData.acquired_voucher_id}
                                disabled={sessionData.acquired_voucher_id && sessionData.acquired_voucher_id !== ""}>
                            <option key="" value="">Selecciona un bono</option>
                            {acquiredVouchers && Object.keys(acquiredVouchers).map((id) => {
                                if (parseInt(id) === sessionData.acquired_voucher_id)
                                    return <option key={id} value={id}>{acquiredVouchers[id].voucher_name}</option>
                                else if (showAllVouchers && parseInt(acquiredVouchers[id].session_type_id) === parseInt(sessionData.session_type_id))
                                    return <option key={id} value={id}>{acquiredVouchers[id].voucher_name}</option>
                                else if (!showAllVouchers && parseInt(acquiredVouchers[id].remaining_sessions) > 0)
                                    return <option key={id} value={id}>{acquiredVouchers[id].voucher_name}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>

        return <div className="container">
            <h2>Pago</h2>
            <MessageSpan messagesDict={errors}/>

            <div className="row g-3">

                <div className="col-12">
                    <CheckBoxComponent
                        label={"Pago con bono previamente adquirido"}
                        onChange={handleChangePaymentType}
                        value={paymentType.voucher}
                        name={"voucher"}
                        disabled={cashRegisterData.cash_register_id && cashRegisterData.cash_register_id !== ""}
                    />
                </div>

                <div className="col-3">
                    <InputComponent
                        label={"Precio inicial"}
                        value={cashRegisterData.initial_price}
                        type={"number"}
                        name={"initial_price"}
                        disabled={true}
                    />
                </div>
                <div className="col-3">
                    <InputComponent
                        label={"Descuento"}
                        value={cashRegisterData.discount}
                        type={"number"}
                        onChange={handleChangeCashRegisterData}
                        name={"discount"}
                        placeholder={"%"}
                        disabled={cashRegisterData.cash_register_id && cashRegisterData.cash_register_id !== ""}
                    />
                </div>
                <div className="col-3">
                    <InputComponent
                        label={"Precio final"}
                        value={cashRegisterData.final_price}
                        type={"number"}
                        name={"final_price"}
                        disabled={true}
                    />
                </div>
                <div className="col-3">
                    <InputComponent
                        label={"Por pagar"}
                        value={cashRegisterData.unpaid}
                        type={"number"}
                        name={"unpaid"}
                        disabled={true}
                    />
                </div>
                {cashRegisterData && cashRegisterData.unpaid > 0 ? <div className="col-6">
                    <InputComponent
                        label={"Pagado"}
                        onChange={handleChangeCashRegisterData}
                        value={cashRegisterData.paid}
                        type={"number"}
                        name={"paid"}
                        placeholder={"Cantidad pagada"}
                    />
                </div> : null}
                {cashRegisterData && cashRegisterData.unpaid > 0 ? <div className="col-6">
                    <label className="form-label">Método de pago</label>
                    <select className="form-select" value={cashRegisterData.payment_method}
                            onChange={handleChangeCashRegisterData}
                            name={"payment_method"}>
                        <option key="" value="">Selecciona el método de pago</option>
                        <option key="Efectivo" value="Efectivo">Efectivo</option>
                        <option key="Tarjeta" value="Tarjeta">Tarjeta</option>
                        <option key="Cheque" value="Transferencia">Transferencia</option>
                        <option key="Otro" value="Otro">Otro</option>
                    </select>
                </div> : null}
            </div>
        </div>
    }
;

export default PaymentSessionComponent;
