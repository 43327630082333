import React from "react";
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";

import MainCalendarComponent from "../components/calendar/MainCalendarComponent";
import NotAllowedScreen from "../screens/public/NotAllowedScreen";
import RegisterClinicComponent from "../components/clinic/RegisterClinicComponent";
import ClinicSelectorComponent from "../components/general/input/ClinicSelectorComponent";


const ProtectedRoute = ({redirectPath = "/login", requiredPermissions, children}) => {
    const auth = useSelector(state => state.auth)
    const activeClinic = useSelector(state => state.activeClinic)

    if (auth.user_type === "manager" && Object.keys(auth.clinics).length === 0)
        return <RegisterClinicComponent/>

    else if (auth.user_type === "manager" && !activeClinic.id)
        return <ClinicSelectorComponent/>

    else if (requiredPermissions.includes(auth.user_type))
        return children ? children : <MainCalendarComponent/>

    else if (auth.user_type === "clinic")
        return <NotAllowedScreen/>

    return <Navigate to={redirectPath} replace/>
}

export default ProtectedRoute;
