import axios from "axios";


export const uploadDocument = (data, closeTooltip) => {
    return () => {
        let formData = new FormData();

        //Adding files to the formdata
        formData.append("clinic_id", data.clinic_id);
        formData.append("patient_id", data.patient_id);
        formData.append("description", data.description);
        formData.append("file", data.file);

        axios.post('/api/managers/documents/', formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then(() => {
                closeTooltip()
            })
    }
}


export const getAllDocumentsData = (params, setData, setPagination, setOrder) => {
    return () => {
        axios.get(`/api/managers/documents/`, {params: params})
            .then(response => {
                setPagination(response.data.pagination)
                setOrder(response.data.order)
                setData(response.data.content)
            })
    }
}


export const deleteDocument = (id, update) => {
    return () => {
        axios.delete(`/api/managers/documents/${id}`,)
            .then(() => {
                setTimeout(() => update(), 200);
            })
    }
}