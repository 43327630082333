import {checkExistsField} from "./auxValidators";

export const validateGroupSessionType = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "name"))
        errors['name'] = "El nombre de la sesión es obligatorio"
    else if(data.name.trim().length < 3)
        errors['name'] = "El nombre es demasiado corto"

    if (!checkExistsField(data, "number_participants"))
        errors['number_participants'] = "El número de participantes es obligatorio"
    else if(parseInt(data.number_participants) <= 0)
        errors['number_participants'] = "El número de participantes debe ser un número entero positivo"

    if (!checkExistsField(data, "price"))
        errors['price'] = "El precio de la sesión es obligatorio"
    else if (parseFloat(data.price) < 0)
        errors['price'] = "El precio de la sesión debe ser un número positivo"

    if (!checkExistsField(data, "session_duration"))
        errors['session_duration'] = "La duración de la sesión es obligatoria"
    else if (parseInt(data.session_duration) <= 0)
        errors['session_duration'] = "La duración de la sesión debe ser un número positivo"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}