import React from "react";

import ListVoucherComponent from "./ListVoucherComponent";
import EditVoucherComponent from "./EditVoucherComponent";


const GeneralVoucherComponent = ({
                                     voucherTypes,
                                     filter,
                                     setFilter,
                                     order,
                                     pagination,
                                     selectedVoucherId,
                                     setSelectedVoucherId,
                                     resetFilter
                                 }) => {
    return <>
        <ListVoucherComponent
            voucherTypes={voucherTypes}
            filter={filter}
            setFilter={setFilter}
            order={order}
            pagination={pagination}
            setSelectedVoucherId={setSelectedVoucherId}
            selectedVoucherId={selectedVoucherId}
        />

        {selectedVoucherId && <EditVoucherComponent
            voucherType={voucherTypes[selectedVoucherId]}
            setSelectedVoucherId={setSelectedVoucherId}
            resetFilter={resetFilter}
        />}
    </>
}

export default GeneralVoucherComponent;