import React from "react";

import WhatsAppReminder from "../general/auxiliar/WhatsAppReminder";


const TablePatientAux = ({
                             data,
                             order,
                             selectText,
                             onSelectClick,
                             deleteText,
                             onDeleteClick,
                             onPaymentClick,
                             onAddToClassRecursive,
                             onDeleteFromClassRecursive
                         }) => {
    return <>
        {(data && Object.keys(data).length > 0) && <div className={"table-responsive"}>
            <table className={"table align-middle my-3"}>
                <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Apellidos</th>
                    <th>Correo</th>
                    <th>Teléfono</th>
                    <th/>

                    {/* Optional buttons */}
                    {onPaymentClick && <th/>}
                    {onAddToClassRecursive && onDeleteFromClassRecursive && <th/>}
                    {deleteText && onDeleteClick && <th/>}
                </tr>
                </thead>

                <tbody>
                {order && order.map((id) => {
                    if (data && data[id] !== undefined) {
                        const patient = data[id]
                        return <tr key={id} className={data[id].unpaid ? "table-danger" : ""}>
                            <td>{patient.name}</td>
                            <td>{patient.surnames}</td>
                            <td>{patient.email}</td>
                            <td>{patient.phone_number} <WhatsAppReminder link={patient.link}/></td>
                            <td className="text-center">
                                <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                    e.preventDefault()
                                    onSelectClick(patient)
                                }}>
                                    {selectText}
                                </button>
                            </td>

                            {/* Optional buttons */}
                            {onPaymentClick && <td className="text-center">
                                <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                    e.preventDefault()
                                    onPaymentClick(id)
                                }}>
                                    Detalles de pago
                                </button>
                            </td>}

                            {onAddToClassRecursive && onDeleteFromClassRecursive && !patient.recurrent &&
                                <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                        e.preventDefault()
                                        onAddToClassRecursive(patient)
                                    }}>
                                        Añadir a posteriores
                                    </button>
                                </td>
                            }

                            {onAddToClassRecursive && onDeleteFromClassRecursive && patient.recurrent &&
                                <td className="text-center">
                                    <button type="button" className="btn btn-danger btn-sm" onClick={(e) => {
                                        e.preventDefault()
                                        onDeleteFromClassRecursive(patient)
                                    }}>
                                        Eliminar de posteriores
                                    </button>
                                </td>
                            }

                            {deleteText && onDeleteClick && <td className="text-center">
                                <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    disabled={patient.role === "Owner"}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onDeleteClick(patient)
                                    }}
                                >
                                    {deleteText}
                                </button>
                            </td>}
                        </tr>
                    }
                })}
                </tbody>
            </table>
        </div>}
    </>
}

export default TablePatientAux;