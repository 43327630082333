import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {format} from "date-fns";

import PhysiotherapistSelectorComponent from "../../../general/input/PhysiotherapistSelectorComponent";
import SessionTypeSelectorComponent from "../../../general/input/SessionTypeSelectorComponent";
import PaymentSessionComponent from "../session/PaymentSessionComponent";
import CheckBoxComponent from "../../../general/input/CheckBoxComponent";
import InputComponent from "../../../general/input/InputComponent";
import TablePatientAux from "../../../patient/TablePatientAux";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import useScreenSize from "../../../../auxiliar/customHooks/useScreenSize";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateSession} from "../../../../auxiliar/formValidators/sessionValidators";
import {softValidateCashRegister} from "../../../../auxiliar/formValidators/cashRegisterValidators";
import {deleteSession, putSessionData} from "../../../../actions/session";
import {checkExistsField, parseJson} from "../../../../auxiliar/formValidators/auxValidators";
import {dispatchRedirect} from "../../../../reducers/redirectReducer";


const EditSessionComponent = ({
                                  closeTooltip,
                                  eventData,
                                  patientData,
                                  cashRegisterData,
                                  setCashRegisterData,
                                  acquiredVouchers,
                                  openTooltipRegister,
                                  loadNewSession,
                                  deletedPhysio = undefined,
                                  deletedSessionType = undefined
                              }) => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)
    const screenSize = useScreenSize()

    const [data, setData] = useState(eventData)
    const [paymentType, setPaymentType] = useState({voucher: false})
    const [voucherData, setVoucherData] = useState({acquired_voucher_id: ""})

    const [errors, setErrors] = useState({})
    const [paymentErrors, setPaymentErrors] = useState({})
    const [updateDisabled, setUpdateDisabled] = useState(false)

    useEffect(() => {
        setData(eventData)
        setPaymentType({voucher: eventData.acquired_voucher_id && eventData.acquired_voucher_id !== ""})
        setVoucherData({acquired_voucher_id: eventData.acquired_voucher_id ? eventData.acquired_voucher_id : ""})
    }, [eventData, setData, setPaymentType, setVoucherData])

    useEffect(() => {
        const sessionTypes = parseJson(window.localStorage.getItem('session_types'))

        if (sessionTypes && cashRegisterData && cashRegisterData.initialized) {
            const discount = cashRegisterData.discount
            let sessionType = sessionTypes.data.content[data.session_type_id]
            if (sessionType === undefined && deletedSessionType)
                sessionType = deletedSessionType

            setCashRegisterData({
                ...cashRegisterData,
                initial_price: sessionType.price,
                final_price: (sessionType.price * (1 - discount / 100)).toFixed(2),
                unpaid: (sessionType.price * (1 - discount / 100)).toFixed(2),
            })
        }
    }, [activeClinic, data.session_type_id, cashRegisterData.discount, dispatch, setCashRegisterData])

    useEffect(() => {
        const sessionTypes = parseJson(window.localStorage.getItem('session_types'))

        if (sessionTypes && Object.keys(sessionTypes.data.content).length > 0) {
            let duration = 60 * 60000
            if (checkExistsField(sessionTypes.data.content, data.session_type_id))
                duration = sessionTypes.data.content[data.session_type_id].session_duration * 60000

            let startingTime = new Date(data.start_datetime).getTime()
            if (isNaN(startingTime))
                startingTime = new Date().getTime()

            setData({
                ...data,
                finish_datetime: (format(new Date(startingTime + duration), "yyyy-MM-dd'T'HH:mm")).toString()
            })
        }
    }, [data.session_type_id, data.start_datetime])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onSelectPatientClick = (patient) => dispatchRedirect(dispatch, `/patient/${patient.patient_id}`)

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (paymentType.voucher) {
            if (validateSession(data, setErrors))
                dispatch(putSessionData(data.session_id, {
                    new_info: {...data, acquired_voucher_id: voucherData.acquired_voucher_id}
                }, closeTooltip, setUpdateDisabled))
            else
                setUpdateDisabled(false)
        } else {
            if (validateSession(data, setErrors) && softValidateCashRegister(cashRegisterData, setPaymentErrors))
                dispatch(putSessionData(data.session_id, {new_info: {...cashRegisterData, ...data}}, closeTooltip, setUpdateDisabled))
            else
                setUpdateDisabled(false)
        }
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar la sesión? No se podrá recuperar la información'))
            dispatch(deleteSession(data.session_id, closeTooltip, setUpdateDisabled, false))
        else
            setUpdateDisabled(false)
    }

    const onDeleteAllClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar está sesión y las sesiones asociadas siguientes? No se podrá recuperar la información'))
            dispatch(deleteSession(data.session_id, closeTooltip, setUpdateDisabled, true))
        else
            setUpdateDisabled(false)
    }

    const onNextSessionClick = (e) => {
        e.preventDefault()
        loadNewSession(data.next_session_id)
    }

    const onPreviousSessionClick = (e) => {
        e.preventDefault()
        loadNewSession(data.previous_session_id)
    }

    const onCopySessionClick = (e) => {
        e.preventDefault()

        if (JSON.stringify(data) === JSON.stringify(eventData) || window.confirm('Existen cambios no guardados en la sesión, ¿desea descartarlos y proceder a copiar la sesion?')) {
            closeTooltip()

            openTooltipRegister.current({
                ...eventData,
                startAt: eventData.start_datetime,
                endAt: eventData.finish_datetime,
                comment: eventData.comment,
                employee_id: eventData.employee_id,
                patient_id: eventData.patient_id,
                session_type_id: eventData.session_type_id,
                session_reason: eventData.session_reason
            }, true)
        }
    }

    if (!data || Object.keys(data).length === 0)
        return null

    return <div>
        <div className={"row"}>
            <div className={"col-6"}>
                <h2>Editar cita</h2>
            </div>
            <div className={"col-6"}>
                {checkExistsField(data, 'next_session_id') &&
                    <button className={"btn btn-dark btn-sm float-end mx-2"} onClick={onNextSessionClick}>
                        {screenSize.width > 992 ? "Siguiente" : ">"}
                    </button>}
                {checkExistsField(data, 'previous_session_id') &&
                    <button className={"btn btn-dark btn-sm float-end mx-2"} onClick={onPreviousSessionClick}>
                        {screenSize.width > 992 ? "Anterior" : "<"}
                    </button>}
                <button className={"btn btn-dark float-end mx-2 btn-sm"} onClick={onCopySessionClick}>
                    Copiar sesión
                </button>
            </div>
        </div>
        <MessageSpan messagesDict={errors}/>

        <div className="col-12">
            <TablePatientAux
                data={patientData}
                order={Object.keys(patientData)}
                selectText={"Detalles"}
                onSelectClick={onSelectPatientClick}
            />
        </div>

        <form className="row g-3">
            <div className="col-12">
                <label htmlFor={"patient_comments"} className="form-label">Anamnesis
                    ({data.patient_comments.length}/10.000)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={data.patient_comments}
                    name={"patient_comments"}
                    placeholder={"Comentario"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha de inicio *"}
                    onChange={handleChange}
                    value={data.start_datetime}
                    type={"datetime-local"}
                    name={"start_datetime"}
                    disabled={eventData.attended}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha de fin *"}
                    onChange={handleChange}
                    value={data.finish_datetime}
                    type={"datetime-local"}
                    name={"finish_datetime"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <PhysiotherapistSelectorComponent
                    handleChangeFilter={handleChange}
                    value={data.employee_id}
                    showAllOption={false}
                    deletedPhysio={deletedPhysio}
                    disabled={eventData.attended}
                />
            </div>
            <div className="col-6">
                <SessionTypeSelectorComponent
                    handleChangeFilter={handleChange}
                    value={data.session_type_id}
                    deletedSessionType={deletedSessionType}
                    disabled={(cashRegisterData.cash_register_id && cashRegisterData.cash_register_id !== "") ||
                        (data.acquired_voucher_id && data.acquired_voucher_id !== "") || eventData.attended}
                />
            </div>
            <div className="col-12">
                <label htmlFor={"comment"} className="form-label">Comentario de la consulta
                    ({data.comment.length}/200)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={data.comment}
                    name={"comment"}
                    placeholder={"Comentario"}
                />
            </div>
            <div className="col-12">
                <label htmlFor={"session_reason"} className="form-label">Motivo de la consulta
                    ({data.session_reason.length}/200)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={data.session_reason}
                    name={"session_reason"}
                    placeholder={"Motivo de consulta"}
                />
            </div>
            <div className="col-6">
                <CheckBoxComponent
                    label={"Asistido"}
                    onChange={handleChange}
                    value={data.attended}
                    name={"attended"}
                />
            </div>

            <PaymentSessionComponent
                sessionData={data}
                sessionType={parseJson(window.localStorage.getItem('session_types'))}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
                cashRegisterData={cashRegisterData}
                setCashRegisterData={setCashRegisterData}
                voucherData={voucherData}
                setVoucherData={setVoucherData}
                acquiredVouchers={acquiredVouchers}
                errors={paymentErrors}
            />

            <div className="col-12 container-fluid mt-4">
                <button type="submit" className="btn btn-dark me-3" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button>
                <button className="btn btn-dark" onClick={closeTooltip}>
                    Cerrar
                </button>
                <button type="submit" className="btn btn-danger float-end" onClick={onDeleteClick}
                        disabled={updateDisabled}>
                    Eliminar
                </button>
                {checkExistsField(data, 'next_session_id') &&
                    <button type="submit" className="btn btn-danger float-end mx-3"
                            onClick={onDeleteAllClick} disabled={updateDisabled}>
                        Eliminar posteriores
                    </button>}
            </div>
        </form>
    </div>
}

export default EditSessionComponent;