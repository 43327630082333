import axios from "axios";

export const getAllSessionsData = (params, setData, setPagination, setOrder = () => {
}) => {
    return () => {
        axios.get(`/api/managers/sessions/`, {params: params})
            .then(response => {
                const data = response.data

                setData(data.content)
                setPagination(data.pagination)
                setOrder(data.order)
            })
    }
}


export const getSessionData = (sessionId, data, setData) => {
    return () => {
        axios.get(`/api/managers/sessions/${sessionId}`)
            .then(response => {
                if (data.finish_datetime && data.start_datetime) {
                    response.data.content.finish_datetime = data.finish_datetime
                    response.data.content.start_datetime = data.start_datetime
                }
                setData(response.data.content)
            })
    }
}


export const postSessionsData = (patientData, sessionData, closeTooltip, setRegisterDisabled) => {
    let reqData = JSON.parse(JSON.stringify(sessionData))

    if (reqData.patient_id === "new")
        reqData.new_patient_info = patientData

    return () => {
        axios.post('/api/managers/sessions/', reqData)
            .then(() => {
                closeTooltip()
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}

export const putSessionData = (sessionId, data, closeTooltip, setUpdateDisabled) => {
    return () => {
        axios.put(`/api/managers/sessions/${sessionId}`, data)
            .then(() => {
                closeTooltip()
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}


export const deleteSession = (sessionId, closeTooltip, setUpdateDisabled, deleteRecurrent = false) => {
    return () => {
        axios.delete(`/api/managers/sessions/${sessionId}?deleteRecurrent=${deleteRecurrent}`)
            .then(() => {
                closeTooltip()
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}


export const getEditSessionData = (sessionId, params, dates, setEventData, setPatientData, setCashRegisterData,
                                   setAcquiredVouchers, setDeletedEntities, setFirstLoad) => {
    return () => {
        axios.get(`/api/managers/sessions/edit_session/${sessionId}`, {params: params})
            .then(response => {
                const data = response.data.content
                const cashRegister = data.cash_register.content
                const acquiredVouchers = data.acquired_vouchers.content
                const deletedEntities = data.deleted_entities
                const patientData = data.patient.content

                const eventData = {
                    ...data.session.content,
                    finish_datetime: dates.finish_datetime ? dates.finish_datetime : data.session.content.finish_datetime,
                    start_datetime: dates.start_datetime ? dates.start_datetime : data.session.content.start_datetime,
                }

                setAcquiredVouchers(acquiredVouchers)
                setPatientData(patientData)
                setCashRegisterData(cashRegister)
                setDeletedEntities(deletedEntities)
                setEventData(eventData)

                setFirstLoad(false)
            })
    }
}
