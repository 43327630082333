import React from "react";
import {useDispatch} from "react-redux";

import {dispatchRedirect} from "../../../../reducers/redirectReducer";
import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";


const ListSessionTypeComponent = ({
                                      sessionTypes,
                                      filter,
                                      setFilter,
                                      order,
                                      pagination,
                                      setSelectedSessionId,
                                      selectedSessionId
                                  }) => {
    const dispatch = useDispatch()

    function onAddClick() {
        dispatchRedirect(dispatch, "/services/session-type/register")
    }

    return <div className={"container mt-5"}>
        <h2>Sesiones Ofertadas</h2>

        {(!sessionTypes || Object.keys(sessionTypes).length <= 0) &&
            <h4 className={"mb-3"}>No hay tipos de sesión disponibles</h4>}

        {sessionTypes && Object.keys(sessionTypes).length > 0 &&
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Duración</th>
                        <th>Precio</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {order && order.map((sessionTypeId) => {
                        if (sessionTypes && sessionTypes[sessionTypeId] !== undefined) {
                            return <tr key={sessionTypeId}>
                                <td>{sessionTypes[sessionTypeId].name}</td>
                                <td>{sessionTypes[sessionTypeId].session_duration} minutos</td>
                                <td>{sessionTypes[sessionTypeId].price} €</td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={() => {
                                        setSelectedSessionId(sessionTypes[sessionTypeId].session_type_id)
                                    }}>
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        }
                    })}
                    </tbody>
                </table>
            </div>
        }

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
            disabled={selectedSessionId !== undefined}
        />

        <div className="col-12 container-fluid">
            <button className="btn btn-dark" onClick={onAddClick}>
                Nueva sesión
            </button>
        </div>
    </div>
}

export default ListSessionTypeComponent;