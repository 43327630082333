import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import InputComponent from "../general/input/InputComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import useScreenSize from "../../auxiliar/customHooks/useScreenSize";
import {deleteLogo, uploadLogo} from "../../actions/clinics";
import {validateLogoData} from "../../auxiliar/formValidators/logoValidator";


const EditLogoComponent = ({originalLogoURL, clinicId}) => {
    const dispatch = useDispatch();

    const screenSize = useScreenSize()

    const [logoData, setLogoData] = useState({
        logo: "",
        logo_url: "",
        file_size: 0
    })

    const [errors, setErrors] = useState({})

    useEffect(() => {
        setLogoData({
            ...logoData,
            logo_url: originalLogoURL
        })
    }, [originalLogoURL])

    const handleChange = (e) => {
        setLogoData({
            ...logoData,
            [e.target.name]: e.target.files[0],
            file_size: e.target.files[0].size / 1024 / 1024
        })
    };

    const onDeleteLogoClick = (e) => {
        e.preventDefault()

        dispatch(deleteLogo(clinicId, setLogoData))
    }

    const onSendLogoClick = (e) => {
        e.preventDefault()

        if (validateLogoData(logoData, setErrors))
            dispatch(uploadLogo(clinicId, logoData, setLogoData))
    }

    return <div className={"my-5"}>
        <div className={"col-12"}>
            <h2>Logo</h2>
        </div>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            {logoData.logo_url && screenSize.width <= 768 &&
                <div className={"col-12 d-flex flex-wrap align-items-center"}>
                    <img src={logoData.logo_url}
                         className={"img-responsive mx-auto my-auto d-block"}
                         width={"90%"}
                         alt='Logo'
                    />
                </div>
            }

            <div className={screenSize.width > 768 ? "col-6" : "col-12"}>
                <div className={"col-12"}>
                    <div className="col-12">
                        <label className="form-label" htmlFor="file">Logo</label>
                        <input type="file" className="form-control" id="logo" name="logo"
                               onChange={handleChange}/>
                    </div>
                    <div className="col-12 mt-3">
                        <InputComponent
                            label={"Tamaño del archivo (max: 25 MB)"}
                            value={logoData.file_size.toFixed(2) + " MB"}
                            type={"text"}
                            name={"size"}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>

            {logoData.logo_url && screenSize.width > 768 &&
                <div className={"col-6 d-flex flex-wrap align-items-center"}>
                    <img src={logoData.logo_url}
                         className={"img-responsive mx-auto my-auto d-block"}
                         height={"150px"}
                         alt='Logo'
                    />
                </div>
            }

            <div className={"container-fluid"}>
                <button className="btn btn-dark" onClick={onSendLogoClick}>
                    Guardar
                </button>
                {logoData.logo_url && <button className="btn btn-danger float-end" onClick={onDeleteLogoClick}>
                    Eliminar
                </button>}
            </div>
        </form>
    </div>
}

export default EditLogoComponent;