import axios from "axios";
import {checkExistsField} from "../auxiliar/formValidators/auxValidators";


export const postPatientAttendsGroupSession = (data, update, recSessions = false) => {
    return () => {
        axios.post(`/api/managers/patient_attends_group_sessions/`, {
            ...data,
            addToRecSessions: recSessions
        })
            .then(() => {
                update()
            })
    }
}

export const deletePatientAttendsGroupSession = (groupSessionID, patientID, update, recSessions = false) => {
    return () => {
        axios.delete(`/api/managers/patient_attends_group_sessions/group_session/${groupSessionID}/patient/${patientID}?deleteRecurrent=${recSessions}`)
            .then(() => {
                update()
            })
    }
}


export const putPatientAttendsGroupSession = (groupSessionID, patientID, data, update, setPatientId) => {
    return () => {
        axios.put(`/api/managers/patient_attends_group_sessions/group_session/${groupSessionID}/patient/${patientID}`, data)
            .then(() => {
                update()
                setPatientId(undefined)
            })
    }
}


export const getPatientAttendsGroupSessionPaymentInfo = (groupSessionID, patientID, clinicID, setCashRegisterData,
                                                         setAcquiredVouchers) => {
    return () => {
        axios.get(`/api/managers/patient_attends_group_sessions/group_session/${groupSessionID}/patient/${patientID}/payment_info?clinic_id=${clinicID}`, {})
            .then(response => {
                const data = response.data.content
                setAcquiredVouchers(data.acquired_voucher.content)
                setCashRegisterData(checkExistsField(data.cash_register, 'content') ? data.cash_register.content : {})
            })
    }
}