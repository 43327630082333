import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../../../general/input/InputComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import {postSessionTypeData} from "../../../../actions/sessionTypes";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateSessionType} from "../../../../auxiliar/formValidators/sessionTypeValidators";
import ColorSelectorComponent from "../../../general/input/ColorSelectorComponent";
import CheckBoxComponent from "../../../general/input/CheckBoxComponent";


const RegisterSessionTypeComponent = () => {
    const dispatch = useDispatch();

    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        name: "",
        session_duration: 60,
        price: "",
        clinic_id: activeClinic.id,
        color: "blue",
        online_booking: false
    })

    const [errors, setErrors] = useState({})

    const [registerDisabled, setRegisterDisabled] = useState(false)

    const handleChange = (e) => {
        handleChangeAux(e, data, setData)
    }

    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validateSessionType(data, setErrors)) {
            dispatch(postSessionTypeData(data, setRegisterDisabled))
        }
        else
            setRegisterDisabled(false)
    }

    return (
        <div className="container my-5">
            <h2>Registro de un nuevo tipo de sesión</h2>
            <MessageSpan messagesDict={errors}/>

            <form className="row g-3">
                <div className="col-6">
                    <InputComponent
                        label={"Nombre *"}
                        onChange={handleChange}
                        value={data.name}
                        type={"text"}
                        name={"name"}
                        placeholder={"Nombre"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Duración de la sesión en minutos *"}
                        onChange={handleChange}
                        value={data.session_duration}
                        type={"number"}
                        name={"session_duration"}
                        placeholder={"60"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Precio base de la sesión *"}
                        onChange={handleChange}
                        value={data.price}
                        type={"number"}
                        name={"price"}
                        placeholder={"Precio"}
                    />
                </div>
                <div className="col-6">
                    <ColorSelectorComponent
                        value={data.color}
                        onChange={handleChange}/>
                </div>
                <div className="col-3">
                    <CheckBoxComponent
                        title={"Marque este campo si desea que este tipo de sesión se muestre cuando un paciente realice una reserva online"}
                        label={"Citas online"}
                        onChange={handleChange}
                        value={data.online_booking}
                        name={"online_booking"}
                    />
                </div>
                <div className="col-12 container-fluid">
                    <button className="btn btn-dark" onClick={onRegisterClick} disabled={registerDisabled}>
                        Confirmar
                    </button>
                </div>
            </form>
        </div>
    )
}

export default RegisterSessionTypeComponent;