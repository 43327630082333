import React, {useEffect} from "react";

import InputComponent from "../../../../general/input/InputComponent";
import {handleChangeAux} from "../../../../../auxiliar/handleChange/handleChangeAux";
import {checkExistsField} from "../../../../../auxiliar/formValidators/auxValidators";
import {format} from "date-fns";


const UniqueParametersComponent = ({data, setData, disableEndRange = true}) => {
    const handleChange = (e) => handleChangeAux(e, data, setData)

    // TODO: Update use effect to do the opposite and rebuilt start_datetime and finish_datetime
    useEffect(() => {
        if (!disableEndRange && checkExistsField(data, 'finish_hour')) {
            setData({
                ...data,
                finish_datetime: (format(new Date(data.start_datetime).setHours(
                    parseInt(data.finish_hour.slice(0, 2)),
                    parseInt(data.finish_hour.slice(3, 5))
                ), "yyyy-MM-dd'T'HH:mm")).toString(),
            })
        }

    }, [])

    return <>
        <div className="col-6">
            <InputComponent
                label={"Fecha de inicio"}
                onChange={handleChange}
                value={data.start_datetime}
                type={"datetime-local"}
                name={"start_datetime"}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Fecha de fin"}
                onChange={handleChange}
                value={data.finish_datetime}
                type={"datetime-local"}
                name={"finish_datetime"}
                disabled={disableEndRange}
            />
        </div>
    </>
}

export default UniqueParametersComponent;