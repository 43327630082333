import React, {useState} from "react";
import {useDispatch} from "react-redux";

import InputComponent from "../general/input/InputComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import CheckBoxComponent from "../general/input/CheckBoxComponent";
import ColorSelectorComponent from "../general/input/ColorSelectorComponent";
import {deletePhysiotherapist, putPhysiotherapistData} from "../../actions/physiotherapist";
import {validatePhysiotherapistData} from "../../auxiliar/formValidators/physioterapistValidators";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";


const EditPhysiotherapistComponent = ({physio, setPhysio, remount}) => {
    const dispatch = useDispatch();

    const [errors, setErrors] = useState({})

    const [updateDisabled, setUpdateDisabled] = useState(false)

    const [disabled, setDisabled] = useState(true)

    const handleChange = (e) => {
        handleChangeAux(e, physio, setPhysio)
    }

    const onCancelClick = (e) => {
        e.preventDefault()

        setDisabled(true)
        setErrors({})
        remount()
    }

    const onEditClick = (e) => {
        e.preventDefault()

        setDisabled(false)
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validatePhysiotherapistData(physio, setErrors))
            dispatch(putPhysiotherapistData(physio.employee_id, {new_info: physio}, setUpdateDisabled, setDisabled))
        else
            setUpdateDisabled(false)
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar este fisioterapeuta? No se podrá recuperar la información'))
            dispatch(deletePhysiotherapist(physio.employee_id, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    return <div className="my-5">
        <h2>Editando el perfil de {physio.name}</h2>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Nombre *"}
                    onChange={handleChange}
                    value={physio.name}
                    type={"text"}
                    name={"name"}
                    placeholder={"Nombre"}
                    disabled={disabled}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Apellidos *"}
                    onChange={handleChange}
                    value={physio.surnames}
                    type={"text"}
                    name={"surnames"}
                    placeholder={"Apellidos"}
                    disabled={disabled}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Correo Electrónico"}
                    onChange={handleChange}
                    value={physio.email}
                    type={"text"}
                    name={"email"}
                    placeholder={"ejemplo@mail.com"}
                    disabled={disabled}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Número de teléfono"}
                    onChange={handleChange}
                    value={physio.phone_number}
                    type={"tel"}
                    name={"phone_number"}
                    placeholder={"Número de teléfono"}
                    disabled={disabled}
                />
            </div>
            <div className="col-12">
                <ColorSelectorComponent
                    value={physio.color}
                    onChange={handleChange}
                    disabled={disabled}
                />
            </div>
            <div className="col-3">
                <CheckBoxComponent
                    title={"Marque este campo si desea recibir recordatorios de las citas al teléfono facilitado"}
                    label={"Recordatorios"}
                    onChange={handleChange}
                    value={physio.reminders}
                    name={"reminders"}
                    disabled={disabled}
                />
            </div>
            <div className="col-3">
                <CheckBoxComponent
                    label={"Prácticas"}
                    onChange={handleChange}
                    value={physio.intern}
                    name={"intern"}
                    disabled={disabled}
                />
            </div>
            <div className="col-3">
                <CheckBoxComponent
                    label={"Activo"}
                    onChange={handleChange}
                    value={physio.active}
                    name={"active"}
                    disabled={disabled}
                />
            </div>
            <div className="col-3">
                <CheckBoxComponent
                    title={"Marque este campo si desea que este empleado se muestre cuando un paciente realice una reserva online"}
                    label={"Citas online"}
                    onChange={handleChange}
                    value={physio.online_booking}
                    name={"online_booking"}
                    disabled={disabled}
                />
            </div>

            <div className="col-12 container-fluid">
                {disabled && <button className="btn btn-dark" onClick={onEditClick}>
                    Editar
                </button>}
                {!disabled && <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button>}
                {!disabled && <button className="mx-4 btn btn-dark" onClick={onCancelClick} disabled={updateDisabled}>
                    Cancelar
                </button>}
                {!disabled && <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                    Eliminar
                </button>}
            </div>
        </form>
    </div>
}

export default EditPhysiotherapistComponent;