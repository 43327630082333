import React, {useState} from "react";
import {useDispatch} from "react-redux";

import SelectPatientForServiceComponent from "./SelectPatientForServiceComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import TablePatientAux from "./TablePatientAux";
import {
    deletePatientAuthorizedAcquiredVoucher,
    postPatientAuthorizedAcquiredVoucher
} from "../../actions/patientAuthorizedAcquiredVoucher";
import {validateSmallPatientData} from "../../auxiliar/formValidators/patientValidators";
import {dispatchRedirect} from "../../reducers/redirectReducer";
import PaginatorComponent from "../general/auxiliar/PaginatorComponent";


const ListPatientsInAcquiredVoucherComponent = ({
                                                    acquiredVoucher,
                                                    patients,
                                                    registeredPatientsFilter,
                                                    setRegisteredPatientsFilter,
                                                    nonRegisteredPatientsFilter,
                                                    setNonRegisteredPatientsFilter,
                                                    patientsOrder,
                                                    patientsPagination,
                                                    updatePatients
                                                }) => {
    const dispatch = useDispatch()

    const [errors, setErrors] = useState({})

    const [showAddPatients, setShowAddPatients] = useState(false)

    const resetPatientFilters = () => {
        setNonRegisteredPatientsFilter({
            ...nonRegisteredPatientsFilter,
            name: "",
            surnames: "",
            email: "",
            phone_number: "",
            page: 1
        })

        setRegisteredPatientsFilter({
            ...registeredPatientsFilter,
            page: 1
        })
    }

    const onRegisterClick = (e) => {
        e.preventDefault()
        setShowAddPatients(true)
    }

    const onCancelClick = (e) => {
        e.preventDefault()
        resetPatientFilters()
        setShowAddPatients(false)
    }

    const onSuccessRequest = () => {
        setShowAddPatients(false)
        resetPatientFilters()
        updatePatients()
    }

    const onPatientSelect = (patient) => {
        if (patient.patient_id !== "new" || validateSmallPatientData(patient, setErrors))
            dispatch(postPatientAuthorizedAcquiredVoucher({
                patient_id: patient.patient_id,
                acquired_voucher_id: acquiredVoucher.acquired_voucher_id,
                ...(patient.patient_id === 'new' && {new_patient_info: patient})
            }, onSuccessRequest))
    }

    const onPatientSelectClick = (patient) => dispatchRedirect(dispatch, `/patient/${patient.patient_id}`)

    const onPatientDeleteClick = (patient) => dispatch(deletePatientAuthorizedAcquiredVoucher(
        patient.patient_id, acquiredVoucher.acquired_voucher_id, onSuccessRequest))

    if (!patients || Object.keys(patients).length === 0)
        return null

    return <div className={"my-4"}>
        <div>
            <h2>Pacientes autorizados</h2>

            <TablePatientAux
                data={patients}
                order={patientsOrder}
                onSelectClick={onPatientSelectClick}
                selectText={"Detalles"}
                onDeleteClick={onPatientDeleteClick}
                deleteText={"Eliminar"}
            />

            <PaginatorComponent
                pagination={patientsPagination}
                filter={registeredPatientsFilter}
                setFilter={setRegisteredPatientsFilter}
            />
        </div>

        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            {showAddPatients && <SelectPatientForServiceComponent
                filter={nonRegisteredPatientsFilter}
                setFilter={setNonRegisteredPatientsFilter}
                onClientSelect={onPatientSelect}
            />}

            {((new Date()).getTime() <= (new Date(acquiredVoucher.due_date)).getTime()) &&
                <div className="col-12 container-fluid">
                    {showAddPatients ? <button className="btn btn-dark" onClick={onCancelClick}>
                        Cancelar
                    </button> : <button className="btn btn-dark" onClick={onRegisterClick}>
                        Añadir paciente
                    </button>}
                </div>}
        </form>
    </div>
}

export default ListPatientsInAcquiredVoucherComponent;