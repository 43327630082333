import {messageTypes, redirectTypes} from "./types/types";

export const redirectReducer = (state = {}, action) => {
    switch (action.type) {
        case redirectTypes.redirect:
            return action.payload
        case redirectTypes.clean:
            return {};
        default:
            return state;
    }
}

export const dispatchRedirect = (dispatch, path) => {
    dispatch({
        type: redirectTypes.redirect,
        payload: {
            url: path,
            date: new Date()
        }
    })
}

export const dispatchRedirectClean = (dispatch) => {
    dispatch({
        type: messageTypes.clean
    })
}