import {checkExistsField} from "./auxValidators";

export const validateAcquiredVoucher = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "voucher_type_id"))
        errors['type'] = "Seleccione el tipo de bono"

    if (!checkExistsField(data, "employee_id"))
        errors['employee_id'] = "Seleccione el fisioterapeuta asignado"

    if (!checkExistsField(data, "due_date"))
        errors['due_date'] = "La fecha de caducidad es obligatoria"
    else if (new Date(data.due_date) < new Date())
        errors['due_date'] = "La fecha de caducidad debe ser posterior a la fecha actual"

    if (checkExistsField(data, "paid")) {
        if (!checkExistsField(data, "discount"))
            errors['discount'] = "El descuento es obligatorio"
        else if (parseFloat(data.discount) < 0)
            errors['discount'] = "El descuento debe ser mayor o igual que el 0%"
        else if (parseFloat(data.discount) > 100)
            errors['discount'] = "El descuento debe ser menor o igual que el 100%"

        if (parseFloat(data.paid) < 0)
            errors['paid'] = "La cantidad abonada debe ser un número positivo"
        else if (parseFloat(data.paid) > parseFloat(data.unpaid))
            errors['paid'] = "La cantidad abonada no puede ser superior al precio final"

        if (parseFloat(data.paid) > 0 && !checkExistsField(data, "payment_method"))
            errors['payment_method'] = "Seleccione el metodo de pago"
    }

    setErrors(errors)
    return Object.keys(errors).length === 0;
}


export const validateAcquiredVoucherDate = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "due_date"))
        errors['due_date'] = "La fecha de caducidad es obligatoria"
    else if (new Date(data.due_date) < new Date())
        errors['due_date'] = "La fecha de caducidad debe ser posterior a la fecha actual"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}


export const validateAcquiredVoucherUsable = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "acquired_voucher_id"))
        errors['acquired_voucher_id '] = "Debe seleccionar un bono válido"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}