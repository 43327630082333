import React from "react";

import CheckBoxComponent from "./CheckBoxComponent";
import {handleChangeAux} from "../../../auxiliar/handleChange/handleChangeAux";


const ScheduleSelectorComponent = ({
                                       id,
                                       label,
                                       schedule,
                                       setSchedule,
                                       disabled = false,
                                       closedPrompt = 'Cerrado'
                                   }) => {

    const handleChangeSchedule = (e) => {
        if (e.target.type === "checkbox")
            setSchedule({
                    ...schedule,
                    [id.concat('_schedule_m')]: "",
                    [id.concat('_schedule_e')]: "",
                    [e.target.name]: e.target.checked
                }
            )
        else
            handleChangeAux(e, schedule, setSchedule)
    }

    return <div>
        <label className="form-label">{label}</label>

        <input
            onChange={handleChangeSchedule}
            value={schedule[id.concat('_schedule_m')]}
            type={'time'}
            id={id.concat('_schedule_m')}
            name={id.concat('_schedule_m')}
            className="form-control"
            disabled={disabled || schedule[id.concat('_schedule_c')]}
        />

        <input
            onChange={handleChangeSchedule}
            value={schedule[id.concat('_schedule_e')]}
            type={'time'}
            id={id.concat('_schedule_e')}
            name={id.concat('_schedule_e')}
            className="form-control"
            disabled={disabled || schedule[id.concat('_schedule_c')]}
        />

        <CheckBoxComponent
            label={closedPrompt}
            value={schedule[id.concat('_schedule_c')]}
            name={id.concat('_schedule_c')}
            onChange={handleChangeSchedule}
            disabled={disabled}
        />
    </div>

}

export default ScheduleSelectorComponent
