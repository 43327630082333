import React from "react";

import CheckBoxComponent from "../general/input/CheckBoxComponent";
import RegisterChargePopup from "../charge/popupRegisterCharge/RegisterChargePopUp";
import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import {handleChangeFilterAux} from "../../auxiliar/handleChange/handleChangeFilterAux";
import {checkExistsField} from "../../auxiliar/formValidators/auxValidators";
import {dispatchRedirect} from "../../reducers/redirectReducer";
import {postInvoice} from "../../actions/invoice";
import {useDispatch, useSelector} from "react-redux";


const ListCashRegistersInPatientComponent = ({
                                                 cashRegisters,
                                                 pagination,
                                                 order,
                                                 filter,
                                                 setFilter,
                                                 updateComponent
                                             }) => {
    const activeClinic = useSelector(state => state.activeClinic)

    const dispatch = useDispatch()

    const handleCashRegisterFilterChange = (e) => handleChangeFilterAux(e, filter, setFilter)

    return <div className={"container my-5"}>
        <div className={"row"}>
            <div className="col-6">
                <h2>Albaranes</h2>
            </div>

            <div className="col-3">
                <CheckBoxComponent
                    title={"Muestra aquellos albaranes que tienen una deuda pendiente"}
                    label={"Por pagar"}
                    onChange={handleCashRegisterFilterChange}
                    value={filter.unpaid}
                    name={"unpaid"}
                />
            </div>
            <div className="col-3">
                <CheckBoxComponent
                    title={"Muestra aquellos albaranes que tienen una factura asociada"}
                    label={"Factura asociada"}
                    onChange={handleCashRegisterFilterChange}
                    value={filter.has_invoice}
                    name={"has_invoice"}
                />
            </div>
        </div>

        {Object.keys(cashRegisters).length <= 0 ?
            <h4 className={"mb-3"}>No hay albaranes disponibles</h4> :
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Emisión</th>
                        <th>Precio</th>
                        <th>Deuda</th>
                        <th>Tipo</th>
                        <th>Fisioterapeuta</th>
                        <th/>
                        <th/>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {order && order.map((id) => {
                        if (checkExistsField(cashRegisters, id)) {
                            const cashRegister = cashRegisters[id]

                            return <tr key={id} className={cashRegister.unpaid > 0 ? "table-danger" : ""}>
                                <td>{cashRegister.issue_date}</td>
                                <td>{String(cashRegister.final_price)} €</td>
                                <td>{String(cashRegister.unpaid)} €</td>
                                <td>{cashRegister.type_name}</td>
                                <td>{cashRegister.name_employee}</td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                        e.preventDefault()
                                        dispatchRedirect(dispatch, `/cash-register/${id}`)
                                    }}>
                                        Ver albarán
                                    </button>
                                </td>

                                {checkExistsField(cashRegister, "invoice_id") ? <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                        e.preventDefault()
                                        dispatchRedirect(dispatch, `/invoice/${cashRegister.invoice_id}`)
                                    }}>
                                        Ver factura
                                    </button>
                                </td> : <td className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-dark btn-sm"
                                        disabled={!cashRegister.is_invoice_possible}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            dispatch(postInvoice({
                                                cash_register_id: id,
                                                clinic_id: activeClinic.id
                                            }))
                                        }}>
                                        Crear factura
                                    </button>
                                </td>}

                                {cashRegister.unpaid > 0 ? <td>
                                    <RegisterChargePopup
                                        cashRegisterData={cashRegister}
                                        updateComponent={updateComponent}
                                    />
                                </td> : null}
                            </tr>
                        }
                    })}
                    </tbody>
                </table>
            </div>}

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
        />
    </div>
}

export default ListCashRegistersInPatientComponent;