import React from "react";

import InputComponent from "./InputComponent";
import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";
import {checkExistsField} from "../../../auxiliar/formValidators/auxValidators";


const TimeFiltersComponent = ({
                                  filter,
                                  onChange,
                              }) => {
    const screenSize = useScreenSize()

    return <>
        <div className={screenSize.width >= 400 ? "col-4" : "col-12"}>
            <label className="form-label">Temporalidad</label>
            <select value={filter.temporality} className="form-select" onChange={onChange} name={"temporality"}>
                <option value="">Todas</option>
                <option value="future">Futuras</option>
                <option value="past">Pasadas</option>
                <option value="current">Actuales</option>
                <option value="custom">Personalizado</option>
            </select>
        </div>

        {!checkExistsField(filter, 'temporality') && screenSize.width >= 400 && <div className={"col-8"}/>}

        {['future', 'past', 'current'].includes(filter.temporality) &&
            <div className={screenSize.width >= 400 ? "col-4" : "col-12"}>
                <label className="form-label">Periodo</label>
                <select value={filter.period} className="form-select" onChange={onChange} name={"period"}>
                    <option value="days">Día</option>
                    <option value="weeks">Semana</option>
                    <option value="months">Mes</option>
                    <option value="years">Año</option>
                </select>
            </div>}

        {filter.temporality === "custom" && <>
            <div className={screenSize.width >= 400 ? "col-4" : "col-12"}>
                <InputComponent
                    label={"Fecha inicial"}
                    onChange={onChange}
                    value={filter.starting_date}
                    type={"date"}
                    name={"starting_date"}
                    placeholder={"Fecha de inicio"}
                />
            </div>
            <div className={screenSize.width >= 400 ? "col-4" : "col-12"}>
                <InputComponent
                    label={"Fecha final"}
                    onChange={onChange}
                    value={filter.finishing_date}
                    type={"date"}
                    name={"finishing_date"}
                    placeholder={"Fecha final"}
                />
            </div>
        </>}

        {['future', 'past'].includes(filter.temporality) && <>
            <div className={screenSize.width >= 400 ? "col-4" : "col-12"}>
                <InputComponent
                    label={"Duración"}
                    onChange={onChange}
                    value={filter.duration}
                    type={"number"}
                    name={"duration"}
                    placeholder={"Todos"}
                />
            </div>
        </>}
    </>
}

export default TimeFiltersComponent