import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import PhysiotherapistSelectorComponent from "../../../general/input/PhysiotherapistSelectorComponent";
import InputComponent from "../../../general/input/InputComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import {getAllVoucherData} from "../../../../actions/voucher";
import {postAcquiredVoucherData} from "../../../../actions/acquiredVoucher";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateAcquiredVoucher} from "../../../../auxiliar/formValidators/acquiredVoucherValidators";


const RegisterAcquiredVoucherComponent = ({patientData}) => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        acquired_voucher_id: "",
        remaining_sessions: 0,
        voucher_type_id: "",
        clinic_id: activeClinic.id,
        due_date: undefined,
        cash_register_id: "",
        paid: 0,
        initial_price: 0,
        discount: 0,
        final_price: 0,
        unpaid: 0,
        type: "acquired_voucher",
        employee_id: "",
        payment_method: "",
        ...patientData
    })

    const [errors, setErrors] = useState({})

    const [vouchers, setVouchers] = useState({})

    const [vouchersOrder, setVouchersOrder] = useState([])

    const [registerDisabled, setRegisterDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, data, setData)

    useEffect(() => {
        dispatch(getAllVoucherData({
            "clinic_id": activeClinic.id,
            "order": "name",
            "per_page": 1000
        }, setVouchers, setVouchersOrder, () => {}))
    }, [activeClinic, dispatch, setVouchers])

    useEffect(() => {
        const finalPrice = (data.initial_price - (data.initial_price * data.discount / 100)).toFixed(2)
        setData({
            ...data,
            final_price: finalPrice,
            unpaid: finalPrice
        })
    }, [data.initial_price, data.discount])


    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validateAcquiredVoucher(data, setErrors))
            dispatch(postAcquiredVoucherData(data, patientData.patient_id, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    const onChangeVoucher = (e) => {
        e.preventDefault()

        let dueDate = new Date()
        let totalSessions = 0
        let price = 0
        if (e.target.value === "") {
            dueDate = undefined
        } else {
            dueDate.setMonth(dueDate.getMonth() + vouchers[e.target.value].months_validity)
            dueDate = dueDate.toISOString().slice(0, 10)
            totalSessions = vouchers[e.target.value].total_sessions
            price = vouchers[e.target.value].price
        }

        setData({
            ...data,
            voucher_type_id: e.target.value,
            remaining_sessions: totalSessions,
            initial_price: price,
            due_date: dueDate
        })
    }

    // TODO: Create specific component for voucherTypes
    let voucherSelectElements = []
    voucherSelectElements.push(<option value="" key="" name={"voucher_type_id"}>Seleccione bono</option>)
    for (const key of vouchersOrder) {
        voucherSelectElements.push(<option value={key} key={key}>{vouchers[key].name}</option>)
    }

    let voucherSelector = <select className="form-select" onChange={onChangeVoucher}>
        {voucherSelectElements}
    </select>

    return <div className="container my-5">
        <h2>Compra de bono</h2>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-6">
                <label className="form-label">Tipo de bono *</label>
                {voucherSelector}
            </div>
            <div className="col-6">
                <PhysiotherapistSelectorComponent
                    handleChangeFilter={handleChange}
                    value={data.employee_id}
                    showAllOption={false}
                    showSelectOption={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Sesiones restantes"}
                    onChange={handleChange}
                    value={data.remaining_sessions}
                    type={"text"}
                    name={"remaining_sessions"}
                    placeholder={data.remaining_sessions}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha de caducidad  *"}
                    onChange={handleChange}
                    value={data.due_date}
                    type={"date"}
                    name={"due_date"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Porcentaje de descuento aplicado *"}
                    onChange={handleChange}
                    value={data.discount}
                    type={"number"}
                    name={"discount"}
                    placeholder={"Descuento aplicado (%)"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Precio final"}
                    onChange={handleChange}
                    value={data.final_price}
                    type={"number"}
                    name={"final_price"}
                    placeholder={data.initial_price - (data.initial_price * data.discount / 100)}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Pagado"}
                    onChange={handleChange}
                    value={data.paid}
                    type={"number"}
                    name={"paid"}
                    placeholder={"Cantidad pagada"}
                />
            </div>
            <div className="col-6">
                <label className="form-label">Método de pago</label>
                <select className="form-select" onChange={handleChange} name={"payment_method"}>
                    <option value="">Seleccione método de pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Tarjeta">Tarjeta</option>
                    <option value="Transferencia">Transferencia</option>
                    <option value="Otro">Otro</option>
                </select>
            </div>
            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onRegisterClick} disabled={registerDisabled}>
                    Confirmar
                </button>
            </div>
        </form>
    </div>
}

export default RegisterAcquiredVoucherComponent;