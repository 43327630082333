import {useState} from "react";

function useLocalStorage(key, initialValue, keepCalendarParams = true) {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === "undefined")
            return initialValue;

        try {
            const item = window.localStorage.getItem(key);

            if (!item)
                return initialValue;

            const parsed = JSON.parse(item)

            // bookings filter reset on first reload
            if (!keepCalendarParams) {
                parsed['employee_id'] = ''
                parsed['view'] = 'week'
                parsed['starting_date'] = undefined
                parsed['finishing_date'] = undefined
                parsed['initial_date'] = new Date()

                if (typeof window !== "undefined")
                    window.localStorage.setItem(key, JSON.stringify(parsed));
            }

            return parsed
        } catch (error) {
            // If error also return initialValue
            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (typeof window !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            //pass
        }
    };
    return [storedValue, setValue];
}

export default useLocalStorage;