import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import NotFoundScreen from "../../public/NotFoundScreen";
import EditInvoiceTemplateComponent from "../../../components/clinic/EditInvoiceTemplateComponent";
import EditLogoComponent from "../../../components/clinic/EditLogoComponent";
import EditClinicComponent from "../../../components/clinic/EditClinicComponent";
import ScheduleSelectorGeneralComponent from "../../../components/general/input/ScheduleSelectorGeneralComponent";
import ClinicPasswordComponent from "../../../components/clinic/ClinicPasswordComponent";
import {getClinicData} from "../../../actions/clinics";
import {checkExistsField} from "../../../auxiliar/formValidators/auxValidators";
import ShowClinicBookingsComponent from "../../../components/clinic/ShowClinicBookingsComponent";


const EditClinicScreen = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const userType = useSelector(state => state.auth.user_type)

    const [clinic, setClinic] = useState({})

    const [schedule, setSchedule] = useState({
        monday_schedule_m: '',
        monday_schedule_e: '',
        monday_schedule_c: false,
        tuesday_schedule_m: '',
        tuesday_schedule_e: '',
        tuesday_schedule_c: false,
        wednesday_schedule_m: '',
        wednesday_schedule_e: '',
        wednesday_schedule_c: false,
        thursday_schedule_m: '',
        thursday_schedule_e: '',
        thursday_schedule_c: false,
        friday_schedule_m: '',
        friday_schedule_e: '',
        friday_schedule_c: false,
        saturday_schedule_m: '',
        saturday_schedule_e: '',
        saturday_schedule_c: false,
        sunday_schedule_m: '',
        sunday_schedule_e: '',
        sunday_schedule_c: false
    })

    const [invoice, setInvoice] = useState({})

    const [logoURL, setLogoURL] = useState(undefined)

    const [link, setLink] = useState(undefined)

    const [remountBool, setRemountBool] = useState(false)

    useEffect(() => {
        dispatch(getClinicData(params.clinic_id, 'advanced', setClinic, setSchedule, setInvoice, setLogoURL, setLink))
    }, [params.clinic_id, remountBool, dispatch, setClinic, setSchedule, setInvoice])

    const remount = () => setRemountBool(!remountBool)

    if (!checkExistsField(params, 'clinic_id') || !clinic || Object.keys(clinic).length === 0)
        return <NotFoundScreen version={'clinic'}/>

    return <div className={"container"}>
        <EditClinicComponent
            clinic={clinic}
            setClinic={setClinic}
            remount={remount}
            editAllowed={userType === "manager"}
        />

        {link && <ShowClinicBookingsComponent
            link={link}
        />}

        {userType === "manager" && <ClinicPasswordComponent
            existingProfile={clinic.has_password}
            remount={remount}
            clinicId={params.clinic_id}
        />}

        <ScheduleSelectorGeneralComponent
            type={'clinic'}
            schedule={schedule}
            setSchedule={setSchedule}
            remount={remount}
            clinicId={params.clinic_id}
        />

        {userType === "manager" && <EditLogoComponent
            originalLogoURL={logoURL}
            clinicId={params.clinic_id}
        />}

        {userType === "manager" && <EditInvoiceTemplateComponent
            invoice={invoice}
            setInvoice={setInvoice}
            clinicId={params.clinic_id}
            remount={remount}
        />}
    </div>
}

export default EditClinicScreen;