import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Popup from 'reactjs-popup';
import {format} from "date-fns";

import EditSessionComponent from "./EditSessionComponent";
import EditGroupSessionComponent from "./EditGroupSessionComponent";
import EditBlockedHoursComponent from "./EditBlockedHoursComponent";
import EditUnconfirmedSessionComponent from "./EditUnconfirmedSessionComponent";
import MessageComponent from "../../../general/auxiliar/message/MessageComponent";
import {getEditGroupSessionData} from "../../../../actions/groupSession";
import {getEditSessionData} from "../../../../actions/session";
import {checkExistsField} from "../../../../auxiliar/formValidators/auxValidators";
import {getAllPatientData} from "../../../../actions/patient";
import {getCalendarTimespan} from "../../../../actions/calendarTimespan";
import {getUnconfirmedSessionByID} from "../../../../actions/unconfirmedSession";


const EditSessionPopup = ({childFunc, updateComponent, openTooltipRegister}) => {
    const ref = useRef();
    const openTooltip = () => ref.current.open();
    const closeTooltip = () => ref.current.close();

    const dispatch = useDispatch()
    const activeClinic = useSelector(state => state.activeClinic)

    const [eventData, setEventData] = useState({})
    const [dates, setDates] = useState({})
    const [type, setType] = useState()
    const [filter, setFilter] = useState({
        clinic_id: activeClinic.id
    })
    const [boolUpdate, setBoolUpdate] = useState(false)

    const [showAddPatients, setShowAddPatients] = useState({showAdd: false})
    const [patientData, setPatientData] = useState({})
    const [patientPagination, setPatientPagination] = useState({})
    const [patientOrder, setPatientOrder] = useState([])

    const [deletedEntities, setDeletedEntities] = useState({})

    const [acquiredVouchers, setAcquiredVouchers] = useState({})

    const [cashRegisterData, setCashRegisterData] = useState({})

    const [firstLoad, setFirstLoad] = useState(true)

    useEffect(() => {
        childFunc.current = (data) => {
            const dates = {
                start_datetime: (format(new Date(data.startAt), "yyyy-MM-dd'T'HH:mm")).toString(),
                finish_datetime: (format(new Date(data.endAt), "yyyy-MM-dd'T'HH:mm")).toString(),
            }

            requestData(data.event_type, data, dates)
            setType(data.event_type)
            setDates(dates)
            openTooltip()
        }
    }, [])

    useEffect(() => {
        if (eventData && Object.keys(eventData).length > 0) {
            cleanData(false)
            requestData(type, {
                ...eventData,
                id: checkExistsField(eventData, 'session_id') ? eventData.session_id : eventData.group_session_id
            }, dates)
        }
    }, [boolUpdate])

    useEffect(() => {
        if (!firstLoad && (checkExistsField(filter, "patient_id") ||
            checkExistsField(filter, "group_session_id")))
            dispatch(getAllPatientData(filter, setPatientData, setPatientPagination, setPatientOrder))
    }, [filter])

    const update = () => setBoolUpdate(!boolUpdate)

    const requestData = (type, data, dates) => {
        if (type === 'individual') {
            setFilter({patient_id: data.patient_id})
            dispatch(getEditSessionData(data.id, {'clinic_id': activeClinic.id}, dates, setEventData,
                setPatientData, setCashRegisterData, setAcquiredVouchers, setDeletedEntities, setFirstLoad))
        } else if (type === 'group') {
            setFilter({group_session_id: data.id, per_page: 5, order: "name", page: 1})
            dispatch(getEditGroupSessionData(data.id, {'clinic_id': activeClinic.id, order: "name"}, dates,
                setEventData, setPatientData, setPatientPagination, setPatientOrder, setDeletedEntities, setFirstLoad))
        } else if (type === 'block') {
            dispatch(getCalendarTimespan(data.id, dates, setEventData))
        } else if (type === "unconfirmed_session") {
            dispatch(getUnconfirmedSessionByID(data.id, activeClinic.id, setEventData, setFilter))
        }
    }

    const cleanData = (cleanAll = true) => {
        setEventData({})
        setPatientData({})
        setCashRegisterData({})
        setAcquiredVouchers({})

        if (cleanAll) {
            setShowAddPatients({showAdd: false})
            setDates({})
        }
    }

    const loadNewSession = (id) => {
        cleanData()
        requestData(type, {id: id}, {})
    }

    const onClose = () => {
        setTimeout(() => updateComponent(), 100);
        cleanData()
    }

    return <Popup contentStyle={{margin: '50px auto', width: "95%"}}
                  modal nested lockScroll position="bottom center" ref={ref} onClose={onClose}
                  closeOnDocumentClick={false}>

        <div className={"p-3 mb-2 bg-light border rounded border-dark w-100"}
             style={{width: "100%", maxHeight: "100%", overflowY: "auto"}}>

            {eventData && Object.keys(eventData).length > 0 && <div>
                <MessageComponent/>

                {type === 'individual' && <EditSessionComponent
                    closeTooltip={closeTooltip}
                    eventData={eventData}
                    filter={filter} setFilter={setFilter}
                    patientData={patientData}
                    cashRegisterData={cashRegisterData}
                    setCashRegisterData={setCashRegisterData}
                    acquiredVouchers={acquiredVouchers}
                    openTooltipRegister={openTooltipRegister}
                    loadNewSession={loadNewSession}
                    deletedPhysio={checkExistsField(deletedEntities, 'employee') ?
                        deletedEntities.employee.content : undefined}
                    deletedSessionType={checkExistsField(deletedEntities, 'session_type') ?
                        deletedEntities.session_type.content : undefined}
                />}

                {type === 'group' && <EditGroupSessionComponent
                    closeTooltip={closeTooltip}
                    eventData={eventData}
                    setFilter={setFilter}
                    filter={filter}
                    update={update}
                    patientData={patientData}
                    patientPagination={patientPagination}
                    patientOrder={patientOrder}
                    showAddPatients={showAddPatients}
                    setShowAddPatients={setShowAddPatients}
                    loadNewGroupSession={loadNewSession}
                    deletedPhysio={checkExistsField(deletedEntities, 'employee') ?
                        deletedEntities.employee.content : undefined}
                    deletedGroupSessionType={checkExistsField(deletedEntities, 'group_session_type') ?
                        deletedEntities.group_session_type.content : undefined}
                />}

                {type === 'block' && <EditBlockedHoursComponent
                    closeTooltip={closeTooltip}
                    eventData={eventData}
                    loadNewCalendarTimespan={loadNewSession}
                />}

                {type === 'unconfirmed_session' && <EditUnconfirmedSessionComponent
                    closeTooltip={closeTooltip}
                    eventData={eventData}
                    filter={filter}
                    setFilter={setFilter}
                />}
            </div>}
        </div>
    </Popup>
};


export default EditSessionPopup