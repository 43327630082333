export const storeInCache = (data, key, status = 200) => {
    // Save result in cache only if all data is displayed
    // If not reset cache to force reload with more data per page
    if (status === 200) {
        if (data.pagination && data.pagination.max_pages === 1)
            window.localStorage.setItem(key, JSON.stringify({
                data: data,
                date: new Date().getTime()
            }));
        else
            window.localStorage.setItem(key, undefined);
    }
}