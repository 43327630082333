import React from "react";

import useHoverCapableDevice from "../../../auxiliar/customHooks/useHoverCapableDevice";


const CheckBoxComponent = ({value, onChange, name, label, title = "", disabled = false}) => {
    const hoverCapableDevice = useHoverCapableDevice()

    if (value === null || value === undefined)
        value = false

    if (title === "" || !hoverCapableDevice)
        return <>
            <input
                onChange={onChange}
                checked={value}
                type="checkbox"
                id={name}
                name={name}
                className="form-check-input"
                disabled={disabled}
            />
            <label htmlFor={name} className="form-check-label ms-2">{label}</label>
        </>

    return <>
        <input
            onChange={onChange}
            checked={value}
            type="checkbox"
            id={name}
            name={name}
            className="form-check-input"
            disabled={disabled}
        />
        <label htmlFor={name} className="form-check-label ms-2">
            {label} <span title={title}>&#x2139;</span>
        </label>
    </>
}

export default CheckBoxComponent