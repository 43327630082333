import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import ClinicSelectorDropdown from "./ClinicSelectorDropdown";
import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";
import {logout} from "../../../actions/auth";


const NavbarLoggedIn = () => {
    const dispatch = useDispatch()
    const userType = useSelector(state => state.auth.user_type)
    const clinicID = useSelector(state => state.activeClinic.id)
    const screenSize = useScreenSize()

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const handleLogout = (e) => {
        e.preventDefault()
        dispatch(logout())
    }

    if (screenSize.width < 992)
        return <nav className="py-2 navbar navbar-expand-lg navbar-dark bg-dark">
            <Link className="navbar-brand ms-3" to="/agenda">
                <img src={require("../../../media/Logo/Icono.png")} height={20} alt={'Logo'}
                     className={"mb-1"}/>
            </Link>

            <div className={"align-items-center justify-content-center"}>
                <ClinicSelectorDropdown/>
            </div>

            <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#toggle" aria-controls="toggle"
                    aria-expanded={!isNavCollapsed}
                    onClick={handleNavCollapse}>
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`${isNavCollapsed ? 'collapse' : 'ps-4'} navbar-collapse`} id="toggle">
                <ul className={`navbar-nav me-auto mb-2 mb-lg-0`}>
                    { /* General endpoints */ }
                    <Link className="nav-link" to="/agenda">Agenda</Link>
                    <Link className="nav-link" to="/physiotherapist">Fisios</Link>
                    <Link className="nav-link" to="/services">Servicios</Link>
                    <Link className="nav-link" to="/patient">Pacientes</Link>

                    { /* Manager endpoints */ }
                    {userType === "manager" && <>
                        <Link className="nav-link" to="/cash-register">Facturación</Link>
                        <Link className="nav-link" to="/clinic">Clínicas</Link>
                        <Link className="nav-link" to="/profile">Perfil</Link>
                    </>}

                    { /* Clinic endpoints */ }
                    {userType === "clinic" && <>
                        <Link className="nav-link" to={`/clinic/${clinicID}`}>Clínica</Link>
                    </>}

                    { /* General endpoints */ }
                    <Link className="nav-link" to="/task">Tareas</Link>


                    { /* General endpoints */ }
                    <span className="nav-link" onClick={handleLogout}>Cerrar sesión</span>
                </ul>
            </div>
        </nav>

    return <nav className="px-4 py-2 navbar navbar-expand-lg navbar-dark bg-dark">
        <Link className="navbar-brand ms-3" to="/agenda">
            <img src={require("../../../media/Logo/Icono.png")} height={20} alt={'Logo'}
                 className={"mb-1"}/> MiFisio
        </Link>

        <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse"
                data-target="#toggle" aria-controls="toggle"
                aria-expanded={!isNavCollapsed}
                onClick={handleNavCollapse}>
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`${isNavCollapsed ? 'collapse' : 'ps-4'} navbar-collapse`} id="toggle">
            <ul className={`navbar-nav me-auto mb-2 mb-lg-0`}>
                { /* General endpoints */ }
                <Link className="nav-link" to="/agenda">Agenda</Link>
                <Link className="nav-link" to="/physiotherapist">Fisios</Link>
                <Link className="nav-link" to="/services">Servicios</Link>
                <Link className="nav-link" to="/patient">Pacientes</Link>

                { /* Manager endpoints */ }
                {userType === "manager" && <>
                    <Link className="nav-link" to="/cash-register">Facturación</Link>
                    <Link className="nav-link" to="/clinic">Clínicas</Link>
                    <Link className="nav-link" to="/profile">Perfil</Link>
                </>}

                { /* Clinic endpoints */ }
                {userType === "clinic" && <>
                    <Link className="nav-link" to={`/clinic/${clinicID}`}>Clínica</Link>
                </>}

                { /* General endpoints */ }
                <Link className="nav-link" to="/task">Tareas</Link>
            </ul>
        </div>

        <ClinicSelectorDropdown/>

        <button className="btn btn-light btn-sm justify-content-end" onClick={handleLogout} type="submit">
            Cerrar sesión
        </button>
    </nav>
}

export default NavbarLoggedIn;