import axios from "axios";

export const getAllChargesData = (params, setData) => {
    return () => {
        axios.get(`/api/managers/charges/`, {params: params})
            .then(response => {
                setData(response.data.content)
            })
    }
}

export const postChargeData = (data, closeTooltip, setRegisterDisabled) => {
    return () => {
        axios.post('/api/managers/charges/', data)
            .then(() => {
                closeTooltip()
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}