import React, {useState} from "react";
import {useDispatch} from "react-redux";

import ListDocumentInPatientComponent from "../document/ListDocumentInPatientComponent";
import InputComponent from "../general/input/InputComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import CheckBoxComponent from "../general/input/CheckBoxComponent";
import UploadDocumentPopUp from "../document/popupUploadDocument/UploadDocumentPupUp";
import {deletePatient, getPatientData, putPatientData} from "../../actions/patient";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";
import {validatePatientData} from "../../auxiliar/formValidators/patientValidators";


const EditPatientComponent = ({
                                  patientId,
                                  data,
                                  setData,
                                  updateComponent,
                                  documentsData,
                                  documentsFilter,
                                  documentsOrder,
                                  setDocumentsFilter,
                                  documentsPagination
                              }) => {
    const dispatch = useDispatch();

    const [errors, setErrors] = useState({})

    const [disabled, setDisabled] = useState(true)

    const [updateDisabled, setUpdateDisabled] = useState(false)

    const handleChangePatient = (e) => handleChangeAux(e, data, setData)

    const onEditClick = (e) => {
        e.preventDefault()
        setDisabled(false)
    }

    const onCancelClick = (e) => {
        e.preventDefault()

        setDisabled(true)
        dispatch(getPatientData(patientId, setData))
        setErrors({})
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validatePatientData(data, setErrors))
            dispatch(putPatientData(patientId, {new_info: data}, setDisabled, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)

        if (window.confirm('¿Está seguro de que quiere eliminar los datos del paciente? No se podrá recuperar la información'))
            dispatch(deletePatient(patientId, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    if (!data || Object.keys(data).length === 0)
        return null

    return <div className="container my-5">
        <div className={"row"}>
            <div className={"col-6"}>
                <h2>Datos de {data.name}</h2>
            </div>
            <div className={"col-6 container-fluid"}>
                <UploadDocumentPopUp
                    patientId={patientId}
                    updateComponent={updateComponent}
                    btnClass={"btn-dark"}
                />
            </div>
        </div>

        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <ListDocumentInPatientComponent
                updateComponent={updateComponent}
                documents={documentsData}
                filter={documentsFilter}
                setFilter={setDocumentsFilter}
                order={documentsOrder}
                pagination={documentsPagination}
            />

            <div className="col-6">
                <InputComponent
                    label={"Nombre *"}
                    onChange={handleChangePatient}
                    value={data.name}
                    type={"text"}
                    name={"name"}
                    placeholder={"Nombre"}
                    disabled={disabled}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Apellidos *"}
                    onChange={handleChangePatient}
                    value={data.surnames}
                    type={"text"}
                    name={"surnames"}
                    placeholder={"Apellidos"}
                    disabled={disabled}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Correo electrónico"}
                    onChange={handleChangePatient}
                    value={data.email}
                    type={"text"}
                    name={"email"}
                    placeholder={"email"}
                    disabled={disabled}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Teléfono"}
                    onChange={handleChangePatient}
                    value={data.phone_number}
                    type={"tel"}
                    name={"phone_number"}
                    placeholder={"Teléfono"}
                    disabled={disabled}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Fecha de nacimiento"}
                    onChange={handleChangePatient}
                    value={data.birth_date}
                    type={"date"}
                    name={"birth_date"}
                    placeholder={"Fecha de nacimiento"}
                    disabled={disabled}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Descuento habitual"}
                    onChange={handleChangePatient}
                    value={data.discount}
                    type={"number"}
                    name={"discount"}
                    placeholder={"(%)"}
                    disabled={disabled}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"DNI"}
                    onChange={handleChangePatient}
                    value={data.DNI}
                    type={"text"}
                    name={"DNI"}
                    placeholder={"00000000X"}
                    disabled={disabled}
                />
            </div>
            <div className="col-12">
                <InputComponent
                    label={"Dirección"}
                    onChange={handleChangePatient}
                    value={data.address}
                    type={"text"}
                    name={"address"}
                    placeholder={"Dirección"}
                    disabled={disabled}
                />
            </div>
            <div className="col-12">
                <label htmlFor={"comment"} className="form-label">Anamnesis ({data.comments.length}/10.000)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChangePatient}
                    value={data.comments}
                    name={"comments"}
                    placeholder={"Comentario"}
                    disabled={disabled}
                />
            </div>
            <div className="col-6">
                <CheckBoxComponent
                    title={"Marque este campo si desea recibir recordatorios de las citas al teléfono facilitado"}
                    label={"Recordatorios"}
                    onChange={handleChangePatient}
                    value={data.reminders}
                    name={"reminders"}
                    disabled={disabled}
                />
            </div>

            <div className="col-12 container-fluid">
                {disabled ? <button className="btn btn-dark" onClick={onEditClick}>
                    Editar
                </button> : null}

                {!disabled ? <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button> : null}

                {!disabled ? <button className="ms-3 btn btn-dark" onClick={onCancelClick}>
                    Cancelar
                </button> : null}

                <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                    Eliminar
                </button>
            </div>
        </form>
    </div>
}

export default EditPatientComponent;