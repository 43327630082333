export const loginTypes = {
    login: '[Auth] login',
    logout: '[Auth] logout'
}

export const redirectTypes = {
    redirect: '[Redirect] redirect',
    clean: '[Redirect] clean'
}

export const messageTypes = {
    message: '[Message] message',
    clean: '[Message] clean'
}

export const activeClinicTypes = {
    save: '[Active Clinic] save',
    clean: '[Active Clinic] clean'
}