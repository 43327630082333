import {checkExistsField, checkValidMail, checkValidPhoneNumber} from "./auxValidators";

export const validatePhysiotherapistData = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "name"))
        errors['name'] = "El nombre es obligatorio"
    else if(data.name.trim().length < 3)
        errors['name'] = "El nombre es demasiado corto"

    if (!checkExistsField(data, "surnames"))
        errors['surnames'] = "Los apellidos son obligatorios"
    else if (data.surnames.trim().length < 3)
        errors['surnames'] = "Los apellidos son demasiado cortos"

    if (checkExistsField(data, "email") && !checkValidMail(data.email)) // not compulsory
        errors['email'] = "El correo introducido no es válido"

    if (checkExistsField(data, "phone_number") && !checkValidPhoneNumber(data.phone_number)) // not compulsory
        errors['phone_number'] = "El número de teléfono introducido no es válido"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}