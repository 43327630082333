import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../../../components/general/input/InputComponent";
import UpdateManagerPasswordComponent from "../../../components/manager/UpdateManagerPasswordComponent";
import ShowReferralManagerComponent from "../../../components/manager/ShowReferralManagerComponent";
import MessageSpan from "../../../components/general/auxiliar/message/MessageSpan";
import {deleteManagerData, getManagerData, putManagerData} from "../../../actions/manager";
import {validateManagerData} from "../../../auxiliar/formValidators/managerValidators";
import {handleChangeAux} from "../../../auxiliar/handleChange/handleChangeAux";


const ManagerProfileScreen = () => {
    const dispatch = useDispatch()
    const id = useSelector(state => state.auth.id)

    const [data, setData] = useState({
        name: "",
        surnames: "",
        phone_number: "",
        email: "",
        association_code: "",
        entry_date: "",
        manager_code: ""
    })

    const [errors, setErrors] = useState({})

    const [disabled, setDisabled] = useState(true)

    const [updateDisabled, setUpdateDisabled] = useState(false)

    useEffect(() => {
        dispatch(getManagerData(id, setData))
    }, [id, dispatch, setData])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onEditClick = (e) => {
        e.preventDefault()

        setDisabled(false)
    }

    const onCancelClick = (e) => {
        e.preventDefault()

        dispatch(getManagerData(id, setData))
        setDisabled(true)
        setErrors({})
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validateManagerData(data, setErrors))
            dispatch(putManagerData(id, {new_info: data}, onSuccessRequest, onErrorRequest))
        else
            setUpdateDisabled(false)
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar el usuario? No se podrá recuperar la información'))
            dispatch(deleteManagerData(id, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    const onSuccessRequest = () => {
        setDisabled(true)
        setUpdateDisabled(false)
    }

    const onErrorRequest = () => setUpdateDisabled(false)

    return <div className="container my-5">
        <div className="container">
            <MessageSpan messagesDict={errors}/>

            <h2>Perfil</h2>
            <form className="row g-3">
                <div className="col-6">
                    <InputComponent
                        label={"Nombre *"}
                        onChange={handleChange}
                        value={data.name}
                        type={"text"}
                        name={"name"}
                        placeholder={"Nombre"}
                        disabled={disabled}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Apellidos *"}
                        onChange={handleChange}
                        value={data.surnames}
                        type={"text"}
                        name={"surnames"}
                        placeholder={"Apellidos"}
                        disabled={disabled}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Correo electrónico *"}
                        onChange={handleChange}
                        value={data.email}
                        type={"text"}
                        name={"email"}
                        placeholder={"ejemplo@mail.com"}
                        disabled={disabled}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Número de teléfono *"}
                        onChange={handleChange}
                        value={data.phone_number}
                        type={"tel"}
                        name={"phone_number"}
                        placeholder={"Número de teléfono"}
                        disabled={disabled}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Código de colegio o asociación"}
                        onChange={handleChange}
                        value={data.association_code}
                        type={"text"}
                        name={"association_code"}
                        placeholder={"Código"}
                        disabled={disabled}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Fecha de registro"}
                        onChange={handleChange}
                        value={data.entry_date}
                        type={"date"}
                        name={"entry_date"}
                        disabled={true}
                    />
                </div>
                <div className="col-12 container-fluid">
                    {disabled ? <button className="btn btn-dark" onClick={onEditClick}>
                        Editar
                    </button> : null}
                    {!disabled ? <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                        Guardar
                    </button> : null}
                    {!disabled ? <button className="mx-4 btn btn-dark" onClick={onCancelClick}>
                        Cancelar
                    </button> : null}
                    <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                        Eliminar
                    </button>
                </div>
            </form>
        </div>

        <ShowReferralManagerComponent code={data.manager_code}/>

        <UpdateManagerPasswordComponent/>
    </div>
}

export default ManagerProfileScreen;