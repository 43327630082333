import {checkExistsField} from "./auxValidators";

export const validateTaskData = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "topic"))
        errors['topic'] = "El nombre es obligatorio"
    else if(data.topic.trim().length < 3)
        errors['topic'] = "El nombre es demasiado corto"
    else if(data.topic.length > 20)
        errors['topic'] = "El nombre es demasiado largo"

    if (!checkExistsField(data, "date"))
        errors['date'] = "La fecha es obligatoria"

    if (checkExistsField(data, "priority") && (data.priority < 0 || data.priority > 127)) // not compulsory
        errors['email'] = "La prioridad debe ser un número entre 0 y 127"

    if (checkExistsField(data, "description") && data.description.length > 200) // not compulsory
        errors['description'] = "La descripción es demasiado larga"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}