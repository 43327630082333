import React from 'react'
import {Link, useMatch} from "react-router-dom";

import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";


const Footer = () => {
    const screenSize = useScreenSize()
    const fullScreen = useMatch("/full-screen-agenda")
    const reservation = useMatch("/reservation/*")

    const params = useQueryParams()

    const getDynamicLink = (path) => {
        const aux = reservation ? "/reservation" : ""
        if (params && params.has('clinic'))
            return `${aux}/${path}?clinic=${params.get('clinic')}`
        else if (params && params.has('session'))
            return `${aux}/${path}?session=${params.get('session')}`

        return `${aux}/${path}`
    }

    if (fullScreen && screenSize.width > 400)
        return null

    return <footer className="flex-shrink-0 py-1 bg-light border-top border-dark">
        <div className="container text-center">
            <small>Copyright&copy; MFISIO SCHEDULA SL - <Link to={getDynamicLink("about-us")}>About us</Link></small>
            <br/>
            <small><Link to={getDynamicLink("terms-of-use")}>Términos de uso</Link></small>
        </div>
    </footer>

}

export default Footer;