import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {dispatchRedirect} from "../../../reducers/redirectReducer";
import {resetPassword} from "../../../actions/manager";


const PasswordChangeScreen = () => {
    const dispatch = useDispatch();

    const queryParameters = new URLSearchParams(window.location.search)
    const code = queryParameters.get("code")

    const [validState, setValidState] = useState("validating")

    useEffect(() => {
        dispatch(resetPassword(code, setValidState))
    }, [code])

    const onReturnClick = (e) => {
        e.preventDefault()
        dispatchRedirect(dispatch, "/login")
    }
    return <div className={"container my-5"}>
        <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                {validState === 'validating' && <span className="display-4">Solicitando nueva contraseña</span>}

                {validState === 'valid' &&
                    <span className="display-4">Nueva contraseña enviada, compruebe su correo electrónico</span>}

                {validState === 'invalid' && <span className="display-4">Ha habido algún problema con la creación
                    de la nueva contraseña, vuelva a intentarlo de nuevo</span>}
            </div>
        </div>
        <div className="col-12 container-fluid">
            <button className="btn btn-dark" onClick={onReturnClick}>
                Volver
            </button>
        </div>
    </div>
}

export default PasswordChangeScreen