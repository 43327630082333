import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../general/input/InputComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";
import {postClinicData} from "../../actions/clinics";
import {validateClinicData} from "../../auxiliar/formValidators/clinicValidators";
import CheckBoxComponent from "../general/input/CheckBoxComponent";


const RegisterClinicComponent = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)

    const [data, setData] = useState({
        manager_id: auth.id,
        address: "",
        town: "",
        province: "",
        name: "",
        landline_phone: "",
        mobile_phone: "",
        email: "",
        invoice_code: "",
        invoice_number: "",
        CIF: "",
        online_booking: false
    })

    const [errors, setErrors] = useState({})

    const [registerDisabled, setRegisterDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onRegisterClick = (e) => {
        e.preventDefault()
        setRegisterDisabled(true)
        if (validateClinicData(data, setErrors))
            dispatch(postClinicData(data, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    return <div className="container my-5">
        <h2>Registro de una nueva clínica</h2>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Nombre de la clínica *"}
                    onChange={handleChange}
                    value={data.name}
                    type={"text"}
                    name={"name"}
                    placeholder={"Nombre"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Correo electrónico de la clínica *"}
                    onChange={handleChange}
                    value={data.email}
                    type={"text"}
                    name={"email"}
                    placeholder={"ejemplo@mail.com"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Número de teléfono móvil de la clínica"}
                    onChange={handleChange}
                    value={data.mobile_phone}
                    type={"tel"}
                    name={"mobile_phone"}
                    placeholder={"Número de teléfono móvil"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Número de teléfono fijo de la clínica"}
                    onChange={handleChange}
                    value={data.landline_phone}
                    type={"tel"}
                    name={"landline_phone"}
                    placeholder={"Número de teléfono fijo"}
                />
            </div>
            <div className="col-12">
                <InputComponent
                    label={"Dirección"}
                    onChange={handleChange}
                    value={data.address}
                    type={"text"}
                    name={"address"}
                    placeholder={"Dirección"}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Provincia"}
                    onChange={handleChange}
                    value={data.province}
                    type={"text"}
                    name={"province"}
                    placeholder={"Provincia"}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Localidad"}
                    onChange={handleChange}
                    value={data.town}
                    type={"text"}
                    name={"town"}
                    placeholder={"Localidad"}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"CIF/NIF"}
                    onChange={handleChange}
                    value={data.CIF}
                    type={"text"}
                    name={"CIF"}
                    placeholder={"CIF/NIF"}
                    title={"Identificador de la empresa, necesario para emitir facturas"}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Prefijo del código de factura *"}
                    onChange={handleChange}
                    value={data.invoice_code}
                    type={"text"}
                    name={"invoice_code"}
                    placeholder={"Código factura"}
                    title={"Código alfanumérico que servirá como prefijo para identificar las facturas"}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Número de factura inicial *"}
                    onChange={handleChange}
                    value={data.invoice_number}
                    type={"number"}
                    name={"invoice_number"}
                    placeholder={"Nº factura"}
                    title={"Numeración de la primera factura generada por el sistema"}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Código de la primera factura"}
                    onChange={handleChange}
                    value={data.invoice_code + '-' + data.invoice_number}
                    type={"text"}
                    disabled={true}
                />
            </div>
            <div className="col-3">
                <CheckBoxComponent
                    title={"Marque este campo si desea que se puedan realizar citas online para esta clínica"}
                    label={"Citas online"}
                    onChange={handleChange}
                    value={data.online_booking}
                    name={"online_booking"}
                />
            </div>
            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onRegisterClick} disabled={registerDisabled}>
                    Confirmar
                </button>
            </div>
        </form>
    </div>
}

export default RegisterClinicComponent;