import {activeClinicTypes} from "./types/types";
import {dispatchRedirect} from "./redirectReducer";

export const activeClinicReducer = (state = {}, action) => {
    switch (action.type) {
        case activeClinicTypes.save:
            return action.payload;
        case activeClinicTypes.clean:
            return {};
        default:
            return state;
    }
}

export const dispatchActiveClinicSave = (dispatch, id, name, status) => {
    window.localStorage.removeItem('physiotherapists')
    window.localStorage.removeItem('voucher_types')
    window.localStorage.removeItem('session_types')
    window.localStorage.removeItem('group_session_types')
    window.localStorage.removeItem('calendar_filter')
    dispatchRedirect(dispatch, "/clinic")

    dispatch({
        type: activeClinicTypes.save,
        payload: {
            id: id,
            name: name,
            status: status
        }
    })
}

export const dispatchActiveClinicClean = (dispatch) => {
    dispatch({
        type: activeClinicTypes.clean
    })
}