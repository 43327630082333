import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import MessageSpan from "../general/auxiliar/message/MessageSpan";
import SelectPatientForSessionComponent from "../patient/SelectPatientForSessionComponent";
import ColorSelectorComponent from "../general/input/ColorSelectorComponent";
import InputComponent from "../general/input/InputComponent";
import CheckBoxComponent from "../general/input/CheckBoxComponent";
import {postTaskData} from "../../actions/task";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";
import {validateSmallPatientData} from "../../auxiliar/formValidators/patientValidators";
import {validateTaskData} from "../../auxiliar/formValidators/taskValidators";


const RegisterTaskComponent = () => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        description: "",
        date: "",
        clinic_id: activeClinic.id,
        color: "blue",
        priority: 0,
        topic: "",
        finished: false,
        associated_patient: false,
        patient_id: ""
    })

    const [patientFilter, setPatientFilter] = useState({
        clinic_id: activeClinic.id,
        name: "",
        surnames: "",
        email: "",
        phone_number: "",
        per_page: 5,
        order: "name"
    })

    const [errors, setErrors] = useState({})
    const [userErrors, setUserErrors] = useState({})

    const [registerDisabled, setRegisterDisabled] = useState(false)

    useEffect(() => {
        if (!data.associated_patient) {
            setPatientFilter({
                clinic_id: activeClinic.id,
                name: "",
                surnames: "",
                email: "",
                phone_number: "",
                per_page: 5,
                order: "name"
            })
            setData({...data, patient_id: ""})
        }
    }, [data.associated_patient])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validateTaskData(data, setErrors) && (!data.associated_patient || validateSmallPatientData(patientFilter, setUserErrors))) {
            dispatch(postTaskData(data, patientFilter, setRegisterDisabled))
        } else
            setRegisterDisabled(false)
    }

    return <div className="container my-5">
        <h2>Registro de una nueva tarea</h2>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Nombre *"}
                    onChange={handleChange}
                    value={data.topic}
                    type={"text"}
                    name={"topic"}
                    placeholder={"Nombre"}
                />
            </div>

            <div className="col-6">
                <InputComponent
                    label={"Fecha *"}
                    onChange={handleChange}
                    value={data.date}
                    type={"date"}
                    name={"date"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Prioridad"}
                    onChange={handleChange}
                    value={data.priority}
                    type={"number"}
                    name={"priority"}
                    placeholder={"0"}
                />
            </div>
            <div className="col-6">
                <ColorSelectorComponent
                    value={data.color}
                    onChange={handleChange}
                    label={"Color"}
                />
            </div>

            <div className="col-12">
                <label htmlFor={"description"} className="form-label">Descripción
                    ({data.description.length}/200)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={data.description}
                    name={"description"}
                    placeholder={"Descripción"}
                />
            </div>
            <div className="col-6">
                <CheckBoxComponent
                    label={"Finalizada"}
                    onChange={handleChange}
                    value={data.finished}
                    name={"finished"}
                />
            </div>

            <div className="col-6">
                <CheckBoxComponent
                    label={"Asociar a paciente"}
                    onChange={handleChange}
                    value={data.associated_patient}
                    name={"associated_patient"}
                />
            </div>

            {data.associated_patient && <SelectPatientForSessionComponent
                data={data}
                setData={setData}
                filter={patientFilter}
                setFilter={setPatientFilter}
                disabled={false}
                userErrors={userErrors}
            />}

            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onRegisterClick} disabled={registerDisabled}>
                    Confirmar
                </button>
            </div>
        </form>
    </div>
}

export default RegisterTaskComponent;