import React from "react";
import {useDispatch} from "react-redux";

import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import {dispatchRedirect} from "../../../../reducers/redirectReducer";


const ListGroupSessionTypeComponent = ({
                                           groupSessionTypes,
                                           filter,
                                           setFilter,
                                           order,
                                           pagination,
                                           setSelectedGroupSessionId,
                                           selectedGroupSessionId
                                       }) => {
    const dispatch = useDispatch()

    function onAddClick() {
        dispatchRedirect(dispatch, "/services/group-session-type/register/")
    }

    return <div className={"container mt-5"}>
        <h2>Clases Ofertadas</h2>

        {(!groupSessionTypes || Object.keys(groupSessionTypes).length <= 0) &&
            <h4 className={"mb-3"}>No hay tipos de sesiones grupales disponibles</h4>}

        {groupSessionTypes && Object.keys(groupSessionTypes).length > 0 &&
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Duración</th>
                        <th>Participantes</th>
                        <th>Precio</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {order && order.map((groupSessionTypeId) => {
                        if (groupSessionTypes && groupSessionTypes[groupSessionTypeId] !== undefined) {
                            return <tr key={groupSessionTypeId}>
                                <td>{groupSessionTypes[groupSessionTypeId].name}</td>
                                <td>{groupSessionTypes[groupSessionTypeId].session_duration} minutos</td>
                                <td>{groupSessionTypes[groupSessionTypeId].number_participants}</td>
                                <td>{groupSessionTypes[groupSessionTypeId].price} €</td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={() =>
                                        setSelectedGroupSessionId(groupSessionTypeId)
                                    }>
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        }
                    })}
                    </tbody>
                </table>
            </div>
        }

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
            disabled={selectedGroupSessionId !== undefined}
        />

        <div className="col-12 container-fluid">
            <button className="btn btn-dark" onClick={onAddClick}>
                Nueva clase
            </button>
        </div>
    </div>
}

export default ListGroupSessionTypeComponent;