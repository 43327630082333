const NotAllowedScreen = ({version = "default"}) => {
    return <div className="d-flex align-items-center justify-content-center m-5">
        <div className="text-center">
            <h1 className="display-1 fw-bold">403</h1>
            <p className="fs-3"><span className="text-danger">Opps!</span> Acceso no autorizado</p>
            <p className="lead">
                La página a la que estas intentando acceder es privada. Inicia sesión con una cuenta con permisos
                suficientes para acceder.
            </p>
        </div>
    </div>
}

export default NotAllowedScreen;