import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import InputComponent from "../../../general/input/InputComponent";
import SessionTypeSelectorComponent from "../../../general/input/SessionTypeSelectorComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import {deleteVoucher, putVoucherData} from "../../../../actions/voucher";
import {validateVoucherData} from "../../../../auxiliar/formValidators/voucherValidators";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import GroupSessionTypeSelectorComponent from "../../../general/input/GroupSessionTypeSelectorComponent";


const EditVoucherComponent = ({voucherType, setSelectedVoucherId, resetFilter}) => {
    const dispatch = useDispatch();

    const [data, setData] = useState(voucherType)

    const [errors, setErrors] = useState({})

    const [updateDisabled, setUpdateDisabled] = useState(false)

    useEffect(() => {
        setData(voucherType)
    }, [voucherType, setData])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onCancelClick = (e) => {
        e.preventDefault()

        setSelectedVoucherId(undefined)
        setErrors({})
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validateVoucherData(data, setErrors))
            dispatch(putVoucherData(
                voucherType.voucher_type_id, {new_info: data}, setSelectedVoucherId, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar este voucher_type? No se podrá recuperar la información'))
            dispatch(deleteVoucher(voucherType.voucher_type_id, setSelectedVoucherId, setUpdateDisabled, resetFilter))
        else
            setUpdateDisabled(false)
    }

    return <div className="container mt-3 mb-5">
        <h5>Editar tipo de bono {data.name}</h5>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Nombre *"}
                    onChange={handleChange}
                    value={data.name}
                    type={"text"}
                    name={"name"}
                    placeholder={"Nombre"}
                />
            </div>
            {/* TODO: add deleted session type */}
            {data.session_type_id && <div className="col-6">
                <SessionTypeSelectorComponent
                    handleChangeFilter={handleChange}
                    value={data.session_type_id}
                    disabled={true}
                />
            </div>}
            {/* TODO: add deleted group session type */}
            {data.group_session_type_id && <div className="col-6">
                <GroupSessionTypeSelectorComponent
                    handleChangeFilter={handleChange}
                    value={data.group_session_type_id}
                    showSelectOption={false}
                />
            </div>}
            <div className="col-6">
                <InputComponent
                    label={"Sesiones totales *"}
                    onChange={handleChange}
                    value={data.total_sessions}
                    type={"number"}
                    name={"total_sessions"}
                    placeholder={"Numero total de sesiones"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Precio *"}
                    onChange={handleChange}
                    value={data.price}
                    type={"number"}
                    name={"price"}
                    placeholder={"Precio base del bono"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Meses de validez *"}
                    onChange={handleChange}
                    value={data.months_validity}
                    type={"number"}
                    name={"months_validity"}
                    placeholder={"Número de meses de validez"}
                />
            </div>
            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button>
                <button className="mx-4 btn btn-dark" onClick={onCancelClick}>
                    Cancelar
                </button>
                <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                    Eliminar
                </button>
            </div>
        </form>
    </div>
}

export default EditVoucherComponent;