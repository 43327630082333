import {Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";


const RedirectComponent = () => {
    const location = useLocation()
    const auth = useSelector(state => state.auth)
    const clinicID = useSelector(state => state.activeClinic.id)
    const redirect = useSelector(state => state.redirect)

    let path = redirect.url
    if (path === "/clinic" && auth.user_type === "clinic")
        if (clinicID)
            path = `/clinic/${clinicID}`
        else
            path = undefined

    if (
        path && (path !== location.pathname && path !== location.pathname + location.search) &&
        (((new Date()).getTime() - (new Date(redirect.date)).getTime()) / 1000 <= 1)
    ) {
        return <Navigate to={path}/>;
    }

    return null
}

export default RedirectComponent;
