import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import NotFoundScreen from "../../public/NotFoundScreen";
import ShowVoucherDataComponent from "../../../components/services/voucher/voucher/ShowVoucherComponent";
import ShowCashRegisterComponent from "../../../components/cashRegister/ShowCashRegisterComponent";
import ListSessionsInAcquiredVoucherComponent
    from "../../../components/services/session/session/ListSessionsInAcquiredVoucherComponent";
import EditAcquiredVoucherComponent
    from "../../../components/services/voucher/acquiredVoucher/edit/EditAcquiredVoucherComponent";
import ListPatientsInAcquiredVoucherComponent from "../../../components/patient/ListPatientsInAcquiredVoucherComponent";
import {getAcquiredVoucherContext} from "../../../actions/acquiredVoucher";
import {getAllSessionsData} from "../../../actions/session";
import {checkExistsField} from "../../../auxiliar/formValidators/auxValidators";
import ListGroupSessionsInAcquiredVoucherComponent
    from "../../../components/services/session/groupSession/ListGroupSessionsInAcquiredVoucherComponent";
import {getAllGroupSessionsData} from "../../../actions/groupSession";
import {getAllPatientData} from "../../../actions/patient";


const AcquiredVoucherScreen = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const activeClinic = useSelector(state => state.activeClinic)

    const [patientsData, setPatientsData] = useState({})
    const [patientsPagination, setPatientsPagination] = useState({})
    const [patientsOrder, setPatientsOrder] = useState([])
    const [patientsUpdateBool, setPatientsUpdateBool] = useState(false)
    const [patientsFilter, setPatientsFilter] = useState({
        clinic_id: activeClinic.id,
        acquired_voucher_id: params.acquired_voucher_id,
        page: 1,
        per_page: 5,
        order: "name"
    })
    const [otherPatientFilter, setOtherPatientFilter] = useState({
        clinic_id: activeClinic.id,
        exclude_acquired_voucher_id: params.acquired_voucher_id,
        name: "",
        surnames: "",
        email: "",
        phone_number: "",
        per_page: 5,
        page: 1,
        order: "name"
    })

    const [sessionsData, setSessionsData] = useState({})
    const [sessionsPagination, setSessionsPagination] = useState({})
    const [sessionsOrder, setSessionsOrder] = useState([])
    const [sessionFilter, setSessionFilter] = useState({
        clinic_id: activeClinic.id,
        acquired_voucher_id: params.acquired_voucher_id,
        order: "date",
        per_page: 5,
        page: 1
    })

    const [groupSessionsData, setGroupSessionsData] = useState({})
    const [groupSessionsPagination, setGroupSessionsPagination] = useState({})
    const [groupSessionsOrder, setGroupSessionsOrder] = useState([])
    const [groupSessionsFilter, setGroupSessionsFilter] = useState({
        clinic_id: activeClinic.id,
        acquired_voucher_id: params.acquired_voucher_id,
        order: "date",
        per_page: 5,
        page: 1
    })

    const [acquiredVoucher, setAcquiredVoucher] = useState({})
    const [voucherType, setVoucherType] = useState({})
    const [cashRegister, setCashRegister] = useState({})
    const [firstLoad, setFirstLoad] = useState(true)

    useEffect(() => {
        dispatch(getAcquiredVoucherContext(
            params.acquired_voucher_id, setFirstLoad,
            setAcquiredVoucher, setVoucherType, setCashRegister,
            setPatientsData, setPatientsPagination, setPatientsOrder,
            setSessionsData, setSessionsPagination, setSessionsOrder,
            setGroupSessionsData, setGroupSessionsPagination, setGroupSessionsOrder
        ))
    }, [])

    useEffect(() => {
        if (!firstLoad)
            dispatch(getAllPatientData(patientsFilter, setPatientsData, setPatientsPagination, setPatientsOrder,))
    }, [patientsFilter, patientsUpdateBool])

    useEffect(() => {
        if (!firstLoad)
            dispatch(getAllSessionsData(sessionFilter, setSessionsData, setSessionsPagination, setSessionsOrder))
    }, [sessionFilter])

    useEffect(() => {
        if (!firstLoad)
            dispatch(getAllGroupSessionsData(groupSessionsFilter, setGroupSessionsData, setGroupSessionsPagination, setGroupSessionsOrder))
    }, [groupSessionsFilter])

    const updatePatients = () => setPatientsUpdateBool(!patientsUpdateBool)

    if (!checkExistsField(params, 'acquired_voucher_id') || !acquiredVoucher || Object.keys(acquiredVoucher).length === 0)
        return <NotFoundScreen version={'acquired_voucher'}/>

    return <div className="container my-5">
        <ShowVoucherDataComponent
            data={voucherType}
        />

        <EditAcquiredVoucherComponent
            data={acquiredVoucher}
            setData={setAcquiredVoucher}
        />

        <ListPatientsInAcquiredVoucherComponent
            acquiredVoucher={acquiredVoucher}
            registeredPatientsFilter={patientsFilter}
            setRegisteredPatientsFilter={setPatientsFilter}
            nonRegisteredPatientsFilter={otherPatientFilter}
            setNonRegisteredPatientsFilter={setOtherPatientFilter}
            patients={patientsData}
            patientsOrder={patientsOrder}
            patientsPagination={patientsPagination}
            updatePatients={updatePatients}
        />

        {checkExistsField(voucherType, 'session_type_id') && <ListSessionsInAcquiredVoucherComponent
            data={sessionsData}
            order={sessionsOrder}
            pagination={sessionsPagination}
            filter={sessionFilter}
            setFilter={setSessionFilter}
        />}

        {checkExistsField(voucherType, 'group_session_type_id') && <ListGroupSessionsInAcquiredVoucherComponent
            data={groupSessionsData}
            order={groupSessionsOrder}
            pagination={groupSessionsPagination}
            filter={groupSessionsFilter}
            setFilter={setGroupSessionsFilter}
        />}


        <ShowCashRegisterComponent
            data={cashRegister}
            redirectButtonDisplay={true}
        />
    </div>
}
export default AcquiredVoucherScreen;
