export const handleChangeAux = (e, data, setData) => {
    if (e.target.type === "checkbox") {
        setData({
            ...data,
            [e.target.name]: e.target.checked
        })
    } else {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
}