import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {putClinicInvoiceTemplateData} from "../../actions/clinics";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";
import {downloadInvoiceExample} from "../../actions/invoice";


const EditInvoiceTemplateComponent = ({invoice, setInvoice, clinicId, remount}) => {
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState(true)

    const [updateDisabled, setUpdateDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, invoice, setInvoice)

    const onEditClick = (e) => {
        e.preventDefault()
        setDisabled(false)
    }

    const onCancelClick = (e) => {
        e.preventDefault()

        remount()
        setDisabled(true)
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        dispatch(putClinicInvoiceTemplateData(clinicId, {new_info: invoice}, setDisabled, setUpdateDisabled))
    }

    const onDownloadInvoiceExample = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        dispatch(downloadInvoiceExample(clinicId, setUpdateDisabled))
    }

    if (!invoice || Object.keys(invoice).length === 0)
        return null

    return <div className="my-5">
        <div className="row">
            <div className={"col-8"}>
                <h2>Plantilla para las facturas</h2>
            </div>
            <div className={"col-4 container-fluid"}>
                <button className="btn btn-dark float-end btn-sm" onClick={onDownloadInvoiceExample}
                        disabled={updateDisabled}>
                    Descargar plantilla
                </button>
            </div>
        </div>

        <span className={"mt-0"}>
            MiFisio Agenda permite enviar las facturas directamente al correo del paciente. Para ello, proporcionamos
            una plantilla de la que se pueden editar tres campos. Para mas información puedes consultar el <a
            href={'/tutorials/invoice-template'} target={'_blank'}>siguiente tutorial</a>.
        </span>

        <form className="row g-3 mt-1">
            <div className="col-12">
                <label htmlFor={"vertical_text"} className="form-label">Texto 1 - Vertical
                    ({invoice.vertical_text.length}/150)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={invoice.vertical_text}
                    name={"vertical_text"}
                    placeholder={"Texto 1"}
                    disabled={disabled}
                />
            </div>
            <div className="col-12">
                <label htmlFor={"GDPR_text"} className="form-label">Texto 2 - Principal
                    ({invoice.GDPR_text.length}/1000)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={invoice.GDPR_text}
                    name={"GDPR_text"}
                    placeholder={"Texto 2"}
                    disabled={disabled}
                />
            </div>
            <div className="col-12">
                <label htmlFor={"footer"} className="form-label">Texto 3 - Pie de página
                    ({invoice.footer.length}/200)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={invoice.footer}
                    name={"footer"}
                    placeholder={"Texto 3"}
                    disabled={disabled}
                />
            </div>

            <div className="col-12 container-fluid">
                {disabled ? <button className="btn btn-dark" onClick={onEditClick}>
                    Editar
                </button> : null}
                {!disabled ? <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button> : null}
                {!disabled ? <button className="mx-4 btn btn-dark" onClick={onCancelClick}>
                    Cancelar
                </button> : null}
            </div>
        </form>
    </div>
}

export default EditInvoiceTemplateComponent;