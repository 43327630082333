import React from "react";
import {dispatchRedirect} from "../../../reducers/redirectReducer";
import {useDispatch} from "react-redux";

const PaymentErrorScreen = () => {
    const dispatch = useDispatch()

    const onRedirectClick = () => dispatchRedirect(dispatch, "/clinic")

    return <>
        <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                <h6 className="display-5 danger">Ha surgido un error en la pasarela de pago.</h6>
            </div>
        </div>
        <div className="container  my-5">
            <p>Puede volver intentar añadir un método de pago desde la pestaña "home".</p>
            <p>Recuerde:</p>
            <ul className="list-group">
                <li>El primer cobro se realizará el día 1 del mes siguiente al mes de registro.</li>
                <li>Todos los cobros posteriores se realizarán el día 1, en caso de no poder ejecutar el cobro se le
                    enviará un correo recordandole que actualize los métodos de pago de su clínica.
                </li>
                <li>En caso de un cobro fallido se realizará otro intento el día 4 del mismo mes, en caso de no poder
                    ejecutar el cobro se desactivará la clínica. Una clínica desactivada no se podrá utilizar hasta que
                    se ejecute el pago.
                </li>
            </ul>
            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onRedirectClick}>
                    Volver a inicio
                </button>
            </div>
        </div>
    </>
}

export default PaymentErrorScreen