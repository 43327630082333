import React, {useState} from 'react'
import {Link} from "react-router-dom";

import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";


const NavbarReservation = () => {
    const screenSize = useScreenSize()
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const params = useQueryParams()

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const getDynamicLink = (path) => {
        if (params.has('clinic'))
            return `/reservation/${path}?clinic=${params.get('clinic')}`
        else if (params.has('session'))
            return `/reservation/${path}?session=${params.get('session')}`
    }

    if (screenSize.width < 992) {
        return <nav className="py-2 navbar navbar-expand-lg navbar-dark bg-dark">
            <Link className="navbar-brand ms-3" to="/login">
                <img src={require("../../../media/Logo/Icono.png")} height={20} alt={'Logo'}
                     className={"mb-1"}/> MiFisio
            </Link>
            <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#toggle" aria-controls="toggle"
                    aria-expanded={!isNavCollapsed}
                    onClick={handleNavCollapse}>
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="toggle">
                <ul className={`${isNavCollapsed ? '' : 'ps-4'} navbar-nav me-auto mb-2 mb-lg-0`}>
                    <Link className="nav-link" to={getDynamicLink('agenda')}>Agenda</Link>
                    {params.has('session') && <Link className="nav-link" to={getDynamicLink('session')}>Reserva</Link>}
                    <Link className="nav-link" to={getDynamicLink('clinic')}>Clínica</Link>
                    <Link className="nav-link" to={getDynamicLink('about-us')}>Información</Link>
                    <Link className="nav-link" to="/login">Soy un fisio</Link>
                </ul>
            </div>
        </nav>
    }

    return <nav className="px-4 py-2 navbar navbar-expand-lg navbar-dark bg-dark">
        <Link className="navbar-brand ms-3" to="/login">
            <img src={require("../../../media/Logo/Icono.png")} height={20} alt={'Logo'}
                 className={"mb-1"}/> MiFisio
        </Link>
        <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse"
                data-target="#toggle" aria-controls="toggle"
                aria-expanded={!isNavCollapsed}
                onClick={handleNavCollapse}>
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="toggle">
            <ul className={`${isNavCollapsed ? '' : 'ps-4'} navbar-nav me-auto mb-2 mb-lg-0`}>
                <Link className="nav-link" to={getDynamicLink('agenda')}>Agenda</Link>
                {params.has('session') && <Link className="nav-link" to={getDynamicLink('session')}>Reserva</Link>}
                <Link className="nav-link" to={getDynamicLink('clinic')}>Clínica</Link>
                <Link className="nav-link" to={getDynamicLink('about-us')}>Información</Link>
                <Link className="nav-link" to="/login">Soy un fisio</Link>
            </ul>
        </div>
    </nav>
}

export default NavbarReservation;