import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import useLocalStorage from "../../../auxiliar/customHooks/useLocalStorage";
import {cacheAllSessionTypeData} from "../../../actions/sessionTypes";


const SessionTypeSelectorComponent = ({
                                          handleChangeFilter,
                                          value,
                                          showLabel = true,
                                          showSelectOption = false,
                                          deletedSessionType = undefined,
                                          disabled = false
                                      }) => {
    const activeClinic = useSelector(state => state.activeClinic)

    const dispatch = useDispatch()

    const [sessionTypes, setSessionTypes] = useLocalStorage('session_types', undefined)

    useEffect(() => {
        if (sessionTypes === undefined)
            dispatch(cacheAllSessionTypeData({
                clinic_id: activeClinic.id,
                order: "name",
                per_page: 1000,
                page: 1
            }, setSessionTypes))
    }, [activeClinic, dispatch])

    return <>
        {showLabel && <label htmlFor={"session_type_id"} className="form-label">Tipo de sesión *</label>}

        <select className="form-select" onChange={handleChangeFilter} name={"session_type_id"} value={value}
                disabled={disabled}>
            {showSelectOption && <option key={""} value="">Seleccione el tipo de sesión</option>}

            {deletedSessionType && <option
                key={deletedSessionType.session_type_id}
                value={deletedSessionType.session_type_id}
            >
                {deletedSessionType.display_name}
            </option>}


            {sessionTypes && sessionTypes.data && sessionTypes.data.order && sessionTypes.data.order.map((key) => {
                if (sessionTypes.data.content && sessionTypes.data.content[key]) {
                    return <option key={key} value={key}>
                        {sessionTypes.data.content[key].name}
                    </option>
                }
            })}
        </select>
    </>
}

export default SessionTypeSelectorComponent;