import React from "react";
import {NavDropdown} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";

import useLocalStorage from "../../../auxiliar/customHooks/useLocalStorage";
import {dispatchActiveClinicSave} from "../../../reducers/activeClinicReducer";
import {dispatchRedirect} from "../../../reducers/redirectReducer";


const ClinicSelectorDropdown = ({variant = "dark"}) => {
    const activeClinic = useSelector(state => state.activeClinic)
    const clinics = useSelector(state => state.auth.clinics)
    const userType = useSelector(state => state.auth.user_type)

    const dispatch = useDispatch()

    const [filter, setFilter] = useLocalStorage("calendar_filter", {
        clinic_id: activeClinic.id,
        per_page: 2000,
        starting_date: new Date(),
        finishing_date: "",
        view: "week",
        color_code: 'session_type',
        employee_id: ""
    }, true)

    const handleCreateClinic = (e) => {
        e.preventDefault()
        dispatchRedirect(dispatch, "/clinic/register")
    }

    if (clinics === undefined)
        return null

    return <NavDropdown menuVariant={variant} disabled={userType !== "manager"} title={
        <span className={variant === "dark" ? "text-light" : "text-dark"}>
                {activeClinic.name === undefined ? "Selecciona una Clínica" : activeClinic.name}
            </span>
    }>
        {
            Object.keys(clinics).map((key) => {
                const clinic = clinics[key]
                return <NavDropdown.Item key={key} onClick={(e) => {
                    e.preventDefault()
                    setFilter({
                        ...filter,
                        clinic_id: key
                    })

                    dispatchActiveClinicSave(dispatch, key, clinic.name, clinic.status)
                }}>{clinic.name}</NavDropdown.Item>
            })
        }

        {Object.keys(clinics).length === 0 ? null : <NavDropdown.Divider key={"sep"}/>}
        <NavDropdown.Item key={"new"} onClick={handleCreateClinic}>Nueva clínica</NavDropdown.Item>
    </NavDropdown>
}

export default ClinicSelectorDropdown;