import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../../../general/input/InputComponent";
import SessionTypeSelectorComponent from "../../../general/input/SessionTypeSelectorComponent";
import GroupSessionTypeSelectorComponent from "../../../general/input/GroupSessionTypeSelectorComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import {postVoucherData} from "../../../../actions/voucher";
import {validateVoucherData} from "../../../../auxiliar/formValidators/voucherValidators";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";


const RegisterVoucherComponent = () => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        total_sessions: "",
        price: "",
        session_type_id: "",
        group_session_type_id: "",
        name: "",
        clinic_id: activeClinic.id,
        months_validity: "",
        type: "",
        group_indiv: "individual",
    })

    const [errors, setErrors] = useState({})

    const [registerDisabled, setRegisterDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validateVoucherData(data, setErrors))
            dispatch(postVoucherData(data, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    return <div className="container my-5">
        <h2 className={'mb-3'}>Registro de un nuevo tipo de bono</h2>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-12">
                <select className="form-select" onChange={handleChange} name={"group_indiv"} value={data.group_indiv}>
                    <option key={"individual"} value={"individual"}>Bono para sesión individual</option>
                    <option key={"group"} value={"group"}>Bono para sesión grupal</option>
                </select>
            </div>

            <div className="col-6">
                <InputComponent
                    label={"Nombre *"}
                    onChange={handleChange}
                    value={data.name}
                    type={"text"}
                    name={"name"}
                    placeholder={"Nombre"}
                />
            </div>
            {data.group_indiv === "individual" && <div className="col-6">
                <SessionTypeSelectorComponent
                    handleChangeFilter={handleChange}
                    value={data.session_type_id}
                    showSelectOption={true}
                />
            </div>}
            {data.group_indiv === "group" && <div className="col-6">
                <GroupSessionTypeSelectorComponent
                    handleChangeFilter={handleChange}
                    value={data.group_session_type_id}
                    showSelectOption={true}
                />
            </div>}
            <div className="col-6">
                <InputComponent
                    label={"Sesiones totales *"}
                    onChange={handleChange}
                    value={data.total_sessions}
                    type={"number"}
                    name={"total_sessions"}
                    placeholder={"Número total de sesiones"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Precio *"}
                    onChange={handleChange}
                    value={data.price}
                    type={"number"}
                    name={"price"}
                    placeholder={"Precio base del bono"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Meses de validez *"}
                    onChange={handleChange}
                    value={data.months_validity}
                    type={"number"}
                    name={"months_validity"}
                    placeholder={"Numero de meses de validez"}
                />
            </div>
            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onRegisterClick} disabled={registerDisabled}>
                    Confirmar
                </button>
            </div>
        </form>
    </div>
}

export default RegisterVoucherComponent;