import React from "react";


const PaymentPlanComponent = ({payment_plan}) => {
    if (!payment_plan)
        return null

    return <div className="m-auto text-center">
        {payment_plan.type === "full" && <h2>Plan Completo</h2>}
        {payment_plan.type === "discount" && <h2>Plan Colegiado</h2>}
        {payment_plan.type === "fisiocampus" && <h2>Plan FisioCampus</h2>}
        {payment_plan.type === "acef" && <h2>Plan ACEF</h2>}

        <p>
            Disfruta de todos los beneficios de MiFisio Agenda. Por solo:
        </p>
        <div className={"mb-2"}>
            <span className={"display-4"}>
                {payment_plan.type === 'full' && <b>24€/mes</b>}
                {['discount', 'acef'].includes(payment_plan.type) && <b>18€/mes</b>}
                {payment_plan.type === 'fisiocampus' && <b>14,40€/mes</b>}
            </span>
        </div>
        <p>
            Además, gracias a tus referidos dispones de {payment_plan.free_months} {payment_plan.free_months === 1 ? "mes gratuito" : "meses gratuitos"}.
        </p>
    </div>
}

export default PaymentPlanComponent;