import axios from "axios";

export const getAllGroupSessionsData = (params, setData, setPagination, setOrder) => {
    return () => {
        axios.get(`/api/managers/group_sessions/`, {params: params})
            .then(response => {
                const data = response.data

                setData(data.content)
                setPagination(data.pagination)
                setOrder(data.order)
            })
    }
}

export const getGroupSessionData = (sessionId, data, setData) => {
    return () => {
        axios.get(`/api/managers/group_sessions/${sessionId}`)
            .then(response => {
                if (data.finish_datetime && data.start_datetime) {
                    response.data.content.finish_datetime = data.finish_datetime
                    response.data.content.start_datetime = data.start_datetime
                }
                setData(response.data.content)
            })
    }
}

export const postGroupSessionsData = (data, closeTooltip, setRegisterDisabled) => {
    return () => {
        axios.post('/api/managers/group_sessions/', data)
            .then(() => {
                closeTooltip()
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}

export const putGroupSessionData = (sessionId, data, closeTooltip, setUpdateDisabled) => {
    return () => {
        axios.put(`/api/managers/group_sessions/${sessionId}`, data)
            .then(() => {
                closeTooltip()
                setUpdateDisabled()
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}

export const deleteGroupSession = (sessionId, closeTooltip, setUpdateDisabled, deleteRecurrent = false) => {
    return () => {
        axios.delete(`/api/managers/group_sessions/${sessionId}?deleteRecurrent=${deleteRecurrent}`)
            .then(() => {
                closeTooltip()
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}

export const getEditGroupSessionData = (sessionId, params, dates, setEventData, setPatientData, setPatientsPagination,
                                        setPatientOrder, setDeletedEntities, setFirstLoad) => {
    return () => {
        axios.get(`/api/managers/group_sessions/edit_group_session/${sessionId}`, {params: params})
            .then(response => {
                const data = response.data.content

                setPatientData(data.patients.content)
                setPatientsPagination(data.patients.pagination)
                setPatientOrder(data.patients.order)
                setDeletedEntities(data.deleted_entities)

                setEventData({
                    ...data.group_session.content,
                    finish_datetime: dates.finish_datetime ? dates.finish_datetime : data.group_session.content.finish_datetime,
                    start_datetime: dates.start_datetime ? dates.start_datetime : data.group_session.content.start_datetime,
                })

                setFirstLoad(false)
            })
    }
}