import axios from "axios";
import {dispatchRedirect} from "../reducers/redirectReducer";


export const getInvoiceData = (invoiceId, setData) => {
    return () => {
        axios.get(`/api/managers/invoices/${invoiceId}`)
            .then(response => {
                setData(response.data.content)
            })
    }
}


export const postInvoice = (data) => {
    return (dispatch) => {
        axios.post('/api/managers/invoices/', data)
            .then((response) => {
                dispatchRedirect(dispatch, `/invoice/${response.data.content.invoice_id}`)
            })
    }
}


export const downloadInvoiceExample = (clinicId, setDownloadDisabled) => {
    return () => {
        axios.get(`/api/managers/invoices/example_template/${clinicId}`, {responseType: 'blob'})
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', response.headers['x-filename']);
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                setDownloadDisabled(false)
            })
    }
}

export const downloadInvoice = (invoiceId) => {
    return () => {
        axios.get(`/api/managers/invoices/download_invoice/${invoiceId}`, {responseType: 'blob'})
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', response.headers['x-filename']);
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
    }
}

export const sendInvoiceEmail = (invoiceId) => {
    return () => {
        axios.get(`/api/managers/invoices/send_invoice_email/${invoiceId}`)
            .then(() => {})
    }
}