import React from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../general/input/InputComponent";
import {dispatchRedirect} from "../../reducers/redirectReducer";
import {checkExistsField} from "../../auxiliar/formValidators/auxValidators";
import {postInvoice} from "../../actions/invoice";


const ShowCashRegisterComponent = ({data, redirectButtonDisplay = false}) => {
    const activeClinic = useSelector(state => state.activeClinic)

    const dispatch = useDispatch()

    if (!data)
        return null

    return <div className="container my-5">
        <div className="row">
            <div className="col-6">
                <h2>Datos del albarán</h2>
            </div>

            {
                !redirectButtonDisplay && <div className="col-6 container-fluid">
                    {checkExistsField(data, "invoice_id") ?
                        <button type="button" className="btn btn-dark float-end btn-sm" onClick={(e) => {
                            e.preventDefault()
                            dispatchRedirect(dispatch, `/invoice/${data.invoice_id}`)
                        }}>
                            Ver factura
                        </button> : <button
                            type="button"
                            className="btn btn-dark float-end"
                            disabled={!data.is_invoice_possible}
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch(postInvoice({
                                    cash_register_id: data.cash_register_id,
                                    clinic_id: activeClinic.id
                                }))
                            }}>
                            Crear factura
                        </button>}
                </div>}
        </div>

        <form className="row g-3">
            <div className="col-3">
                <InputComponent
                    label={"Precio inicial"}
                    value={data.initial_price}
                    type={"number"}
                    name={"initial_price"}
                    disabled={true}
                />
            </div>
            <div className="col-3">
                <InputComponent
                    label={"Descuento"}
                    value={data.discount}
                    type={"number"}
                    name={"discount"}
                    placeholder={"%"}
                    disabled={true}
                />
            </div>
            <div className="col-3">
                <InputComponent
                    label={"Precio final"}
                    value={data.final_price}
                    type={"number"}
                    name={"final_price"}
                    disabled={true}
                />
            </div>
            <div className="col-3">
                <InputComponent
                    label={"Por pagar"}
                    value={data.unpaid}
                    type={"number"}
                    name={"unpaid"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha emisión"}
                    value={data.issue_date}
                    type={"text"}
                    name={"issue_date"}
                    disabled={true}
                />
            </div>
            {redirectButtonDisplay && <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={(e) => {
                    e.preventDefault()
                    dispatchRedirect(dispatch, `/cash-register/${data.cash_register_id}`)
                }}>
                    Detalles del albarán
                </button>
            </div>}
        </form>
    </div>
}

export default ShowCashRegisterComponent;