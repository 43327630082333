import React from "react";

import InputComponent from "../../../general/input/InputComponent";
import SessionTypeSelectorComponent from "../../../general/input/SessionTypeSelectorComponent";
import GroupSessionTypeSelectorComponent from "../../../general/input/GroupSessionTypeSelectorComponent";
import {checkExistsField} from "../../../../auxiliar/formValidators/auxValidators";


const ShowVoucherComponent = ({data}) => {
    if (!checkExistsField(data, 'session_type_id') && !checkExistsField(data, 'group_session_type_id'))
        return null

    return <div className={"my-4"}>
        <h2>Datos del tipo de bono {data.name}</h2>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Nombre"}
                    value={data.name}
                    type={"text"}
                    name={"name"}
                    placeholder={"Nombre"}
                    disabled={true}
                />
            </div>
            {checkExistsField(data, 'session_type_id') && <div className="col-6">
                <SessionTypeSelectorComponent
                    value={data.session_type_id}
                    disabled={true}
                    deletedSessionType={data.deleted_session_type}
                />
            </div>}
            {checkExistsField(data, 'group_session_type_id') && <div className="col-6">
                <GroupSessionTypeSelectorComponent
                    value={data.group_session_type_id}
                    disabled={true}
                    deletedGroupSessionType={data.deleted_group_session_type}
                />
            </div>}
            <div className="col-6">
                <InputComponent
                    label={"Precio base"}
                    value={data.price}
                    type={"number"}
                    name={"price"}
                    placeholder={"Precio base del bono"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Meses de validez"}
                    value={data.months_validity}
                    type={"number"}
                    name={"months_validity"}
                    placeholder={"Numero de meses de validez"}
                    disabled={true}
                />
            </div>
        </form>
    </div>
}

export default ShowVoucherComponent;