import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import NotFoundScreen from "../../public/NotFoundScreen";
import EditSessionPopup from "../../../components/services/session/popupEditSession/EditSessionPopup";
import ShowCashRegisterComponent from "../../../components/cashRegister/ShowCashRegisterComponent";
import ListChargeComponent from "../../../components/charge/ListChargeComponent";
import ShowPatientComponent from "../../../components/patient/ShowPatientComponent";
import ShowSessionComponent from "../../../components/services/session/session/ShowSessionComponent";
import ShowGroupSessionComponent from "../../../components/services/session/groupSession/ShowGroupSessionComponent";
import ShowAcquiredVoucherComponent
    from "../../../components/services/voucher/acquiredVoucher/ShowAcquiredVoucherComponent";
import {getCashRegisterDetails} from "../../../actions/cashRegister";
import {checkExistsField} from "../../../auxiliar/formValidators/auxValidators";


const CashRegisterDataScreen = () => {
    const openTooltipEdit = useRef(null)

    const dispatch = useDispatch()
    const activeClinic = useSelector(state => state.activeClinic)
    const params = useParams()

    const [cashRegisterData, setCashRegisterData] = useState({})

    const [update, setUpdate] = useState(false)

    useEffect(() => {
        if (checkExistsField(params, 'cash_register_id'))
            dispatch(getCashRegisterDetails(params.cash_register_id, {'clinic_id': activeClinic.id}, setCashRegisterData))
    }, [update])

    const updateComponent = () => setUpdate(!update)

    if (!checkExistsField(params, 'cash_register_id') || !cashRegisterData || Object.keys(cashRegisterData).length === 0)
        return <NotFoundScreen version={'cash_register'}/>

    return <div className="container my-5">
        <ShowCashRegisterComponent
            data={cashRegisterData.cash_register}
        />

        <ListChargeComponent
            charges={cashRegisterData.charges.content}
            cashRegisterData={cashRegisterData.cash_register}
            updateComponent={updateComponent}
        />

        <ShowPatientComponent patient={cashRegisterData.patient.content}/>

        {cashRegisterData.cash_register.type === "session" &&
            <ShowSessionComponent
                session={cashRegisterData.session.content}
                openTooltipEdit={openTooltipEdit}
            />}
        {cashRegisterData.cash_register.type === "group_session" &&
            <ShowGroupSessionComponent
                groupSession={cashRegisterData.group_session.content}
                openTooltipEdit={openTooltipEdit}
            />}
        {cashRegisterData.cash_register.type === "acquired_voucher" &&
            <ShowAcquiredVoucherComponent
                acquiredVoucher={cashRegisterData.acquired_voucher.content}
            />}

        <EditSessionPopup
            childFunc={openTooltipEdit}
            updateComponent={updateComponent}
        />
    </div>

}

export default CashRegisterDataScreen;