import React from "react";

import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";


const ListSessionsInAcquiredVoucherComponent = ({data, order, pagination, filter, setFilter}) => {
    return <div className={"container my-5"}>
        <h2>Sesiones</h2>

        {(!data || Object.keys(data).length <= 0) ?
            <h4 className={"mb-3"}>El bono no se ha utilizado en ninguna sesión</h4> :
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Paciente</th>
                        <th>Fecha</th>
                        <th>Entrada</th>
                        <th>Salida</th>
                        <th>Fisioterapeuta</th>
                        <th>Tipo</th>
                    </tr>
                    </thead>

                    <tbody>
                    {order && order.map((id) => {
                        if (data && data[id] !== undefined) {
                            const session = data[id]
                            return <tr key={id} value={id}>
                                <td>{session.patient_name}</td>
                                <td>{session.start_datetime.substring(0, 10)}</td>
                                <td>{session.start_datetime.substring(11, 16)}</td>
                                <td>{session.finish_datetime.substring(11, 16)}</td>
                                <td>{session.physio_name}</td>
                                <td>{session.type}</td>
                            </tr>
                        }
                    })}
                    </tbody>

                </table>
            </div>}

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
        />
    </div>
}

export default ListSessionsInAcquiredVoucherComponent;