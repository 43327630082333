import {checkExistsField, checkValidMail, checkValidPhoneNumber} from "./auxValidators";

export const validateManagerPassword = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "password") || !checkExistsField(data, "password_rep"))
        errors['password'] = "La contraseña y la contraseña de confirmación son obligatorios"
    else if (data.password.trim().length < 8)
        errors['password'] = "La contraseña debe tener al menos 8 caracteres"
    else if (data.password.trim() !== data.password_rep.trim())
        errors['password'] = "La contraseña y la contraseña de confirmación deben ser iguales"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}

export const validateManagerData = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "name"))
        errors['name'] = "El nombre es obligatorio"
    else if(data.name.trim().length < 3)
        errors['name'] = "El nombre es demasiado corto"

    if (!checkExistsField(data, "surnames"))
        errors['surnames'] = "Los apellidos son obligatorios"
    else if (data.surnames.trim().length < 3)
        errors['surnames'] = "Los apellidos son demasiado cortos"

    if (!checkExistsField(data, "email"))
        errors['email'] = "El correo es obligatorio"
    else if (!checkValidMail(data.email))
        errors['email'] = "El correo introducido no es válido"

    if (!checkExistsField(data, "phone_number"))
        errors['phone_number'] = "El número de teléfono es obligatorio"
    else if (!checkValidPhoneNumber(data.phone_number))
        errors['phone_number'] = "El número de teléfono introducido no es válido"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}