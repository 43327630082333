import React from 'react'
import {Link} from "react-router-dom";
import logo from "../../media/Logo/Logo_mediano.png";


const PricingScreen = () => {

    return (
        <div className="container mt-5" style={{textAlign: "justify"}}>
            <div>
                <h2>Nuestra propuesta</h2>

                <p>
                    MiFisio Agenda es un programa de gestión especializado en clínicas de fisioterapia que nace para
                    facilitar la vida a los fisioterapeutas españoles. El software es sencillo, intuitivo y
                    asequible para todos, e incluye funcionalidades que facilitan el día a día de las clínicas, la
                    importación de los datos y el salto al uso de nuevas tecnologías en cualquier entorno. Para obtener
                    más información sobre nosotros haz click <Link to="/about-us">aquí</Link>.
                </p>


                <div className="col-md-8 offset-md-2 my-4">
                    <div className="bg-light mt-4 p-4 border border-dark rounded text-center">
                        <div className="text-center container">
                            <img src={logo} alt={'Logo'} height={"200px"}/>
                        </div>

                        <div className={"h4 mx-5"}>
                            Empieza a usar ahora el primer programa de gestión hecho por fisios y para fisios.
                        </div>

                        <div className={"h6"}>
                            Pruébalo gratis hasta el día 1 del próximo mes sin compromiso, y después disponible por solo
                        </div>

                        <div className={"mb-2"}>
                            <span className={"display-4"}>
                                <b>24€/mes</b>
                            </span>
                        </div>

                        <div className={"h6"}>
                            <p>
                                Sin ningún tipo de letra pequeña.
                            </p>

                            <p>¿A qué estás esperando?</p>

                            <p><Link to="/register">¡Regístrate ya!</Link></p>
                        </div>
                    </div>

                </div>
            </div>
            <div className={"my-5"}>

                <h2>Funcionalidades</h2>

                <div>
                    <p>
                        Nuestro equipo ha tratado de solucionar los problemas que surgen en el día a día en una clínica
                        de fisioterapia, y para ello incluye las siguientes funcionalidades:

                        <ul>
                            <li>
                                Gestiona de forma centralizada todas tus clínicas.
                            </li>
                            <li>
                                Almacena la información de los fisioterapeutas, pacientes y servicios ofrecidos.
                            </li>
                            <li>
                                Ofrece a tus clientes la posibilidad de reservar citas online.
                            </li>
                            <li>
                                Gestiona la agenda de una forma rápida e intuitiva.
                            </li>
                            <li>
                                Almacena y gestiona la información de pago de tus clientes y genera facturas en un
                                click.
                            </li>
                            <li>
                                Envía recordatorios por WhatsApp o por correo.
                            </li>

                        </ul>
                    </p>

                    <p>
                        Si quieres disfrutar de todas estás funcionalidades y muchas otras <Link to="/register">regístrate
                        ya</Link>. Si necesitas más información, tienes dudas o sugerencias de posibles mejoras puedes
                        contactar con nosotros mandando un correo a <a
                        href={"mailto:info@mifisioagenda.com"}>info@mifisioagenda.com</a>.
                    </p>
                </div>

            </div>

        </div>
    );
}

export default PricingScreen;