import React, {useState} from "react";
import {useDispatch} from "react-redux";

import PhysiotherapistSelectorComponent from "../../../general/input/PhysiotherapistSelectorComponent";
import InputComponent from "../../../general/input/InputComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import useScreenSize from "../../../../auxiliar/customHooks/useScreenSize";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateCalendarTimespan} from "../../../../auxiliar/formValidators/calendarTimespanValidator";
import {deleteCalendarTimespan, putCalendarTimespan} from "../../../../actions/calendarTimespan";
import {checkExistsField} from "../../../../auxiliar/formValidators/auxValidators";


const EditBlockedHoursComponent = ({closeTooltip, eventData, loadNewCalendarTimespan}) => {
    const dispatch = useDispatch();
    const screenSize = useScreenSize()

    const [data, setData] = useState(eventData)

    const [errors, setErrors] = useState({})

    const [updateDisabled, setUpdateDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onNextSessionClick = (e) => {
        e.preventDefault()
        loadNewCalendarTimespan(data.next_calendar_timespan_id)
    }

    const onPreviousSessionClick = (e) => {
        e.preventDefault()
        loadNewCalendarTimespan(data.previous_calendar_timespan_id)
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validateCalendarTimespan(data, setErrors))
            dispatch(putCalendarTimespan(data.calendar_timespan_id, {new_info: data}, closeTooltip, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    const onCloseClick = (e) => {
        e.preventDefault()
        closeTooltip()
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar las horas bloqueadas? No se podrá recuperar la información'))
            dispatch(deleteCalendarTimespan(data.calendar_timespan_id, closeTooltip, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    const onDeleteAllClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar está sesión y las sesiones asociadas siguientes? No se podrá recuperar la información'))
            dispatch(deleteCalendarTimespan(data.calendar_timespan_id, closeTooltip, setUpdateDisabled, true))
        else
            setUpdateDisabled(false)
    }

    return <div>
        <div className={"row"}>
            <div className={"col-6"}>
                <h2>Editar horas bloqueadas</h2>
            </div>
            <div className={"col-6 mt-n5"}>
                {checkExistsField(data, 'next_calendar_timespan_id') &&
                    <button className={"btn btn-dark btn-sm float-end mx-2"} onClick={onNextSessionClick}>
                        {screenSize.width > 992 ? "Siguiente" : ">"}
                    </button>}
                {checkExistsField(data, 'previous_calendar_timespan_id') &&
                    <button className={"btn btn-dark btn-sm float-end mx-2"} onClick={onPreviousSessionClick}>
                        {screenSize.width > 992 ? "Anterior" : "<"}
                    </button>}
            </div>
        </div>

        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-12">
                <InputComponent
                    label={"Título *"}
                    onChange={handleChange}
                    value={data.name}
                    type={"text"}
                    name={"name"}
                    placeholder={"Ausencia"}
                />
            </div>
            <div className="col-12">
                <PhysiotherapistSelectorComponent
                    handleChangeFilter={handleChange}
                    value={data.employee_id}
                    showAllOption={false}
                    showSelectOption={true}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha de inicio *"}
                    onChange={handleChange}
                    value={data.start_datetime}
                    type={"datetime-local"}
                    name={"start_datetime"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha de fin *"}
                    onChange={handleChange}
                    value={data.finish_datetime}
                    type={"datetime-local"}
                    name={"finish_datetime"}
                />
            </div>
            <div className="col-12">
                <label htmlFor={"description"} className="form-label">Descripcion
                    ({data.description.length}/200)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={data.description}
                    name={"description"}
                    placeholder={"Descripción"}
                />
            </div>

            <div className="col-12 container-fluid">
                <button type="submit" className="btn btn-dark me-3" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button>
                <button className="btn btn-dark me-3" onClick={onCloseClick} disabled={updateDisabled}>
                    Cerrar
                </button>
                <button type="submit" className="btn btn-danger float-end" onClick={onDeleteClick}
                        disabled={updateDisabled}>
                    Eliminar
                </button>
                {checkExistsField(data, 'next_calendar_timespan_id') &&
                    <button type="submit" className="btn btn-danger float-end mx-3"
                            onClick={onDeleteAllClick} disabled={updateDisabled}>
                        Eliminar posteriores
                    </button>}
            </div>
        </form>
    </div>
}

export default EditBlockedHoursComponent;