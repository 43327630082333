import React, {useState} from 'react';
import {useDispatch} from "react-redux";

import MessageSpan from "../general/auxiliar/message/MessageSpan";
import InputComponent from "../general/input/InputComponent";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";
import {validateManagerPassword} from "../../auxiliar/formValidators/managerValidators";
import {deleteClinicProfile, putClinicData} from "../../actions/clinics";


const ClinicPasswordComponent = ({existingProfile = false, remount, clinicId}) => {
    const dispatch = useDispatch()

    const [data, setData] = useState({
        password: "",
        new_info_password: "",
        new_info_password_rep: ""
    })

    const [errors, setErrors] = useState({})

    const [updateDisabled, setUpdateDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onRequestSuccess = (request) => {
        remount()
        setUpdateDisabled(false)

        if (request === 'delete')
            alert("El perfil de la clínica ha sido eliminado con éxito")
        else
            alert("La contraseña de la clínica ha sido actualizado con éxito")
    }

    const onRequestFail = () => setUpdateDisabled(false)

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere el perfil de la clínica? No podrá iniciar sesión en el perfil ' +
            'de cliínica hasta que no establezcas una nueva contraseña'))
            dispatch(deleteClinicProfile(clinicId, onRequestSuccess, onRequestFail))
        else
            setUpdateDisabled(false)
    }

    const onUpdatePasswordClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        const req_data = {
            password: data.password,
            new_info: {
                password: data.new_info_password,
                password_rep: data.new_info_password_rep
            }
        }
        if (validateManagerPassword(req_data.new_info, setErrors)) {
            dispatch(putClinicData(clinicId, req_data, onRequestSuccess, onRequestFail))
            setData({
                password: "",
                new_info_password: "",
                new_info_password_rep: ""
            })
        } else
            setUpdateDisabled(false)
    }

    return <div className="my-5">
        <h2>Perfil de clínica</h2>

        <p className={"mt-0 mb-2"}>
            Puedes crear un perfil para la clínica con permisos restringidos. Desde este perfil no se puede acceder a la
            información general del mánager ni a la información de otras clínicas. Tampoco se puede exportar información
            ni acceder a la facturación completa.
        </p>

        {existingProfile ? <p className={"mt-0 mb-2"}>
            Esta clínica ya tiene un perfil registrado. Puedes cambiar la contraseña o eliminarlo. Eliminar este perfil
            no afectará a los datos, pero no se podrá volver iniciar sesión con este perfil.
        </p> : <p className={"mt-0 mb-2"}>
            Esta clínica no tiene un perfil registrado. Para crearlo puedes guardar una contraseña, que se asociará
            con el correo electronico de la clínica.
        </p>}


        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-12">
                <InputComponent
                    label={"Contraseña actual"}
                    onChange={handleChange}
                    value={data.password}
                    type={"password"}
                    name={"password"}
                    placeholder={"Contraseña"}
                    disabled={!existingProfile}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Nueva contraseña"}
                    onChange={handleChange}
                    value={data.new_info_password}
                    type={"password"}
                    name={"new_info_password"}
                    placeholder={"Contraseña"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Repita la nueva contraseña"}
                    onChange={handleChange}
                    value={data.new_info_password_rep}
                    type={"password"}
                    name={"new_info_password_rep"}
                    placeholder={"Contraseña"}
                />
            </div>
            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onUpdatePasswordClick} disabled={updateDisabled}>
                    Confirmar
                </button>
                {existingProfile &&
                    <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                        Eliminar
                    </button>
                }
            </div>
        </form>
    </div>
}

export default ClinicPasswordComponent;