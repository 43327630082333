import React, {useState} from "react";
import {useDispatch} from "react-redux";

import MessageSpan from "../../../../general/auxiliar/message/MessageSpan";
import InputComponent from "../../../../general/input/InputComponent";
import {deleteAcquiredVoucher, putAcquiredVoucherData} from "../../../../../actions/acquiredVoucher";
import {handleChangeAux} from "../../../../../auxiliar/handleChange/handleChangeAux";
import {validateAcquiredVoucherDate} from "../../../../../auxiliar/formValidators/acquiredVoucherValidators";
import {dispatchRedirect} from "../../../../../reducers/redirectReducer";


const EditAcquiredVoucherComponent = ({data, setData, closeTooltip}) => {
    const dispatch = useDispatch();

    const [originalData, setOriginalData] = useState({})

    const [errors, setErrors] = useState({})

    const [updateDisabled, setUpdateDisabled] = useState(false)

    const [editBool, setEditBool] = useState(false)

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const auxClose = () => closeTooltip ? closeTooltip() : setEditBool(false)

    const auxDelete = () => closeTooltip ? closeTooltip() : dispatchRedirect(dispatch, "/patient")

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validateAcquiredVoucherDate(data, setErrors))
            dispatch(putAcquiredVoucherData(data.acquired_voucher_id, {new_info: data}, auxClose,
                setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    const onEditClick = (e) => {
        e.preventDefault()

        setOriginalData(data)
        setEditBool(true)
    }

    const onCancelClick = (e) => {
        e.preventDefault()

        setData(originalData)
        setErrors({})
        auxClose()
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar el bono? No se podrá recuperar la información'))
            dispatch(deleteAcquiredVoucher(data.acquired_voucher_id, auxDelete, setUpdateDisabled))
    }

    if (!data || Object.keys(data).length === 0)
        return null

    return <div className={`${closeTooltip ? "container my-5" : "my-3"}`}>
        {closeTooltip ? <h2>Editar bono adquirido</h2> : <h4>Editar bono adquirido</h4>}
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Sesiones restantes"}
                    value={data.remaining_sessions}
                    type={"text"}
                    name={"remaining_sessions"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <label htmlFor={"due_date"} className="form-label">{"Fecha de caducidad*"}</label>
                <input
                    onChange={handleChange}
                    value={data.due_date}
                    type={"date"}
                    id={"due_date"}
                    name={"due_date"}
                    className="form-control"
                    disabled={!editBool && !closeTooltip}
                />
            </div>
            <div className="col-12 container-fluid">
                {(editBool || closeTooltip) &&
                    <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                        Guardar
                    </button>}
                {(editBool || closeTooltip) &&
                    <button className="btn btn-dark mx-3" onClick={onCancelClick} disabled={updateDisabled}>
                        Cancelar
                    </button>}
                {((editBool || closeTooltip) && data.deletable) &&
                    <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                        Eliminar
                    </button>}
                {(!editBool && !closeTooltip) &&
                    <button className="btn btn-dark" onClick={onEditClick} disabled={updateDisabled}>
                        Editar
                    </button>}
            </div>
        </form>
    </div>
}

export default EditAcquiredVoucherComponent;