import React from "react";

import EditGroupSessionTypeComponent from "./EditGroupSessionTypeComponent";
import ListGroupSessionTypeComponent from "./ListGroupSessionTypeComponent";


const GeneralGroupSessionTypeComponent = ({
                                              groupSessionTypes,
                                              filter,
                                              setFilter,
                                              order,
                                              pagination,
                                              selectedGroupSessionId,
                                              setSelectedGroupSessionId,
                                              resetFilter
                                          }) => {
    return <>
        <ListGroupSessionTypeComponent
            groupSessionTypes={groupSessionTypes}
            filter={filter}
            setFilter={setFilter}
            order={order}
            pagination={pagination}
            setSelectedGroupSessionId={setSelectedGroupSessionId}
            selectedGroupSessionId={selectedGroupSessionId}
        />

        {selectedGroupSessionId && <EditGroupSessionTypeComponent
            setSelectedGroupSessionId={setSelectedGroupSessionId}
            groupSessionType={groupSessionTypes[selectedGroupSessionId]}
            resetFilter={resetFilter}
        />}
    </>
}

export default GeneralGroupSessionTypeComponent;