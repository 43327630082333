import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import useLocalStorage from "../../../auxiliar/customHooks/useLocalStorage";
import {cacheAllPhysiotherapistData} from "../../../actions/physiotherapist";


const PhysiotherapistSelectorComponent = ({
                                              handleChangeFilter,
                                              value,
                                              showAllOption = true,
                                              showLabel = true,
                                              showSelectOption = false,
                                              deletedPhysio = undefined,
                                              disabled = false
                                          }) => {
    const activeClinic = useSelector(state => state.activeClinic)

    const dispatch = useDispatch()

    const [physiotherapists, setPhysiotherapists] = useLocalStorage('physiotherapists', undefined)

    useEffect(() => {
        if (physiotherapists === undefined)
            dispatch(cacheAllPhysiotherapistData({
                clinic_id: activeClinic.id,
                order: "name",
                per_page: 1000,
                page: 1
            }, setPhysiotherapists))
    }, [activeClinic, dispatch])

    return <>
        {showLabel && <label htmlFor={"employee_id"} className="form-label">Fisioterapeuta *</label>}

        <select className="form-select" onChange={handleChangeFilter} name={"employee_id"} value={value}
                disabled={disabled}>
            {showAllOption && <option key={""} value="">Todos</option>}

            {showSelectOption && <option key={""} value="">Seleccione fisioterapeuta</option>}

            {deletedPhysio &&
                <option key={deletedPhysio.employee_id} value={deletedPhysio.employee_id}>
                    {deletedPhysio.display_name}
                </option>}

            {physiotherapists && physiotherapists.data && physiotherapists.data.order && physiotherapists.data.order.map((key) => {
                if (physiotherapists.data.content && physiotherapists.data.content[key])
                    return <option key={key} value={key}>
                        {physiotherapists.data.content[key].name} {physiotherapists.data.content[key].surnames}
                    </option>
            })}
        </select>
    </>
}

export default PhysiotherapistSelectorComponent;