import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import InputComponent from "../general/input/InputComponent";
import {getPatientData} from "../../actions/patient";


const ShowPatientComponent = ({patientId, patient}) => {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        patient_id: "",
        name: "",
        surnames: "",
        email: "",
        phone_number: "",
        reminders: false,
        address: "",
        birth_date: ""
    })

    useEffect(() => {
        if (patient !== undefined)
            setData(patient)
        else
            dispatch(getPatientData(patientId, setData))
    }, [patientId, dispatch, setData])

    if (!data)
        return <div className="container my-5">
            <h2>Datos del paciente no disponibles</h2>
            <p>El perfil del paciente ha sido eliminado de la base de datos</p>
        </div>

    return <div className="container my-5">
        <h2>Datos del paciente</h2>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Nombre"}
                    value={data.name}
                    type={"text"}
                    name={"name"}
                    placeholder={"Nombre"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Apellidos"}
                    value={data.surnames}
                    type={"text"}
                    name={"surnames"}
                    placeholder={"Apellidos"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Correo"}
                    value={data.email}
                    type={"text"}
                    name={"email"}
                    placeholder={"Correo"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Teléfono"}
                    value={data.phone_number}
                    type={"tel"}
                    name={"phone_number"}
                    placeholder={"Teléfono"}
                    disabled={true}
                />
            </div>
        </form>
    </div>
}

export default ShowPatientComponent;