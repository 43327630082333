import {checkExistsField, checkValidMail, checkValidPhoneNumber} from "./auxValidators";

export const validateClinicData = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "name"))
        errors['name'] = "El nombre de la clínica es obligatorio"
    else if(data.name.trim().length < 3)
        errors['name'] = "El nombre es demasiado corto"

    if (!checkExistsField(data, "email"))
        errors['email'] = "El correo es obligatorio"
    else if (!checkValidMail(data.email))
        errors['email'] = "El correo introducido no es válido"

    if (checkExistsField(data, "mobile_phone") && !checkValidPhoneNumber(data.mobile_phone)) // not compulsory
        errors['mobile_phone'] = "El número de teléfono móvil no es válido"

    if (checkExistsField(data, "landline_phone") && !checkValidPhoneNumber(data.landline_phone)) // not compulsory
        errors['landline_phone'] = "El número de teléfono fijo no es válido"

    if (checkExistsField(data, "address") && data.address.trim().length < 4) // not compulsory
        errors['address'] = "La dirección es demasiado corta"

    if (checkExistsField(data, "province") && data.province.trim().length < 3) // not compulsory
        errors['province'] = "El nombre de la provincia es demasiado corto"

    if (checkExistsField(data, "town") && data.town.trim().length < 3) // not compulsory
        errors['town'] = "El nombre de la localidad es demasiado corto"

    if(checkExistsField(data, "CIF") && data.CIF.trim().length !== 9) // not compulsory
        errors['CIF'] = "CIF incorrecto"

    if (!checkExistsField(data, "invoice_code"))
        errors['invoice_code'] = "El código de factura es obligatorio"
    else if (data.invoice_code.trim().length < 3 || data.invoice_code.trim().length > 5)
        errors['invoice_code'] = "El código de factura debe tener entre 3 y 5 caracteres"

    if (!checkExistsField(data, "invoice_number"))
        errors['invoice_number'] = "El número inicial de factura es obligatorio"
    if (parseInt(data.invoice_number) < 0)
        errors['invoice_number'] = "El número inicial de factura debe ser mayor o igual que 0"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}