import axios from "axios";
import {dispatchRedirect} from "../reducers/redirectReducer";


export const postTaskData = (data, patientData, setRegisterDisabled) => {
    let req_data = JSON.parse(JSON.stringify(data))
    if (data.associated_patient && req_data.patient_id === "new")
        req_data.new_patient_info = patientData

    return (dispatch) => {
        axios.post('/api/managers/tasks/', req_data)
            .then(() => {
                dispatchRedirect(dispatch, "/task")
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}


export const getAllTaskData = (params, setData, setOrder, setPagination) => {
    return () => {
        axios.get(`/api/managers/tasks/`, {params: params})
            .then(response => {
                setData(response.data.content)
                setOrder(response.data.order)
                setPagination(response.data.pagination)
            })
    }
}


export const putTaskData = (taskId, data, onSuccessRequest, onErrorRequest) => {
    return () => {
        axios.put(`/api/managers/tasks/${taskId}`, data)
            .then(() => {
                onSuccessRequest()
            }).catch(() => {
                onErrorRequest()
            }
        )
    }
}


export const deleteTask = (taskId, onSuccessRequest, onErrorRequest) => {
    return () => {
        axios.delete(`/api/managers/tasks/${taskId}`,)
            .then(() => {
                onSuccessRequest()
            }).catch(() => {
                onErrorRequest()
            }
        )
    }
}