import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../general/input/InputComponent";
import TablePatientAux from "./TablePatientAux";
import {handleChangeFilterAux} from "../../auxiliar/handleChange/handleChangeFilterAux";
import {getAllPatientData} from "../../actions/patient";


const SelectPatientForServiceComponent = ({filter, setFilter, onClientSelect}) => {
    const dispatch = useDispatch()
    const activeClinic = useSelector(state => state.activeClinic)

    const [patients, setPatients] = useState({})
    const [patientsOrder, setPatientsOrder] = useState([])
    const [newPatient, setNewPatient] = useState(false)

    useEffect(() => {
        if (!newPatient)
            dispatch(getAllPatientData(filter, setPatients, () => {}, setPatientsOrder))
    }, [filter, newPatient])

    const handleFilterChange = (e) => handleChangeFilterAux(e, filter, setFilter)

    const onPatientRegister = (e) => {
        e.preventDefault()
        onClientSelect({
            clinic_id: activeClinic.id,
            patient_id: 'new',
            name: filter.name,
            surnames: filter.surnames,
            email: filter.email,
            phone_number: filter.phone_number
        })
    }

    const onFilterClean = (e) => {
        e.preventDefault()

        setFilter({
            ...filter,
            name: "",
            surnames: "",
            email: "",
            phone_number: "",
        })
    }

    const onLookForPatientClick = (e) => {
        e.preventDefault()
        setNewPatient(false)
    }

    const onRegisterNewPatientClick = (e) => {
        e.preventDefault()
        setNewPatient(true)
    }

    return <>
        <h4>Datos del paciente a añadir</h4>

        <div className={'row'}>
            <div className={"col-6"}>
                Actualmente esta
                {newPatient ? " registrando un nuevo paciente" : " buscando un paciente existente"}
            </div>

            <div className={"col-6"}>
                {newPatient ?
                    <button type="submit" className="btn btn-dark btn-sm float-end" onClick={onLookForPatientClick}>
                        Paciente existente
                    </button> :
                    <button type="submit" className="btn btn-dark btn-sm float-end"
                            onClick={onRegisterNewPatientClick}>
                        Nuevo paciente
                    </button>}

                <button type="submit" className="btn btn-dark btn-sm mx-3 float-end" onClick={onFilterClean}>
                    Reiniciar
                </button>

                {newPatient &&
                    <button type="submit" className="btn btn-dark btn-sm mx float-end" onClick={onPatientRegister}>
                        Confirmar
                    </button>}
            </div>
        </div>

        <div className="col-6">
            <InputComponent
                label={"Nombre *"}
                onChange={handleFilterChange}
                value={filter.name}
                type={"text"}
                name={"name"}
                placeholder={"Nombre"}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Apellidos *"}
                onChange={handleFilterChange}
                value={filter.surnames}
                type={"text"}
                name={"surnames"}
                placeholder={"Apellidos"}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Correo"}
                onChange={handleFilterChange}
                value={filter.email}
                type={"text"}
                name={"email"}
                placeholder={"Correo"}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Teléfono"}
                onChange={handleFilterChange}
                value={filter.phone_number}
                type={"tel"}
                name={"phone_number"}
                placeholder={"Número de teléfono"}
            />
        </div>

        {(!patients || Object.keys(patients).length <= 0) && <div className={"col-12"}>
            <h4>No hay pacientes disponibles</h4>
        </div>}

        {!newPatient && <TablePatientAux
            data={patients}
            order={patientsOrder}
            onSelectClick={onClientSelect}
            selectText={"Seleccionar"}
        />}
    </>
}

export default SelectPatientForServiceComponent;