import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../../general/input/InputComponent";
import MessageSpan from "../../general/auxiliar/message/MessageSpan";
import {postChargeData} from "../../../actions/charge";
import {validateCharge} from "../../../auxiliar/formValidators/chargeValidators";
import {handleChangeAux} from "../../../auxiliar/handleChange/handleChangeAux";


const RegisterChargeComponent = ({cashRegisterData, closeTooltip}) => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        charge_id: "",
        clinic_id: activeClinic.id,
        cash_register_id: cashRegisterData.cash_register_id,
        paid: "0",
        payment_method: ""
    })

    const [errors, setErrors] = useState({})

    const [registerDisabled, setRegisterDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validateCharge(data, cashRegisterData.unpaid, setErrors))
            dispatch(postChargeData(data, closeTooltip, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    return <div className="container my-5">
        <h2>Registro de un nuevo pago</h2>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Pagado *"}
                    onChange={handleChange}
                    value={data.paid}
                    type={"number"}
                    name={"paid"}
                    placeholder={"Cantidad pagada"}
                />
            </div>
            <div className="col-6">
                <label className="form-label">Método de pago *</label>
                <select className="form-select" value={data.payment_method} onChange={handleChange}
                        name={"payment_method"}>
                    <option key="" value="">Selecciona el método de pago</option>
                    <option key="Efectivo" value="Efectivo">Efectivo</option>
                    <option key="Tarjeta" value="Tarjeta">Tarjeta</option>
                    <option key="Cheque" value="Transferencia">Transferencia</option>
                    <option key="Otro" value="Otro">Otro</option>
                </select>
            </div>
            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onRegisterClick} disabled={registerDisabled}>
                    Confirmar
                </button>
            </div>
        </form>
    </div>
}

export default RegisterChargeComponent;