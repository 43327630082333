import logo_ACEF from "../../../media/Partners/Logo_ACEF.png"
import logo_colegio_cantabria from "../../../media/Partners/Logo_colegio_cantabria.png"
import logo_colegio_valencia from "../../../media/Partners/Logo_colegio_valencia.png"
import logo_fisiocampus from "../../../media/Partners/Logo_fisiocampus.png"
import plus_symbol from "../../../media/Partners/green-blue.png"

import React from "react";

import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";


const PartnersComponent = () => {
    const screenSize = useScreenSize()

    if (screenSize.width > 768)
        return <div className={"my-5"}>
            <h2>Partners</h2>

            En esta sección están incluidos nuestros principales socios.
            <div className={"container-fluid"}>
                <div className={"row py-1"}>
                    <div className={"col-4"}>
                        <a href={"https://acefisioterapia.org/"}>
                            <img className="mx-auto d-block mt-3" src={logo_ACEF}
                                 alt={'Logo Asociación española de clínicas de fisioterapia'}
                                 height={"200px"}/>
                        </a>
                    </div>
                    <div className={"col-4"}>
                        <a href={"https://colfisiocant.org/"}>
                            <img className="mx-auto d-block mt-3" src={logo_colegio_cantabria}
                                 alt={'Logo ilustre colegio de fisioterapeutas de Cantabria'}
                                 height={"200px"}/>
                        </a>
                    </div>
                    <div className={"col-4"}>
                        <a href={"https://colfisiocant.org/"}>
                            <img className="mx-auto d-block mt-3" src={logo_colegio_valencia}
                                 alt={'Logo ilustre colegio de fisioterapeutas de la Comunidad Valenciana'}
                                 height={"200px"}/>
                        </a>
                    </div>
                </div>
                <div className={"row py-2"}>
                    <div className={"col-4"}>
                        <p className={'text-center'}>
                            <a className={"link-dark"} href={"https://acefisioterapia.org/"}>
                                Asociación de Clínicas Españolas de Fisioterapia</a>
                        </p>
                    </div>
                    <div className={"col-4"}>
                        <p className={'text-center'}>
                            <a className={"link-dark"} href={"https://colfisiocant.org/"}>
                                Ilustre Colegio Profesional de Fisioterapeutas de Cantabria</a>
                        </p>
                    </div>
                    <div className={"col-4"}>
                        <p className={'text-center'}>
                            <a className={"link-dark"} href={"https://colfisiocv.com/"}>
                                Ilustre Colegio Profesional de Fisioterapeutas de la Comunidad Valenciana</a>
                        </p>
                    </div>
                </div>
                <div className={"row py-2"}>
                    <div className={"col-6"}>
                        <img className="mx-auto d-block mt-3" src={logo_fisiocampus}
                             alt={'fisiocampus'}
                             height={"200px"}/>
                    </div>
                    <div className={"col-6"}>
                        <img className="mx-auto d-block mt-3" src={plus_symbol}
                             alt={'Símbolo más'}
                             height={"200px"}/>
                    </div>
                </div>
                <div className={"row py-2"}>
                    <div className={"col-6"}>
                        <p className={'text-center'}>
                            <a className={"link-dark"} href={"https://www.fisiocampus.com/"}>
                                FisioCampus</a>
                        </p>
                    </div>
                    <div className={"col-6"}>
                        <p className={'text-center'}>
                            Próximamente
                        </p>
                    </div>
                </div>
            </div>
        </div>

    return <div className={"my-5"}>
        <h2>Partners</h2>

        En esta sección están incluidos nuestros principales socios.
        <div className={"container-fluid"}>
            <div className={"row py-1"}>
                <div className={"col-12"}>
                    <a href={"https://acefisioterapia.org/"}>
                        <img className="mx-auto d-block mt-3" src={logo_ACEF}
                             alt={'Logo Asociación española de clínicas de fisioterapia'}
                             height={"200px"}/>
                    </a>
                </div>
                <div className={"col-12"}>
                    <p className={'text-center'}>
                        <a className={"link-dark"} href={"https://acefisioterapia.org/"}>
                            Asociación de Clínicas Españolas de Fisioterapia</a>
                    </p>
                </div>
            </div>

            <div className={"row py-1"}>
                <div className={"col-12"}>
                    <a href={"https://colfisiocant.org/"}>
                        <img className="mx-auto d-block mt-3" src={logo_colegio_cantabria}
                             alt={'Logo ilustre colegio de fisioterapeutas de Cantabria'}
                             height={"200px"}/>
                    </a>
                </div>
                <div className={"col-12"}>
                    <p className={'text-center'}>
                        <a className={"link-dark"} href={"https://colfisiocant.org/"}>
                            Ilustre Colegio Profesional de Fisioterapeutas de Cantabria</a>
                    </p>
                </div>
            </div>

            <div className={"row py-1"}>
                <div className={"col-12"}>
                    <a href={"https://colfisiocant.org/"}>
                        <img className="mx-auto d-block mt-3" src={logo_colegio_valencia}
                             alt={'Logo ilustre colegio de fisioterapeutas de la Comunidad Valenciana'}
                             height={"200px"}/>
                    </a>
                </div>
                <div className={"col-12"}>
                    <p className={'text-center'}>
                        <a className={"link-dark"} href={"https://colfisiocv.com//"}>
                            Ilustre Colegio Profesional de Fisioterapeutas de la Comunidad Valenciana</a>
                    </p>
                </div>
            </div>

            <div className={"row py-1"}>
                <div className={"row py-2"}>
                    <div className={"col-12"}>
                        <img className="mx-auto d-block mt-3" src={plus_symbol}
                             alt={'Más'}
                             height={"200px"}/>
                    </div>
                </div>
                <div className={"row py-2"}>
                    <div className={"col-12"}>
                        <p className={'text-center'}>
                            Próximamente
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>


}

export default PartnersComponent;