import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {Link} from 'react-router-dom';

import MessageSpan from "../../../components/general/auxiliar/message/MessageSpan";
import InputComponent from "../../../components/general/input/InputComponent";
import {login} from "../../../actions/auth";
import {handleChangeAux} from "../../../auxiliar/handleChange/handleChangeAux";
import {validateLoginForm} from "../../../auxiliar/formValidators/authValidators";

const LoginScreen = () => {
    const dispatch = useDispatch()

    const [data, setData] = useState({
        email: "",
        password: "",
        type: "manager"
    })

    const [errors, setErrors] = useState({})

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const handleLogin = (e) => {
        e.preventDefault()

        if (validateLoginForm(data, setErrors))
            dispatch(login(data))
    }

    return <div className="container">
        <MessageSpan messagesDict={errors}/>

        <div className="col-md-8 offset-md-2 my-5">
            <div className="login-form bg-light mt-4 p-4 border border-dark rounded">
                <form onSubmit={handleLogin} className="row g-3">
                    <h4>Bienvenido de nuevo</h4>
                    <div className="col-12">
                        <select className="form-select" onChange={handleChange} name={"type"}>
                            <option key={"manager"} value={"manager"}>Mánager</option>
                            <option key={"clinic"} value={"clinic"}>Clínica</option>
                        </select>
                    </div>
                    <div className="col-12">
                        <InputComponent
                            label={"Correo Electrónico"}
                            onChange={handleChange}
                            value={data.email}
                            type={"text"}
                            name={"email"}
                            autocomplete={"true"}
                            placeholder={"ejemplo@mail.com"}
                        />
                    </div>
                    <div className="col-12">
                        <InputComponent
                            label={"Contraseña"}
                            onChange={handleChange}
                            value={data.password}
                            type={"password"}
                            name={"password"}
                            autocomplete={"true"}
                            placeholder={"Contraseña"}
                        />
                    </div>
                    <div className="col-12 container-fluid">
                        <button className="btn btn-dark">
                            Iniciar Sesión
                        </button>
                    </div>
                </form>
                <hr className="mt-4"/>
                <div className="row">
                    <div className="col-6">
                        <p className="text-center mb-0">
                            ¿Todavía no tienes cuenta?
                            <br/>
                            <Link to="/register">Regístrate aquí</Link>
                        </p>
                    </div>
                    <div className="col-6">
                        <p className="text-center mb-0">
                            ¿Has olvidado tu contraseña?
                            <br/>
                            <Link to="/forgotten-password">Recordar contraseña</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default LoginScreen;