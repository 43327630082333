import axios from 'axios';

import {dispatchLogin, dispatchLogout} from "../reducers/authReducer";
import {dispatchActiveClinicSave} from "../reducers/activeClinicReducer";
import {dispatchRedirect} from "../reducers/redirectReducer";
import {dispatchMessageSave} from "../reducers/messageReducer";


export const register = (data, setRegisterDisabled) => {
    let req_data = JSON.parse(JSON.stringify(data))
    delete req_data.password_rep

    return (dispatch) => {
        axios.post('/api/managers/register', req_data)
            .then(response => {
                if (response.status === 201) {
                    dispatchRedirect(dispatch, "/login")
                    dispatchMessageSave(dispatch,
                        "Usuario registrado con éxito, confirme el correo para inciar sesión", false)
                }
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}

export const login = (data) => {
    return (dispatch) => {
        axios.post('/api/managers/login', data)
            .then(response => {
                dispatchLogin(dispatch, response.data.content)
                const clinics = response.data.content.clinics
                const clinicKeys = Object.keys(clinics)

                if (clinicKeys.length > 0) {
                    const clinicId = clinicKeys[0]
                    const clinic = clinics[clinicId]
                    dispatchActiveClinicSave(dispatch, clinicId, clinic.name, clinic.status)
                }
            }).catch(error => {
                if (error.response.data.msg === "Correo no validado. Compruebe su bandeja de entrada" &&
                    window.confirm('Correo no validado, ¿quiere reenviar el correo de confirmación?')) {
                    axios.post('/api/managers/resend_validation_email', data).then(
                        r => dispatchMessageSave(dispatch,
                            "Correo de confirmación reenviado, siga las instrucciones del mismo", false)
                    )

                }
            }
        )
    }
}

export const logout = () => {
    return (dispatch) => {
        axios.post('/api/managers/logout')
            .then(() => {
                dispatchLogout(dispatch)
            })
    }
}


export const requestNewPassword = (data) => {
    return (dispatch) => {
        axios.post('/api/managers/request_new_password', data)
            .then(() => {
                    dispatchRedirect(dispatch, "/login")
                    dispatchMessageSave(dispatch,
                        "Correo con instrucciones enviado con éxito, siga las indicaciones para inciar sesión", false)
                }
            )

    }
}