import React from "react";

import ClinicSelectorDropdown from "../navbar/ClinicSelectorDropdown";


const ClinicSelectorComponent = () => {
    return <div className="container my-5">
        <h4>Seleccione una clínica para continuar</h4>
        <form className="row g-3">
            <ClinicSelectorDropdown variant={"light"}/>
        </form>
    </div>
}

export default ClinicSelectorComponent