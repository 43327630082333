import axios from "axios";

export const sendFilesSalus = (data) => {
    return () => {
        let formData = new FormData();

        //Adding files to the formdata
        formData.append("clinic_id", data.clinic_id);
        formData.append("import_type", data.import_type);
        formData.append("all_sessions", data.all_sessions);
        formData.append("patients", data.patients);
        formData.append("employees", data.employees);
        formData.append("sessions", data.sessions);
        formData.append("vouchers", data.vouchers);
        formData.append("cash_registers", data.cash_registers);
        axios.post('/api/managers/data_import/salus', formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then(() => {
            })
    }
}


export const sendFilesClinicCloud = (data) => {
    return () => {
        let formData = new FormData();

        //Adding files to the formdata
        formData.append("clinic_id", data.clinic_id);
        formData.append("import_type", data.import_type);
        formData.append("patients", data.patients);
        formData.append("services", data.services);
        formData.append("sessions", data.sessions);
        formData.append("sessions_2", data.sessions_2);
        formData.append("vouchers", data.vouchers);
        axios.post('/api/managers/data_import/clinic_cloud', formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then(() => {
            })
    }
}
export const rollBackImport = (clinic_id, code) => {
    return () => {
        axios.delete('/api/managers/data_import/', {params: {'clinic_id': clinic_id, 'code': code}})
            .then(() => {
            })
    }
}

export const getCashRegistersFile = (filter) => {
    return () => {
        axios.get('/api/managers/data_export/cash_registers', {params: filter, responseType: 'blob'})
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Albaranes.xlsx');
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
    }
}

export const sendFilesExcel = (data) => {
    return () => {
        let formData = new FormData();

        //Adding files to the formdata
        formData.append("clinic_id", data.clinic_id);
        formData.append("patients", data.patients);
        axios.post('/api/managers/data_import/excel', formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then(() => {
            })
    }
}