import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import InputComponent from "../../../general/input/InputComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import {deleteSessionType, putSessionTypeData} from "../../../../actions/sessionTypes";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateSessionType} from "../../../../auxiliar/formValidators/sessionTypeValidators";
import ColorSelectorComponent from "../../../general/input/ColorSelectorComponent";
import CheckBoxComponent from "../../../general/input/CheckBoxComponent";


const EditSessionTypeComponent = ({sessionType, setSelectedSessionId, resetFilter}) => {

    const dispatch = useDispatch();

    const [data, setData] = useState(sessionType)

    const [errors, setErrors] = useState({})

    const [updateDisabled, setUpdateDisabled] = useState(false)

    useEffect(() => {
        setData(sessionType)
    }, [sessionType])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onCancelClick = (e) => {
        e.preventDefault()

        setSelectedSessionId(undefined)
        setErrors({})
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validateSessionType(data, setErrors))
            dispatch(putSessionTypeData(
                sessionType.session_type_id, {new_info: data}, setSelectedSessionId, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm(
            '¿Está seguro de que quiere eliminar este tipo de sesión? No se podrá recuperar la información'
        ))
            dispatch(deleteSessionType(sessionType.session_type_id, setSelectedSessionId, setUpdateDisabled, resetFilter))
        else
            setUpdateDisabled(false)
    }

    return <div className="container my-3">
        <h5>Editar tipo de sesión {data.name}</h5>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Nombre *"}
                    onChange={handleChange}
                    value={data.name}
                    type={"text"}
                    name={"name"}
                    placeholder={"Nombre"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Duración de la cita en minutos *"}
                    onChange={handleChange}
                    value={data.session_duration}
                    type={"number"}
                    name={"session_duration"}
                    placeholder={"60"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Precio base de la sesión *"}
                    onChange={handleChange}
                    value={data.price}
                    type={"number"}
                    name={"price"}
                    placeholder={"Precio"}
                />
            </div>
            <div className="col-6">
                <ColorSelectorComponent
                    value={data.color}
                    onChange={handleChange}/>
            </div>
            <div className="col-4">
                <CheckBoxComponent
                    title={"Marque este campo si desea que este empleado se muestre cuando un paciente realice una reserva online"}
                    label={"Citas online"}
                    onChange={handleChange}
                    value={data.online_booking}
                    name={"online_booking"}
                />
            </div>

            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button>
                <button className="mx-4 btn btn-dark" onClick={onCancelClick}>
                    Cancelar
                </button>
                <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                    Eliminar
                </button>
            </div>
        </form>
    </div>
}

export default EditSessionTypeComponent;