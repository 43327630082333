const NotFoundScreen = ({version = "default"}) => {
    if (version === "patient")
        return <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"><span className="text-danger">Opps!</span> Paciente no encontrado</p>
                <p className="lead">
                    El paciente al que estás intentando acceder no existe o no tienes permisos para acceder a él.
                </p>
            </div>
        </div>

    if (version === "acquired_voucher")
        return <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"><span className="text-danger">Opps!</span> Bono adquirido no encontrado</p>
                <p className="lead">
                    El bono adquirido al que estás intentando acceder no existe o no tienes permisos para acceder a él.
                </p>
            </div>
        </div>

    if (version === "cash_register")
        return <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"><span className="text-danger">Opps!</span> Albarán no encontrado</p>
                <p className="lead">
                    El albarán al que estás intentando acceder no existe o no tienes permisos para acceder a él.
                </p>
            </div>
        </div>

    if (version === "invoice")
        return <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"><span className="text-danger">Opps!</span> Factura no encontrada</p>
                <p className="lead">
                    La factura a la que estás intentando acceder no existe o no tienes permisos para acceder a ella.
                </p>
            </div>
        </div>

    if (version === "physio")
        return <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"><span className="text-danger">Opps!</span> Fisioterapeuta no encontrado</p>
                <p className="lead">
                    El fisioterapeuta al que estás intentando acceder no existe o no tienes permisos para acceder a él.
                </p>
            </div>
        </div>

    if (version === "clinic")
        return <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"><span className="text-danger">Opps!</span> Clínica no encontrada</p>
                <p className="lead">
                    La clinica a la que estás intentando acceder no existe o no tienes permisos para acceder a ella.
                </p>
            </div>
        </div>

    if (version === "unconfirmedSession")
        return <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"><span className="text-danger">Opps!</span> Sesión no encontrada</p>
                <p className="lead">
                    La sesión a la que estás intentando acceder no existe o no tienes permisos para acceder a ella. Si
                    crees que se trata de un error ponte en contacto con tu clínica.
                </p>
            </div>
        </div>


    return <div className="d-flex align-items-center justify-content-center m-5">
        <div className="text-center">
            <h1 className="display-1 fw-bold">404</h1>
            <p className="fs-3"><span className="text-danger">Opps!</span> Página no encontrada</p>
            <p className="lead">
                La página a la que estás intentando acceder no existe.
            </p>
        </div>
    </div>


}

export default NotFoundScreen;