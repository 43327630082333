import logo from "../../../media/Logo/Logo_grande.png"

import React from "react";
import {Link} from "react-router-dom";

import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";
import PartnersComponent from "../../../components/general/partners/PartnersComponent";


const AboutUsBookingScreen = () => {
    const screenSize = useScreenSize()

    return <div className="container mt-5" style={{textAlign: "justify"}}>
        <div className="mt-4 p-4 rounded text-center">
            <h1 className={"h2"}>
                De fisios, para fisios
            </h1>

            <div className="text-center container">
                <img src={logo} alt={'Logo'} height={screenSize.width > 600 ? "400px" : '200px'}/>
            </div>
        </div>

        <div>
            <h2>About us</h2>

            <p>
                El equipo de MiFisio Agenda está formado por fisioterapeutas e informáticos con ganas de
                revolucionar un sector en el que las necesidades no están cubiertas de una forma óptima. Para ello,
                hemos creado el único CRM exclusivo para clínicas de fisioterapia basándonos en las necesidades del
                sector tras años de experiencia con el objetivo de poner a disposición de los fisioterapeutas las
                herramientas óptimas.
            </p>

            <p>
                Este módulo te permite comprobar la disponibilidad y reservar tus propias citas en tu clínica
                de confianza, introduciendo los datos básicos y abriendo un canal de comunicacion remoto y sencillo
                con la misma.
            </p>

            <p>
                Si tienes cualquier sugerencia, has encontrado un error o quieres ponerte en contacto con nosotros
                puedes mandar un mensaje a <a href={"mailto:info@mifisioagenda.com"}>info@mifisioagenda.com</a>. Si
                eres un fisioterapeuta y quieres saber más haz click <Link to="/pricing">aquí</Link>.
            </p>
        </div>

        <PartnersComponent/>
    </div>
}

export default AboutUsBookingScreen;