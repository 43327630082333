import {applyMiddleware, combineReducers, compose, createStore} from "redux"
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from "redux-thunk"

import {authReducer} from "../reducers/authReducer";
import {redirectReducer} from "../reducers/redirectReducer";
import {messageReducer} from "../reducers/messageReducer";
import {activeClinicReducer} from "../reducers/activeClinicReducer";


const reducers = combineReducers({
    auth: authReducer,
    redirect: redirectReducer,
    message: messageReducer,
    activeClinic: activeClinicReducer,
})

const persistConfig = {
    key: 'mifisio_redux_state',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers)

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
)

export const persistor = persistStore(store);
