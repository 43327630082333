import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import TablePatientAux from "./TablePatientAux";
import InputComponent from "../general/input/InputComponent";
import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import {handleChangeFilterAux} from "../../auxiliar/handleChange/handleChangeFilterAux";
import {dispatchRedirect} from "../../reducers/redirectReducer";
import {getAllPatientData} from "../../actions/patient";


const ListPatientsComponent = () => {
    const dispatch = useDispatch()
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({})
    const [pagination, setPagination] = useState({})
    const [order, setOrder] = useState([])
    const [filter, setFilter] = useState({
        clinic_id: activeClinic.id,
        name: "",
        surnames: "",
        email: "",
        phone_number: "",
        page: 1,
        per_page: 20,
        order: "name"
    })

    useEffect(() => {
        dispatch(getAllPatientData(filter, setData, setPagination, setOrder))
    }, [filter])

    const handleChange = (e) => handleChangeFilterAux(e, filter, setFilter)

    const onAddClick = () => dispatchRedirect(dispatch, "/patient/register/")

    const onSelectPatientClick = (patient) => dispatchRedirect(dispatch, `/patient/${patient.patient_id}`)

    return <div className={"container my-5"}>
        <div className="row">
            <div className={"col-6"}>
                <h2>Pacientes</h2>

            </div>
            <div className={"col-6 containter-fluid"}>
                <button className="btn btn-dark btn-sm float-end" onClick={onAddClick}>
                    Nuevo paciente
                </button>
            </div>
        </div>

        <div className={'mt-3 mb-4'}>
            <h5>Filtros</h5>
            <form className="row g-3" onSubmit={(e) => e.preventDefault()}>
                <div className="col-6">
                    <InputComponent
                        label={"Nombre"}
                        onChange={handleChange}
                        value={filter.name}
                        type={"text"}
                        name={"name"}
                        placeholder={"Nombre"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Apellidos"}
                        onChange={handleChange}
                        value={filter.surnames}
                        type={"text"}
                        name={"surnames"}
                        placeholder={"Apellidos"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Correo"}
                        onChange={handleChange}
                        value={filter.email}
                        type={"text"}
                        name={"email"}
                        placeholder={"Correo"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Teléfono"}
                        onChange={handleChange}
                        value={filter.phone_number}
                        type={"tel"}
                        name={"phone_number"}
                        placeholder={"Teléfono"}
                    />
                </div>
            </form>
        </div>

        {(!data || Object.keys(data).length <= 0) ?
            <h4 className={"mb-3"}>No hay pacientes disponibles</h4> :
            <h4>{pagination.total_items} pacientes disponibles</h4>}

        <TablePatientAux
            data={data}
            order={order}
            selectText={"Detalles"}
            onSelectClick={onSelectPatientClick}
        />

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
        />
    </div>
}

export default ListPatientsComponent;