import React from "react";


const WhatsAppReminder = ({link}) => {
    if (!link || link === "")
        return null

    return <a href={link} target="_blank">
        <img
            src={require("../../../media/whatsapp_icon.png")}
            height={20}
            alt={'WhatsApp icon'}
            className={"mb-1 rounded"}/>
    </a>
}


export default WhatsAppReminder;