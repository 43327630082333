import React from "react";

import ListSessionTypeComponent from "./ListSessionTypeComponent";
import EditSessionTypeComponent from "./EditSessionTypeComponent";


const GeneralSessionTypeComponent = ({
                                         sessionTypes,
                                         filter,
                                         setFilter,
                                         order,
                                         pagination,
                                         selectedSessionId,
                                         setSelectedSessionId,
                                         resetFilter
                                     }) => {
    return <>
        <ListSessionTypeComponent
            sessionTypes={sessionTypes}
            filter={filter}
            setFilter={setFilter}
            order={order}
            pagination={pagination}
            setSelectedSessionId={setSelectedSessionId}
            selectedSessionId={selectedSessionId}
        />

        {selectedSessionId && <EditSessionTypeComponent
            setSelectedSessionId={setSelectedSessionId}
            sessionType={sessionTypes[selectedSessionId]}
            resetFilter={resetFilter}
        />}
    </>
}

export default GeneralSessionTypeComponent;