import React from "react";

import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import {checkExistsField} from "../../../../auxiliar/formValidators/auxValidators";


const ListGroupSessionsInPatientComponent = ({
                                                 groupSessions,
                                                 pagination,
                                                 order,
                                                 filter,
                                                 setFilter,
                                                 openTooltipEdit
                                             }) => {
    return <div className={"container my-5"}>
        <h2>Clases</h2>

        {Object.keys(groupSessions).length <= 0 ?
            <h4 className={"mb-3"}>No hay clases disponibles</h4> :
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Entrada</th>
                        <th>Salida</th>
                        <th>Fisioterapeuta</th>
                        <th>Tipo</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {order && order.map((id) => {
                        if (checkExistsField(groupSessions, id)) {
                            const groupSession = groupSessions[id]
                            return <tr key={id} className={groupSessions[id].unpaid ? 'table-danger' : ''}>
                                <td>{groupSession.start_datetime.substring(0, 10)}</td>
                                <td>{groupSession.start_datetime.substring(11, 16)}</td>
                                <td>{groupSession.finish_datetime.substring(11, 16)}</td>
                                <td>{groupSession.employee_name}</td>
                                <td>{groupSession.type}</td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={() => {
                                        openTooltipEdit.current({
                                            ...groupSession,
                                            event_type: 'group',
                                            startAt: groupSession.start_datetime,
                                            endAt: groupSession.finish_datetime
                                        })
                                    }}>
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        }
                    })}
                    </tbody>
                </table>
            </div>}

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
        />
    </div>
}

export default ListGroupSessionsInPatientComponent;