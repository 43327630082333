import {checkExistsField, checkValidMail, checkValidPhoneNumber} from "./auxValidators";

export const validatePatientData = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "name"))
        errors['name'] = "El nombre del paciente es obligatorio"
    else if(data.name.trim().length < 3)
        errors['name'] = "El nombre es demasiado corto"

    if (!checkExistsField(data, "surnames"))
        errors['surnames'] = "Los apellidos del paciente son obligatorios"
    else if (data.surnames.trim().length < 3)
        errors['surnames'] = "Los apellidos son demasiado cortos"

    if (checkExistsField(data, "email") && !checkValidMail(data.email)) // not compulsory
        errors['email'] = "El correo introducido no es válido"

    if (checkExistsField(data, "phone_number") && !checkValidPhoneNumber(data.phone_number)) // not compulsory
        errors['phone_number'] = "El número de teléfono introducido no es válido"

    if (checkExistsField(data, "address") && data.address.trim().length < 4) // not compulsory
        errors['address'] = "La dirección intrducida es demasiado corta"

    if (checkExistsField(data, "DNI") && data.DNI.trim().length !== 9) // not compulsory
        errors['DNI'] = "El DNI no tiene la logitud adecuada"

    if (checkExistsField(data, "comments") && data.comments.length > 10_000) // not compulsory
        errors['comments'] = "La anamnesis es demasiado larga"

    if (!checkExistsField(data, "discount"))
        errors['discount'] = "El descuento habitual es obligatorio"
    else if (parseFloat(data.discount) < 0)
        errors['discount'] = "El descuento habitual debe ser mayor o igual que el 0%"
    else if (parseFloat(data.discount) > 100)
        errors['discount'] = "El descuento habitual debe ser menor o igual que el 100%"

    if (checkExistsField(data, "discount") && new Date(data.birth_date) >= new Date()) // not compulsory
        errors['birth_date'] = "La fecha de nacimiento debe ser anterior a la fecha actual"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}


export const validateSmallPatientData = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "name"))
        errors['name'] = "El nombre del paciente es obligatorio"
    else if(data.name.trim().length < 3)
        errors['name'] = "El nombre es demasiado corto"

    if (!checkExistsField(data, "surnames"))
        errors['surnames'] = "Los apellidos del paciente son obligatorios"
    else if (data.surnames.trim().length < 3)
        errors['surnames'] = "Los apellidos son demasiado cortos"

    if (checkExistsField(data, "email") && !checkValidMail(data.email)) // not compulsory
        errors['email'] = "El correo introducido no es válido"

    if (checkExistsField(data, "phone_number") && !checkValidPhoneNumber(data.phone_number)) // not compulsory
        errors['phone_number'] = "El número de teléfono introducido no es válido"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}