import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import InputComponent from "../../../general/input/InputComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import {deleteGroupSessionType, putGroupSessionTypeData} from "../../../../actions/groupSessionTypes";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateGroupSessionType} from "../../../../auxiliar/formValidators/groupSessionTypeValidators";
import ColorSelectorComponent from "../../../general/input/ColorSelectorComponent";


const EditGroupSessionTypeComponent = ({setSelectedGroupSessionId, groupSessionType, resetFilter}) => {
    const dispatch = useDispatch();
    const [data, setData] = useState(groupSessionType)

    const [errors, setErrors] = useState({})

    const [updateDisabled, setUpdateDisabled] = useState(false)

    useEffect(() => {
        setData(groupSessionType)
    }, [groupSessionType])

    const handleChange = (e) => {
        handleChangeAux(e, data, setData)
    }

    const onCancelClick = (e) => {
        e.preventDefault()

        setSelectedGroupSessionId(undefined)
        setErrors({})
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validateGroupSessionType(data, setErrors))
            dispatch(putGroupSessionTypeData(groupSessionType.group_session_type_id, {new_info: data},
                setSelectedGroupSessionId, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar esta class? No se podrá recuperar la información'))
            dispatch(deleteGroupSessionType(
                groupSessionType.group_session_type_id, setSelectedGroupSessionId, setUpdateDisabled, resetFilter))
        else
            setUpdateDisabled(false)
    }

    return <div className="container my-3">
        <h5>Editar tipo de clase {data.name}</h5>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Nombre *"}
                    onChange={handleChange}
                    value={data.name}
                    type={"text"}
                    name={"name"}
                    placeholder={"Nombre"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Duración de la clase en minutos *"}
                    onChange={handleChange}
                    value={data.session_duration}
                    type={"number"}
                    name={"session_duration"}
                    placeholder={"60"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Precio base de la clase *"}
                    onChange={handleChange}
                    value={data.price}
                    type={"number"}
                    name={"price"}
                    placeholder={"Precio base"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Nº máximo de participantes *"}
                    onChange={handleChange}
                    value={data.number_participants}
                    type={"number"}
                    name={"number_participants"}
                    placeholder={"1"}
                    disabled={true}
                />
            </div>
            <div className="col-12">
                <ColorSelectorComponent
                    value={data.color}
                    onChange={handleChange}
                />
            </div>

            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button>
                <button className="mx-4 btn btn-dark" onClick={onCancelClick}>
                    Cancelar
                </button>
                <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                    Eliminar
                </button>
            </div>
        </form>
    </div>
}

export default EditGroupSessionTypeComponent;