import React from 'react';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';
import {PersistGate} from "redux-persist/integration/react";

import {persistor, store} from "./store/store";
import AppRouter from "./routers/AppRouter";

import './styles/body.css';

function App() {
    return (
        <CookiesProvider>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <AppRouter/>
                </PersistGate>
            </Provider>
        </CookiesProvider>
    );
}

export default App;

