import axios from "axios";

export const postUnconfirmedSession = (data, closeTooltip, setRegisterDisabled) => {
    return () => {
        axios.post('/api/managers/unconfirmed_sessions/', data)
            .then(() => {
                closeTooltip()
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}


export const getUnconfirmedSessionData = (clinicCode, setData) => {
    return () => {
        axios.get(`/api/managers/unconfirmed_sessions/reservation/${clinicCode}`)
            .then(response => {
                setData(response.data.content)
            })
    }
}


export const deleteUnconfirmedSession = (clinicCode, onSuccess, onError) => {
    return () => {
        axios.delete(`/api/managers/unconfirmed_sessions/reservation/${clinicCode}`)
            .then(() => {
                onSuccess()
            }).catch(() => {
                onError()
            }
        )
    }
}


export const getUnconfirmedSessionByID = (unconfirmedSessionID, clinicID, setData, setFilter) => {
    return () => {
        axios.get(`/api/managers/unconfirmed_sessions/${unconfirmedSessionID}`)
            .then(response => {
                const data = response.data.content

                setData(response.data.content)
                setFilter({
                    per_page: 5,
                    page: 1,
                    order: "name",
                    name: data.patient_name,
                    surnames: data.patient_surnames,
                    email: data.patient_email,
                    phone_number: data.patient_phone_number,
                    clinic_id: clinicID
                })
            })
    }
}


export const deleteUnconfirmedSessionByID = (unconfirmedSessionID, onSuccess, onError) => {
    return () => {
        axios.delete(`/api/managers/unconfirmed_sessions/${unconfirmedSessionID}`)
            .then(() => {
                onSuccess()
            }).catch(() => {
                onError()
            }
        )
    }
}


export const acceptUnconfirmedSession = (unconfirmedSessionID, sessionData, patientData, onSuccess, onError) => {
    let reqData = JSON.parse(JSON.stringify(sessionData))

    if (reqData.patient_id === "new")
        reqData.new_patient_info = patientData

    return () => {
        axios.post(`/api/managers/unconfirmed_sessions/${unconfirmedSessionID}/accept`, reqData)
            .then(() => {
                onSuccess()
            }).catch(() => {
                onError()
            }
        )
    }
}