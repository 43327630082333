import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import NotFoundScreen from "../NotFoundScreen";
import InputComponent from "../../../components/general/input/InputComponent";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import {deleteUnconfirmedSession, getUnconfirmedSessionData} from "../../../actions/unconfirmedSession";
import {dispatchRedirect} from "../../../reducers/redirectReducer";
import {handleChangeAux} from "../../../auxiliar/handleChange/handleChangeAux";
import {dispatchMessageSave} from "../../../reducers/messageReducer";


const EditUnconfirmedSessionScreen = () => {
    const dispatch = useDispatch();
    const queryParams = useQueryParams()

    const clinicCode = queryParams.get("session")

    const [data, setData] = useState({})

    const [updateDisabled, setUpdateDisabled] = useState(false)

    useEffect(() => {
        dispatch(getUnconfirmedSessionData(clinicCode, setData))
    }, [clinicCode])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    // TODO: Update redirect path
    const onDeleteSuccess = () => {
        dispatchMessageSave(dispatch, "La solicitud de sesión ha sido eliminada con éxito")
        dispatchRedirect(dispatch, "/about-us")
    }

    const onDeleteError = () => setUpdateDisabled(false)

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm(
            '¿Está seguro de que quiere eliminar esta sesión? Se eliminará la solicitud y ya no podrá ser aceptada'
        ))
            dispatch(deleteUnconfirmedSession(clinicCode, onDeleteSuccess, onDeleteError))
        else
            setUpdateDisabled(false)
    }

    if (!data || Object.keys(data).length === 0)
        return <NotFoundScreen version={'unconfirmedSession'}/>

    // TODO: allow edit here
    return <div className="container my-5">
        <h2>Sesión no confirmada</h2>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Nombre *"}
                    onChange={handleChange}
                    value={data.patient_name}
                    type={"text"}
                    name={"patient_name"}
                    placeholder={"Nombre"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Apellidos *"}
                    onChange={handleChange}
                    value={data.patient_surnames}
                    type={"text"}
                    name={"patient_surnames"}
                    placeholder={"Apellidos"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Email *"}
                    onChange={handleChange}
                    value={data.patient_email}
                    type={"text"}
                    name={"patient_email"}
                    placeholder={"Correo"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Teléfono"}
                    onChange={handleChange}
                    value={data.patient_phone_number}
                    type={"text"}
                    name={"patient_phone_number"}
                    placeholder={"Número de teléfono"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha de inicio"}
                    onChange={handleChange}
                    value={data.start_datetime}
                    type={"datetime-local"}
                    name={"start_datetime"}
                    placeholder={""}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha de fin"}
                    onChange={handleChange}
                    value={data.finish_datetime}
                    type={"datetime-local"}
                    name={"finish_datetime"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Empleado"}
                    onChange={handleChange}
                    value={data.employee_name}
                    type={"text"}
                    name={"employee_name"}
                    placeholder={"Empleado"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Tipo de sesión"}
                    onChange={handleChange}
                    value={data.session_type_name}
                    type={"text"}
                    name={"session_type_name"}
                    placeholder={"Tipo de sesión"}
                    disabled={true}
                />
            </div>
            <div className="col-12">
                <label htmlFor={"session_reason"} className="form-label">
                    Motivo de consulta ({data.session_reason.length}/200)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={data.session_reason}
                    name={"session_reason"}
                    placeholder={"Motivo de consulta"}
                    disabled={true}
                />
            </div>

            <div className="col-12 container-fluid mt-4">
                <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                    Eliminar
                </button>
            </div>
        </form>
    </div>
}

export default EditUnconfirmedSessionScreen;