import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {Link} from 'react-router-dom';

import MessageSpan from "../../../components/general/auxiliar/message/MessageSpan";
import InputComponent from "../../../components/general/input/InputComponent";
import {requestNewPassword} from "../../../actions/auth";
import {handleChangeAux} from "../../../auxiliar/handleChange/handleChangeAux";
import {validateLoginForm} from "../../../auxiliar/formValidators/authValidators";

const ForgottenPasswordScreen = () => {
    const dispatch = useDispatch()

    const [data, setData] = useState({
        email: "",
    })

    const [errors, setErrors] = useState({})

    const handleChange = (e) => {
        handleChangeAux(e, data, setData)
    }

    const handleSendMail = (e) => {
        e.preventDefault()

        if (validateLoginForm(data, setErrors)) {
            dispatch(requestNewPassword(data))
        }
    }

    return (
        <>
            <div className="container">
                <MessageSpan messagesDict={errors}/>

                <div className="col-md-8 offset-md-2 my-5">
                    <div className="login-form bg-light mt-4 p-4 border border-dark rounded">
                        <form onSubmit={handleSendMail} className="row g-3">
                            <h4>Recordar contraseña</h4>
                            <p>Introduzca su correo y recibirá instrucciones por email en los proximos minutos.</p>
                            <div className="col-12">
                                <InputComponent
                                    label={"Correo Electrónico"}
                                    onChange={handleChange}
                                    value={data.email}
                                    type={"text"}
                                    name={"email"}
                                    placeholder={"ejemplo@mail.com"}
                                />
                            </div>

                            <div className="col-12">
                                <button className="btn btn-dark">
                                    Restablecer contraseña
                                </button>
                            </div>
                        </form>
                        <hr className="mt-4"/>
                        <div className="row">
                            <div className="col-6">
                                <p className="text-center mb-0">
                                    ¿Todavía no tienes cuenta?
                                    <br/>
                                    <Link to="/register">Regístrate aquí</Link>
                                </p>
                            </div>
                            <div className="col-6">
                                <p className="text-center mb-0">
                                    ¿Has recordado tu contraseña?
                                    <br/>
                                    <Link to="/login">Incia sesión</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgottenPasswordScreen;