import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import NotFoundScreen from "../../public/NotFoundScreen";
import RegisterAcquiredVoucherComponent
    from "../../../components/services/voucher/acquiredVoucher/RegisterAcquiredVoucherComponent";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import {getPatientData} from "../../../actions/patient";


const RegisterAcquiredVoucherScreen = () => {
    const dispatch = useDispatch()
    const queryParams = useQueryParams()

    const [patientData, setPatientData] = useState({})

    useEffect(() => {
        if (queryParams.has('patient'))
            dispatch(getPatientData(queryParams.get('patient'), setPatientData))
    }, [])


    if (!queryParams.has('patient') || !patientData || Object.keys(patientData).length === 0)
        return <NotFoundScreen version={'patient'}/>

    return <RegisterAcquiredVoucherComponent patientData={patientData}/>
}

export default RegisterAcquiredVoucherScreen;