import React, {useState} from "react";

import NotFoundScreen from "../../../../screens/public/NotFoundScreen";
import SelectPatientForSessionComponent from "../../../patient/SelectPatientForSessionComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import InputComponent from "../../../general/input/InputComponent";
import PhysiotherapistSelectorComponent from "../../../general/input/PhysiotherapistSelectorComponent";
import SessionTypeSelectorComponent from "../../../general/input/SessionTypeSelectorComponent";
import {useDispatch, useSelector} from "react-redux";
import {acceptUnconfirmedSession, deleteUnconfirmedSessionByID} from "../../../../actions/unconfirmedSession";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateSession} from "../../../../auxiliar/formValidators/sessionValidators";
import {validateSmallPatientData} from "../../../../auxiliar/formValidators/patientValidators";
import {checkExistsField} from "../../../../auxiliar/formValidators/auxValidators";


const EditUnconfirmedSessionComponent = ({eventData, filter, setFilter, closeTooltip}) => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({...eventData, patient_id: "", comment: "", clinic_id: activeClinic.id})

    const [updateDisabled, setUpdateDisabled] = useState(false)
    const [userErrors, setUserErrors] = useState({})
    const [errors, setErrors] = useState({})

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onRequestError = () => setUpdateDisabled(false)

    const onResetDataClick = (e) => {
        e.preventDefault()

        setData({...eventData, comment: ""})
    }

    const onCloseClick = (e) => {
        e.preventDefault()

        closeTooltip()
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm(
            '¿Está seguro de que quiere eliminar esta sesión? Se eliminará la solicitud y ya no podrá ser aceptada'
        ))
            dispatch(deleteUnconfirmedSessionByID(data.unconfirmed_session_id, closeTooltip, onRequestError))
        else
            setUpdateDisabled(false)
    }

    const onConfirmClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)

        if (!checkExistsField(data, 'patient_id')) {
            setUserErrors({patient_id: "Selecciona un paciente existente o registra uno nuevo"})
            setUpdateDisabled(false)
        } else if (validateSession(data, setErrors) && validateSmallPatientData(filter, setUserErrors))
            dispatch(acceptUnconfirmedSession(data.unconfirmed_session_id, data, filter, closeTooltip, onRequestError))
        else
            setUpdateDisabled(false)
    }

    if (!data || Object.keys(data).length === 0)
        return <NotFoundScreen version={'unconfirmedSession'}/>

    return <div>
        <h2>Sesión no confirmada</h2>

        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <h4 className={"mt-4"}>Solicitud online</h4>

            <div className={'row'}>
                <div className={"col-12"}>
                    Los datos originales de la sesión rellenados por el paciente online.
                </div>
            </div>

            <div className="col-6">
                <InputComponent
                    label={"Fecha de inicio"}
                    onChange={handleChange}
                    value={eventData.start_datetime}
                    type={"datetime-local"}
                    name={"start_datetime"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha de fin"}
                    onChange={handleChange}
                    value={eventData.finish_datetime}
                    type={"datetime-local"}
                    name={"finish_datetime"}
                    disabled={true}
                />
            </div>

            <div className="col-6">
                <PhysiotherapistSelectorComponent
                    showAllOption={false}
                    handleChangeFilter={handleChange}
                    value={eventData.employee_id}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <SessionTypeSelectorComponent
                    showAllOption={false}
                    handleChangeFilter={handleChange}
                    value={eventData.session_type_id}
                    disabled={true}
                />
            </div>

            <div className="col-6">
                <InputComponent
                    label={"Nombre paciente *"}
                    onChange={handleChange}
                    value={eventData.patient_name}
                    type={"text"}
                    name={"patient_name"}
                    placeholder={"Nombre"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Apellidos paciente *"}
                    onChange={handleChange}
                    value={eventData.patient_surnames}
                    type={"text"}
                    name={"patient_surnames"}
                    placeholder={"Apellidos"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Email *"}
                    onChange={handleChange}
                    value={eventData.patient_email}
                    type={"text"}
                    name={"patient_email"}
                    placeholder={"Correo"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Teléfono"}
                    onChange={handleChange}
                    value={eventData.patient_phone_number}
                    type={"text"}
                    name={"patient_phone_number"}
                    placeholder={"Número de teléfono"}
                    disabled={true}
                />
            </div>

            <h4 className={"mt-4"}>Sesión</h4>

            <div className={'row'}>
                <div className={"col-6"}>
                    Puedes editar la sesión que se va a registrar a continuación.
                </div>

                <div className={"col-6"}>
                    <button type="submit" className="btn btn-dark btn-sm float-end" onClick={onResetDataClick}>
                        Reiniciar
                    </button>
                </div>
            </div>

            <div className={'row'}>
                <div className={"col-12"}>
                </div>
            </div>

            <div className="col-6">
                <InputComponent
                    label={"Fecha de inicio"}
                    onChange={handleChange}
                    value={data.start_datetime}
                    type={"datetime-local"}
                    name={"start_datetime"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha de fin"}
                    onChange={handleChange}
                    value={data.finish_datetime}
                    type={"datetime-local"}
                    name={"finish_datetime"}
                />
            </div>

            <div className="col-6">
                <PhysiotherapistSelectorComponent
                    showAllOption={false}
                    handleChangeFilter={handleChange}
                    value={data.employee_id}
                />
            </div>
            <div className="col-6">
                <SessionTypeSelectorComponent
                    showAllOption={false}
                    handleChangeFilter={handleChange}
                    value={data.session_type_id}
                />
            </div>

            <div className="col-12">
                <label htmlFor={"session_reason"} className="form-label">
                    Motivo de consulta ({data.session_reason.length}/200)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={data.session_reason}
                    name={"session_reason"}
                    placeholder={"Motivo de consulta"}
                />
            </div>
            <div className="col-12">
                <label htmlFor={"comment"} className="form-label">Comentario ({data.comment.length}/200)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={data.comment}
                    name={"comment"}
                    placeholder={"Comentario"}
                />
            </div>

            <SelectPatientForSessionComponent
                data={data}
                setData={setData}
                filter={filter}
                setFilter={setFilter}
                disabled={false}
                userErrors={userErrors}
            />
        </form>

        <div className="col-12 container-fluid mt-4">
            <button className="btn btn-dark" onClick={onConfirmClick} disabled={updateDisabled}>
                Aceptar
            </button>
            <button className="btn btn-dark mx-3" onClick={onCloseClick} disabled={updateDisabled}>
                Cerrar
            </button>
            <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                Rechazar
            </button>
        </div>
    </div>

}

export default EditUnconfirmedSessionComponent;