import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import RegisterChargePopup from "../charge/popupRegisterCharge/RegisterChargePopUp";
import PhysiotherapistSelectorComponent from "../general/input/PhysiotherapistSelectorComponent";
import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import CheckBoxComponent from "../general/input/CheckBoxComponent";
import InputComponent from "../general/input/InputComponent";
import {dispatchRedirect} from "../../reducers/redirectReducer";
import {getAllCashRegistersData} from "../../actions/cashRegister";
import {postInvoice} from "../../actions/invoice";
import {getCashRegistersFile} from "../../actions/dataImport";
import {handleChangeFilterAux} from "../../auxiliar/handleChange/handleChangeFilterAux";
import {checkExistsField} from "../../auxiliar/formValidators/auxValidators";
import TimeFiltersComponent from "../general/input/TimeFiltersComponent";


const ListCashRegistersComponent = () => {
    const activeClinic = useSelector(state => state.activeClinic)

    const dispatch = useDispatch()

    const [pagination, setPagination] = useState({})
    const [order, setOrder] = useState([])

    const [data, setData] = useState({})

    const [filter, setFilter] = useState({
        clinic_id: activeClinic.id,
        employee_id: "",
        payment_method: "",
        patient_name: "",
        patient_surnames: "",
        starting_date: "",
        finishing_date: "",
        temporality: "",
        advanced_temporality: false,
        duration: "",
        period: "days",
        unpaid: false,
        has_invoice: false,
        page: 1,
        order: 'issue_date'
    })

    const [errors, setErrors] = useState({})

    const [update, setUpdate] = useState(false)

    useEffect(() => {
        setErrors({})
        dispatch(getAllCashRegistersData(filter, setData, setPagination, setOrder))
    }, [filter, update, dispatch, setData, setPagination, setUpdate])

    const handleChangeFilter = (e) => handleChangeFilterAux(e, filter, setFilter)

    const updateComponent = () => setUpdate(!update)

    const downloadData = (e) => {
        e.preventDefault()

        if (pagination.total_items <= 20000)
            dispatch(getCashRegistersFile(filter))
        else
            setErrors({
                download: "Con los filtros seleccionados se supera el límite máximo de facturas que se pueden " +
                    "descargar (20000). Cambia los filtros y vuelve a intentarlo con menos resultados."
            })
    }

    return <div className={"container my-5"}>
        <h2>Albaranes</h2>

        <div>
            <h5>Filtros</h5>
            <form className="row g-3" onSubmit={(e) => e.preventDefault()}>
                <div className="col-6">
                    <PhysiotherapistSelectorComponent
                        handleChangeFilter={handleChangeFilter}
                        value={filter.employee_id}
                    />
                </div>
                <div className="col-6">
                    <label className="form-label">Método de pago</label>
                    <select value={filter.payment_method} className="form-select" onChange={handleChangeFilter} name={"payment_method"}>
                        <option value="">Todos</option>
                        <option value="Efectivo">Efectivo</option>
                        <option value="Tarjeta">Tarjeta</option>
                        <option value="Transferencia">Transferencia</option>
                        <option value="Otro">Otro</option>
                    </select>
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Nombre"}
                        onChange={handleChangeFilter}
                        value={filter.patient_name}
                        type={"text"}
                        name={"patient_name"}
                        placeholder={"Nombre"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Apellidos"}
                        onChange={handleChangeFilter}
                        value={filter.patient_surnames}
                        type={"text"}
                        name={"patient_surnames"}
                        placeholder={"Apellidos"}
                    />
                </div>

                <TimeFiltersComponent
                    filter={filter}
                    onChange={handleChangeFilter}
                />

                <div className="col-6">
                    <CheckBoxComponent
                        title={"Muestra aquellos albaranes que tienen una deuda pendiente"}
                        label={"Por pagar"}
                        onChange={handleChangeFilter}
                        value={filter.unpaid}
                        name={"unpaid"}
                    />
                </div>
                <div className="col-6">
                    <CheckBoxComponent
                        title={"Muestra aquellos albaranes que tienen una factura asociada"}
                        label={"Factura"}
                        onChange={handleChangeFilter}
                        value={filter.has_invoice}
                        name={"has_invoice"}
                    />
                </div>
            </form>
        </div>

        <MessageSpan messagesDict={errors}/>

        <div className={"container mt-4"}>
            {(!data || Object.keys(data).length <= 0) ?
                <h4 className={"mb-3"}>No hay albaranes disponibles</h4> :
                <div>
                    <div className={"row"}>
                        <div className={"col-6 m"}>
                            <h4>{pagination.total_items} resultados</h4>
                        </div>
                        <div className={"col-6"}>
                            <button className={"btn btn-dark float-end"} onClick={downloadData}>
                                Exportar resultados
                            </button>
                        </div>
                    </div>
                    <div className={"table-responsive"}>
                        <table className={"table align-middle my-3"}>
                            <thead>
                            <tr>
                                <th>Paciente</th>
                                <th>Fecha</th>
                                <th>Precio</th>
                                <th>Deuda</th>
                                <th>Tipo</th>
                                <th>Fisioterapeuta</th>
                                <th/>
                                <th/>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {order && order.map((id) => {
                                if (data && data[id] !== undefined) {
                                    const cashRegister = data[id]
                                    return <tr key={id} value={id}
                                               className={cashRegister.unpaid > 0 ? "table-danger" : ""}>
                                        <td>{cashRegister.name_patient}</td>
                                        <td>{cashRegister.issue_date}</td>
                                        <td>{cashRegister.final_price} €</td>
                                        <td>{cashRegister.unpaid} €</td>
                                        <td>{cashRegister.name}</td>
                                        <td>{cashRegister.name_employee}</td>
                                        <td className="text-center">
                                            <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                                e.preventDefault()
                                                dispatchRedirect(dispatch, `/cash-register/${id}`)
                                            }}>
                                                Ver albarán
                                            </button>
                                        </td>

                                        {checkExistsField(cashRegister, "invoice_id") ? <td className={"text-center"}>
                                            <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                                e.preventDefault()
                                                dispatchRedirect(dispatch, `/invoice/${cashRegister.invoice_id}`)
                                            }}>
                                                Ver factura
                                            </button>
                                        </td> : <td className={"text-center"}>
                                            <button
                                                type="button"
                                                className="btn btn-dark btn-sm"
                                                disabled={!cashRegister.is_invoice_possible}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    dispatch(postInvoice({
                                                        cash_register_id: id,
                                                        clinic_id: activeClinic.id
                                                    }))
                                                }}>
                                                Crear factura
                                            </button>
                                        </td>}

                                        {cashRegister.unpaid > 0 ? <td>
                                            <RegisterChargePopup
                                                cashRegisterData={cashRegister}
                                                updateComponent={updateComponent}
                                            />
                                        </td> : <td/>}
                                    </tr>
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            <PaginatorComponent
                pagination={pagination}
                setFilter={setFilter}
                filter={filter}
            />
        </div>

    </div>
}

export default ListCashRegistersComponent;