import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Kalend, {CalendarView} from 'kalend';
import 'kalend/dist/styles/index.css'

import RegisterSessionPopup from "../services/session/popupRegisterSession/RegisterSessionPopup";
import EditSessionPopup from "../services/session/popupEditSession/EditSessionPopup";
import PhysiotherapistSelectorComponent from "../general/input/PhysiotherapistSelectorComponent";
import useScreenSize from "../../auxiliar/customHooks/useScreenSize";
import useQueryParams from "../../auxiliar/customHooks/useQueryParams";
import useLocalStorage from "../../auxiliar/customHooks/useLocalStorage";
import {dispatchRedirect} from "../../reducers/redirectReducer";
import {handleChangeFilterAux} from "../../auxiliar/handleChange/handleChangeFilterAux";
import {getAllCalendarData} from "../../actions/calendar";
import {parseJson} from "../../auxiliar/formValidators/auxValidators";


const FullScreenCalendarComponent = () => {
    const openTooltipRegister = useRef(null)
    const openTooltipEdit = useRef(null)
    const kalendRef = useRef();

    const dispatch = useDispatch()
    const activeClinic = useSelector(state => state.activeClinic)
    const screenSize = useScreenSize()
    const queryParams = useQueryParams()

    const [update, setUpdate] = useState(true)

    const [data, setData] = useState({
        display_date: undefined
    })

    // Don't change, error in Kalend library
    const [filter, setFilter] = useLocalStorage("calendar_filter", {
        clinic_id: activeClinic.id,
        per_page: 2000,
        starting_date: new Date(),
        finishing_date: "",
        view: "week",
        color_code: 'session_type',
        employee_id: ""
    }, queryParams.has('keep') && queryParams.get('keep') === 'true')

    const [events, setEvents] = useState([])

    useEffect(() => {
        if (filter.starting_date !== "" && filter.finishing_date !== "") {
            let startingDate = new Date(filter.starting_date)
            let finishingDate = new Date(filter.finishing_date)

            if (filter.view === "month") {
                if (startingDate.getDate() !== 1) {
                    startingDate.setMonth(startingDate.getMonth() + 1, 1);
                }
                if (finishingDate.getDate() !== 31 && finishingDate.getDate() !== 30 && finishingDate.getDate() !== 29 && finishingDate.getDate() !== 28) {
                    finishingDate.setMonth(finishingDate.getMonth(), 0);
                }
            }

            setData({
                display_date: startingDate.toLocaleDateString('es-ES', {
                    year: 'numeric',
                    month: 'long'
                })
            })
        }
    }, [filter])

    useEffect(() => {
        if (filter.starting_date !== "" && filter.finishing_date !== "") {
            setEvents([])
            setTimeout(() => dispatch(getAllCalendarData(filter, setEvents)), 1)
        }
    }, [filter.starting_date, filter.finishing_date, filter.employee_id, update, dispatch, setEvents])

    useEffect(() => {
        const new_events = []
        setEvents([])
        for (const e of events) {
            e.color = e.color_dict[filter.color_code]
            new_events.push(e)
        }
        setTimeout(() => setEvents(new_events), 1)
    }, [filter.color_code])

    const updateComponent = () => setUpdate(!update)

    const handleChangeFilter = (e) => {
        e.preventDefault()
        handleChangeFilterAux(e, filter, setFilter)
    }

    const onFullScreenLeaveClick = (e) => {
        e.preventDefault()
        dispatchRedirect(dispatch, `/agenda?keep=true`)
    };

    const onNewEventClick = (data) => {
        const actualFilter = "calendar_filter" in localStorage ?
            parseJson(window.localStorage.getItem('calendar_filter')) : {
                clinic_id: activeClinic.id,
                per_page: 2000,
                starting_date: undefined,
                finishing_date: undefined,
                initial_date: new Date(),
                view: "week",
                color_code: 'session_type',
                employee_id: ""
            }

        openTooltipRegister.current({...data, employee_id: actualFilter.employee_id})
    }

    const onEventClick = (data) => {
        if (data.type !== "block")
            openTooltipEdit.current(data)
    };

    const onEventDragFinish = (prev, current, data, resetPosition) => {
        if (current.type !== "block")
            openTooltipEdit.current(current)
        else
            resetPosition()
    }

    const goForward = (e) => {
        e.preventDefault()
        kalendRef?.current?.navigateForward()
    };

    const goBack = (e) => {
        e.preventDefault()
        kalendRef?.current?.navigateBackwards()
    };

    const goToday = (e) => {
        e.preventDefault()
        kalendRef?.current?.navigateToTodayDate()
    };

    const onStateChange = (state) => {
        // Don't change, error in Kalend library
        const actualFilter = "calendar_filter" in localStorage ?
            parseJson(window.localStorage.getItem('calendar_filter')) : {
                clinic_id: activeClinic.id,
                per_page: 2000,
                starting_date: undefined,
                finishing_date: undefined,
                initial_date: new Date(),
                view: "week",
                color_code: 'session_type',
                employee_id: ""
            }

        setFilter({
            ...actualFilter,
            starting_date: state.range.rangeFrom,
            finishing_date: state.range.rangeTo,
            initial_date: state.selectedDate,
            view: state.calendarDays.length === 1 ? CalendarView.DAY : state.selectedView
        })
    };

    if (screenSize.width < 401)
        return <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                <p className="fs-3"><span className="text-danger">Opps!</span> Página no disponible</p>
                <p className="lead">
                    Utilice un dispositivo con una pantalla más grande para acceder. Haz click <Link
                    to="/agenda">aquí</Link> para volver a la agenda.
                </p>
            </div>
        </div>

    else if (screenSize.width < 780)
        return <div className={"h-100 w-100"}>
            <div className={"row px-3 align-items-center py-1"} style={{height: "5vh", width: "100vw"}}>
                <div className={"col-4 p-2"}>
                    <button className={"btn btn-success btn-sm float-none"} onClick={goBack}>
                        {"<"}
                    </button>
                    <button className={"btn btn-success btn-sm mx-1 float-none"} onClick={goToday}>
                        Hoy
                    </button>
                    <button className={"btn btn-success btn-sm float-none"} onClick={goForward}>
                        {">"}
                    </button>
                </div>

                <div className={"col-4 p-2 text-center"}>
                    <span>{data.display_date}</span>
                </div>

                <div className={"col-4 p-2"}>
                    <button className={"btn btn-success btn-sm float-end"} onClick={onFullScreenLeaveClick}>
                        Salir
                    </button>
                </div>
            </div>

            <div className={"row px-3 align-items-center"} style={{height: "8vh", width: "100vw"}}>
                <div className={"col-4 p-2"}>
                    <PhysiotherapistSelectorComponent
                        handleChangeFilter={handleChangeFilter}
                        value={filter.employee_id}
                        showLabel={false}
                    />
                </div>

                <div className={"col-4 p-2"}>
                    <select className="form-select" onChange={handleChangeFilter} name={"color_code"}
                            value={filter.color_code}>
                        <option key={"session_type"} value={"session_type"}>
                            Tipos de sesión
                        </option>
                        <option key={"employee"} value={"employee"}>
                            Fisioterapeutas
                        </option>
                        <option key={"state"} value={"state"}>
                            Estado de la sesion
                        </option>
                    </select>
                </div>

                <div className={"col-4 p-2"}>
                    <select className="form-select w-10" onChange={handleChangeFilter} name={"view"}
                            value={filter.view}>
                        <option key={CalendarView.MONTH} value={CalendarView.MONTH}>
                            Mes
                        </option>
                        <option key={CalendarView.WEEK} value={CalendarView.WEEK}>
                            Semana
                        </option>
                        <option key={CalendarView.THREE_DAYS} value={CalendarView.THREE_DAYS}>
                            Tres días
                        </option>
                        <option key={CalendarView.DAY} value={CalendarView.DAY}>
                            Dia
                        </option>
                        <option key={CalendarView.AGENDA} value={CalendarView.AGENDA}>
                            Diario
                        </option>
                    </select>
                </div>
            </div>

            <div style={{height: "87vh", width: "100vw"}}>
                <Kalend
                    kalendRef={kalendRef}
                    colors={{light: {primaryColor: 'green'}}}
                    language={'es'}
                    hourHeight={100}
                    autoScroll={true}
                    showTimeLine={true}
                    newEventText={"Nueva sesión"}
                    disabledViews={[]}
                    selectedView={filter.view}
                    initialView={CalendarView.WEEK}
                    initialDate={filter.initial_date ? new Date(filter.initial_date).toISOString() : new Date().toISOString()}
                    events={events}
                    onEventDragFinish={onEventDragFinish}
                    onNewEventClick={onNewEventClick}
                    onEventClick={onEventClick}
                    onStateChange={onStateChange}
                    draggingDisabledConditions={{type: "block"}}
                    resizeDisabledConditions={{resize: "disabled"}}
                />
            </div>

            <RegisterSessionPopup
                childFunc={openTooltipRegister}
                updateComponent={updateComponent}
            />

            <EditSessionPopup
                childFunc={openTooltipEdit}
                updateComponent={updateComponent}
                openTooltipRegister={openTooltipRegister}
            />
        </div>


    return <div className={"h-100 w-100"}>
        <div className={"row px-3 align-items-center"} style={{height: "6vh", width: "100vw"}}>
            <div className={"col-2 p-2"}>
                <PhysiotherapistSelectorComponent
                    handleChangeFilter={handleChangeFilter}
                    value={filter.employee_id}
                    showLabel={false}
                />
            </div>

            <div className={"col-2 p-2"}>
                <select className="form-select" onChange={handleChangeFilter} name={"color_code"}
                        value={filter.color_code}>
                    <option key={"session_type"} value={"session_type"}>
                        Tipos de sesión
                    </option>
                    <option key={"employee"} value={"employee"}>
                        Fisioterapeutas
                    </option>
                    <option key={"state"} value={"state"}>
                        Estado de la sesion
                    </option>
                </select>
            </div>

            <div className={"col-2 p-2"}>
                <select className="form-select w-10" onChange={handleChangeFilter} name={"view"}
                        value={filter.view}>
                    <option key={CalendarView.MONTH} value={CalendarView.MONTH}>
                        Mes
                    </option>
                    <option key={CalendarView.WEEK} value={CalendarView.WEEK}>
                        Semana
                    </option>
                    <option key={CalendarView.THREE_DAYS} value={CalendarView.THREE_DAYS}>
                        Tres días
                    </option>
                    <option key={CalendarView.DAY} value={CalendarView.DAY}>
                        Dia
                    </option>
                    <option key={CalendarView.AGENDA} value={CalendarView.AGENDA}>
                        Diario
                    </option>
                </select>
            </div>

            <div className={"col-2 p-2"}>
                <button className={"btn btn-success btn-sm float-end"} onClick={goForward}>
                    {">"}
                </button>
                <button className={"btn btn-success btn-sm mx-1 float-end"} onClick={goToday}>
                    Hoy
                </button>
                <button className={"btn btn-success btn-sm float-end"} onClick={goBack}>
                    {"<"}
                </button>
            </div>

            <div className={"col-2 p-2 text-center"}>
                <span>{data.display_date}</span>
            </div>

            <div className={"col-2 p-2"}>
                <button className={"btn btn-success btn-sm float-end"} onClick={onFullScreenLeaveClick}>
                    Salir
                </button>
            </div>
        </div>

        <div style={{height: "94vh", width: "100vw"}}>
            <Kalend
                kalendRef={kalendRef}
                colors={{light: {primaryColor: 'green'}}}
                language={'es'}
                hourHeight={120}
                autoScroll={true}
                showTimeLine={true}
                newEventText={"Nueva sesión"}
                disabledViews={[]}
                selectedView={filter.view}
                initialView={CalendarView.WEEK}
                initialDate={filter.initial_date ? new Date(filter.initial_date).toISOString() : new Date().toISOString()}
                events={events}
                onEventDragFinish={onEventDragFinish}
                onNewEventClick={onNewEventClick}
                onEventClick={onEventClick}
                onStateChange={onStateChange}
                draggingDisabledConditions={{type: "block"}}
                resizeDisabledConditions={{resize: "disabled"}}
            />
        </div>

        <RegisterSessionPopup
            childFunc={openTooltipRegister}
            updateComponent={updateComponent}
        />

        <EditSessionPopup
            childFunc={openTooltipEdit}
            updateComponent={updateComponent}
            openTooltipRegister={openTooltipRegister}
        />
    </div>
}

export default FullScreenCalendarComponent;