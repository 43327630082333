import axios from "axios";

import {dispatchActiveClinicClean, dispatchActiveClinicSave} from "../reducers/activeClinicReducer";
import {dispatchLogin} from "../reducers/authReducer";
import {dispatchRedirect} from "../reducers/redirectReducer";


export const getAllClinicsData = (params, setData) => {
    return () => {
        axios.get(`/api/managers/clinics/`, {params: params})
            .then(response => {
                setData(response.data.content)
            })
    }
}


export const getClinicData = (id, dataLevel, setClinic, setSchedule, setInvoice, setLogoURL, setLink) => {
    return () => {
        axios.get(`/api/managers/clinics/${id}?data_level=${dataLevel}`)
            .then(response => {
                const data = response.data.content
                setClinic(data.clinic_data)

                if (dataLevel === 'advanced') {
                    setSchedule(data.schedule)
                    setInvoice(data.invoice_template)
                    setLogoURL(data.logo_url)
                    setLink(data.calendar_link)
                }
            })
    }
}

export const postClinicData = (data, setRegisterDisabled) => {
    return (dispatch) => {
        axios.post('/api/managers/clinics/', data)
            .then(response => {
                const clinics = response.data.content.clinics
                const clinicId = Object.keys(clinics).find(key => clinics[key].name === data.name)
                const clinic = clinics[clinicId]

                dispatchActiveClinicSave(dispatch, clinicId, clinic.name, clinic.status)

                dispatchLogin(dispatch, response.data.content)
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}

export const putClinicData = (id, data, onRequestSuccess, onRequestFail) => {
    let req_data = JSON.parse(JSON.stringify(data))

    return () => {
        axios.put(`/api/managers/clinics/${id}`, req_data)
            .then(() => {
                onRequestSuccess()
            }).catch(() => {
                onRequestFail()
            }
        )
    }
}

export const putClinicScheduleData = (id, data, setUpdateDisabled, setDisabled) => {

    return () => {
        axios.put(`/api/managers/clinics/${id}/update_schedule`, data)
            .then(() => {
                setDisabled(true)
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}

export const putClinicInvoiceTemplateData = (id, data, setDisabled, setUpdateDisabled) => {
    let req_data = JSON.parse(JSON.stringify(data))

    return () => {
        axios.put(`/api/managers/clinics/update_invoice_template/${id}`, req_data)
            .then(() => {
                setDisabled(true)
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}

export const deleteClinic = (id) => {
    return (dispatch) => {
        axios.delete(`/api/managers/clinics/${id}`,)
            .then(response => {
                dispatchLogin(dispatch, response.data.content)
                dispatchActiveClinicClean(dispatch)
                dispatchRedirect(dispatch, "/clinic")
            })
    }
}

export const deleteClinicProfile = (id, onRequestSuccess, onRequestFail) => {
    return () => {
        axios.delete(`/api/managers/clinics/${id}/remove_password`,)
            .then(() => {
                onRequestSuccess('delete')
            }).catch(() => {
            onRequestFail()
        })
    }
}

export const uploadLogo = (clinicId, data, setData) => {
    return () => {
        let formData = new FormData();

        //Adding file to the formdata
        formData.append("clinic_id", clinicId);
        formData.append("logo", data.logo);

        axios.post(`/api/managers/clinics/add_logo/${clinicId}`, formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then((response) => {
                setData({
                    ...data,
                    logo_url: response.data.content.logo_url
                })
            })
    }
}

export const deleteLogo = (clinicId, setLogoData) => {
    return () => {
        axios.delete(`/api/managers/clinics/remove_logo/${clinicId}`)
            .then(() => {
                setLogoData({
                    logo: "",
                    logo_url: "",
                    file_size: 0
                })
            })
    }
}

export const getClinicPublicData = (params, setData, setSchedule, setLogo) => {
    return () => {
        axios.get(`/api/managers/clinics/public_data`, {params: params})
            .then(response => {
                const data = response.data.content

                setData(data.clinic_data)
                setSchedule(data.schedule)
                setLogo(data.logo_url)
            })
    }
}