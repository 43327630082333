import axios from "axios";
import {dispatchMessageClean, dispatchMessageSave} from "../reducers/messageReducer";
import {dispatchLogout} from "../reducers/authReducer";
import {useDispatch, useSelector} from "react-redux";

const CookiesManager = () => {
    const dispatch = useDispatch()
    const message = useSelector(state => state.message)

    axios.defaults.withCredentials = true;
    axios.interceptors.response.use(
        response => {
            if (response && response.data && response.data.cookies) {
                axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.cookies.csrf_access_token;

                window.localStorage.setItem("csrf_refresh_token", response.data.cookies.csrf_refresh_token);
                window.localStorage.setItem("refresh_token", response.data.cookies.refresh_token);
            }

            if (message.date !== undefined)
                dispatchMessageClean(dispatch)

            return response
        }, err => {
            const originalRequest = err.config;

            if (originalRequest.url === "/api/managers/refresh_token") {
                dispatchLogout(dispatch)
                dispatchMessageSave(dispatch, "Se ha cerrado la sesión por inactividad. Inicie sesión de nuevo si desea seguir utilizando el servicio.")
            } else if (err.response && err.response.status === 401 && !originalRequest._retry && localStorage.getItem("csrf_refresh_token") !== null) {
                originalRequest._retry = true;
                const csrfRefreshToken = window.localStorage.getItem('csrf_refresh_token');
                const refreshTokenCookie = window.localStorage.getItem('refresh_token');

                return axios.post(
                    '/api/managers/refresh_token', {},
                    {headers: {"X-CSRF-TOKEN": csrfRefreshToken, "REFRESH-TOKEN": refreshTokenCookie}}
                ).then(async response => {
                    if (response.status === 200) {
                        axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.cookies.csrf_access_token;
                        originalRequest.headers["X-CSRF-TOKEN"] = response.data.cookies.csrf_access_token;

                        return axios(originalRequest);
                    }
                })
            }

            if (err.response && err.response.data && err.response.data.msg) {
                if (err.response.data.msg === "Token has expired") {
                    dispatchMessageSave(dispatch, "Se ha cerrado la sesión por inactividad", true)
                } else {
                    dispatchMessageSave(dispatch, err.response.data.msg, true)
                }
            }

            return Promise.reject(err);
        }
    );

    return null
}

export default CookiesManager;