import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NotFoundScreen from "../../screens/public/NotFoundScreen";
import PaymentPlanComponent from "../paymentGateway/PaymentPlanComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import InputComponent from "../general/input/InputComponent";
import CheckBoxComponent from "../general/input/CheckBoxComponent";
import useScreenSize from "../../auxiliar/customHooks/useScreenSize";
import {deleteClinic, putClinicData} from "../../actions/clinics";
import {validateClinicData} from "../../auxiliar/formValidators/clinicValidators";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";


const EditClinicComponent = ({clinic, setClinic, remount, editAllowed = true}) => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)
    const screenSize = useScreenSize()

    const [errors, setErrors] = useState({})

    const [disabled, setDisabled] = useState(true)

    const [updateDisabled, setUpdateDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, clinic, setClinic)

    const onRequestSuccess = () => {
        setDisabled(true)
        setUpdateDisabled(false)
        remount()
    }

    const onRequestFail = () => setUpdateDisabled(false)

    const onEditClick = (e) => {
        e.preventDefault()

        setDisabled(false)
    }

    const onCancelClick = (e) => {
        e.preventDefault()

        setDisabled(true)
        setErrors({})
        remount()
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validateClinicData(clinic, setErrors))
            dispatch(putClinicData(activeClinic.id, {new_info: clinic}, onRequestSuccess, onRequestFail))
        else
            setUpdateDisabled(false)
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        if (window.confirm('¿Está seguro de que quiere eliminar la clínica? No se podrá recuperar la información'))
            dispatch(deleteClinic(activeClinic.id))
    }

    if (!clinic)
        return <NotFoundScreen version={'clinic'}/>

    return <div className="my-5">
        <h2>Clínica {activeClinic.name}</h2>
        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            {screenSize.width < 768 ? <>
                <div className={"col-12"}>
                    <PaymentPlanComponent
                        payment_plan={clinic.payment_plan}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Nombre *"}
                        onChange={handleChange}
                        value={clinic.name}
                        type={"text"}
                        name={"name"}
                        placeholder={"Nombre"}
                        disabled={disabled}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Correo electrónico *"}
                        onChange={handleChange}
                        value={clinic.email}
                        type={"text"}
                        name={"email"}
                        placeholder={"ejemplo@mail.com"}
                        disabled={disabled}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Teléfono móvil"}
                        onChange={handleChange}
                        value={clinic.mobile_phone}
                        type={"tel"}
                        name={"mobile_phone"}
                        placeholder={"Número de teléfono móvil"}
                        disabled={disabled}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Teléfono fijo"}
                        onChange={handleChange}
                        value={clinic.landline_phone}
                        type={"tel"}
                        name={"landline_phone"}
                        placeholder={"Número de teléfono fijo"}
                        disabled={disabled}
                    />
                </div>
            </> : <>
                <div className="col-6">
                    <div className="col-12 mt-3">
                        <InputComponent
                            label={"Nombre *"}
                            onChange={handleChange}
                            value={clinic.name}
                            type={"text"}
                            name={"name"}
                            placeholder={"Nombre"}
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <InputComponent
                            label={"Correo electrónico *"}
                            onChange={handleChange}
                            value={clinic.email}
                            type={"text"}
                            name={"email"}
                            placeholder={"ejemplo@mail.com"}
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <InputComponent
                            label={"Teléfono móvil"}
                            onChange={handleChange}
                            value={clinic.mobile_phone}
                            type={"tel"}
                            name={"mobile_phone"}
                            placeholder={"Número de teléfono móvil"}
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <InputComponent
                            label={"Teléfono fijo"}
                            onChange={handleChange}
                            value={clinic.landline_phone}
                            type={"tel"}
                            name={"landline_phone"}
                            placeholder={"Número de teléfono fijo"}
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div className={"col-6 d-flex align-items-center"}>
                    <PaymentPlanComponent
                        payment_plan={clinic.payment_plan}
                    />
                </div>
            </>}
            <div className="col-12">
                <InputComponent
                    label={"Dirección"}
                    onChange={handleChange}
                    value={clinic.address}
                    type={"text"}
                    name={"address"}
                    placeholder={"Dirección"}
                    disabled={disabled}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Provincia"}
                    onChange={handleChange}
                    value={clinic.province}
                    type={"text"}
                    name={"province"}
                    placeholder={"Provincia"}
                    disabled={disabled}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Localidad"}
                    onChange={handleChange}
                    value={clinic.town}
                    type={"text"}
                    name={"town"}
                    placeholder={"Localidad"}
                    disabled={disabled}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"CIF/NIF"}
                    onChange={handleChange}
                    value={clinic.CIF}
                    type={"text"}
                    name={"CIF"}
                    placeholder={"CIF/NIF"}
                    disabled={disabled}
                    title={"Identificador de la empresa, necesario para emitir facturas"}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Código de factura *"}
                    onChange={handleChange}
                    value={clinic.invoice_code}
                    type={"text"}
                    name={"invoice_code"}
                    placeholder={"Código factura"}
                    disabled={disabled}
                    title={"Código alfanumérico que servirá como prefijo para identificar las facturas"}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Siguiente nº de factura *"}
                    onChange={handleChange}
                    value={clinic.invoice_number}
                    type={"number"}
                    name={"invoice_number"}
                    placeholder={"Nº factura"}
                    disabled={disabled}
                    title={"Numeración de la próxima factura generada"}
                />
            </div>
            <div className="col-4">
                <InputComponent
                    label={"Código de la primera factura"}
                    onChange={handleChange}
                    value={clinic.invoice_code + '-' + clinic.invoice_number}
                    type={"text"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <CheckBoxComponent
                    value={clinic.task_generation}
                    onChange={handleChange}
                    name={"task_generation"}
                    label={"Recordatorios cumpleaños de pacientes"}
                    title={"Marca este campo si quieres que se generen tareas para los cumpleaños de los pacientes de forma automática"}
                    disabled={disabled}
                />
            </div>
            <div className="col-6">
                <CheckBoxComponent
                    title={"Marca este campo si desea que se puedan realizar citas online para esta clínica"}
                    label={"Citas online"}
                    onChange={handleChange}
                    value={clinic.online_booking}
                    name={"online_booking"}
                    disabled={disabled}
                />
            </div>
            <div className="col-12 container-fluid">
                {editAllowed && disabled ? <button className="btn btn-dark" onClick={onEditClick}>
                    Editar
                </button> : null}
                {!disabled ? <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button> : null}
                {!disabled ? <button className="mx-4 btn btn-dark" onClick={onCancelClick}>
                    Cancelar
                </button> : null}
                {editAllowed &&
                    <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                        Eliminar
                    </button>}
            </div>
        </form>
    </div>
}

export default EditClinicComponent;