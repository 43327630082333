import axios from 'axios';
import {dispatchLogout} from "../reducers/authReducer";


export const getManagerData = (id, setData) => {
    return () => {
        axios.get(`/api/managers/managers/${id}`)
            .then(response => {
                setData(response.data.content)
            })
    }
}

export const putManagerData = (id, data, onSuccessUpdate, onErrorRequest) => {
    let req_data = JSON.parse(JSON.stringify(data))

    return () => {
        axios.put(`/api/managers/managers/${id}`, req_data)
            .then(() => {
                onSuccessUpdate()
            }).catch(() => {
                onErrorRequest()
            }
        )
    }
}

export const deleteManagerData = (id, setUpdateDisabled) => {
    return (dispatch) => {
        axios.delete(`/api/managers/managers/${id}`)
            .then(() => {
                dispatchLogout(dispatch)
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}

export const validateManager = (id, code, setData) => {
    return () => {
        axios.post(`/api/managers/validate_email/${id}`, {"validation_code": code})
            .then(() => {
                setData('valid')
            })
            .catch(() => {
                setData('invalid')
            })
    }
}

export const resetPassword = (code, setData) => {
    return () => {
        axios.post(`/api/managers/reset_password`, {"code": code})
            .then(() => {
                setData('valid')
            })
            .catch(() => {
                setData('invalid')
            })
    }
}