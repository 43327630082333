import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../../../general/input/InputComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import {postGroupSessionTypeData} from "../../../../actions/groupSessionTypes";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateGroupSessionType} from "../../../../auxiliar/formValidators/groupSessionTypeValidators";
import ColorSelectorComponent from "../../../general/input/ColorSelectorComponent";


const RegisterGroupSessionTypeComponent = () => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        name: "",
        number_participants: "",
        session_duration: "",
        price: "",
        clinic_id: activeClinic.id,
        color: "blue"
    })

    const [errors, setErrors] = useState({})

    const [registerDisabled, setRegisterDisabled] = useState(false)

    const handleChange = (e) => {
        handleChangeAux(e, data, setData)
    }

    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validateGroupSessionType(data, setErrors)) {
            dispatch(postGroupSessionTypeData(data, setRegisterDisabled))
        }
        else
            setRegisterDisabled(false)
    }

    return (
        <div className="container my-5">
            <h2>Registro de un nuevo tipo de clase</h2>
            <MessageSpan messagesDict={errors}/>

            <form className="row g-3">
                <div className="col-6">
                    <InputComponent
                        label={"Nombre *"}
                        onChange={handleChange}
                        value={data.name}
                        type={"text"}
                        name={"name"}
                        placeholder={"Nombre"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Nº máximo de participantes *"}
                        onChange={handleChange}
                        value={data.number_participants}
                        type={"number"}
                        name={"number_participants"}
                        placeholder={"1"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Precio base de la clase *"}
                        onChange={handleChange}
                        value={data.price}
                        type={"number"}
                        name={"price"}
                        placeholder={"Precio base"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Duración de la clase en minutos *"}
                        onChange={handleChange}
                        value={data.session_duration}
                        type={"number"}
                        name={"session_duration"}
                        placeholder={"60"}
                    />
                </div>
                <div className="col-12">
                    <ColorSelectorComponent
                        value={data.color}
                        onChange={handleChange}/>
                </div>
                <div className="col-12 container-fluid">
                    <button className="btn btn-dark" onClick={onRegisterClick} disabled={registerDisabled}>
                        Confirmar
                    </button>
                </div>
            </form>
        </div>
    )
}

export default RegisterGroupSessionTypeComponent;