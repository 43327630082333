import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import EditClinicComponent from "../../../components/clinic/EditClinicComponent";
import ListClinicsComponent from "../../../components/clinic/ListClinicsComponent";
import {getAllClinicsData} from "../../../actions/clinics";


const MainClinicScreen = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const activeClinic = useSelector(state => state.activeClinic)

    const [clinics, setClinics] = useState({})

    const [selectedClinic, setSelectedClinic] = useState({})

    const [remountBool, setRemountBool] = useState(false)

    useEffect(() => {
        dispatch(getAllClinicsData({'manager_id': auth.id}, setClinics))
    }, [remountBool, dispatch, setClinics, activeClinic])

    useEffect(() => {
        setSelectedClinic(clinics[activeClinic.id])
    }, [activeClinic.id, clinics, setSelectedClinic])

    const remount = () => setRemountBool(!remountBool)

    return <div className={'container'}>
        <ListClinicsComponent
            clinics={clinics}
        />

        <EditClinicComponent
            clinic={selectedClinic}
            setClinic={setSelectedClinic}
            remount={remount}
        />
    </div>
}

export default MainClinicScreen;
