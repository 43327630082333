import React from "react";

import RegisterChargePopup from "./popupRegisterCharge/RegisterChargePopUp";


const ListChargeComponent = ({charges, cashRegisterData, updateComponent}) => {
    let totalPaid = 0

    return <div className={"container my-5"}>
        <h2>Pagos</h2>

        {Object.keys(charges).length <= 0 ?
            <h4 className={"mb-3"}>No hay pagos disponibles</h4> :
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Pagado</th>
                        <th>Método de pago</th>
                    </tr>
                    </thead>
                    <tbody>
                    {charges && Object.keys(charges).map((id) => {
                        totalPaid = totalPaid + charges[id].paid
                        return <tr key={id}>
                            <td>{charges[id].charge_date}</td>
                            <td>{charges[id].paid}</td>
                            <td>{charges[id].payment_method}</td>
                        </tr>
                    })}
                    <tr key={-1}>
                        <td className={"fw-bold"}>Total pagado</td>
                        <td>{totalPaid}</td>
                        <td>-</td>
                    </tr>
                    </tbody>
                </table>
            </div>}

        {cashRegisterData.unpaid > 0 && <div className="col-12 container-fluid">
            <RegisterChargePopup
                cashRegisterData={cashRegisterData}
                updateComponent={updateComponent}
                btnClass={"btn-dark"}
            />
        </div>}
    </div>
}

export default ListChargeComponent;