import React from "react";


const ShowGroupSessionComponent = ({groupSession, openTooltipEdit}) => {
    if (!groupSession)
        return <div className="container my-5">
            <h2>Datos de la clase no disponibles</h2>
            <p>Los datos de la clase han sido eliminados</p>
        </div>

    return <div className="container my-5">
        <h2>Datos de la clase</h2>
        <div className={"table-responsive"}>
            <table className={"table align-middle my-3"}>
                <thead>
                <tr>
                    <th>Fecha</th>
                    <th>Entrada</th>
                    <th>Salida</th>
                    <th>Fisioterapeuta</th>
                    <th>Tipo</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                <tr key={groupSession.group_session_id}>
                    <td>{groupSession.start_datetime.substring(0, 10)}</td>
                    <td>{groupSession.start_datetime.substring(11, 16)}</td>
                    <td>{groupSession.finish_datetime.substring(11, 16)}</td>
                    <td>{groupSession.employee_name}</td>
                    <td>{groupSession.name}</td>
                    <td className="text-center">
                        <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                            e.preventDefault()
                            openTooltipEdit.current({
                                ...groupSession,
                                event_type: 'group',
                                startAt: groupSession.start_datetime,
                                endAt: groupSession.finish_datetime,
                                id: groupSession.group_session_id
                            })
                        }}>
                            Editar
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
}

export default ShowGroupSessionComponent;