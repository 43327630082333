import React from "react";

import {useDispatch} from "react-redux";
import {dispatchRedirect} from "../../reducers/redirectReducer";

import invoice_example from "../../media/Tutorial/invoice_example.png"


const EditInvoiceTemplateTutorial = () => {
    const dispatch = useDispatch();

    const onReturnClick = (e) => {
        e.preventDefault()

        dispatchRedirect(dispatch, "/clinic")
    }

    return <div className={"container my-5"}>
        <h2>Tutorial plantilla de factura</h2>
        <p>MiFisio Agenda permite descargar las facturas generadas o enviarlas por correo al paciente.</p>
        <p>Para ello proporcionamos una plantilla con algunos elementos personalizables.</p>
        <p>
            Hay 4 elementos que se pueden modificar:
        </p>
        <ol>
            <li>El logo se modificará para mostrar el logo de la clínica en caso de que se haya añadido.</li>
            <li>El elemento "Texto 1" permite poner un pequeño texto en vertical en el márgen de la página.</li>
            <li>El elemento "Texto 2" permite incluir un texto de hasta 1000 caracteres en la parte inferior de la
                página.
            </li>
            <li>El elemento "Texto 3" permite incluir un pequeño texto en el pie de página.</li>
        </ol>
        <div>
            <img src={invoice_example} alt={'Ejemplo de factura generada por MiFisio Agenda'}/>
        </div>
        <p>
            Para una mayor personalización, se pueden incluir diferentes etiquetas en el texto:
        </p>
        <ol>
            <li>Utiliza la etiqueta &lt;strong&gt;&lt;/strong&gt; para incluir texto en negrita. Por ejemplo:</li>
            <lu>El texto "&lt;strong&gt;Texto en negrita&lt;/strong&gt;" producirá el siguiente resultado: <strong>Texto
                en negrita</strong></lu>
            <li>Utiliza la etiqueta &lt;u&gt;&lt;/u&gt; para incluir texto subrayado. Por ejemplo:</li>
            <lu>El texto "&lt;u&gt;Texto subrayado&lt;/u&gt;" producirá el siguiente resultado: <u>Texto subrayado</u>
            </lu>
            <li>Utiliza la etiqueta &lt;i&gt;&lt;/i&gt; para incluir texto en cursiva. Por ejemplo:</li>
            <lu>El texto "&lt;i&gt;Texto en cursiva&lt;/i&gt;" producirá el siguiente resultado: <i>Texto en cursiva</i>
            </lu>
            <li>Utiliza la etiqueta &lt;/br&gt; para incluir un salto de línea. Por ejemplo:</li>
            <lu>El texto "Texto línea 1&lt;/br&gt;Texto línea 2" producirá el siguiente resultado:<br></br> Texto línea
                1<br></br>Texto línea 2
            </lu>
        </ol>
        <p>Estas etiquetas se pueden combinar, por ejemplo, el texto "&lt;strong&gt;&lt;u&gt;Texto en negrita y
            subrayado&lt;/u&gt;&lt;/strong&gt;" producirá el siguiente resultado:<strong><u>Texto en negrita y
                subrayado</u></strong>.</p>

        <button className="btn btn-dark" onClick={onReturnClick}>
            Volver
        </button>
    </div>
}

export default EditInvoiceTemplateTutorial;