import React, {useState} from "react";
import InputComponent from "../general/input/InputComponent";

const ShowClinicBookingsComponent = ({link}) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (e) => {
        e.preventDefault()

        navigator.clipboard.writeText(link)
            .then(() => {
                setCopied(true);
            })
    }

    return <div className="my-5">
        <h2>Reservas online</h2>

        <p>
            Para que los pacientes reserven sesiones online puedes enviarles el siguiente enlace, donda podrán ver los
            huecos libres (no se mostrará información personal) y podrán solicitar una sesión.
            Cuando lo hagan la clínica recibirá un aviso por correo electrónico y la solicitud aparecerá en el
            calendario. Es necesario que la clínica confirme la cita.
        </p>

        <form className="row g-3">
            <div className={"col-12"}>
                <InputComponent
                    label={"Código de referido"}
                    value={link}
                    type={"text"}
                    name={"referral_code"}
                    disabled={true}
                />
            </div>
            <div className={"col-12"}>
                <button className={"btn btn-dark"} onClick={copyToClipboard}>
                    Copiar al portapapeles
                </button>
                {copied && <span className={'mx-3'}>
                    Copiado con éxito.
                </span>}
            </div>

        </form>
    </div>
}

export default ShowClinicBookingsComponent;