import {checkExistsField} from "./auxValidators";

export const validateLogoData = (data, setErrors) => {
    const errors = {}
    if (!checkExistsField(data, 'logo'))
        errors['logo'] = "Seleccione el archivo que desea almacenar"
    else if (data.file_size > 25)
        errors['logo'] = "El archivo es demasido grande"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}