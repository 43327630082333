import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../general/input/InputComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import {putManagerData} from "../../actions/manager";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";
import {validateManagerPassword} from "../../auxiliar/formValidators/managerValidators";


const UpdateManagerPasswordComponent = () => {
    const dispatch = useDispatch()
    const id = useSelector(state => state.auth.id)

    const [data, setData] = useState({
        password: "",
        new_info_password: "",
        new_info_password_rep: ""
    })

    const [errors, setErrors] = useState({})

    const [updateDisabled, setUpdateDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onUpdatePasswordClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        const req_data = {
            password: data.password,
            new_info: {
                password: data.new_info_password,
                password_rep: data.new_info_password_rep
            }
        }
        if (validateManagerPassword(req_data.new_info, setErrors)) {
            dispatch(putManagerData(id, req_data, setUpdateDisabled))
            setData({
                password: "",
                new_info_password: "",
                new_info_password_rep: ""
            })
        } else
            setUpdateDisabled(false)
    }

    return <div className="container my-5">
        <h2>Cambiar contraseña</h2>

        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-12">
                <InputComponent
                    label={"Contraseña actual"}
                    onChange={handleChange}
                    value={data.password}
                    type={"password"}
                    name={"password"}
                    placeholder={"Contraseña"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Nueva contraseña"}
                    onChange={handleChange}
                    value={data.new_info_password}
                    type={"password"}
                    name={"new_info_password"}
                    placeholder={"Contraseña"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Repita la nueva contraseña"}
                    onChange={handleChange}
                    value={data.new_info_password_rep}
                    type={"password"}
                    name={"new_info_password_rep"}
                    placeholder={"Contraseña"}
                />
            </div>
            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onUpdatePasswordClick} disabled={updateDisabled}>
                    Confirmar
                </button>
            </div>
        </form>
    </div>
}

export default UpdateManagerPasswordComponent;