import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import NotFoundScreen from "../../public/NotFoundScreen";
import EditPatientComponent from "../../../components/patient/EditPatientComponent";
import ListAcquiredVoucherComponent
    from "../../../components/services/voucher/acquiredVoucher/ListAcquiredVoucherComponent";
import ListCashRegistersInPatientComponent from "../../../components/cashRegister/ListCashRegistersInPatientComponent";
import ListSessionsInPatientComponent
    from "../../../components/services/session/session/ListSessionsInPatientComponent";
import RegisterSessionPopup from "../../../components/services/session/popupRegisterSession/RegisterSessionPopup";
import EditSessionPopup from "../../../components/services/session/popupEditSession/EditSessionPopup";
import ListGroupSessionsInPatientComponent
    from "../../../components/services/session/groupSession/ListGroupSessionsInPatientComponent";
import {checkExistsField} from "../../../auxiliar/formValidators/auxValidators";
import {getPatientCard} from "../../../actions/patient";
import {getAllCashRegistersData} from "../../../actions/cashRegister";
import {getAllDocumentsData} from "../../../actions/document";
import {getAllSessionsData} from "../../../actions/session";
import {getAllGroupSessionsData} from "../../../actions/groupSession";
import {getAllAcquiredVoucherData} from "../../../actions/acquiredVoucher";


const PatientCardScreen = () => {
    const openTooltipRegister = useRef(null)
    const openTooltipEdit = useRef(null)

    const dispatch = useDispatch()
    const params = useParams();
    const activeClinic = useSelector(state => state.activeClinic)

    const [update, setUpdate] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)

    const [patientData, setPatientData] = useState({})
    const [existingPatient, setExistingPatient] = useState(checkExistsField(params, 'patient_id'))

    const [sessionsData, setSessionsData] = useState({})
    const [sessionsPagination, setSessionsPagination] = useState({})
    const [sessionsOrder, setSessionsOrder] = useState([])
    const [sessionsFilter, setSessionsFilter] = useState({
        per_page: 5,
        page: 1,
        clinic_id: activeClinic.id,
        patient_id: params.patient_id,
        order: "date"
    })

    const [groupSessionsData, setGroupSessionsData] = useState({})
    const [groupSessionsPagination, setGroupSessionsPagination] = useState({})
    const [groupSessionsOrder, setGroupSessionsOrder] = useState([])
    const [groupSessionsFilter, setGroupSessionsFilter] = useState({
        per_page: 5,
        page: 1,
        clinic_id: activeClinic.id,
        patient_id: params.patient_id,
        order: "date"
    })

    const [cashRegistersData, setCashRegistersData] = useState({})
    const [cashRegistersPagination, setCashRegistersPagination] = useState({})
    const [cashRegisterOrder, setCashRegisterOrder] = useState([])
    const [cashRegistersFilter, setCashRegistersFilter] = useState({
        per_page: 5,
        page: 1,
        clinic_id: activeClinic.id,
        patient_id: params.patient_id,
        unpaid: false,
        has_invoice: false,
        order: "issue_date"
    })

    const [acquiredVouchersData, setAcquiredVouchersData] = useState({})
    const [acquiredVouchersPagination, setAcquiredVouchersPagination] = useState({})
    const [acquiredVouchersOrder, setAcquiredVouchersOrder] = useState([])
    const [acquiredVouchersFilter, setAcquiredVouchersFilter] = useState({
        per_page: 5,
        page: 1,
        clinic_id: activeClinic.id,
        patient_id: params.patient_id,
        remaining_sessions: true,
        not_expired: true,
        order: "remaining_session-due_date"
    })

    const [documentsData, setDocumentsData] = useState({})
    const [documentsPagination, setDocumentsPagination] = useState({})
    const [documentsOrder, setDocumentsOrder] = useState([])
    const [documentsFilter, setDocumentsFilter] = useState({
        per_page: 5,
        page: 1,
        clinic_id: activeClinic.id,
        patient_id: params.patient_id,
        order: "upload_date"
    })

    useEffect(() => {
        if (existingPatient)
            dispatch(getPatientCard(params.patient_id, {'clinic_id': activeClinic.id},
                setPatientData, setFirstLoad, setExistingPatient,
                setSessionsData, setSessionsPagination, setSessionsOrder,
                setGroupSessionsData, setGroupSessionsPagination, setGroupSessionsOrder,
                setCashRegistersData, setCashRegistersPagination, setCashRegisterOrder,
                setAcquiredVouchersData, setAcquiredVouchersPagination, setAcquiredVouchersOrder,
                setDocumentsData, setDocumentsPagination, setDocumentsOrder))
    }, [activeClinic, update, dispatch])

    useEffect(() => {
        if (!firstLoad)
            dispatch(getAllSessionsData(sessionsFilter, setSessionsData, setSessionsPagination, setSessionsOrder))
    }, [sessionsFilter])

    useEffect(() => {
        if (!firstLoad)
            dispatch(getAllGroupSessionsData(groupSessionsFilter, setGroupSessionsData, setGroupSessionsPagination, setGroupSessionsOrder))
    }, [groupSessionsFilter])

    useEffect(() => {
        if (!firstLoad)
            dispatch(getAllCashRegistersData(cashRegistersFilter, setCashRegistersData, setCashRegistersPagination, setCashRegisterOrder))
    }, [cashRegistersFilter])

    useEffect(() => {
        if (!firstLoad)
            dispatch(getAllAcquiredVoucherData(acquiredVouchersFilter, setAcquiredVouchersData, setAcquiredVouchersPagination, setAcquiredVouchersOrder))
    }, [acquiredVouchersFilter])

    useEffect(() => {
        if (!firstLoad)
            dispatch(getAllDocumentsData(documentsFilter, setDocumentsData, setDocumentsPagination, setDocumentsOrder))
    }, [documentsFilter])

    const updateComponent = () => setUpdate(!update)

    if (!existingPatient)
        return <NotFoundScreen version={'patient'}/>

    return <div>
        <EditPatientComponent
            patientId={params.patient_id}
            data={patientData}
            setData={setPatientData}
            updateComponent={updateComponent}
            documentsData={documentsData}
            documentsFilter={documentsFilter}
            documentsOrder={documentsOrder}
            setDocumentsFilter={setDocumentsFilter}
            documentsPagination={documentsPagination}
        />

        <ListSessionsInPatientComponent
            patientId={params.patient_id}
            sessions={sessionsData}
            pagination={sessionsPagination}
            order={sessionsOrder}
            filter={sessionsFilter}
            setFilter={setSessionsFilter}
            openTooltipRegister={openTooltipRegister}
            openTooltipEdit={openTooltipEdit}
        />

        <ListGroupSessionsInPatientComponent
            groupSessions={groupSessionsData}
            pagination={groupSessionsPagination}
            order={groupSessionsOrder}
            filter={groupSessionsFilter}
            setFilter={setGroupSessionsFilter}
            openTooltipEdit={openTooltipEdit}
        />

        <ListAcquiredVoucherComponent
            patientId={params.patient_id}
            acquiredVouchers={acquiredVouchersData}
            pagination={acquiredVouchersPagination}
            order={acquiredVouchersOrder}
            filter={acquiredVouchersFilter}
            setFilter={setAcquiredVouchersFilter}
            updateComponent={updateComponent}
        />

        <ListCashRegistersInPatientComponent
            cashRegisters={cashRegistersData}
            pagination={cashRegistersPagination}
            order={cashRegisterOrder}
            filter={cashRegistersFilter}
            setFilter={setCashRegistersFilter}
            updateComponent={updateComponent}
        />

        <RegisterSessionPopup
            childFunc={openTooltipRegister}
            updateComponent={updateComponent}
        />

        <EditSessionPopup
            childFunc={openTooltipEdit}
            updateComponent={updateComponent}
        />
    </div>
}

export default PatientCardScreen;