import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import AuthRoute from "./AuthRoute";
import ProtectedRoute from "./ProtectedRoute";
import Navbar from "../components/general/navbar/Navbar";
import Footer from "../components/general/footer/Footer";
import RedirectComponent from "../components/general/auxiliar/RedirectComponent";
import LoginScreen from "../screens/public/login/LoginScreen";
import RegisterScreen from "../screens/public/register/RegisterScreen";
import TermsScreen from "../screens/public/TermsScreen";
import AboutUsScreen from "../screens/public/AboutUsScreen";
import ManagerProfileScreen from "../screens/manager/basics/ManagerProfileScreen";
import MainClinicScreen from "../screens/manager/clinic/MainClinicScreen";
import NotFoundScreen from "../screens/public/NotFoundScreen";
import RegisterClinicScreen from "../screens/manager/clinic/RegisterClinicScreen";
import MessageComponent from "../components/general/auxiliar/message/MessageComponent";
import RegisterPhysiotherapistScreen from "../screens/manager/physioterapists/RegisterPhysiotherapistScreen";
import RegisterClassTypeScreen from "../screens/manager/services/RegisterClassTypeScreen";
import RegisterVoucherScreen from "../screens/manager/services/RegisterVoucherScreen";
import MainPhysiotherapistScreen from "../screens/manager/physioterapists/MainPhysiotherapistScreen";
import MainServicesScreen from "../screens/manager/services/MainServicesScreen";
import RegisterPatientScreen from "../screens/manager/patients/RegisterPatientScreen";
import RegisterAcquiredVoucherScreen from "../screens/manager/services/RegisterAcquiredVoucherScreen";
import MainPatientsScreen from "../screens/manager/patients/MainPatientsScreen";
import PatientCardScreen from "../screens/manager/patients/PatientCardScreen";
import CalendarScreen from "../screens/manager/calendar/CalendarScreen";
import MainCashRegisterScreen from "../screens/manager/cashRegister/MainCashRegisterScreen";
import CashRegisterDataScreen from "../screens/manager/cashRegister/CashRegisterDataScreen";
import RegisterSessionTypeScreen from "../screens/manager/services/RegisterSessionTypeScreen";
import AcquiredVoucherScreen from "../screens/manager/services/AcquiredVoucherScreen";
import HealthCheckScreen from "../screens/public/HealthCheckScreen";
import PaymentGatewayScreen from "../screens/manager/paymentGateway/PaymentGatewayScreen";
import RegisterValidationScreen from "../screens/public/register/RegisterValidationScreen";
import ForgottenPasswordScreen from "../screens/public/login/ForgottenPasswordScreen";
import PasswordChangeScreen from "../screens/public/login/PasswordChangeScreen";
import PricingScreen from "../screens/public/PricingScreen";
import InvoiceScreen from "../screens/manager/invoice/InvoiceScreen";
import PaymentErrorScreen from "../screens/manager/paymentGateway/PaymentErrorScreen";
import FullScreenCalendarComponent from "../components/calendar/FullScreenCalendarComponent";
import EditInvoiceTemplateTutorial from "../components/clinic/EditInvoiceTemplateTutorial";
import EditPhysiotherapistScreen from "../screens/manager/physioterapists/EditPhysiotherapistScreen";
import EditClinicScreen from "../screens/manager/clinic/EditClinicScreen";
import CookiesManager from "./CookiesManager";
import MainTaskScreen from "../screens/manager/task/MainTaskScreen";
import RegisterTaskComponent from "../components/task/RegisterTaskComponent";
import AnonymousCalendarScreen from "../screens/public/bookings/AnonymousCalendarScreen";
import EditUnconfirmedSessionScreen from "../screens/public/bookings/EditUnconfirmedSessionScreen";
import ShowClinicScreen from "../screens/public/bookings/ShowClinicScreen";
import AboutUsBookingScreen from "../screens/public/bookings/AboutUsBokingScreen";


const AppRouter = () => {

    return <Router>
        <CookiesManager/>
        <Navbar/>
        <RedirectComponent/>
        <MessageComponent className={"mt-n4"}/>

        <Routes>
            {/* Info */}
            <Route path={"/terms-of-use"} element={
                <TermsScreen/>
            }/>
            <Route path={"/about-us"} element={
                <AboutUsScreen/>
            }/>
            <Route path={"/health-check"} element={
                <HealthCheckScreen/>
            }/>
            <Route path={"/pricing"} element={
                <PricingScreen/>
            }/>

            {/* User management */}
            <Route path={"/"} element={<AuthRoute>
                <LoginScreen/>
            </AuthRoute>}/>
            <Route path={"/login"} element={<AuthRoute>
                <LoginScreen/>
            </AuthRoute>}/>
            <Route path={"/register"} element={<AuthRoute>
                <RegisterScreen/>
            </AuthRoute>}/>
            <Route path={"/validate-email"} element={<AuthRoute>
                <RegisterValidationScreen/>
            </AuthRoute>}/>
            <Route path={"/reset-password"} element={<AuthRoute>
                <PasswordChangeScreen/>
            </AuthRoute>}/>
            <Route path={"/forgotten-password"} element={<AuthRoute>
                <ForgottenPasswordScreen/>
            </AuthRoute>}/>


            {/* Manager */}
            <Route path={"/profile"} element={<ProtectedRoute requiredPermissions={["manager"]}>
                <ManagerProfileScreen/>
            </ProtectedRoute>}/>

            {/* Payment */}
            <Route path={"/payment/gateway/:clinic_id"} element={<ProtectedRoute requiredPermissions={["manager"]}>
                <PaymentGatewayScreen/>
            </ProtectedRoute>}/>
            <Route path={"/payment/error"} element={<ProtectedRoute requiredPermissions={["manager"]}>
                <PaymentErrorScreen/>
            </ProtectedRoute>}/>

            {/* Agenda */}
            <Route path={"/agenda"}
                   element={<ProtectedRoute requiredPermissions={["manager", "clinic"]} redirectPath={"/login"}>
                       <CalendarScreen/>
                   </ProtectedRoute>}/>
            <Route path={"/full-screen-agenda"} element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                <FullScreenCalendarComponent/>
            </ProtectedRoute>}/>

            {/* Clinics */}
            <Route path={"/clinic"} element={<ProtectedRoute requiredPermissions={["manager"]}>
                <MainClinicScreen/>
            </ProtectedRoute>}/>
            <Route path={"/clinic/register"} element={<ProtectedRoute requiredPermissions={["manager"]}>
                <RegisterClinicScreen/>
            </ProtectedRoute>}/>
            <Route path={"/clinic/:clinic_id"} element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                <EditClinicScreen/>
            </ProtectedRoute>}/>

            {/* Physios */}
            <Route path={"/physiotherapist"} element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                <MainPhysiotherapistScreen/>
            </ProtectedRoute>}/>
            <Route path={"/physiotherapist/register"}
                   element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                       <RegisterPhysiotherapistScreen/>
                   </ProtectedRoute>}/>
            <Route path={"/physiotherapist/:physio_id"}
                   element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                       <EditPhysiotherapistScreen/>
                   </ProtectedRoute>}/>

            {/* Services */}
            <Route path={"/services"} element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                <MainServicesScreen/>
            </ProtectedRoute>}/>
            <Route path={"/services/group-session-type/register"}
                   element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                       <RegisterClassTypeScreen/>
                   </ProtectedRoute>}/>
            <Route path={"/services/session-type/register"}
                   element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                       <RegisterSessionTypeScreen/>
                   </ProtectedRoute>}/>
            <Route path={"/services/voucher/register"}
                   element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                       <RegisterVoucherScreen/>
                   </ProtectedRoute>}/>

            {/* Acquired vouchers */}
            <Route path={"/acquired-voucher/register"}
                   element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                       <RegisterAcquiredVoucherScreen/>
                   </ProtectedRoute>}/>
            <Route path={"/acquired-voucher/:acquired_voucher_id"}
                   element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                       <AcquiredVoucherScreen/>
                   </ProtectedRoute>}/>

            {/* Patients */}
            <Route path={"/patient"} element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                <MainPatientsScreen/>
            </ProtectedRoute>}/>
            <Route path={"/patient/register"} element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                <RegisterPatientScreen/>
            </ProtectedRoute>}/>
            <Route path={"/patient/:patient_id"} element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                <PatientCardScreen/>
            </ProtectedRoute>}/>

            {/* Cash registers and invoices */}
            <Route path={"/cash-register"} element={<ProtectedRoute requiredPermissions={["manager"]}>
                <MainCashRegisterScreen/>
            </ProtectedRoute>}/>
            <Route path={"/cash-register/:cash_register_id"}
                   element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                       <CashRegisterDataScreen/>
                   </ProtectedRoute>}/>
            <Route path={"/invoice/:invoice_id"} element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                <InvoiceScreen/>
            </ProtectedRoute>}/>

            {/* Task */}
            <Route path={"/task"} element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                <MainTaskScreen/>
            </ProtectedRoute>}/>
            <Route path={"/task/register"} element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                <RegisterTaskComponent/>
            </ProtectedRoute>}/>

            {/* tutorials */}
            <Route path={"/tutorials/invoice-template"}
                   element={<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
                       <EditInvoiceTemplateTutorial/>
                   </ProtectedRoute>}/>

            {/* online bookings */}
            <Route path={"/reservation/agenda"} element={
                <AnonymousCalendarScreen/>
            }/>
            <Route path={"/reservation/session"} element={
                <EditUnconfirmedSessionScreen/>
            }/>
            <Route path={"/reservation/clinic"} element={
                <ShowClinicScreen/>
            }/>
            <Route path={"/reservation/about-us"} element={
                <AboutUsBookingScreen/>
            }/>
            <Route path={"/reservation/terms-of-use"} element={
                <TermsScreen/>
            }/>


            {/* Not found */}
            <Route path='*' element={
                <NotFoundScreen/>
            }/>
        </Routes>

        <Footer className={"mt-auto"}/>
    </Router>
}

export default AppRouter;