import React from "react";

import InputComponent from "../general/input/InputComponent";
import TimeFiltersComponent from "../general/input/TimeFiltersComponent";
import CheckBoxComponent from "../general/input/CheckBoxComponent";
import {handleChangeFilterAux} from "../../auxiliar/handleChange/handleChangeFilterAux";


const TaskFiltersComponent = ({filter, setFilter}) => {

    const handleChange = (e) => handleChangeFilterAux(e, filter, setFilter)

    return <div className="my-3">
        <h5>Filtros</h5>

        <form className="row g-3">
            <div className="col-6">
                <label className="form-label">Orden</label>
                <select value={filter.order} className="form-select" onChange={handleChange} name={"order"}>
                    <option value="date_desc">Fecha - descendente</option>
                    <option value="date_asc">Fecha - ascendente</option>
                    <option value="priority_desc">Prioridad - descendente</option>
                    <option value="priority_asc">Prioridad - ascendente</option>
                </select>
            </div>

            <div className="col-6">
                <InputComponent
                    label={"Tema"}
                    onChange={handleChange}
                    value={filter.topic}
                    type={"text"}
                    name={"topic"}
                    placeholder={"Tema"}
                />
            </div>

            <TimeFiltersComponent
                filter={filter}
                onChange={handleChange}
            />

            <div className="col-6">
                <CheckBoxComponent
                    label={"Sin finalizar"}
                    onChange={handleChange}
                    value={filter.unfinished}
                    name={"unfinished"}
                />
            </div>
            <div className="col-6">
                <CheckBoxComponent
                    label={"Generadas automáticamente"}
                    onChange={handleChange}
                    value={filter.autogenerated}
                    name={"autogenerated"}
                />
            </div>
        </form>
    </div>
}

export default TaskFiltersComponent;