import React from "react";
import {format} from "date-fns";

import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import {checkExistsField} from "../../../../auxiliar/formValidators/auxValidators";


const ListSessionsInPatientComponent = ({
                                            patientId,
                                            sessions,
                                            pagination,
                                            order,
                                            filter,
                                            setFilter,
                                            openTooltipRegister,
                                            openTooltipEdit
                                        }) => {
    const onNewSessionClick = (e) => {
        e.preventDefault()

        const date = new Date()
        openTooltipRegister.current({
            patient_id: patientId,
            startAt: (format(new Date(), "yyyy-MM-dd'T'HH:mm")).toString(),
            endAt: (format(date.setTime(date.getTime() + 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm")).toString()
        }, true)
    }

    return <div className={"container my-5"}>
        <h2>Sesiones</h2>

        {Object.keys(sessions).length <= 0 ?
            <h4 className={"mb-3"}>No hay sesiones disponibles</h4> :
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Entrada</th>
                        <th>Salida</th>
                        <th>Fisioterapeuta</th>
                        <th>Tipo</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {order && order.map((sessionId) => {
                        if (checkExistsField(sessions, sessionId)) {
                            const session = sessions[sessionId]
                            return <tr key={sessionId} className={session.unpaid ? 'table-danger' : ''}>
                                <td>{session.start_datetime.substring(0, 10)}</td>
                                <td>{session.start_datetime.substring(11, 16)}</td>
                                <td>{session.finish_datetime.substring(11, 16)}</td>
                                <td>{session.physio_name}</td>
                                <td>{session.type}</td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={() =>
                                        openTooltipEdit.current({
                                            ...session,
                                            event_type: 'individual',
                                            startAt: session.start_datetime,
                                            endAt: session.finish_datetime
                                        })
                                    }>
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        }
                    })}
                    </tbody>
                </table>
            </div>}

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
        />

        <button className="btn btn-dark" onClick={onNewSessionClick}>
            Nueva Sesión
        </button>
    </div>
}

export default ListSessionsInPatientComponent;