import React from "react";


const ShowSessionComponent = ({session, openTooltipEdit}) => {
    if (!session)
        return <div className="container my-5">
            <h2>Datos de la sesión</h2>

            <p>Los datos de la sesión no están disponibles</p>
        </div>

    return <div className="container my-5">
        <h2>Datos de la sesión</h2>

        <div className={"table-responsive"}>
            <table className={"table align-middle my-3"}>
                <thead>
                <tr>
                    <th>Fecha</th>
                    <th>Entrada</th>
                    <th>Salida</th>
                    <th>Fisioterapeuta</th>
                    <th>Tipo</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                <tr key={session.session_id}>
                    <td>{session.start_datetime.substring(0, 10)}</td>
                    <td>{session.start_datetime.substring(11, 16)}</td>
                    <td>{session.finish_datetime.substring(11, 16)}</td>
                    <td>{session.employee_name}</td>
                    <td>{session.name}</td>
                    <td className="text-center">
                        <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                            e.preventDefault()
                            openTooltipEdit.current({
                                ...session,
                                event_type: 'individual',
                                startAt: session.start_datetime,
                                endAt: session.finish_datetime,
                                id: session.session_id
                            })
                        }}>
                            Editar
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

}

export default ShowSessionComponent;