import {loginTypes} from "./types/types";
import {dispatchActiveClinicClean} from "./activeClinicReducer";
import {dispatchRedirect} from "./redirectReducer";

export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case loginTypes.login:
            return action.payload;
        case loginTypes.logout:
            return {};
        default:
            return state;
    }
}

export const dispatchLogin = (dispatch, content) => {
    dispatch({
        type: loginTypes.login,
        payload: {
            id: content.id,
            displayName: content.name,
            user_type: content.user_type,
            clinics: content.clinics
        }
    })
}

export const dispatchLogout = (dispatch) => {
    dispatchRedirect(dispatch, "/login")
    dispatchActiveClinicClean(dispatch)

    window.localStorage.removeItem('csrf_refresh_token')
    window.localStorage.removeItem('refresh_token')
    window.localStorage.removeItem('physiotherapists')
    window.localStorage.removeItem('voucher_types')
    window.localStorage.removeItem('session_types')
    window.localStorage.removeItem('group_session_types')
    window.localStorage.removeItem('calendar_filter')

    dispatch({
        type: loginTypes.logout
    })
}