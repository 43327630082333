import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../general/input/InputComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import CheckBoxComponent from "../general/input/CheckBoxComponent";
import {postPatientData} from "../../actions/patient";
import {validatePatientData} from "../../auxiliar/formValidators/patientValidators";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";


const RegisterPatientComponent = () => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        patient_id: "",
        name: "",
        surnames: "",
        email: "",
        phone_number: "",
        reminders: false,
        address: "",
        birth_date: "",
        clinic_id: activeClinic.id,
        comments: "",
        discount: 0,
        DNI: ""
    })

    const [errors, setErrors] = useState({})

    const [registerDisabled, setRegisterDisabled] = useState(false)

    const handleChange = (e) => {
        handleChangeAux(e, data, setData)
    }

    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validatePatientData(data, setErrors))
            dispatch(postPatientData(data, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    return (
        <div className="container my-5">
            <h2>Registro de un nuevo paciente</h2>
            <MessageSpan messagesDict={errors}/>

            <form className="row g-3">
                <div className="col-6">
                    <InputComponent
                        label={"Nombre *"}
                        onChange={handleChange}
                        value={data.name}
                        type={"text"}
                        name={"name"}
                        placeholder={"Nombre"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Apellidos *"}
                        onChange={handleChange}
                        value={data.surnames}
                        type={"text"}
                        name={"surnames"}
                        placeholder={"Apellidos"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Correo"}
                        onChange={handleChange}
                        value={data.email}
                        type={"text"}
                        name={"email"}
                        placeholder={"Correo"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Teléfono"}
                        onChange={handleChange}
                        value={data.phone_number}
                        type={"tel"}
                        name={"phone_number"}
                        placeholder={"Teléfono"}
                    />
                </div>
                <div className="col-4">
                    <InputComponent
                        label={"Fecha de nacimiento"}
                        onChange={handleChange}
                        value={data.birth_date}
                        type={"date"}
                        name={"birth_date"}
                        placeholder={"Fecha de nacimiento"}
                    />
                </div>
                <div className="col-4">
                    <InputComponent
                        label={"Porcentaje de descuento habitual"}
                        onChange={handleChange}
                        value={data.discount}
                        type={"number"}
                        name={"discount"}
                        placeholder={"(%)"}
                    />
                </div>
                <div className="col-4">
                    <InputComponent
                        label={"DNI"}
                        onChange={handleChange}
                        value={data.DNI}
                        type={"text"}
                        name={"DNI"}
                        placeholder={"00000000X"}
                    />
                </div>
                <div className="col-12">
                    <InputComponent
                        label={"Dirección"}
                        onChange={handleChange}
                        value={data.address}
                        type={"text"}
                        name={"address"}
                        placeholder={"Dirección"}
                    />
                </div>
                <div className="col-12">
                    <label htmlFor={"comment"} className="form-label">Comentario ({data.comments.length}/10.000)</label><br/>
                    <textarea
                        className={"form-control"}
                        onChange={handleChange}
                        value={data.comments}
                        name={"comments"}
                        placeholder={"Comentario"}
                    />
                </div>
                <div className="col-6">
                    <CheckBoxComponent
                        title={"Marque este campo si desea recibir recordatorios de las citas al teléfono facilitado"}
                        label={"Recordatorios"}
                        onChange={handleChange}
                        value={data.reminders}
                        name={"reminders"}
                    />
                </div>
                <div className="col-12 container-fluid">
                    <button className="btn btn-dark" onClick={onRegisterClick} disabled={registerDisabled}>
                        Confirmar
                    </button>
                </div>
            </form>
        </div>
    )
}

export default RegisterPatientComponent;