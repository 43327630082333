import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import CheckBoxComponent from "../../../general/input/CheckBoxComponent";
import InputComponent from "../../../general/input/InputComponent";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateAcquiredVoucherUsable} from "../../../../auxiliar/formValidators/acquiredVoucherValidators";
import {
    softValidateGroupSessionCashRegister
} from "../../../../auxiliar/formValidators/cashRegisterValidators";
import {
    getPatientAttendsGroupSessionPaymentInfo,
    putPatientAttendsGroupSession
} from "../../../../actions/patientAttendsGroupSession";
import {checkExistsField} from "../../../../auxiliar/formValidators/auxValidators";


const PaymentGroupSessionComponent = ({
                                          patientId,
                                          setPatientId,
                                          groupSessionId,
                                          groupSessionTypeId,
                                          acquiredVoucherId,
                                          patientName,
                                          update
                                      }) => {
    const activeClinic = useSelector(state => state.activeClinic)
    const dispatch = useDispatch()

    const [paymentType, setPaymentType] = useState({voucher: false})

    const [cashRegisterData, setCashRegisterData] = useState({})

    const [voucherData, setVoucherData] = useState({acquired_voucher_id: ""})

    const [acquiredVouchers, setAcquiredVouchers] = useState({})

    const [errors, setErrors] = useState({})

    const [showAllVouchers, setShowAllVouchers] = useState(!acquiredVoucherId || acquiredVoucherId === "")

    useEffect(() => {
        setCashRegisterData({})
        setAcquiredVouchers({})
        setVoucherData({acquired_voucher_id: ""})
        setPaymentType({voucher: false})
        setErrors({})

        if (patientId !== undefined)
            dispatch(getPatientAttendsGroupSessionPaymentInfo(groupSessionId, patientId, activeClinic.id,
                setCashRegisterData, setAcquiredVouchers))
    }, [patientId])

    useEffect(() => {
        if (cashRegisterData && cashRegisterData.initialized)
            setCashRegisterData({
                ...cashRegisterData,
                final_price: (cashRegisterData.initial_price * (1 - cashRegisterData.discount / 100)).toFixed(2),
                unpaid: (cashRegisterData.initial_price * (1 - cashRegisterData.discount / 100)).toFixed(2),
            })
    }, [cashRegisterData.discount, dispatch, setCashRegisterData])

    useEffect(() => {
        if (cashRegisterData && "cash_register_id" in cashRegisterData)
            setPaymentType({voucher: false})
        else if (acquiredVoucherId) {
            setPaymentType({voucher: true})
            setVoucherData({
                ...voucherData,
                acquired_voucher_id: acquiredVoucherId
            })
        }
    }, [cashRegisterData.cash_register_id, acquiredVoucherId])

    useEffect(() => {
        setErrors({})
    }, [paymentType])

    const handleChangePaymentType = (e) => handleChangeAux(e, paymentType, setPaymentType)

    const handleChangeCashRegisterData = (e) => handleChangeAux(e, cashRegisterData, setCashRegisterData)

    const handleChangeVouchersShow = (e) => setShowAllVouchers(e.target.checked)

    const handleChangeVoucherData = (e) => {
        if (e.target.value !== "")
            setVoucherData(acquiredVouchers[e.target.value])
        else
            setVoucherData({acquired_voucher_id: ""})
    }

    const onConfirmPaymentClick = (e) => {
        e.preventDefault()
        if (paymentType.voucher) {
            if (validateAcquiredVoucherUsable(voucherData, setErrors)) {
                dispatch(putPatientAttendsGroupSession(groupSessionId, patientId, {
                    new_info: {acquired_voucher_id: voucherData.acquired_voucher_id}
                }, update, setPatientId))
            }
        } else {
            let paid = undefined
            if (parseInt(cashRegisterData.discount) === 100 || cashRegisterData.initial_price === 0)
                paid = 0
            else if (checkExistsField(cashRegisterData, 'paid'))
                paid = cashRegisterData.paid
            const tmpCashRegisterData = {
                ...cashRegisterData,
                paid: paid
            }
            if (softValidateGroupSessionCashRegister(tmpCashRegisterData, setErrors)) {
                dispatch(putPatientAttendsGroupSession(groupSessionId, patientId, {
                    new_info: tmpCashRegisterData
                }, update, setPatientId))
            }
        }
    }

    if (paymentType && paymentType.voucher === true)
        return <div className="container">
            <div className={"row"}>
                <div className={"col-6"}>
                    <h4>Pago {patientName}</h4>
                </div>
                <div className={"col-6"}>
                    <button className={"btn btn-sm btn-dark float-end"} onClick={() => setPatientId(undefined)}>
                        {acquiredVoucherId && acquiredVoucherId !== "" ? "Cerrar" : "Cancelar pago"}
                    </button>
                    {!(acquiredVoucherId && acquiredVoucherId !== "") &&
                        <button className={"btn btn-sm btn-dark float-end me-2"} onClick={onConfirmPaymentClick}>
                            Confirmar pago
                        </button>}
                </div>
            </div>

            <MessageSpan messagesDict={errors}/>

            <div className="row g-3">
                <div className="col-6">
                    <CheckBoxComponent
                        label={"Pago con bono previamente adquirido"}
                        onChange={handleChangePaymentType}
                        value={paymentType.voucher}
                        name={"voucher"}
                        disabled={acquiredVoucherId && acquiredVoucherId !== ""}
                    />
                </div>

                <div className="col-6">
                    <CheckBoxComponent
                        label={"Mostrar solo los bonos que coincidan con el tipo de sesión"}
                        onChange={handleChangeVouchersShow}
                        value={showAllVouchers}
                        name={"display"}
                        disabled={acquiredVoucherId && acquiredVoucherId !== ""}
                    />
                </div>

                <div className="col-12">
                    <select className="form-select" onChange={handleChangeVoucherData} name={"acquired_voucher_id"}
                            value={voucherData.acquired_voucher_id}
                            disabled={acquiredVoucherId && acquiredVoucherId !== ""}>
                        <option key="" value="">Selecciona un bono</option>
                        {acquiredVouchers && Object.keys(acquiredVouchers).map((id) => {
                            if (parseInt(id) === acquiredVoucherId)
                                return <option key={id} value={id}>{acquiredVouchers[id].voucher_name}</option>
                            else if (showAllVouchers && parseInt(acquiredVouchers[id].group_session_type_id) === parseInt(groupSessionTypeId))
                                return <option key={id} value={id}>{acquiredVouchers[id].voucher_name}</option>
                            else if (!showAllVouchers && parseInt(acquiredVouchers[id].remaining_sessions) > 0)
                                return <option key={id} value={id}>{acquiredVouchers[id].voucher_name}</option>
                        })}
                    </select>
                </div>
            </div>
        </div>

    return <div className="container">
        <div className={"row"}>
            <div className={"col-6"}>
                <h4>Pago {patientName}</h4>
            </div>
            <div className={"col-6"}>
                <button className={"btn btn-sm btn-dark float-end"} onClick={() => setPatientId(undefined)}>
                    {(cashRegisterData.unpaid > 0 || parseInt(cashRegisterData.discount) || cashRegisterData.initial_price === 0) ? "Cancelar pago" : "Cerrar"}
                </button>
                {(cashRegisterData.unpaid > 0 || parseInt(cashRegisterData.discount) >= 100 || cashRegisterData.initial_price === 0) &&
                    <button className={"btn btn-sm btn-dark float-end me-2"} onClick={onConfirmPaymentClick}>
                        Confirmar pago
                    </button>}
            </div>
        </div>

        <MessageSpan messagesDict={errors}/>

        <div className="row g-3">

            <div className="col-12">
                <CheckBoxComponent
                    label={"Pago con bono previamente adquirido"}
                    onChange={handleChangePaymentType}
                    value={paymentType.voucher}
                    name={"voucher"}
                    disabled={cashRegisterData.cash_register_id && cashRegisterData.cash_register_id !== ""}
                />
            </div>

            <div className="col-3">
                <InputComponent
                    label={"Precio inicial"}
                    value={cashRegisterData.initial_price}
                    type={"number"}
                    name={"initial_price"}
                    disabled={true}
                />
            </div>
            <div className="col-3">
                <InputComponent
                    label={"Descuento"}
                    value={cashRegisterData.discount}
                    type={"number"}
                    onChange={handleChangeCashRegisterData}
                    name={"discount"}
                    placeholder={"%"}
                    disabled={cashRegisterData.cash_register_id && cashRegisterData.cash_register_id !== ""}
                />
            </div>
            <div className="col-3">
                <InputComponent
                    label={"Precio final"}
                    value={cashRegisterData.final_price}
                    type={"number"}
                    name={"final_price"}
                    disabled={true}
                />
            </div>
            <div className="col-3">
                <InputComponent
                    label={"Por pagar"}
                    value={cashRegisterData.unpaid}
                    type={"number"}
                    name={"unpaid"}
                    disabled={true}
                />
            </div>
            {cashRegisterData && cashRegisterData.unpaid > 0 ? <div className="col-6">
                <InputComponent
                    label={"Pagado *"}
                    onChange={handleChangeCashRegisterData}
                    value={cashRegisterData.paid}
                    type={"number"}
                    name={"paid"}
                    placeholder={"Cantidad pagada"}
                />
            </div> : null}
            {cashRegisterData && cashRegisterData.unpaid > 0 ? <div className="col-6">
                <label className="form-label">Método de pago *</label>
                <select className="form-select" value={cashRegisterData.payment_method}
                        onChange={handleChangeCashRegisterData}
                        name={"payment_method"}>
                    <option key="" value="">Selecciona el método de pago</option>
                    <option key="Efectivo" value="Efectivo">Efectivo</option>
                    <option key="Tarjeta" value="Tarjeta">Tarjeta</option>
                    <option key="Cheque" value="Transferencia">Transferencia</option>
                    <option key="Otro" value="Otro">Otro</option>
                </select>
            </div> : null}
        </div>
    </div>
}

export default PaymentGroupSessionComponent
