import React, {useState} from 'react'
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";

import MessageSpan from "../../../components/general/auxiliar/message/MessageSpan";
import InputComponent from "../../../components/general/input/InputComponent";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import {handleChangeAux} from "../../../auxiliar/handleChange/handleChangeAux";
import {validateRegisterForm} from "../../../auxiliar/formValidators/authValidators";
import {register} from "../../../actions/auth";

const RegisterScreen = () => {
    const dispatch = useDispatch()

    const queryParams = useQueryParams()

    const [data, setData] = useState({
        name: "",
        surnames: "",
        phone_number: "",
        email: "",
        password: "",
        password_rep: "",
        referral_code: queryParams.has('referral_code') ? queryParams.get('referral_code') : "",
        promo_code: queryParams.has('promo_code') ? queryParams.get('promo_code') : ""
    })

    const [errors, setErrors] = useState({})

    const [registerDisabled, setRegisterDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const handleRegister = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validateRegisterForm(data, setErrors))
            dispatch(register(data, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    return <div className="container">
        <MessageSpan messagesDict={errors}/>

        <div className="col-md-8 offset-md-2 my-5">
            <div className="login-form bg-light mt-4 p-4 border border-dark rounded">
                <form onSubmit={handleRegister} className="row g-3">
                    <h4>Bienvenido a MiFisio</h4>
                    <h5 className="align-center">Formulario de registro</h5>
                    <div className="col-6">
                        <InputComponent
                            label={"Nombre *"}
                            onChange={handleChange}
                            value={data.name}
                            type={"text"}
                            name={"name"}
                            placeholder={"Nombre"}
                        />
                    </div>
                    <div className="col-6">
                        <InputComponent
                            label={"Apellidos *"}
                            onChange={handleChange}
                            value={data.surnames}
                            type={"text"}
                            name={"surnames"}
                            placeholder={"Apellidos"}
                        />
                    </div>
                    <div className="col-12">
                        <InputComponent
                            label={"Correo Electrónico *"}
                            onChange={handleChange}
                            value={data.email}
                            type={"text"}
                            name={"email"}
                            placeholder={"ejemplo@mail.com"}
                        />
                    </div>
                    <div className="col-12">
                        <InputComponent
                            label={"Número de teléfono *"}
                            onChange={handleChange}
                            value={data.phone_number}
                            type={"tel"}
                            name={"phone_number"}
                            placeholder={"Número de teléfono"}
                        />
                    </div>
                    <div className="col-6">
                        <InputComponent
                            label={"Contraseña *"}
                            onChange={handleChange}
                            value={data.password}
                            type={"password"}
                            name={"password"}
                            placeholder={"Contraseña"}
                        />
                    </div>
                    <div className="col-6">
                        <InputComponent
                            label={"Repita la contraseña *"}
                            onChange={handleChange}
                            value={data.password_rep}
                            type={"password"}
                            name={"password_rep"}
                            placeholder={"Contraseña"}
                        />
                    </div>
                    {queryParams.has('referral_code') ? <div className="col-12">
                        <InputComponent
                            label={"Código de referido"}
                            onChange={handleChange}
                            value={data.referral_code}
                            type={"text"}
                            name={"referral_code"}
                            placeholder={"Código"}
                            disabled={true}
                        />
                    </div> : <div className="col-12">
                        <InputComponent
                            label={"Código promocional"}
                            onChange={handleChange}
                            value={data.promo_code}
                            type={"text"}
                            name={"promo_code"}
                            placeholder={"Código"}
                            disabled={queryParams.has('promo_code')}
                        />
                    </div>}
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="terms" required/>
                        <label className="form-check-label" htmlFor="dropdownCheck">
                            He leído y acepto los <Link to="/terms-of-use" target="_blank">términos de
                            uso</Link>
                        </label>
                    </div>
                    <div className="col-12">
                        <button className="btn btn-dark" disabled={registerDisabled}>
                            Confirmar
                        </button>
                    </div>
                </form>
                <hr className="mt-4"/>
                <div className="col-12">
                    <p className="text-center mb-0">
                        ¿Ya estás registrado?
                        <br/>
                        <Link to="/login">Inicia sesión aquí</Link>
                    </p>
                </div>
            </div>
        </div>
    </div>
}

export default RegisterScreen;