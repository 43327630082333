import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

import EditPhysiotherapistComponent from "../../../components/physiotherapist/EditPhysiotherapistComponent";
import ScheduleSelectorGeneralComponent from "../../../components/general/input/ScheduleSelectorGeneralComponent";
import {getPhysiotherapistData} from "../../../actions/physiotherapist";
import {checkExistsField} from "../../../auxiliar/formValidators/auxValidators";
import NotFoundScreen from "../../public/NotFoundScreen";


const EditPhysiotherapistScreen = () => {
    const dispatch = useDispatch()
    const params = useParams()

    const [physio, setPhysio] = useState({})

    const [schedule, setSchedule] = useState({
        monday_schedule_m: '',
        monday_schedule_e: '',
        monday_schedule_c: false,
        tuesday_schedule_m: '',
        tuesday_schedule_e: '',
        tuesday_schedule_c: false,
        wednesday_schedule_m: '',
        wednesday_schedule_e: '',
        wednesday_schedule_c: false,
        thursday_schedule_m: '',
        thursday_schedule_e: '',
        thursday_schedule_c: false,
        friday_schedule_m: '',
        friday_schedule_e: '',
        friday_schedule_c: false,
        saturday_schedule_m: '',
        saturday_schedule_e: '',
        saturday_schedule_c: false,
        sunday_schedule_m: '',
        sunday_schedule_e: '',
        sunday_schedule_c: false
    })

    const [remountBool, setRemountBool] = useState(false)

    const remount = () => setRemountBool(!remountBool)

    useEffect(() => {
        dispatch(getPhysiotherapistData(params.physio_id, setPhysio, setSchedule))
    }, [remountBool, params.physio_id, dispatch, setPhysio, setSchedule])

    if (!checkExistsField(params, 'physio_id') || !physio || Object.keys(physio).length === 0)
        return <NotFoundScreen version={'physio'}/>

    return <div className={"container"}>
        <EditPhysiotherapistComponent
            physio={physio}
            setPhysio={setPhysio}
            remount={remount}
        />

        <ScheduleSelectorGeneralComponent
            type={'physio'}
            schedule={schedule}
            setSchedule={setSchedule}
            remount={remount}
            physioId={params.physio_id}
        />
    </div>
}

export default EditPhysiotherapistScreen;