import InputComponent from "../../../../general/input/InputComponent";
import CheckBoxComponent from "../../../../general/input/CheckBoxComponent";
import useScreenSize from "../../../../../auxiliar/customHooks/useScreenSize";
import {handleChangeAux} from "../../../../../auxiliar/handleChange/handleChangeAux";
import {useEffect} from "react";
import {format} from "date-fns";


const RecurrentParametersComponent = ({data, setData, disableEndRange = true}) => {
    const screenSize = useScreenSize()

    useEffect(() => {
        setData({
            ...data,
            first_date: (format(new Date(data.start_datetime), "yyyy-MM-dd")).toString(),
            start_hour: (format(new Date(data.start_datetime), "HH:mm")).toString(),
            finish_hour: (format(new Date(data.finish_datetime), "HH:mm")).toString()
        })
    }, [])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    return <>
        <div className="col-6">
            <InputComponent
                label={"Fecha de incio"}
                onChange={handleChange}
                value={data.first_date}
                type={"date"}
                name={"first_date"}
            />
        </div>

        <div className="col-6">
            <InputComponent
                label={"Fecha de fin"}
                onChange={handleChange}
                value={data.last_date}
                type={"date"}
                name={"last_date"}
            />
        </div>

        <div className="col-6">
            <InputComponent
                label={"Hora de incio"}
                onChange={handleChange}
                value={data.start_hour}
                type={"time"}
                name={"start_hour"}
            />
        </div>

        <div className="col-6">
            <InputComponent
                label={"Hora de fin"}
                onChange={handleChange}
                value={data.finish_hour}
                type={"time"}
                name={"finish_hour"}
                disabled={disableEndRange}
            />
        </div>

        <span>Dias de la semana en los que se repite la clase</span>
        <div className={'row'}>
            <div className={screenSize.width < 550 ? "col-6" : "col-3"}>
                <CheckBoxComponent
                    value={data.repeat_monday}
                    onChange={handleChange}
                    name={"repeat_monday"}
                    label={"Lunes"}
                    disabled={data.weekday === 1}
                />
            </div>
            <div className={screenSize.width < 550 ? "col-6" : "col-3"}>
                <CheckBoxComponent
                    value={data.repeat_tuesday}
                    onChange={handleChange}
                    name={"repeat_tuesday"}
                    label={"Martes"}
                    disabled={data.weekday === 2}
                />
            </div>
            <div className={screenSize.width < 550 ? "col-6" : "col-3"}>
                <CheckBoxComponent
                    value={data.repeat_wednesday}
                    onChange={handleChange}
                    name={"repeat_wednesday"}
                    label={"Miércoles"}
                    disabled={data.weekday === 3}
                />
            </div>
            <div className={screenSize.width < 550 ? "col-6" : "col-3"}>
                <CheckBoxComponent
                    value={data.repeat_thursday}
                    onChange={handleChange}
                    name={"repeat_thursday"}
                    label={"Jueves"}
                    disabled={data.weekday === 4}
                />
            </div>
            <div className={screenSize.width < 550 ? "col-6" : "col-3"}>
                <CheckBoxComponent
                    value={data.repeat_friday}
                    onChange={handleChange}
                    name={"repeat_friday"}
                    label={"Viernes"}
                    disabled={data.weekday === 5}
                />
            </div>
            <div className={screenSize.width < 550 ? "col-6" : "col-3"}>
                <CheckBoxComponent
                    value={data.repeat_saturday}
                    onChange={handleChange}
                    name={"repeat_saturday"}
                    label={"Sábado"}
                    disabled={data.weekday === 6}
                />
            </div>
            <div className={screenSize.width < 550 ? "col-6" : "col-3"}>
                <CheckBoxComponent
                    value={data.repeat_sunday}
                    onChange={handleChange}
                    name={"repeat_sunday"}
                    label={"Domingo"}
                    disabled={data.weekday === 0}
                />
            </div>
        </div>
    </>
}

export default RecurrentParametersComponent;