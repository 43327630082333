import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {dispatchRedirect} from "../../reducers/redirectReducer";
import {dispatchActiveClinicSave} from "../../reducers/activeClinicReducer";


const ListClinicsComponent = ({clinics}) => {
    const activeClinic = useSelector(state => state.activeClinic)
    const dispatch = useDispatch()

    const onAddClick = () => dispatchRedirect(dispatch, "/clinic/register/")

    return <div className={"my-5"}>
        <h2>Clinicas</h2>

        {(!clinics || Object.keys(clinics).length <= 0) ?
            <h4 className={"mb-3"}>No hay clínicas disponibles</h4> :
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Teléfono</th>
                        <th>Correo</th>
                        <th>Estado</th>
                        <th>Tarjeta válida</th>
                        <th>Plan</th>
                        <th/>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(clinics).map((clinicId) => {
                        const clinic = clinics[clinicId]

                        let clinicState = "Activa"
                        let row_type = "table-success"
                        if (!clinic.active) {
                            row_type = "table-secondary"
                            clinicState = "Inactiva"
                        } else if (!clinic.paid) {
                            row_type = "table-danger"
                            clinicState = "No pagada"
                        }

                        return <tr key={clinicId} className={row_type}>
                            <td>{clinic.name}</td>
                            <td>{clinic.mobile_phone}</td>
                            <td>{clinic.email}</td>
                            <td>{clinicState}</td>
                            <td>{(clinic.payment_method ? 'Si' : 'No')}</td>
                            <td>{clinic.payment_plan.type === 'full' ? 'Completo' : 'Colegiado'}</td>
                            <td className="text-center">
                                <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                    e.preventDefault()
                                    dispatchRedirect(dispatch, `/payment/gateway/${clinicId}`)
                                }}>
                                    {clinic.payment_method ? "Cambiar" : "Añadir"} método de pago
                                </button>
                            </td>
                            {(clinicId !== activeClinic.id && clinic.active) ? <td className="text-center">
                                <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                    e.preventDefault()
                                    dispatchActiveClinicSave(dispatch, clinicId, clinic.name, clinic.status)
                                }}>
                                    Seleccionar
                                </button>
                            </td> : <td className="text-center">
                                <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                    e.preventDefault()
                                    dispatchRedirect(dispatch, `/clinic/${clinicId}`)
                                }}>
                                    Opciones avanzadas
                                </button>
                            </td>}
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>}

        <div className="col-12 container-fluid">
            <button className="btn btn-dark" onClick={onAddClick}>
                Nueva clínica
            </button>
        </div>
    </div>
}

export default ListClinicsComponent;