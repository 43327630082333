import axios from "axios";

import {dispatchRedirect} from "../reducers/redirectReducer";
import {storeInCache} from "../auxiliar/axiosAux";


export const getAllPhysiotherapistData = (params, setData, setOrder, setPagination) => {
    return () => {
        axios.get(`/api/managers/employees/`, {params: params})
            .then(response => {
                setData(response.data.content)
                setOrder(response.data.order)
                setPagination(response.data.pagination)

                storeInCache(response.data, "physiotherapists", response.status)
            })
    }
}


export const cacheAllPhysiotherapistData = (params, setData) => {
    return () => {
        axios.get(`/api/managers/employees/`, {params: params})
            .then(response => {
                setData({
                    data: response.data,
                    date: new Date().getTime()
                })
            })
    }
}


export const getPhysiotherapistData = (physiotherapistId, setData, setSchedule) => {
    return () => {
        axios.get(`/api/managers/employees/${physiotherapistId}`)
            .then(response => {
                setData(response.data.content.employee)
                setSchedule(response.data.content.schedule)
            })
    }
}


export const postPhysiotherapistData = (data, setRegisterDisabled) => {
    return (dispatch) => {
        axios.post('/api/managers/employees/', data)
            .then(() => {
                dispatchRedirect(dispatch, "/physiotherapist")
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}


export const putPhysiotherapistData = (physiotherapistId, data, setUpdateDisabled, setDisabled) => {
    let req_data = JSON.parse(JSON.stringify(data))

    return () => {
        axios.put(`/api/managers/employees/${physiotherapistId}`, req_data)
            .then(() => {
                setDisabled(true)
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}


export const putPhysiotherapistSchedule = (physiotherapistId, data, setUpdateDisabled, setDisabled) => {
    let req_data = JSON.parse(JSON.stringify(data))

    return () => {
        axios.put(`/api/managers/employees/${physiotherapistId}/update_schedule`, req_data)
            .then(() => {
                setDisabled(true)
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}


export const deletePhysiotherapist = (physiotherapistId, setUpdateDisabled) => {
    return (dispatch) => {
        axios.delete(`/api/managers/employees/${physiotherapistId}`,)
            .then(() => {
                dispatchRedirect(dispatch, '/physiotherapist')
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}