import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../general/input/InputComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import CheckBoxComponent from "../general/input/CheckBoxComponent";
import ColorSelectorComponent from "../general/input/ColorSelectorComponent";
import {postPhysiotherapistData} from "../../actions/physiotherapist";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";
import {validatePhysiotherapistData} from "../../auxiliar/formValidators/physioterapistValidators";


const RegisterPhysiotherapistComponent = () => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        clinic_id: activeClinic.id,
        name: "",
        phone_number: "",
        email: "",
        surnames: "",
        color: "blue",
        reminders: false,
        intern: false,
        online_booking: false
    })

    const [errors, setErrors] = useState({})

    const [registerDisabled, setRegisterDisabled] = useState(false)

    const handleChange = (e) => {
        handleChangeAux(e, data, setData)
    }

    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validatePhysiotherapistData(data, setErrors))
            dispatch(postPhysiotherapistData(data, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    return (
        <div className="container my-5">
            <h2>Registro de un nuevo fisioterapeuta</h2>
            <MessageSpan messagesDict={errors}/>

            <form className="row g-3">
                <div className="col-6">
                    <InputComponent
                        label={"Nombre  *"}
                        onChange={handleChange}
                        value={data.name}
                        type={"text"}
                        name={"name"}
                        placeholder={"Nombre"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Apellidos  *"}
                        onChange={handleChange}
                        value={data.surnames}
                        type={"text"}
                        name={"surnames"}
                        placeholder={"Apellidos"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Correo Electrónico"}
                        onChange={handleChange}
                        value={data.email}
                        type={"text"}
                        name={"email"}
                        placeholder={"ejemplo@mail.com"}
                    />
                </div>
                <div className="col-6">
                    <InputComponent
                        label={"Número de teléfono"}
                        onChange={handleChange}
                        value={data.phone_number}
                        type={"tel"}
                        name={"phone_number"}
                        placeholder={"Número de teléfono"}
                    />
                </div>
                <div className="col-12">
                    <ColorSelectorComponent
                        value={data.color}
                        onChange={handleChange}/>
                </div>
                <div className="col-4">
                    <CheckBoxComponent
                        title={"Marque este campo si desea recibir recordatorios de las citas al teléfono facilitado"}
                        label={"Recordatorios"}
                        onChange={handleChange}
                        value={data.reminders}
                        name={"reminders"}
                    />
                </div>
                <div className="col-4">
                    <CheckBoxComponent
                        label={"Estudiante en prácticas"}
                        onChange={handleChange}
                        value={data.intern}
                        name={"intern"}
                    />
                </div>
                <div className="col-4">
                    <CheckBoxComponent
                        title={"Marque este campo si desea que este tipo de sesión se muestre cuando un paciente realice una reserva online"}
                        label={"Citas online"}
                        onChange={handleChange}
                        value={data.online_booking}
                        name={"online_booking"}
                    />
                </div>
                <div className="col-12 container-fluid">
                    <button className="btn btn-dark" onClick={onRegisterClick} disabled={registerDisabled}>
                        Confirmar
                    </button>
                </div>
            </form>
        </div>
    )
}

export default RegisterPhysiotherapistComponent;