import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {format} from 'date-fns';

import PhysiotherapistSelectorComponent from "../../../general/input/PhysiotherapistSelectorComponent";
import GroupSessionTypeSelectorComponent from "../../../general/input/GroupSessionTypeSelectorComponent";
import RecurrentParametersComponent from "./parameters/RecurrentParametersComponent";
import UniqueParametersComponent from "./parameters/UniqueParametersComponent";
import CheckBoxComponent from "../../../general/input/CheckBoxComponent";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateGroupSessionRegister} from "../../../../auxiliar/formValidators/groupSessionValidators";
import {postGroupSessionsData} from "../../../../actions/groupSession";
import {checkExistsField, parseJson} from "../../../../auxiliar/formValidators/auxValidators";


const RegisterGroupSessionComponent = ({closeTooltip, eventData, setErrors}) => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        clinic_id: activeClinic.id,
        start_datetime: (format(new Date(eventData.startAt), "yyyy-MM-dd'T'HH:mm")).toString(),
        finish_datetime: (format(new Date(eventData.endAt), "yyyy-MM-dd'T'HH:mm")).toString(),
        comments: "",
        employee_id: eventData.employee_id ? eventData.employee_id : "",
        group_session_type_id: eventData.session_type_id ? eventData.session_type_id : "",
        recurrent: false,
        first_date: undefined,
        last_date: undefined,
        start_hour: undefined,
        finish_hour: undefined,
        repeat_monday: false,
        repeat_tuesday: false,
        repeat_wednesday: false,
        repeat_thursday: false,
        repeat_friday: false,
        repeat_saturday: false,
        repeat_sunday: false
    })

    const [registerDisabled, setRegisterDisabled] = useState(false)

    useEffect(() => {
        const groupSessionTypes = parseJson(window.localStorage.getItem('group_session_types'))

        if (groupSessionTypes && Object.keys(groupSessionTypes.data.content).length > 0) {
            let duration = 60 * 60000
            if (checkExistsField(data, 'group_session_type_id') && checkExistsField(groupSessionTypes.data.content, data.group_session_type_id))
                duration = groupSessionTypes.data.content[data.group_session_type_id].session_duration * 60000

            if (!data.recurrent && checkExistsField(data, 'start_datetime')) {
                let startingTime = new Date(data.start_datetime).getTime()
                if (isNaN(startingTime))
                    startingTime = new Date().getTime()

                setData({
                    ...data,
                    finish_datetime: (format(new Date(startingTime + duration), "yyyy-MM-dd'T'HH:mm")).toString()
                })
            } else if (data.recurrent && checkExistsField(data, 'start_hour')) {
                let startingTime = new Date(new Date().setHours(data.start_hour.slice(0, 2), data.start_hour.slice(3, 5))).getTime()
                if (isNaN(startingTime))
                    startingTime = new Date().getTime()

                setData({
                    ...data,
                    finish_hour: (format(new Date(startingTime + duration), "HH:mm")).toString()
                })
            }
        }
    }, [data.group_session_type_id, data.start_datetime, data.start_hour])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validateGroupSessionRegister(data, setErrors))
            dispatch(postGroupSessionsData(data, closeTooltip, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    return <form className="row g-3">
        {data.recurrent ?
            <RecurrentParametersComponent
                data={data}
                setData={setData}/> :
            <UniqueParametersComponent
                data={data}
                setData={setData}
            />
        }

        <div className="col-6">
            <PhysiotherapistSelectorComponent
                handleChangeFilter={handleChange}
                value={data.employee_id}
                showAllOption={false}
                showSelectOption={true}
            />
        </div>
        <div className="col-6">
            <GroupSessionTypeSelectorComponent
                handleChangeFilter={handleChange}
                value={data.group_session_type_id}
                showSelectOption={true}
            />
        </div>
        <div className="col-12">
            <label htmlFor={"comments"} className="form-label">Comentario ({data.comments.length}/200)</label><br/>
            <textarea
                className={"form-control"}
                onChange={handleChange}
                value={data.comments}
                name={"comments"}
                placeholder={"Comentario"}
            />
        </div>

        <div className="col-12 mb-3">
            <CheckBoxComponent
                value={data.recurrent}
                onChange={handleChange}
                name={"recurrent"}
                label={"Recurrente"}
            />
        </div>

        <div className="col-12 container-fluid">
            <button type="submit" className="btn btn-dark me-3" onClick={onRegisterClick}
                    disabled={registerDisabled}>
                Confirmar
            </button>
            <button type="submit" className="btn btn-dark float-end" onClick={closeTooltip}>
                Cancelar
            </button>
        </div>
    </form>
}

export default RegisterGroupSessionComponent;