import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

import {getParametersPaymentGateway} from "../../../actions/paymentGateway";
import {checkExistsField} from "../../../auxiliar/formValidators/auxValidators";


const PaymentGatewayScreen = () => {
    const params = useParams()
    const dispatch = useDispatch();

    const [data, setData] = useState({
        Ds_SignatureVersion: "",
        Ds_MerchantParameters: "",
        Ds_Signature: ""
    })
    const [error, setError] = useState(false)

    useEffect(() => {
        if (checkExistsField(params, 'clinic_id'))
            dispatch(getParametersPaymentGateway(params.clinic_id, setData, setError))
    }, [])

    useEffect(() => {
        if (checkExistsField(data, 'Ds_SignatureVersion') &&
            checkExistsField(data, "Ds_MerchantParameters") &&
            checkExistsField(data, "Ds_Signature")){
            document.form.submit()
        }
    }, [data])

    return <div className="d-flex align-items-center justify-content-center m-5">
        <div className="text-center">
            {checkExistsField(params, 'clinic_id') && !error ?
                <h6 className="display-4">Redirigiendo a la pasarela de pago</h6> :
                <h6 className="display-4">Error redirigiendo a la pasarela de pago</h6>}
        </div>
        <div className="col-12 container-fluid" hidden>
            <form name="form" action="https://sis.redsys.es/sis/realizarPago" method="POST">
                <input name="Ds_SignatureVersion" value={data.Ds_SignatureVersion} readOnly={true}/>
                <input name="Ds_MerchantParameters" value={data.Ds_MerchantParameters} readOnly={true}/>
                <input name="Ds_Signature" value={data.Ds_Signature} readOnly={true}/>
                <button className="btn btn-dark" value="Submit">Enviar formulario</button>
            </form>
        </div>
    </div>
}

export default PaymentGatewayScreen