import axios from "axios";
import {storeInCache} from "../auxiliar/axiosAux";


export const getAllServicesData = (
    params, setFirstLoad,
    setSessionTypes, setSessionTypesOrder, setSessionTypesPagination,
    setGroupSessionTypes, setGroupSessionTypesOrder, setGroupSessionTypesPagination,
    setVoucherTypes, setVoucherTypesOrder, setVoucherTypesPagination
) => {
    return () => {
        axios.get(`/api/managers/services/`, {params: params})
            .then(response => {
                const sessionTypes = response.data.content.session_types
                const groupSessionTypes = response.data.content.group_session_types
                const voucherTypes = response.data.content.voucher_types

                setSessionTypes(sessionTypes.content)
                setSessionTypesOrder(sessionTypes.order)
                setSessionTypesPagination(sessionTypes.pagination)

                setGroupSessionTypes(groupSessionTypes.content)
                setGroupSessionTypesOrder(groupSessionTypes.order)
                setGroupSessionTypesPagination(groupSessionTypes.pagination)

                setVoucherTypes(voucherTypes.content)
                setVoucherTypesOrder(voucherTypes.order)
                setVoucherTypesPagination(voucherTypes.pagination)

                setFirstLoad(false)

                storeInCache(sessionTypes, "session_types", response.status)
                storeInCache(groupSessionTypes, "group_session_types", response.status)
                storeInCache(voucherTypes, "voucher_types", response.status)
            })
    }
}