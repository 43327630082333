import axios from "axios";


export const getCalendarTimespan = (id, dates, setData) => {
    return () => {
        axios.get(`/api/managers/calendar_timespans/${id}`)
            .then(response => {
                const data = response.data.content

                setData({
                    ...data,
                    finish_datetime: dates.finish_datetime ? dates.finish_datetime : data.finish_datetime,
                    start_datetime: dates.start_datetime ? dates.start_datetime : data.start_datetime,
                })
            })
    }
}


export const postCalendarTimespan = (data, closeTooltip, setRegisterDisabled) => {
    return () => {
        axios.post('/api/managers/calendar_timespans/', data)
            .then(() => {
                closeTooltip()
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}

export const putCalendarTimespan = (id, data, closeTooltip, setUpdateDisabled) => {
    return () => {
        axios.put(`/api/managers/calendar_timespans/${id}`, data)
            .then(() => {
                closeTooltip()
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}


export const deleteCalendarTimespan = (id, closeTooltip, setUpdateDisabled, deleteRecurrent=false) => {
    return () => {
        axios.delete(`/api/managers/calendar_timespans/${id}?deleteRecurrent=${deleteRecurrent}`)
            .then(() => {
                closeTooltip()
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}