import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import MessageSpan from "../general/auxiliar/message/MessageSpan";
import InputComponent from "../general/input/InputComponent";
import CheckBoxComponent from "../general/input/CheckBoxComponent";
import ColorSelectorComponent from "../general/input/ColorSelectorComponent";
import {deleteTask, putTaskData} from "../../actions/task";
import {handleChangeAux} from "../../auxiliar/handleChange/handleChangeAux";
import {validateTaskData} from "../../auxiliar/formValidators/taskValidators";
import TablePatientAux from "../patient/TablePatientAux";
import {dispatchRedirect} from "../../reducers/redirectReducer";
import {checkExistsField} from "../../auxiliar/formValidators/auxValidators";


const EditTaskComponent = ({setSelectedTaskId, task, update}) => {
    const dispatch = useDispatch();

    const [data, setData] = useState(task)

    const [errors, setErrors] = useState({})

    const [updateDisabled, setUpdateDisabled] = useState(false)

    useEffect(() => {
        setData(task)
    }, [task])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onSuccessRequest = () => {
        update()
        setSelectedTaskId(undefined)
        setUpdateDisabled(false)
    }

    const onErrorRequest = () => setUpdateDisabled(false)

    const onSelectPatientClick = (patient) => dispatchRedirect(dispatch, `/patient/${patient.patient_id}`)

    const onCancelClick = (e) => {
        e.preventDefault()

        setSelectedTaskId(undefined)
        setErrors({})
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validateTaskData(data, setErrors))
            dispatch(putTaskData(task.task_id, {new_info: data}, onSuccessRequest, onErrorRequest))
        else
            setUpdateDisabled(false)
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar esta tarea? No se podrá recuperar la información'))
            dispatch(deleteTask(task.task_id, onSuccessRequest, onErrorRequest))
        else
            setUpdateDisabled(false)
    }

    return <div className="my-4">
        <h5>Editar tarea {task.name}</h5>

        {checkExistsField(task, 'patient_id') && <div className="col-12">
            <TablePatientAux
                data={{[task.patient_id]: {
                        email: task.patient_email,
                        name: task.patient_name,
                        patient_id: task.patient_id,
                        phone_number: task.patient_phone_number,
                        surnames: task.patient_surnames,
                        link: task.whatsapp_link
                    }}}
                order={[task.patient_id]}
                selectText={"Detalles"}
                onSelectClick={onSelectPatientClick}
            />
        </div>}

        <MessageSpan messagesDict={errors}/>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Nombre *"}
                    onChange={handleChange}
                    value={data.topic}
                    type={"text"}
                    name={"topic"}
                    placeholder={"Nombre"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha *"}
                    onChange={handleChange}
                    value={data.date}
                    type={"date"}
                    name={"date"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Prioridad"}
                    onChange={handleChange}
                    value={data.priority}
                    type={"number"}
                    name={"priority"}
                    placeholder={"0"}
                />
            </div>
            <div className="col-6">
                <ColorSelectorComponent
                    value={data.color}
                    onChange={handleChange}
                    label={"Color"}
                />
            </div>
            <div className="col-12">
                <label htmlFor={"description"} className="form-label">Descripción
                    ({data.description.length}/200)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={data.description}
                    name={"description"}
                    placeholder={"Descripción"}
                />
            </div>
            <div className="col-12">
                <CheckBoxComponent
                    label={"Finalizada"}
                    onChange={handleChange}
                    value={data.finished}
                    name={"finished"}
                />
            </div>
            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button>
                <button className="mx-4 btn btn-dark" onClick={onCancelClick}>
                    Cancelar
                </button>
                <button className="btn btn-danger float-end" onClick={onDeleteClick} disabled={updateDisabled}>
                    Eliminar
                </button>
            </div>
        </form>
    </div>
}

export default EditTaskComponent;