import React from "react";
import {useDispatch} from "react-redux";

import WhatsAppReminder from "../general/auxiliar/WhatsAppReminder";
import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import CheckBoxComponent from "../general/input/CheckBoxComponent";
import {dispatchRedirect} from "../../reducers/redirectReducer";
import {putTaskData} from "../../actions/task";


const ListTaskComponent = ({
                               tasks,
                               filter,
                               setFilter,
                               order,
                               pagination,
                               selectedTaskId,
                               setSelectedTaskId,
                               update
                           }) => {
    const dispatch = useDispatch()

    const onAddClick = () => dispatchRedirect(dispatch, "/task/register")

    const changeFinishedState = (e) => {
        e.preventDefault()
        dispatch(putTaskData(e.target.name, {new_info: {"finished": e.target.checked}}, update, update))
    }

    return <>
        {(!tasks || Object.keys(tasks).length <= 0) ?
            <h4 className={"mb-3"}>No hay tareas disponibles</h4> :
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Nombre</th>
                        <th>Paciente</th>
                        <th>Teléfono</th>
                        <th>Prioridad</th>
                        <th>Finalizada</th>
                        <th/>
                    </tr>
                    </thead>

                    <tbody>
                    {order && order.map((taskId) => {
                        if (tasks && tasks[taskId] !== undefined) {
                            return <tr key={taskId} className={"table-".concat(tasks[taskId].color)}>
                                <td>{tasks[taskId].date}</td>
                                <td>{tasks[taskId].topic}</td>
                                <td>{tasks[taskId].patient_name && tasks[taskId].patient_surnames && `${tasks[taskId].patient_name} ${tasks[taskId].patient_surnames}`}</td>
                                <td>{tasks[taskId].patient_phone_number}<WhatsAppReminder
                                    link={tasks[taskId].whatsapp_link}/></td>
                                <td>{tasks[taskId].priority}</td>
                                <td>
                                    <CheckBoxComponent
                                        value={tasks[taskId].finished}
                                        onChange={changeFinishedState}
                                        name={tasks[taskId].task_id}
                                        label=""
                                    />
                                </td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={() =>
                                        setSelectedTaskId(tasks[taskId].task_id)
                                    }>
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        }
                    })}
                    </tbody>
                </table>
            </div>
        }

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
            disabled={selectedTaskId !== undefined}
        />

        <div className="col-12 container-fluid">
            <button className="btn btn-dark" onClick={onAddClick}>
                Nueva tarea
            </button>
        </div>
    </>
}

export default ListTaskComponent;