import React, {useRef} from 'react';
import Popup from 'reactjs-popup';

import UploadDocument from "./UploadDocument";


const UploadDocumentPopUp = ({
                                 patientId,
                                 updateComponent
                             }) => {
    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    const onClose = () => setTimeout(() => updateComponent(), 200);

    return <Popup onClose={onClose} ref={ref} modal position="bottom center" lockScroll
                  trigger={<button className={"btn btn-dark btn-sm float-end"}>Subir archivo</button>}>

        <div className={"p-3 mb-2 bg-light border rounded border-dark"}>
            <UploadDocument
                patientId={patientId}
                closeTooltip={closeTooltip}
            />
        </div>
    </Popup>
};

export default UploadDocumentPopUp;