import React from "react";
import {useDispatch} from "react-redux";

import {dispatchRedirect} from "../../reducers/redirectReducer";
import PaginatorComponent from "../general/auxiliar/PaginatorComponent";


const ListPhysiotherapistComponent = ({
                                          physios,
                                          filter,
                                          setFilter,
                                          order,
                                          pagination
                                      }) => {
    const dispatch = useDispatch()

    const onAddClick = () => dispatchRedirect(dispatch, "/physiotherapist/register")

    return <>
        <h2>Fisioterapeutas</h2>

        {(!physios || Object.keys(physios).length <= 0) ?
            <h4 className={"mb-3"}>No hay fisioterapeutas disponibles</h4> :
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Correo</th>
                        <th>Teléfono</th>
                        <th/>
                    </tr>
                    </thead>

                    <tbody>
                    {order && order.map((physioId) => {
                        if (physios && physios[physioId] !== undefined) {
                            return <tr key={physioId} className={
                                physios[physioId].active ? physios[physioId].intern ? "table-primary" : "" : "table-danger"
                            }>
                                <td>{physios[physioId].name.concat(" ", physios[physioId].surnames)}</td>
                                <td>{physios[physioId].email}</td>
                                <td>{physios[physioId].phone_number}</td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={() =>
                                        dispatchRedirect(dispatch, `/physiotherapist/${physioId}`)}
                                    >
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        }
                    })}
                    </tbody>
                </table>
            </div>
        }

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
        />

        <div className="col-12 container-fluid">
            <button className="btn btn-dark" onClick={onAddClick}>
                Nuevo fisioterapeuta
            </button>
        </div>
    </>
}

export default ListPhysiotherapistComponent;