import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {format} from "date-fns";

import PaymentGroupSessionComponent from "../groupSession/PaymentGroupSessionComponent";
import GroupSessionTypeSelectorComponent from "../../../general/input/GroupSessionTypeSelectorComponent";
import SelectPatientForServiceComponent from "../../../patient/SelectPatientForServiceComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import TablePatientAux from "../../../patient/TablePatientAux";
import InputComponent from "../../../general/input/InputComponent";
import PhysiotherapistSelectorComponent from "../../../general/input/PhysiotherapistSelectorComponent";
import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateGroupSessionEdit} from "../../../../auxiliar/formValidators/groupSessionValidators";
import {deleteGroupSession, putGroupSessionData} from "../../../../actions/groupSession";
import {checkExistsField, parseJson} from "../../../../auxiliar/formValidators/auxValidators";
import {validateSmallPatientData} from "../../../../auxiliar/formValidators/patientValidators";
import {
    deletePatientAttendsGroupSession,
    postPatientAttendsGroupSession
} from "../../../../actions/patientAttendsGroupSession";
import {dispatchRedirect} from "../../../../reducers/redirectReducer";
import useScreenSize from "../../../../auxiliar/customHooks/useScreenSize";


const EditGroupSessionComponent = ({
                                       closeTooltip,
                                       eventData,
                                       patientData,
                                       patientPagination,
                                       patientOrder,
                                       filter,
                                       setFilter,
                                       update,
                                       showAddPatients,
                                       setShowAddPatients,
                                       loadNewGroupSession,
                                       deletedPhysio = undefined,
                                       deletedGroupSessionType = undefined
                                   }) => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)
    const screenSize = useScreenSize()

    const [data, setData] = useState(eventData)

    const [errors, setErrors] = useState({})

    const [patientFilter, setPatientFilter] = useState({
        clinic_id: activeClinic.id,
        exclude_group_session_id: eventData.group_session_id,
        name: "",
        surnames: "",
        email: "",
        phone_number: "",
        per_page: 5,
        order: "name"
    })

    const [patientId, setPatientId] = useState(undefined)

    const [updateDisabled, setUpdateDisabled] = useState(false)

    useEffect(() => {
        const groupSessionTypes = parseJson(window.localStorage.getItem('group_session_types'))

        if (groupSessionTypes && Object.keys(groupSessionTypes.data.content).length > 0) {
            let duration = 60 * 60000
            if (checkExistsField(groupSessionTypes.data.content, data.group_session_type_id))
                duration = groupSessionTypes.data.content[data.group_session_type_id].session_duration * 60000

            let startingTime = new Date(data.start_datetime).getTime()
            if (isNaN(startingTime))
                startingTime = new Date().getTime()

            setData({
                ...data,
                finish_datetime: (format(new Date(startingTime + duration), "yyyy-MM-dd'T'HH:mm")).toString()
            })
        }
    }, [data.group_session_type_id, data.start_datetime])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (validateGroupSessionEdit(data, setErrors) && (patientId === undefined || !patientData[patientId].unpaid ||
            window.confirm('Los datos de pago serán descartados, ¿Desea continuar?')
        ))
            dispatch(putGroupSessionData(data.group_session_id, {new_info: data}, closeTooltip, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    const onCloseClick = (e) => {
        e.preventDefault()

        if (patientId === undefined || !patientData[patientId].unpaid || window.confirm('Los datos de pago serán descartados, ¿Desea continuar?'))
            closeTooltip()
    }

    const onDeleteClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar la sesión? No se podrá recuperar la información'))
            dispatch(deleteGroupSession(data.group_session_id, closeTooltip, setUpdateDisabled))
        else
            setUpdateDisabled(false)
    }

    const onDeleteAllClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (window.confirm('¿Está seguro de que quiere eliminar está sesión y las sesiones asociadas siguientes? No se podrá recuperar la información'))
            dispatch(deleteGroupSession(data.group_session_id, closeTooltip, setUpdateDisabled, true))
        else
            setUpdateDisabled(false)
    }

    const onPatientSelectClick = (patient) => dispatchRedirect(dispatch, `/patient/${patient.patient_id}`)

    const onPatientAddClick = (patientData) => {
        if (patientData.patient_id !== "new" || validateSmallPatientData(patientData, setErrors))
            dispatch(postPatientAttendsGroupSession({
                group_session_id: data.group_session_id,
                patient_id: patientData.patient_id,
                ...(patientData.patient_id === 'new' && {new_patient_info: patientData})
            }, update))
    }

    const onPatientDeleteClick = (patientData) => {
        setPatientId(undefined)
        dispatch(deletePatientAttendsGroupSession(data.group_session_id, patientData.patient_id, update))
    }

    const onDeleteFromClassRecursive = (patientData) => {
        setPatientId(undefined)
        dispatch(deletePatientAttendsGroupSession(data.group_session_id, patientData.patient_id, update, true))
    }

    const onAddToClassRecursive = (patientData) => {
        dispatch(postPatientAttendsGroupSession({
            group_session_id: data.group_session_id,
            patient_id: patientData.patient_id
        }, update, true))
    }

    const onAddPatientClick = (e) => {
        e.preventDefault()
        setShowAddPatients({showAdd: !showAddPatients.showAdd})
    }

    const onNextSessionClick = (e) => {
        e.preventDefault()
        loadNewGroupSession(data.next_group_session_id)
    }

    const onPreviousSessionClick = (e) => {
        e.preventDefault()
        loadNewGroupSession(data.previous_group_session_id)
    }

    return <div>
        <div className={"row"}>
            <div className={"col-6"}>
                <h2>Editar clase</h2>
            </div>
            <div className={"col-6 mt-n5"}>
                {checkExistsField(data, 'next_group_session_id') &&
                    <button className={"btn btn-dark btn-sm float-end mx-2"} onClick={onNextSessionClick}>
                        {screenSize.width > 992 ? "Clase siguiente" : ">"}
                    </button>}
                {checkExistsField(data, 'previous_group_session_id') &&
                    <button className={"btn btn-dark btn-sm float-end mx-2"} onClick={onPreviousSessionClick}>
                        {screenSize.width > 992 ? "Clase previa" : "<"}
                    </button>}
            </div>
        </div>

        <MessageSpan messagesDict={errors}/>

        <div className="col-12 mt-2">
            <div className={"row"}>
                <div className={"col-7"}>
                    <h4>Asistentes
                        ({data.remaining_places} {data.remaining_places === 1 ? "libre" : "libres"})</h4>
                </div>
                <div className={"col-5"}>
                    <PaginatorComponent
                        filter={filter}
                        setFilter={setFilter}
                        pagination={patientPagination}
                        smallButtons={true}
                    />
                </div>
            </div>

            <TablePatientAux
                data={patientData}
                order={patientOrder}
                selectText={"Detalles"}
                onSelectClick={onPatientSelectClick}
                deleteText={"Eliminar"}
                onDeleteClick={onPatientDeleteClick}
                onPaymentClick={setPatientId}
                onAddToClassRecursive={onAddToClassRecursive}
                onDeleteFromClassRecursive={onDeleteFromClassRecursive}
            />


        </div>

        <form className="row g-3">
            <div className="col-6">
                <InputComponent
                    label={"Fecha de inicio"}
                    onChange={handleChange}
                    value={data.start_datetime}
                    type={"datetime-local"}
                    name={"start_datetime"}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha de fin"}
                    onChange={handleChange}
                    value={data.finish_datetime}
                    type={"datetime-local"}
                    name={"finish_datetime"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <PhysiotherapistSelectorComponent
                    handleChangeFilter={handleChange}
                    value={data.employee_id}
                    showAllOption={false}
                    deletedPhysio={deletedPhysio}
                />
            </div>
            <div className="col-6">
                <GroupSessionTypeSelectorComponent
                    handleChangeFilter={handleChange}
                    value={data.group_session_type_id}
                    showSelectOption={false}
                    deletedGroupSessionType={deletedGroupSessionType}
                    disabled={true}
                />
            </div>
            <div className="col-12">
                <label htmlFor={"comments"} className="form-label">Comentario ({data.comments.length}/200)</label><br/>
                <textarea
                    className={"form-control"}
                    onChange={handleChange}
                    value={data.comments}
                    name={"comments"}
                    placeholder={"Comentario"}
                />
            </div>

            {patientId !== undefined && <PaymentGroupSessionComponent
                patientId={patientId}
                setPatientId={setPatientId}
                groupSessionId={data.group_session_id}
                groupSessionTypeId={data.group_session_type_id}
                acquiredVoucherId={patientData[patientId].acquired_voucher_id}
                patientName={patientData[patientId].name + " " + patientData[patientId].surnames}
                update={update}
            />}

            {data.remaining_places > 0 && showAddPatients.showAdd && <SelectPatientForServiceComponent
                filter={patientFilter}
                setFilter={setPatientFilter}
                onClientSelect={onPatientAddClick}
            />}

            <div className="col-12 container-fluid">
                <button type="submit" className="btn btn-dark me-3" onClick={onSaveClick} disabled={updateDisabled}>
                    Guardar
                </button>
                <button className="btn btn-dark me-3" onClick={onCloseClick} disabled={updateDisabled}>
                    Cerrar
                </button>
                {data.remaining_places > 0 && <button className="btn btn-dark me-3" onClick={onAddPatientClick}>
                    {showAddPatients.showAdd ? "Ocultar pacientes" : "Añadir asistente"}
                </button>}
                <button type="submit" className="btn btn-danger float-end" onClick={onDeleteClick}
                        disabled={updateDisabled}>
                    Eliminar
                </button>
                {checkExistsField(data, 'next_group_session_id') &&
                    <button type="submit" className="btn btn-danger float-end mx-3"
                            onClick={onDeleteAllClick} disabled={updateDisabled}>
                        Eliminar posteriores
                    </button>}
            </div>
        </form>
    </div>
}

export default EditGroupSessionComponent;