import logo from "../../media/Logo/Logo_mediano.png"
import {useEffect, useState} from "react";
import {backendHealthCheck, databaseHealthCheck} from "../../actions/healthCheck";
import {useDispatch} from "react-redux";


const HealthCheckScreen = () => {
    const dispatch = useDispatch();

    const [activeBackend, setActiveBackend] = useState(false)
    const [activeDatabase, setActiveDataBase] = useState(false)

    useEffect(() => {
        dispatch(backendHealthCheck(setActiveBackend))
        dispatch(databaseHealthCheck(setActiveDataBase))
    }, [dispatch, setActiveDataBase, setActiveBackend])

    let backendCheck
    if (activeBackend){
        backendCheck = <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="green"
                 className="bi bi-circle-fill" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="12"/>
            </svg>  El servidor está activo</h1>
    } else{
        backendCheck = <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red"
                                className="bi bi-circle-fill" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="12"/>
            </svg>  El servidor no está activo</h1>
    }

    let databaseCheck
    if (activeDatabase){
        databaseCheck = <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="green"
                 className="bi bi-circle-fill" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="12"/>
            </svg>  La base de datos está activa</h1>
    } else{
        databaseCheck = <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red"
                 className="bi bi-circle-fill" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="12"/>
            </svg>  La base de datos no está activa</h1>
    }

    return <div id="container">
        <div className="text-center container my-5">
            <img src={logo} alt={'Logo'}/>
        </div>
        <div className="container my-5">
            {backendCheck}
            {databaseCheck}
        </div>
    </div>
}

export default HealthCheckScreen;