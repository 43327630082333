import axios from "axios";
import {dispatchRedirect} from "../reducers/redirectReducer";
import {storeInCache} from "../auxiliar/axiosAux";


export const getAllGroupSessionTypeData = (params, setData, setOrder, setPagination) => {
    return () => {
        axios.get(`/api/managers/group_session_types/`, {params: params})
            .then(response => {
                setData(response.data.content)
                setOrder(response.data.order)
                setPagination(response.data.pagination)

                storeInCache(response.data, "group_session_types", response.status)
            })
    }
}


export const cacheAllGroupSessionTypeData = (params, setData) => {
    return () => {
        axios.get(`/api/managers/group_session_types/`, {params: params})
            .then(response => {
                setData({
                    data: response.data,
                    date: new Date().getTime()
                })
            })
    }
}


export const getGroupSessionTypeData = (classTypeId, setData) => {
    return () => {
        axios.get(`/api/managers/group_session_types/${classTypeId}`)
            .then(response => {
                setData(response.data.content)
            })
    }
}

export const postGroupSessionTypeData = (data, setRegisterDisabled) => {
    return (dispatch) => {
        axios.post('/api/managers/group_session_types/', data)
            .then(() => {
                dispatchRedirect(dispatch, "/services")
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}

export const putGroupSessionTypeData = (classTypeId, data, setId, setUpdateDisabled) => {
    return () => {
        axios.put(`/api/managers/group_session_types/${classTypeId}`, data)
            .then(() => {
                setId(undefined)
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}

export const deleteGroupSessionType = (classTypeId, setId, setUpdateDisabled, resetFilter) => {
    return () => {
        axios.delete(`/api/managers/group_session_types/${classTypeId}`,)
            .then(() => {
                resetFilter("class")
                setUpdateDisabled(false)
                setId(undefined)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}