import {useSelector} from "react-redux";
import {useMatch} from "react-router-dom";

import MessageSpan from "./MessageSpan";
import {checkExistsField} from "../../../../auxiliar/formValidators/auxValidators";


const ClinicStateMessageComponent = () => {
    const activeClinic = useSelector(state => state.activeClinic)
    const fullScreen = useMatch("/full-screen-agenda")

    const unpaidMsg = "No se ha podido completar el pago y la clínica ha sido bloqueada. Realiza el pago en la pestaña de clinicas para seguir utilizando el servicio."
    const waitingMsg = "No se ha podido completar el pago en la fecha determinada. Realiza el pago en la pestaña de clinicas para evitar un bloqueo posterior del servicio."

    if (fullScreen || !checkExistsField(activeClinic, 'status') || activeClinic.status === 'paid')
        return null

    else if (activeClinic.status === 'waiting')
        return <MessageSpan messagesDict={{"msg": waitingMsg}} alertType={"alert-info"}/>

    else if (activeClinic.status === 'unpaid')
        return <MessageSpan messagesDict={{"error": unpaidMsg}} alertType={"alert-danger"}/>

    return null
}

export default ClinicStateMessageComponent;
