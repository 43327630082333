import React, {useState} from "react";

import InputComponent from "../general/input/InputComponent";


const ShowReferralManagerComponent = ({code}) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (e) => {
        e.preventDefault()

        navigator.clipboard.writeText("mifisioagenda.com/register?referral_code=" + code)
            .then(() => {
                setCopied(true);
            })
    }

    return <div className="container my-5">
        <h2>Referidos</h2>

        <p>
            Si quieres invitar a tus amigos para que usen la aplicación no olvides enviarles el siguiente
            enlace y ambos obtendréis 1 mes gratuito.
        </p>

        <form className="row g-3">
            <div className={"col-12"}>
                <InputComponent
                    label={"Código de referido"}
                    value={"mifisioagenda.com/register?referral_code=" + code}
                    type={"text"}
                    name={"referral_code"}
                    disabled={true}
                />
            </div>
            <div className={"col-12"}>
                <button className={"btn btn-dark"} onClick={copyToClipboard}>
                    Copiar al portapapeles
                </button>
                {copied && <span className={'mx-3'}>
                    Copiado con éxito.
                </span>}
            </div>

        </form>
    </div>
}

export default ShowReferralManagerComponent;