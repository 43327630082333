import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../general/input/InputComponent";
import MessageSpan from "../general/auxiliar/message/MessageSpan";
import TablePatientAux from "./TablePatientAux";
import {handleChangeFilterAux} from "../../auxiliar/handleChange/handleChangeFilterAux";
import {getAllPatientData} from "../../actions/patient";
import {checkExistsField} from "../../auxiliar/formValidators/auxValidators";


const SelectPatientForSessionComponent = ({filter, setFilter, data, setData, userErrors, disabled}) => {
    const dispatch = useDispatch()
    const activeClinic = useSelector(state => state.activeClinic)

    const [patients, setPatients] = useState({})
    const [patientsOrder, setPatientsOrder] = useState([])

    useEffect(() => {
        if (data.patient_id === "")
            dispatch(getAllPatientData(filter, setPatients, () => {}, setPatientsOrder))
    }, [filter, data.patient_id])

    const handleFilterChange = (e) => handleChangeFilterAux(e, filter, setFilter)

    const onClientSelect = (patientData) => {
        setData({
            ...data,
            patient_id: patientData.patient_id
        })

        setFilter({
            per_page: 5,
            order: "name",
            clinic_id: activeClinic.id,
            name: patientData.name,
            surnames: patientData.surnames,
            email: patientData.email,
            phone_number: patientData.phone_number
        })
    }

    const onFilterClean = (e) => {
        e.preventDefault()

        setFilter({
            clinic_id: activeClinic.id,
            name: "",
            surnames: "",
            email: "",
            phone_number: "",
            per_page: 5,
            order: "name"
        })

        if (data.patient_id !== "new")
            setData({...data, patient_id: ""})
    }

    const onLookForPatientClick = (e) => {
        e.preventDefault()

        setData({
            ...data,
            patient_id: ""
        })
    }

    const onRegisterNewPatientClick = (e) => {
        e.preventDefault()

        setData({
            ...data,
            patient_id: "new"
        })
    }

    return <>
        <h4 className={"mt-4"}>Datos del paciente</h4>

        <div className={'row'}>
            <div className={"col-6"}>
                {!checkExistsField(data, 'patient_id') && "Actualmente esta buscando un paciente existente"}
                {data.patient_id === "new" && "Actualmente esta registrando un nuevo paciente"}
                {data.patient_id !== "new" && checkExistsField(data, 'patient_id') && "Paciente existente seleccionado"}
            </div>

            <div className={"col-6"}>
                {disabled ? null : data.patient_id === "new" ?
                    <button type="submit" className="btn btn-dark btn-sm float-end" onClick={onLookForPatientClick}>
                        Paciente existente
                    </button> :
                    <button type="submit" className="btn btn-dark btn-sm float-end"
                            onClick={onRegisterNewPatientClick}>
                        Nuevo paciente
                    </button>}

                {disabled ? null :
                    <button type="submit" className="btn btn-dark btn-sm mx-3 float-end" onClick={onFilterClean}>
                        Reiniciar
                    </button>}
            </div>
        </div>

        <MessageSpan messagesDict={userErrors}/>

        <div className="col-6">
            <InputComponent
                label={"Nombre *"}
                onChange={handleFilterChange}
                value={filter.name}
                type={"text"}
                name={"name"}
                placeholder={"Nombre"}
                disabled={(checkExistsField(data, 'patient_id') && data.patient_id !== "new") || disabled}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Apellidos *"}
                onChange={handleFilterChange}
                value={filter.surnames}
                type={"text"}
                name={"surnames"}
                placeholder={"Apellidos"}
                disabled={(checkExistsField(data, 'patient_id') && data.patient_id !== "new") || disabled}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Correo"}
                onChange={handleFilterChange}
                value={filter.email}
                type={"text"}
                name={"email"}
                placeholder={"Correo"}
                disabled={(checkExistsField(data, 'patient_id') && data.patient_id !== "new") || disabled}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Teléfono"}
                onChange={handleFilterChange}
                value={filter.phone_number}
                type={"tel"}
                name={"phone_number"}
                placeholder={"Número de teléfono"}
                disabled={(checkExistsField(data, 'patient_id') && data.patient_id !== "new") || disabled}
            />
        </div>

        {(data.patient_id === "" && (!patients || Object.keys(patients).length <= 0)) && <div className={"col-12"}>
            <h4>No hay pacientes disponibles</h4>
        </div>}

        {data.patient_id === "" && <TablePatientAux
            data={patients}
            order={patientsOrder}
            onSelectClick={onClientSelect}
            selectText={"Seleccionar"}
        />}
    </>
}

export default SelectPatientForSessionComponent;