import axios from "axios";
import {dispatchRedirect} from "../reducers/redirectReducer";


export const getAllVoucherData = (params, setData, setOrder, setPagination) => {
    return () => {
        axios.get(`/api/managers/voucher_types/`, {params: params})
            .then(response => {
                setData(response.data.content)
                setPagination(response.data.pagination)
                setOrder(response.data.order)
            })
    }
}

export const getVoucherData = (voucherId, setData) => {
    return () => {
        axios.get(`/api/managers/voucher_types/${voucherId}`)
            .then(response => {
                setData(response.data.content)
            })
    }
}

export const postVoucherData = (data, setRegisterDisabled) => {
    if (data.session_type_id === "")
        delete data.session_type_id
    else if (data.group_session_type_id === "")
        delete data.group_session_type_id

    return (dispatch) => {
        axios.post('/api/managers/voucher_types/', data)
            .then(() => {
                dispatchRedirect(dispatch, "/services")
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}

export const putVoucherData = (voucherId, data, setId, setUpdateDisabled) => {
    let req_data = JSON.parse(JSON.stringify(data))

    return () => {
        axios.put(`/api/managers/voucher_types/${voucherId}`, req_data)
            .then(() => {
                setId(undefined)
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}

export const deleteVoucher = (voucherId, setId, setUpdateDisabled, resetFilter) => {
    return () => {
        axios.delete(`/api/managers/voucher_types/${voucherId}`)
            .then(() => {
                resetFilter('voucher')
                setUpdateDisabled(false)
                setId(undefined)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}