import React from "react";
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";

import LoginScreen from "../screens/public/login/LoginScreen";


const AuthRoute = ({redirectPath = `/clinic`, children}) => {
    const auth = useSelector(state => state.auth)
    const clinicID = useSelector(state => state.activeClinic.id)

    let path = redirectPath
    if (path === "/clinic" && auth.user_type === "clinic")
        if (clinicID)
            path = `/clinic/${clinicID}`
        else
            path = undefined


    if (!auth.user_type || !path)
        return children ? children : <LoginScreen/>

    return <Navigate to={path} replace/>
};

export default AuthRoute;