import {checkExistsField} from "./auxValidators";

export const validateCharge = (paymentData, debt, setErrors) => {
    const errors = {}

    if (!checkExistsField(paymentData, "paid"))
        errors['paid'] = "Introduce la cantidad abonada"
    else if (parseFloat(paymentData.paid) <= 0)
        errors['paid'] = "La cantidad abonada debe ser un número positivo"
    else if (parseFloat(paymentData.paid) > parseFloat(debt))
        errors['paid'] = "La cantidad abonada no puede ser superior a la deuda restante"

    if (!checkExistsField(paymentData, "payment_method"))
        errors['payment_method'] = "Seleccione el metodo de pago"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}
