import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import InputComponent from "../../general/input/InputComponent";
import MessageSpan from "../../general/auxiliar/message/MessageSpan";
import {uploadDocument} from "../../../actions/document";
import {validateDocumentData} from "../../../auxiliar/formValidators/documentValidator";


const UploadDocument = ({patientId, closeTooltip}) => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        clinic_id: activeClinic.id,
        file_size: 0,
        patient_id: patientId,
        description: ''
    });

    const [errors, setErrors] = useState({})

    const handleChange = (e) => {
        if (e.target.type === "file") {
            setData({
                ...data,
                [e.target.name]: e.target.files[0],
                file_size: e.target.files[0].size / 1024 / 1024
            })
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    };

    const onSendClick = (e) => {
        e.preventDefault()

        if (validateDocumentData(data, setErrors))
            dispatch(uploadDocument(data, closeTooltip))
    }

    return <div className={"container my-3"}>
        <MessageSpan messagesDict={errors}/>
        <h3>Subir nuevo documento</h3>
        <form className="row g-3">
            <div className="col-12">
                <label className="form-label" htmlFor="file">Archivo</label>
                <input type="file" className="form-control" id="file" name="file" onChange={handleChange}/>
            </div>
            <div className="col-12">
                <InputComponent
                    value={data.description}
                    label={`Descripción (${data.description.length}/30)`}
                    type={"text"}
                    name={"description"}
                    placeholder={"Descripción"}
                    onChange={handleChange}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Tamaño del archivo (max: 25 MB)"}
                    value={`${data.file_size.toFixed(2)} MB`}
                    type={"text"}
                    name={"size"}
                    disabled={true}
                />
            </div>
            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onSendClick}>
                    Guardar
                </button>
            </div>
        </form>
    </div>
}

export default UploadDocument;