import axios from "axios";

import {dispatchRedirect} from "../reducers/redirectReducer";


export const getAllAcquiredVoucherData = (params, setData, setPagination, setOrder) => {
    return () => {
        axios.get(`/api/managers/acquired_vouchers/`, {params: params})
            .then(response => {
                setData(response.data.content)
                setPagination(response.data.pagination)
                setOrder(response.data.order)
            })
    }
}


export const getAcquiredVoucherContext = (id, setFirstLoad,
                                          setAcquiredVoucher, setVoucherType, setCashRegister,
                                          setPatientsData, setPatientsPagination, setPatientsOrder,
                                          setSessionsData, setSessionsPagination, setSessionsOrder,
                                          setGroupSessionsData, setGroupSessionsPagination, setGroupSessionsOrder
) => {
    return () => {
        axios.get(`/api/managers/acquired_vouchers/voucher_details/${id}`)
            .then(response => {
                const data = response.data.content
                const patients = data.patients
                const sessions = data.sessions
                const groupSessions = data.group_sessions

                setAcquiredVoucher(data.acquired_voucher.content)
                setVoucherType(data.voucher_type.content)
                setCashRegister(data.cash_register.content)

                setPatientsOrder(patients.order)
                setPatientsPagination(patients.pagination)
                setPatientsData(patients.content)

                setSessionsOrder(sessions.order)
                setSessionsPagination(sessions.pagination)
                setSessionsData(sessions.content)

                setGroupSessionsData(groupSessions.content)
                setGroupSessionsPagination(groupSessions.pagination)
                setGroupSessionsOrder(groupSessions.order)

                setFirstLoad(false)
            })
    }
}


export const getAcquiredVoucherData = (id, setData) => {
    return () => {
        axios.get(`/api/managers/acquired_vouchers/${id}`)
            .then(response => {
                setData(response.data.content)
            })
    }
}


export const postAcquiredVoucherData = (data, patientId, setRegisterDisabled) => {
    return (dispatch) => {
        axios.post('/api/managers/acquired_vouchers/', data)
            .then(() => {
                dispatchRedirect(dispatch, `/patient/${patientId}`)
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}


export const putAcquiredVoucherData = (id, data, onSuccess, setUpdateDisabled) => {
    return () => {
        axios.put(`/api/managers/acquired_vouchers/${id}`, data)
            .then(() => {
                onSuccess();
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}


export const deleteAcquiredVoucher = (id, onSuccess, onError) => {
    return () => {
        axios.delete(`/api/managers/acquired_vouchers/${id}`,)
            .then(() => {
                setTimeout(() => onSuccess(), 200);
            }).catch(() => {
            onError(false)
        })
    }
}