import React, {useState} from "react";
import {useDispatch} from "react-redux";

import ScheduleSelectorComponent from "./ScheduleSelectorComponent";
import {putPhysiotherapistSchedule} from "../../../actions/physiotherapist";
import {putClinicScheduleData} from "../../../actions/clinics";


const ScheduleSelectorGeneralComponent = ({
                                              type,
                                              schedule,
                                              setSchedule,
                                              remount,
                                              physioId,
                                              clinicId,
                                              readOnly = false
                                          }) => {
    const dispatch = useDispatch()

    const [disabled, setDisabled] = useState(true)

    const [updateDisabled, setUpdateDisabled] = useState(false)

    const onEditClick = (e) => {
        e.preventDefault()
        setDisabled(false)
    }

    const onCancelClick = (e) => {
        e.preventDefault()

        setDisabled(true)
        remount()
    }

    const onSaveClick = (e) => {
        e.preventDefault()

        setUpdateDisabled(true)
        if (type === 'physio')
            dispatch(putPhysiotherapistSchedule(physioId, {new_info: schedule}, setUpdateDisabled, setDisabled))
        else if (type === 'clinic')
            dispatch(putClinicScheduleData(clinicId, {new_info: schedule}, setUpdateDisabled, setDisabled))
    }

    return <form className="row my-5">
        {type === "clinic" && <h2>Horario de la Clínica</h2>}
        {type === "physio" && <h2>Horario del Fisioterapeuta</h2>}

        <span className={"mt-0 mb-2"}>
            La primera columna de cada día es el horario de apertura y la segunda el de cierre.
            {type === "clinic" && !readOnly && " También puedes indicar los días en los que la clínica cierra marcando el cuadro debajo de las horas."}
            {type === "physio" && !readOnly && " También puedes indicar los días libres del fisioterapeuta marcando el cuadro debajo de las horas."}
        </span>

        <div className="row mb-3">
            <div className="col">
                <ScheduleSelectorComponent
                    id={'monday'}
                    label={'Lunes'}
                    schedule={schedule}
                    setSchedule={setSchedule}
                    closedPrompt={type === 'physio' ? 'Día libre' : 'Cerrado'}
                    disabled={disabled || readOnly}
                />
            </div>
            <div className="col">
                <ScheduleSelectorComponent
                    id={'tuesday'}
                    label={'Martes'}
                    schedule={schedule}
                    setSchedule={setSchedule}
                    closedPrompt={type === 'physio' ? 'Día libre' : 'Cerrado'}
                    disabled={disabled || readOnly}
                />
            </div>
            <div className="col">
                <ScheduleSelectorComponent
                    id={'wednesday'}
                    label={'Miércoles'}
                    schedule={schedule}
                    setSchedule={setSchedule}
                    closedPrompt={type === 'physio' ? 'Día libre' : 'Cerrado'}
                    disabled={disabled || readOnly}
                />
            </div>
            <div className="col">
                <ScheduleSelectorComponent
                    id={'thursday'}
                    label={'Jueves'}
                    schedule={schedule}
                    setSchedule={setSchedule}
                    closedPrompt={type === 'physio' ? 'Día libre' : 'Cerrado'}
                    disabled={disabled || readOnly}
                />
            </div>
            <div className="col">
                <ScheduleSelectorComponent
                    id={'friday'}
                    label={'Viernes'}
                    schedule={schedule}
                    setSchedule={setSchedule}
                    closedPrompt={type === 'physio' ? 'Día libre' : 'Cerrado'}
                    disabled={disabled || readOnly}
                />
            </div>
            <div className="col">
                <ScheduleSelectorComponent
                    id={'saturday'}
                    label={'Sábado'}
                    schedule={schedule}
                    setSchedule={setSchedule}
                    closedPrompt={type === 'physio' ? 'Día libre' : 'Cerrado'}
                    disabled={disabled || readOnly}
                />
            </div>
            <div className="col">
                <ScheduleSelectorComponent
                    id={'sunday'}
                    label={'Domingo'}
                    schedule={schedule}
                    setSchedule={setSchedule}
                    closedPrompt={type === 'physio' ? 'Día libre' : 'Cerrado'}
                    disabled={disabled || readOnly}
                />
            </div>
        </div>

        {!readOnly && <div className="col-12 container-fluid">
            {disabled && <button className="btn btn-dark" onClick={onEditClick}>
                Editar
            </button>}

            {!disabled && <button className="btn btn-dark" onClick={onSaveClick} disabled={updateDisabled}>
                Guardar
            </button>}

            {!disabled && <button className="ms-3 btn btn-dark" onClick={onCancelClick}>
                Cancelar
            </button>}
        </div>}
    </form>
}

export default ScheduleSelectorGeneralComponent;