import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ListPhysiotherapistComponent from "../../../components/physiotherapist/ListPhysiotherapistComponent";
import {getAllPhysiotherapistData} from "../../../actions/physiotherapist";


const MainPhysiotherapistsScreen = () => {
    const dispatch = useDispatch()
    const activeClinic = useSelector(state => state.activeClinic)

    const [physios, setPhysios] = useState({})

    const [order, setOrder] = useState([])

    const [filter, setFilter] = useState({
        clinic_id: activeClinic.id,
        order: "name",
        per_page: 20,
        page: 1
    })

    const [pagination, setPagination] = useState({})

    useEffect(() => {
        dispatch(getAllPhysiotherapistData(filter, setPhysios, setOrder, setPagination))
    }, [activeClinic.id, filter, dispatch, setPhysios])

    return <div className={'container my-5'}>
        <ListPhysiotherapistComponent
            physios={physios}
            filter={filter}
            setFilter={setFilter}
            order={order}
            pagination={pagination}
        />
    </div>
}

export default MainPhysiotherapistsScreen;