import React, {useRef} from 'react';
import Popup from 'reactjs-popup';

import ShowCashRegisterComponent from "../../cashRegister/ShowCashRegisterComponent";
import RegisterChargeComponent from "./RegisterChargeComponent";


const RegisterChargePopup = ({
                                 cashRegisterData: cashRegisterData,
                                 updateComponent,
                                 btnClass = "btn-dark btn-sm"
                             }) => {
    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    const onClose = () => setTimeout(() => updateComponent(), 200);

    return <Popup
        modal
        lockScroll
        ref={ref}
        onClose={onClose}
        position="bottom center"
        trigger={<button className={"btn " + btnClass}>Cobrar</button>}
    >
        <div className={"p-3 mb-2 bg-light border rounded border-dark"}>
            <ShowCashRegisterComponent data={cashRegisterData}/>

            <RegisterChargeComponent
                cashRegisterData={cashRegisterData}
                closeTooltip={closeTooltip}
            />
        </div>
    </Popup>
};

export default RegisterChargePopup