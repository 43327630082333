import axios from "axios";

export const getParametersPaymentGateway = (clinicId, setData, setError) => {
    return () => {
        axios.get(`/api/managers/payment_gateway/generate_request`, {'params': {'clinic_id': clinicId}})
            .then(response => {
                setData(response.data.content)
            }).catch(() => {
                setError(true)
            }
        )
    }
}