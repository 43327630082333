export const handleChangeFilterAux = (e, filter, setFilter) => {
    if (e.target.type === "checkbox") {
        setFilter({
            ...filter,
            page: 1,
            [e.target.name]: e.target.checked
        })
    } else {
        setFilter({
            ...filter,
            page: 1,
            [e.target.name]: e.target.value
        })
    }
}


export const resetFilterPage = (filter, setFilter) => {
    setFilter({
        ...filter,
        page: 1
    })
}