import {messageTypes} from "./types/types";

export const messageReducer = (state = {}, action) => {
    switch (action.type) {
        case messageTypes.message:
            return action.payload;
        case messageTypes.clean:
            return {};
        default:
            return state;
    }
}

export const dispatchMessageSave = (dispatch, msg, error = false) => {
    let payload
    if (error){
        payload = {
            error: msg,
            date: new Date()
        }
    } else {
        payload = {
            msg: msg,
            date: new Date()
        }
    }
    dispatch({
        type: messageTypes.message,
        payload: payload
    })
}

export const dispatchMessageClean = (dispatch) => {
    dispatch({
        type: messageTypes.clean
    })
}