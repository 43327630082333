import React from "react";
import {useDispatch} from "react-redux";

import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import {deleteDocument} from "../../actions/document";


const ListDocumentInPatientComponent = ({
                                            updateComponent,
                                            documents,
                                            filter,
                                            setFilter,
                                            order,
                                            pagination
                                        }) => {
    const dispatch = useDispatch()

    if (!documents || Object.keys(documents).length === 0)
        return null

    return <div className={"container mt-3"}>
        <div className={"row"}>
            <div className={"col-4"}>
                <h5>Documentos</h5>
            </div>
            <div className={"col-8"}>
                <PaginatorComponent
                    pagination={pagination}
                    setFilter={setFilter}
                    filter={filter}
                    smallButtons={true}
                />
            </div>
        </div>

        <div className={"table-responsive"}>
            <table className={"table align-middle"}>
                <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Fecha</th>
                    <th>descripción</th>
                    <th/>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {order && order.map((document) => {
                    if (documents && documents[document] !== undefined)
                        return <tr key={document}>
                            <td>{documents[document].file_name}</td>
                            <td>{documents[document].upload_date}</td>
                            <td>{documents[document].description}</td>
                            <td>
                                <a href={documents[document].download_url} download={documents[document].file_name}>
                                    <button type="button" className="btn btn-dark btn-sm">
                                        Descargar
                                    </button>
                                </a>
                            </td>
                            <td className="text-center">
                                <button type="button" className="btn btn btn-danger btn-sm" onClick={() => {
                                    if (window.confirm('¿Está seguro de que quiere eliminar el documento? No se podrá recuperar la información'))
                                        dispatch(deleteDocument(document, updateComponent))
                                }}>
                                    Eliminar
                                </button>
                            </td>
                        </tr>
                })}
                </tbody>
            </table>
        </div>
    </div>
}

export default ListDocumentInPatientComponent;