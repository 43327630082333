import {checkExistsField, checkValidDatetime} from "./auxValidators";

export const validateCalendarTimespan = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "name"))
        errors['name'] = "El título es obligatorio"
    else if (data.name.trim().length < 3)
        errors['name'] = "El título es demasiado corto"

    if (data.recurrent) {
        if (!checkExistsField(data, "first_date") || checkValidDatetime(data.first_date))
            errors['first_date'] = "Debe introducir una fecha de inicio de rango de repetición de sesiones válida"

        if (!checkExistsField(data, "last_date") || checkValidDatetime(data.last_date))
            errors['last_date'] = "Debe introducir una fecha de fin de rango de repetición de sesiones válida"
        else if(new Date(data.first_date).getTime() >= new Date(data.last_date).getTime())
            errors['last_date'] = "La fecha final debe ser posterior a la inicial"

        if (!checkExistsField(data, "start_hour"))
            errors['start_hour'] = "Debe introducir una hora de inicio de sesion válida"

        if (!checkExistsField(data, "finish_hour"))
            errors['finish_hour'] = "Debe introducir una hora de fin de sesion válida"
        else if (data.start_hour >= data.finish_hour)
            errors['finish_hour'] = "La hora de fin debe ser posterior a la de inicio"

        if (
            !data.repeat_monday && !data.repeat_tuesday && !data.repeat_wednesday && !data.repeat_thursday &&
            !data.repeat_friday && !data.repeat_saturday && !data.repeat_sunday
        )
            errors['repeat'] = "Debe seleccionar al menos un día de la semana"
    } else {
        if (!checkExistsField(data, "start_datetime") || checkValidDatetime(data.start_datetime))
            errors['start_datetime'] = "Debe introducir una fecha y hora de inicio de sesion válida"

        if (!checkExistsField(data, "finish_datetime") || checkValidDatetime(data.finish_datetime))
            errors['finish_datetime'] = "Debe introducir una fecha y hora de fin de sesion válida"
        else if (new Date(data.finish_datetime).getTime() <= new Date(data.start_datetime).getTime())
            errors['finish_datetime'] = "La fecha de fin debe ser posterior a la de inicio"
    }



    if (checkExistsField(data, "description") && data.description.length > 200) // not compulsory
        errors['description'] = "La descripción es demasiado larga"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}