import React from "react";

import useHoverCapableDevice from "../../../auxiliar/customHooks/useHoverCapableDevice";


const InputComponent = ({
                            placeholder = "",
                            type,
                            value,
                            onChange = () => {},
                            name,
                            label,
                            title,
                            disabled = false,
                            autocomplete = "off",
                        }) => {
    const hoverCapableDevice = useHoverCapableDevice()

    return <>
        <label htmlFor={name} className="form-label">
            {label} {(title && title !== "" && hoverCapableDevice) && <span title={title}>&#x2139;</span>}
        </label>
        <input
            onChange={onChange}
            value={value === null || value === undefined ? "" : value}
            type={type}
            id={name}
            name={name}
            className="form-control"
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autocomplete}
        />
    </>
}

export default InputComponent