import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {format} from "date-fns";

import InputComponent from "../../../general/input/InputComponent";
import PhysiotherapistSelectorComponent from "../../../general/input/PhysiotherapistSelectorComponent";
import RecurrentParametersComponent from "./parameters/RecurrentParametersComponent";
import UniqueParametersComponent from "./parameters/UniqueParametersComponent";
import CheckBoxComponent from "../../../general/input/CheckBoxComponent";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {postCalendarTimespan} from "../../../../actions/calendarTimespan";
import {validateCalendarTimespan} from "../../../../auxiliar/formValidators/calendarTimespanValidator";


const RegisterBlockedHoursComponent = ({closeTooltip, eventData, setErrors}) => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        clinic_id: activeClinic.id,
        name: "",
        start_datetime: (format(new Date(eventData.startAt), "yyyy-MM-dd'T'HH:mm")).toString(),
        finish_datetime: (format(new Date(eventData.endAt), "yyyy-MM-dd'T'HH:mm")).toString(),
        description: "",
        employee_id: eventData.employee_id ? eventData.employee_id : "",
        recurrent: false,
        first_date: undefined,
        last_date: undefined,
        start_hour: undefined,
        finish_hour: undefined,
        repeat_monday: false,
        repeat_tuesday: false,
        repeat_wednesday: false,
        repeat_thursday: false,
        repeat_friday: false,
        repeat_saturday: false,
        repeat_sunday: false
    })

    const [registerDisabled, setRegisterDisabled] = useState(false)

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onRegisterClick = (e) => {
        e.preventDefault()
        setRegisterDisabled(true)
        if (validateCalendarTimespan(data, setErrors))
            dispatch(postCalendarTimespan(data, closeTooltip, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    return <form className="row g-3">
        {data.recurrent ?
            <RecurrentParametersComponent
                data={data}
                setData={setData}
                disableEndRange={false}
            /> : <UniqueParametersComponent
                data={data}
                setData={setData}
                disableEndRange={false}
            />
        }
        <div className="col-6">
            <PhysiotherapistSelectorComponent
                handleChangeFilter={handleChange}
                value={data.employee_id}
                showAllOption={true}
                showSelectOption={false}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Título *"}
                onChange={handleChange}
                value={data.name}
                type={"text"}
                name={"name"}
                placeholder={"Ausencia"}
            />
        </div>
        <div className="col-12">
            <label htmlFor={"description"} className="form-label">Descripcion
                ({data.description.length}/200)</label><br/>
            <textarea
                className={"form-control"}
                onChange={handleChange}
                value={data.description}
                name={"description"}
                placeholder={"Descripción"}
            />
        </div>
        <div className="col-12 mb-3">
            <CheckBoxComponent
                value={data.recurrent}
                onChange={handleChange}
                name={"recurrent"}
                label={"Recurrente"}
            />
        </div>

        <div className="col-12 container-fluid">
            <button type="submit" className="btn btn-dark me-3" onClick={onRegisterClick}
                    disabled={registerDisabled}>
                Confirmar
            </button>

            <button type="submit" className="btn btn-dark float-end" onClick={closeTooltip}>
                Cancelar
            </button>
        </div>
    </form>
}

export default RegisterBlockedHoursComponent;