import React from "react";

import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";


const PaginatorComponent = ({pagination, filter, setFilter, disabled = false, smallButtons = false}) => {
    const screenSize = useScreenSize()


    const nextPageOnClick = (e) => {
        e.preventDefault()

        setFilter({
            ...filter,
            page: (pagination.curr_page + 1)
        })
    }

    const prevPageOnClick = (e) => {
        e.preventDefault()

        setFilter({
            ...filter,
            page: (pagination.curr_page - 1)
        })
    }

    if (pagination && (pagination.has_next || pagination.has_prev))
        return <div className="container-fluid" style={{height: 1}}>
            {(pagination.has_next) ?
                <button
                    className={`btn btn-dark float-end ${smallButtons ? "btn-sm" : ""}`}
                    onClick={nextPageOnClick} disabled={disabled}
                >
                    {screenSize.width > 992 ? "Siguiente" : ">"}
                </button> : null
            }

            {(pagination.max_pages !== 0 && pagination.max_pages !== 1) ?
                <span className={`m-2 float-end`}>
                    {pagination.curr_page}/{pagination.max_pages}
                </span> : null
            }

            {(pagination.has_prev) ?
                <button
                    className={`btn btn-dark float-end ${smallButtons ? "btn-sm" : ""}`}
                    onClick={prevPageOnClick} disabled={disabled}
                >
                    {screenSize.width > 992 ? "Anterior" : "<"}
                </button> : null
            }
        </div>
    else
        return null
}

export default PaginatorComponent;