import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMatch} from "react-router-dom";

import MessageSpan from "./MessageSpan";
import {dispatchMessageClean} from "../../../../reducers/messageReducer";
import ClinicStateMessageComponent from "./ClinicStateMessageComponent";


const MessageComponent = () => {
    const dispatch = useDispatch();
    const message = useSelector(state => state.message)
    const fullScreen = useMatch("/full-screen-agenda")

    useEffect(() => {
            if (message.date !== undefined) {
                const timeout = setTimeout(() => dispatchMessageClean(dispatch), 10000);
                return () => clearTimeout(timeout);
            }
        }, [message]
    )

    if (fullScreen)
        return null

    return <div className={"container"}>
        <ClinicStateMessageComponent/>
        <MessageSpan messagesDict={{"msg": message.msg}} alertType={"alert-info"}/>
        <MessageSpan messagesDict={{"error": message.error}} alertType={"alert-danger"}/>
    </div>

}

export default MessageComponent;
