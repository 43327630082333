import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import GeneralVoucherComponent from "../../../components/services/voucher/voucher/GeneralVoucherComponent";
import GeneralSessionTypeComponent from "../../../components/services/session/sessionType/GeneralSessionTypeComponent";
import GeneralGroupSessionTypeComponent
    from "../../../components/services/session/groupSessionType/GeneralGroupSessionTypeComponent";
import {getAllServicesData} from "../../../actions/service";
import {getAllSessionTypeData} from "../../../actions/sessionTypes";
import {getAllGroupSessionTypeData} from "../../../actions/groupSessionTypes";
import {getAllVoucherData} from "../../../actions/voucher";
import {resetFilterPage} from "../../../auxiliar/handleChange/handleChangeFilterAux";


const MainServicesScreen = () => {
    const activeClinic = useSelector(state => state.activeClinic)
    const dispatch = useDispatch()

    const [firstLoad, setFirstLoad] = useState(true)

    const [sessionTypes, setSessionTypes] = useState({})
    const [sessionTypesOrder, setSessionTypesOrder] = useState([])
    const [sessionTypesPagination, setSessionTypesPagination] = useState({})
    const [selectedSessionId, setSelectedSessionId] = useState(undefined)
    const [sessionTypesFilter, setSessionTypesFilter] = useState({
        clinic_id: activeClinic.id,
        order: "name",
        per_page: 10,
        page: 1
    })

    const [groupSessionTypes, setGroupSessionTypes] = useState({})
    const [groupSessionTypesOrder, setGroupSessionTypesOrder] = useState([])
    const [groupSessionTypesPagination, setGroupSessionTypesPagination] = useState({})
    const [selectedGroupSessionId, setSelectedGroupSessionId] = useState(undefined)
    const [groupSessionTypesFilter, setGroupSessionTypesFilter] = useState({
        clinic_id: activeClinic.id,
        order: "name",
        per_page: 10,
        page: 1
    })

    const [voucherTypes, setVoucherTypes] = useState({})
    const [voucherTypesOrder, setVoucherTypesOrder] = useState([])
    const [voucherTypesPagination, setVoucherTypesPagination] = useState({})
    const [selectedVoucherId, setSelectedVoucherId] = useState(undefined)
    const [voucherTypesFilter, setVoucherTypesFilter] = useState({
        clinic_id: activeClinic.id,
        order: "name",
        per_page: 10,
        page: 1
    })

    // TODO: Review filters - Error in border cases (i.e. deleting the only element of a page that is not the first one
    useEffect(() => {
        dispatch(getAllServicesData({
                clinic_id: activeClinic.id,
                order: "name",
                per_page: 10,
                page: 1
            }, setFirstLoad,
            setSessionTypes, setSessionTypesOrder, setSessionTypesPagination,
            setGroupSessionTypes, setGroupSessionTypesOrder, setGroupSessionTypesPagination,
            setVoucherTypes, setVoucherTypesOrder, setVoucherTypesPagination
        ))
    }, [])

    useEffect(() => {
        if (!firstLoad && selectedSessionId === undefined)
            dispatch(getAllSessionTypeData(
                sessionTypesFilter, setSessionTypes, setSessionTypesOrder, setSessionTypesPagination))
    }, [
        selectedSessionId, sessionTypesFilter, setSessionTypes, setSessionTypesOrder, setSessionTypesPagination, dispatch
    ])

    useEffect(() => {
        if (!firstLoad && selectedGroupSessionId === undefined)
            dispatch(getAllGroupSessionTypeData(
                groupSessionTypesFilter, setGroupSessionTypes, setGroupSessionTypesOrder, setGroupSessionTypesPagination))
    }, [
        selectedGroupSessionId, groupSessionTypesFilter, setGroupSessionTypes, setGroupSessionTypesOrder,
        setGroupSessionTypesPagination, dispatch
    ])

    useEffect(() => {
        if (!firstLoad && selectedVoucherId === undefined)
            dispatch(getAllVoucherData(
                voucherTypesFilter, setVoucherTypes, setVoucherTypesOrder, setVoucherTypesPagination))
    }, [
        selectedSessionId, selectedGroupSessionId, selectedVoucherId, voucherTypesFilter, setVoucherTypes,
        setVoucherTypesOrder, setVoucherTypesPagination, dispatch
    ])

    const resetFilter = (type) => {
        resetFilterPage(voucherTypesFilter, setVoucherTypesFilter)

        if (type === "session") resetFilterPage(sessionTypesFilter, setSessionTypesFilter)
        else if (type === "class") resetFilterPage(groupSessionTypesFilter, setGroupSessionTypesFilter)
    }

    return <>
        <GeneralSessionTypeComponent
            sessionTypes={sessionTypes}
            filter={sessionTypesFilter}
            setFilter={setSessionTypesFilter}
            order={sessionTypesOrder}
            pagination={sessionTypesPagination}
            selectedSessionId={selectedSessionId}
            setSelectedSessionId={setSelectedSessionId}
            resetFilter={resetFilter}
        />

        <GeneralGroupSessionTypeComponent
            groupSessionTypes={groupSessionTypes}
            filter={groupSessionTypesFilter}
            setFilter={setGroupSessionTypesFilter}
            order={groupSessionTypesOrder}
            pagination={groupSessionTypesPagination}
            selectedGroupSessionId={selectedGroupSessionId}
            setSelectedGroupSessionId={setSelectedGroupSessionId}
            resetFilter={resetFilter}
        />

        <GeneralVoucherComponent
            voucherTypes={voucherTypes}
            filter={voucherTypesFilter}
            setFilter={setVoucherTypesFilter}
            order={voucherTypesOrder}
            pagination={voucherTypesPagination}
            selectedVoucherId={selectedVoucherId}
            setSelectedVoucherId={setSelectedVoucherId}
            resetFilter={resetFilter}
        />
    </>
}

export default MainServicesScreen;