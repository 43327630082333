import React, {useRef, useState} from 'react';
import Popup from 'reactjs-popup';

import EditAcquiredVoucherComponent from "./EditAcquiredVoucherComponent";


const EditAcquiredVoucherPopup = ({acquiredVoucher, update}) => {
    const ref = useRef();
    const closeTooltip = () => ref.current.close();

    const [data, setData] = useState(acquiredVoucher)

    const onClose = () => setTimeout(() => update(), 200);

    return <Popup ref={ref} onClose={onClose} lockScroll
                  trigger={<button type="button" className="btn btn btn-dark btn-sm">Editar</button>}
                  position="bottom center" arrowStyle={{color: '#000'}}>

        <div className={"p-3 mb-2 bg-light border rounded border-dark"}>
            <EditAcquiredVoucherComponent
                data={data}
                setData={setData}
                closeTooltip={closeTooltip}
            />
        </div>
    </Popup>
};


export default EditAcquiredVoucherPopup