import React from 'react';


const MessageSpan = ({messagesDict, alertType = "alert-danger"}) => {
    if (!messagesDict || Object.values(messagesDict).every((val) => val === "" || val === undefined))
        return null

    return <div className={`m-3 alert ${alertType}`} role="alert">
        {Object.keys(messagesDict).map((fieldName, i) => {
            if (messagesDict[fieldName].length > 0)
                return <span key={i}>{messagesDict[fieldName]}<br/></span>
        })}
    </div>
}

export default MessageSpan;