import React, {useEffect, useRef, useState} from 'react';
import Popup from 'reactjs-popup';

import RegisterSessionComponent from "./RegisterSessionComponent";
import RegisterGroupSessionComponent from "./RegisterGroupSessionComponent";
import RegisterBlockedHoursComponent from "./RegisterBlockedHoursComponent";
import MessageComponent from "../../../general/auxiliar/message/MessageComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";


const RegisterSessionPopup = ({childFunc, updateComponent}) => {
    const ref = useRef();
    const openTooltip = () => ref.current.open();
    const closeTooltip = () => ref.current.close();

    const [eventData, setEventData] = useState({})

    const [individualOnly, setIndividualOnly] = useState(false)

    const [selectedSessionType, setSelectedSessionType] = useState("individual")

    const [errors, setErrors] = useState({})

    useEffect(() => {
        childFunc.current = (data, individualOnly = false) => {
            setEventData(data)
            setIndividualOnly(individualOnly)
            openTooltip()
        }
    }, [])

    const handleChange = (e) => setSelectedSessionType(e.target.value)

    const onClose = () => setTimeout(() => {
        setSelectedSessionType('individual')
        setErrors({})
        updateComponent()
    }, 100);

    return <Popup
        modal nested lockScroll closeOnDocumentClick={false}
        contentStyle={{margin: '50px auto', width: "95%"}}
        position="bottom center" ref={ref} onClose={onClose}
    >

        <div className={"p-3 mb-2 bg-light border rounded border-dark w-100"}
             style={{width: "100%", maxHeight: "100%", overflowY: "auto"}}>

            <div>
                <MessageComponent/>

                <h2>Registro de una nueva sesión</h2>
                <MessageSpan messagesDict={errors}/>

                <div className="col-12 mb-3">
                    <label className="form-label">Tipo</label>
                    <select className="form-select" value={selectedSessionType} onChange={handleChange} name={"type"}
                            disabled={individualOnly}>
                        <option key="individual" value="individual">Sesión individual</option>
                        <option key="group" value="group">Clase</option>
                        <option key="block" value="block">Bloquear horas</option>
                    </select>
                </div>

                {selectedSessionType === "individual" && <RegisterSessionComponent
                    closeTooltip={closeTooltip}
                    eventData={eventData}
                    setErrors={setErrors}
                />}

                {selectedSessionType === "group" && <RegisterGroupSessionComponent
                    closeTooltip={closeTooltip}
                    eventData={eventData}
                    setErrors={setErrors}
                />}

                {selectedSessionType === "block" && <RegisterBlockedHoursComponent
                    closeTooltip={closeTooltip}
                    eventData={eventData}
                    setErrors={setErrors}
                />}
            </div>
        </div>
    </Popup>
};


export default RegisterSessionPopup