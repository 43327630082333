import {checkExistsField} from "./auxValidators";

export const validateDocumentData = (data, setErrors) => {
    const errors = {}
    if (!checkExistsField(data, 'file'))
        errors['file'] = "Seleccione el archivo que desea almacenar"
    else if (data.file_size > 25)
        errors['file'] = "El archivo es demasido grande"

    if(data.description.length > 30)
        errors['name'] = "La descripción es demasiado larga"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}