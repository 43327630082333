import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

import NotFoundScreen from "../../public/NotFoundScreen";
import InputComponent from "../../../components/general/input/InputComponent";
import {downloadInvoice, getInvoiceData, sendInvoiceEmail} from "../../../actions/invoice";
import {dispatchRedirect} from "../../../reducers/redirectReducer";


const InvoiceScreen = () => {
    const params = useParams();
    const dispatch = useDispatch()

    const [data, setData] = useState()

    useEffect(() => {
        dispatch(getInvoiceData(params.invoice_id, setData))
    }, [params.invoice_id])

    const onSendEmailClick = (e) => {
        e.preventDefault()
        dispatch(sendInvoiceEmail(data.invoice_id))
    }

    const onDownloadClick = (e) => {
        e.preventDefault()
        dispatch(downloadInvoice(data.invoice_id))
    }

    const onRedirectClick = (e) => {
        e.preventDefault()
        dispatchRedirect(dispatch, `/cash-register/${data.cash_register_id}`)
    }

    if (!data)
        return <NotFoundScreen version={'invoice'}/>

    return <div className="container my-5">
        <div className="row">
            <h2>Datos de la factura {data.invoice_code}</h2>
        </div>
        <form className="row g-3">
            <div className="col-12">
                <InputComponent
                    label={"Concepto"}
                    value={data.concept}
                    type={"text"}
                    name={"concept"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Paciente"}
                    value={data.recipient_name}
                    type={"text"}
                    name={"recipient_name"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Fecha emisión"}
                    value={data.issue_date}
                    type={"text"}
                    name={"issue_date"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Código de factura"}
                    value={data.invoice_code}
                    type={"text"}
                    name={"invoice_code"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Pagado"}
                    value={data.total_paid}
                    type={"number"}
                    name={"total_paid"}
                    disabled={true}
                />
            </div>

            <div className="col-12 container-fluid">
                <button className="btn btn-dark" onClick={onDownloadClick}>
                    Descargar
                </button>
                <button className="mx-4 btn btn-dark" onClick={onSendEmailClick}>
                    Enviar
                </button>
                <button className="btn btn-dark" onClick={onRedirectClick}>
                    Ver albarán
                </button>
            </div>
        </form>
    </div>
}

export default InvoiceScreen;