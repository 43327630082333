import {checkExistsField, checkValidDatetime, checkValidMail, checkValidPhoneNumber} from "./auxValidators";

export const validateUnconfirmedSession = (data, setErrors) => {
    const errors = {}
    if (!checkExistsField(data, "patient_name"))
        errors['patient_name'] = "El nombre del paciente es obligatorio"
    else if(data.patient_name.trim().length < 3)
        errors['patient_name'] = "El nombre es demasiado corto"

    if (!checkExistsField(data, "patient_surnames"))
        errors['patient_surnames'] = "Los apellidos del paciente son obligatorios"
    else if (data.patient_surnames.trim().length < 3)
        errors['patient_surnames'] = "Los apellidos son demasiado cortos"

    if (!checkExistsField(data, "patient_email"))
        errors['patient_email'] = "El correo es obligatorio"
    else if (!checkValidMail(data.patient_email))
        errors['patient_email'] = "El correo introducido no es válido"

    if (checkExistsField(data, "patient_phone_number") && !checkValidPhoneNumber(data.patient_phone_number)) // not compulsory
        errors['patient_phone_number'] = "El número de teléfono introducido no es válido"

    if (!checkExistsField(data, "start_datetime") || checkValidDatetime(data.start_datetime))
        errors['start_datetime'] = "Debe introducir una fecha y hora de inicio de sesion válida"

    if (!checkExistsField(data, "finish_datetime") || checkValidDatetime(data.finish_datetime))
        errors['finish_datetime'] = "Debe introducir una fecha y hora de fin de sesion válida"
    else if (new Date(data.finish_datetime).getTime() <= new Date(data.start_datetime).getTime())
        errors['finish_datetime'] = "La fecha de fin debe ser posterior a la de inicio"

    if (!checkExistsField(data, "session_type_id"))
        errors['session_type_id'] = "Debe seleccionar un tipo de clase válido"

    if (!checkExistsField(data, "employee_id"))
        errors['employee_id'] = "Debe seleccionar un fisioterapeuta válido"

    if (checkExistsField(data, "session_reason") && data.session_reason.length > 200) // not compulsory
        errors['session_reason'] = "El motivo de la sesión es demasiado largo"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}