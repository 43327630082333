import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import InputComponent from "../../../components/general/input/InputComponent";
import ScheduleSelectorGeneralComponent from "../../../components/general/input/ScheduleSelectorGeneralComponent";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";
import {getClinicPublicData} from "../../../actions/clinics";


const ShowClinicScreen = () => {
    const dispatch = useDispatch()
    const queryParams = useQueryParams()
    const screenSize = useScreenSize()

    const [clinic, setClinic] = useState({})
    const [schedule, setSchedule] = useState({})
    const [logo, setLogo] = useState("")

    useEffect(() => {
        dispatch(getClinicPublicData({
            clinic: queryParams.get("clinic"),
            session: queryParams.get("session")
        }, setClinic, setSchedule, setLogo))
    }, [])

    return <div className="container my-5">
        <h2>Clínica {clinic.name}</h2>

        <form className="row g-3">

            { /* No logo */}
            {!logo && <>
                <div className={screenSize.width > 768 ? "col-6" : "col-12"}>
                    <InputComponent
                        label={"Nombre *"}
                        value={clinic.name}
                        type={"text"}
                        name={"name"}
                        placeholder={"Nombre"}
                        disabled={true}
                    />
                </div>
                <div className={screenSize.width > 768 ? "col-6" : "col-12"}>
                    <InputComponent
                        label={"Correo electrónico *"}
                        value={clinic.email}
                        type={"text"}
                        name={"email"}
                        placeholder={"ejemplo@mail.com"}
                        disabled={true}
                    />
                </div>
            </>}

            { /* Logo and big screen */}
            {logo && screenSize.width > 768 && <>
                <div className="col-6">
                    <div className={"col-12"}>
                        <InputComponent
                            label={"Nombre *"}
                            value={clinic.name}
                            type={"text"}
                            name={"name"}
                            placeholder={"Nombre"}
                            disabled={true}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <InputComponent
                            label={"Correo electrónico *"}
                            value={clinic.email}
                            type={"text"}
                            name={"email"}
                            placeholder={"ejemplo@mail.com"}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className={"col-6 d-flex flex-wrap align-items-center"}>
                    <img src={logo}
                         className={"img-responsive mx-auto my-auto d-block"}
                         height={"150px"}
                         alt='Logo'
                    />
                </div>
            </>}

            { /* Logo and small screen */}
            {logo && screenSize.width <= 768 && <>
                <div className={"col-12 d-flex flex-wrap align-items-center"}>
                    <img src={logo}
                         className={"img-responsive mx-auto my-auto d-block"}
                         width={"90%"}
                         alt='Logo'
                    />
                </div>
                <div className={"col-12"}>
                    <InputComponent
                        label={"Nombre *"}
                        value={clinic.name}
                        type={"text"}
                        name={"name"}
                        placeholder={"Nombre"}
                        disabled={true}
                    />
                </div>
                <div className="col-12">
                    <InputComponent
                        label={"Correo electrónico *"}
                        value={clinic.email}
                        type={"text"}
                        name={"email"}
                        placeholder={"ejemplo@mail.com"}
                        disabled={true}
                    />
                </div>
            </>}


            <div className="col-6">
                <InputComponent
                    label={"Teléfono móvil"}
                    value={clinic.mobile_phone}
                    type={"tel"}
                    name={"mobile_phone"}
                    placeholder={"Número de teléfono móvil"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Teléfono fijo"}
                    value={clinic.landline_phone}
                    type={"tel"}
                    name={"landline_phone"}
                    placeholder={"Número de teléfono fijo"}
                    disabled={true}
                />
            </div>
            <div className="col-12">
                <InputComponent
                    label={"Dirección"}
                    value={clinic.address}
                    type={"text"}
                    name={"address"}
                    placeholder={"Dirección"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Provincia"}
                    value={clinic.province}
                    type={"text"}
                    name={"province"}
                    placeholder={"Provincia"}
                    disabled={true}
                />
            </div>
            <div className="col-6">
                <InputComponent
                    label={"Localidad"}
                    value={clinic.town}
                    type={"text"}
                    name={"town"}
                    placeholder={"Localidad"}
                    disabled={true}
                />
            </div>
        </form>

        <ScheduleSelectorGeneralComponent
            type={'clinic'}
            schedule={schedule}
            readOnly={true}
        />
    </div>
}

export default ShowClinicScreen;