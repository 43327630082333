import React from "react";
import {useDispatch} from "react-redux";

import EditAcquiredVoucherPopup from "./edit/EditAcquiredVoucherPopup";
import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import CheckBoxComponent from "../../../general/input/CheckBoxComponent";
import {dispatchRedirect} from "../../../../reducers/redirectReducer";
import {checkExistsField} from "../../../../auxiliar/formValidators/auxValidators";
import {deleteAcquiredVoucher} from "../../../../actions/acquiredVoucher";
import {handleChangeFilterAux} from "../../../../auxiliar/handleChange/handleChangeFilterAux";


const ListAcquiredVoucherComponent = ({
                                          patientId,
                                          acquiredVouchers,
                                          pagination,
                                          order,
                                          filter,
                                          setFilter,
                                          updateComponent,
                                      }) => {
    const dispatch = useDispatch()

    const handleAcquiredVoucherFilterChange = (e) => handleChangeFilterAux(e, filter, setFilter)

    return <div className={"container my-5"}>
        <div className={"row"}>
            <div className="col-6">
                <h2>Bonos adquiridos</h2>
            </div>

            <div className="col-3">
                <CheckBoxComponent
                    title={"Muestra únicamente los bonos adquirdos que tienen sesiones restantes"}
                    label={"Sesiones restantes"}
                    onChange={handleAcquiredVoucherFilterChange}
                    value={filter.remaining_sessions}
                    name={"remaining_sessions"}
                />
            </div>
            <div className="col-3">
                <CheckBoxComponent
                    title={"Muestra únicamente los bonos adquiridos que no han caducado"}
                    label={"No caducados"}
                    onChange={handleAcquiredVoucherFilterChange}
                    value={filter.not_expired}
                    name={"not_expired"}
                />
            </div>
        </div>

        {Object.keys(acquiredVouchers).length <= 0 ?
            <h4 className={"mb-3"}>No hay bonos adquiridos disponibles</h4> :
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr key={"header"}>
                        <th>Nombre</th>
                        <th>Tipo</th>
                        <th>Sesiones restantes</th>
                        <th>Caducidad</th>
                        <th/>
                        <th/>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {order && order.map((id) => {
                        if (checkExistsField(acquiredVouchers, id)) {
                            const voucher = acquiredVouchers[id]

                            return <tr key={id} className={voucher.unpaid === 0 ? "" : "table-danger"}>
                                <td>{voucher.name}</td>
                                <td>{voucher.session_type}</td>
                                <td>{voucher.remaining_sessions}</td>
                                <td>{voucher.due_date.substring(0, 10)}</td>

                                <td>
                                    <EditAcquiredVoucherPopup
                                        acquiredVoucher={voucher}
                                        update={updateComponent}
                                    />
                                </td>

                                <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                                        e.preventDefault()
                                        dispatchRedirect(dispatch, `/acquired-voucher/${id}`)
                                    }}>
                                        Ver detalles
                                    </button>
                                </td>

                                {voucher.deletable ? <td className="text-center">
                                    <button type="button" className="btn btn btn-danger btn-sm" onClick={() => {
                                        if (window.confirm('¿Está seguro de que quiere eliminar el bono? No se podrá recuperar la información'))
                                            dispatch(deleteAcquiredVoucher(id, updateComponent))
                                    }}>
                                        Eliminar bono
                                    </button>
                                </td> : <td className="text-center"/>}
                            </tr>
                        }
                    })}
                    </tbody>
                </table>
            </div>}

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
        />

        <div className="col-12 container-fluid">
            <button className="btn btn-dark" onClick={() =>
                dispatchRedirect(dispatch, `/acquired-voucher/register?patient=${patientId}`)
            }>
                Nuevo bono
            </button>
        </div>
    </div>
}

export default ListAcquiredVoucherComponent;