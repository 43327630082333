import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Kalend, {CalendarView} from 'kalend';
import 'kalend/dist/styles/index.css'
import Calendar from 'react-calendar';
import './../../styles/smallCalendar.css';

import RegisterSessionPopup from "../services/session/popupRegisterSession/RegisterSessionPopup";
import EditSessionPopup from "../services/session/popupEditSession/EditSessionPopup";
import PhysiotherapistSelectorComponent from "../general/input/PhysiotherapistSelectorComponent";
import useLocalStorage from "../../auxiliar/customHooks/useLocalStorage";
import useScreenSize from "../../auxiliar/customHooks/useScreenSize";
import useQueryParams from "../../auxiliar/customHooks/useQueryParams";
import {dispatchRedirect} from "../../reducers/redirectReducer";
import {checkExistsField, isValidDate, parseJson} from "../../auxiliar/formValidators/auxValidators";
import {getAllCalendarData} from "../../actions/calendar";
import {handleChangeFilterAux} from "../../auxiliar/handleChange/handleChangeFilterAux";


const MainCalendarComponent = () => {
    const openTooltipRegister = useRef(null)
    const openTooltipEdit = useRef(null)
    const kalendRef = useRef();

    const dispatch = useDispatch()
    const activeClinic = useSelector(state => state.activeClinic)
    const screenSize = useScreenSize()
    const queryParams = useQueryParams()

    const [update, setUpdate] = useState(true)

    const [events, setEvents] = useState([])

    const [data, setData] = useState({
        small_calendar_date: undefined,
        small_calendar_value: undefined,
        display_date: ""
    })

    // Don't change, error in Kalend library
    const [filter, setFilter] = useLocalStorage("calendar_filter", {
        clinic_id: activeClinic.id,
        per_page: 2000,
        starting_date: undefined,
        finishing_date: undefined,
        initial_date: new Date(),
        view: "week",
        color_code: 'session_type',
        employee_id: ""
    }, queryParams.has('keep') && queryParams.get('keep') === 'true')

    useEffect(() => {
        if (
            checkExistsField(filter, 'starting_date') && checkExistsField(filter, 'finishing_date') &&
            !checkExistsField(data, "small_calendar_date") ||
            (
                new Date(data.small_calendar_date).getTime() <= new Date(filter.finishing_date).getTime() &&
                new Date(data.small_calendar_date).getTime() >= new Date(filter.starting_date).getTime()
            )
        ) {
            setEvents([])
            setTimeout(() => dispatch(getAllCalendarData(filter, setEvents)), 1)
        }
    }, [filter.starting_date, filter.finishing_date, filter.employee_id, update, dispatch, setEvents])

    useEffect(() => {
        const new_events = []
        setEvents([])
        for (const e of events) {
            e.color = e.color_dict[filter.color_code]
            new_events.push(e)
        }
        setTimeout(() => setEvents(new_events), 1)
    }, [filter.color_code])

    useEffect(() => {
        let startingDate = new Date(filter.starting_date)
        let finishingDate = new Date(filter.finishing_date)

        if (filter.view === "month") {
            if (startingDate.getDate() !== 1) {
                startingDate.setMonth(startingDate.getMonth() + 1, 1);
            }
            if (finishingDate.getDate() !== 31 && finishingDate.getDate() !== 30 && finishingDate.getDate() !== 29 && finishingDate.getDate() !== 28) {
                finishingDate.setMonth(finishingDate.getMonth(), 0);
            }
        }

        if (
            checkExistsField(data, "small_calendar_date") &&
            new Date(data.small_calendar_date).getTime() > finishingDate.getTime()
        ) {
            kalendRef?.current?.navigateForward();
        } else if (
            checkExistsField(data, "small_calendar_date") &&
            new Date(data.small_calendar_date).getTime() < startingDate.getTime()
        ) {
            kalendRef?.current?.navigateBackwards();
        } else {
            let date = new Date(filter.starting_date)

            if (filter.view === "month") {
                const first = new Date(filter.starting_date).getTime()
                const last = new Date(filter.finishing_date).getTime()
                date = new Date((first + last) / 2)
            }

            let selected = new Date()
            if (isValidDate(startingDate) && isValidDate(finishingDate))
                selected = filter.view === "day" ? startingDate : [startingDate, finishingDate]

            setData({
                ...data,
                small_calendar_date: undefined,
                small_calendar_value: selected,
                display_date: date.toLocaleDateString('es-ES', {
                    year: 'numeric',
                    month: 'long'
                })
            })
        }
    }, [data.small_calendar_date, filter.starting_date, filter.finishing_date])

    const updateComponent = () => setUpdate(!update)

    const handleChangeFilter = (e) => handleChangeFilterAux(e, filter, setFilter)

    const onNewEventClick = (data) => {
        const actualFilter = "calendar_filter" in localStorage ?
            parseJson(window.localStorage.getItem('calendar_filter')) : {
                clinic_id: activeClinic.id,
                per_page: 2000,
                starting_date: undefined,
                finishing_date: undefined,
                initial_date: new Date(),
                view: "week",
                color_code: 'session_type',
                employee_id: ""
            }

        openTooltipRegister.current({...data, employee_id: actualFilter.employee_id})
    }

    const onEventClick = (data) => {
        if (data.type !== "block")
            openTooltipEdit.current(data)
    };

    const onEventDragFinish = (prev, current, data, resetPosition) => {
        if (current.type !== "block")
            openTooltipEdit.current(current)
        else
            resetPosition()
    }

    const goForward = (e) => {
        e.preventDefault()
        kalendRef?.current?.navigateForward()
    };

    const goBack = (e) => {
        e.preventDefault()
        kalendRef?.current?.navigateBackwards()
    };

    const goToday = (e) => {
        e.preventDefault()
        kalendRef?.current?.navigateToTodayDate()
    };

    const onFullScreenClick = (e) => {
        e.preventDefault()
        dispatchRedirect(dispatch, `/full-screen-agenda?keep=true`)
    };

    const goDate = (value, e) => {
        e.preventDefault()
        setData({
            ...data,
            small_calendar_date: value.toISOString()
        })
    }

    const onStateChange = (state) => {
        // Don't change, error in Kalend library
        const actualFilter = "calendar_filter" in localStorage ?
            parseJson(window.localStorage.getItem('calendar_filter')) : {
                clinic_id: activeClinic.id,
                per_page: 2000,
                starting_date: undefined,
                finishing_date: undefined,
                initial_date: new Date(),
                view: "week",
                color_code: 'session_type',
                employee_id: ""
            }

        setFilter({
            ...actualFilter,
            starting_date: state.range.rangeFrom,
            finishing_date: state.range.rangeTo,
            initial_date: state.selectedDate,
            view: state.calendarDays.length === 1 ? CalendarView.DAY : state.selectedView
        })
    };

    if (!checkExistsField(data, 'small_calendar_value'))
        return null

    else if (screenSize.width < 768)
        return <div className="mt-2">
            <div className={'container'}>

                <div className={'row'}>
                    <div className={'col-6'}>
                        <h2>Agenda</h2>
                    </div>
                    <div className={'col-6 container-fluid'}>
                        {screenSize.width > 400 &&
                            <button className={"btn btn-success btn-sm float-end"} onClick={onFullScreenClick}>
                                Pantalla completa
                            </button>}
                    </div>
                </div>


                <div className={"row mb-4"}>
                    <form onSubmit={(e) => e.preventDefault()}>

                        <div className="col-12">
                            <PhysiotherapistSelectorComponent
                                handleChangeFilter={handleChangeFilter}
                                value={filter.employee_id}
                            />
                        </div>

                        <div className="col-12 mt-2">
                            <label className="form-label">Código de colores</label>
                            <select className="form-select" onChange={handleChangeFilter} name={"color_code"}
                                    value={filter.color_code}>
                                <option key={"session_type"} value={"session_type"}>
                                    Tipos de sesión
                                </option>
                                <option key={"employee"} value={"employee"}>
                                    Fisioterapeutas
                                </option>
                                <option key={"state"} value={"state"}>
                                    Estado de la sesion
                                </option>
                            </select>
                        </div>

                        <div className="col-12 mt-2">
                            <label className="form-label">Vista temporal</label>
                            <select className="form-select" onChange={handleChangeFilter} name={"view"}
                                    value={filter.view}>
                                <option key={CalendarView.MONTH} value={CalendarView.MONTH}>
                                    Mes
                                </option>
                                <option key={CalendarView.WEEK} value={CalendarView.WEEK}>
                                    Semana
                                </option>
                                <option key={CalendarView.THREE_DAYS} value={CalendarView.THREE_DAYS}>
                                    Tres días
                                </option>
                                <option key={CalendarView.DAY} value={CalendarView.DAY}>
                                    Dia
                                </option>
                                <option key={CalendarView.AGENDA} value={CalendarView.AGENDA}>
                                    Diario
                                </option>
                            </select>
                        </div>
                    </form>
                </div>

                <div className={"row mb-2 container-fluid"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-success btn-sm"} onClick={goBack}>
                            {"<"}
                        </button>
                        <button className={"btn btn-success btn-sm mx-1"} onClick={goToday}>
                            Hoy
                        </button>
                        <button className={"btn btn-success btn-sm"} onClick={goForward}>
                            {">"}
                        </button>

                        <span className={"float-end"}>{data.display_date}</span>
                    </div>
                </div>
            </div>

            <div style={{height: 450}}>
                <Kalend
                    kalendRef={kalendRef}
                    colors={{light: {primaryColor: 'green'}}}
                    language={'es'}
                    hourHeight={80}
                    autoScroll={true}
                    showTimeLine={true}
                    newEventText={"Nueva sesión"}
                    disabledViews={[]}
                    selectedView={filter.view}
                    initialView={CalendarView.WEEK}
                    initialDate={filter.initial_date ? new Date(filter.initial_date).toISOString() : new Date().toISOString()}
                    events={events}
                    onEventDragFinish={onEventDragFinish}
                    onNewEventClick={onNewEventClick}
                    onEventClick={onEventClick}
                    onStateChange={onStateChange}
                    draggingDisabledConditions={{type: "block"}}
                    resizeDisabledConditions={{resize: "disabled"}}
                />
            </div>

            <RegisterSessionPopup
                childFunc={openTooltipRegister}
                updateComponent={updateComponent}
            />

            <EditSessionPopup
                childFunc={openTooltipEdit}
                updateComponent={updateComponent}
                openTooltipRegister={openTooltipRegister}
            />
        </div>

    return <div className="mt-5">
        <div className={'container'}>
            <div className={"row mb-4"}>
                <form className={"col-4"} onSubmit={(e) => e.preventDefault()}>
                    <h2>Agenda</h2>

                    <div className="col-12">
                        <PhysiotherapistSelectorComponent
                            handleChangeFilter={handleChangeFilter}
                            value={filter.employee_id}
                        />
                    </div>

                    <div className="col-12 mt-2">
                        <label className="form-label">Código de colores</label>
                        <select className="form-select" onChange={handleChangeFilter} name={"color_code"}
                                value={filter.color_code}>
                            <option key={"session_type"} value={"session_type"}>
                                Tipos de sesión
                            </option>
                            <option key={"employee"} value={"employee"}>
                                Fisioterapeutas
                            </option>
                            <option key={"state"} value={"state"}>
                                Estado de la sesion
                            </option>
                        </select>
                    </div>

                    <div className="col-12 mt-2">
                        <label className="form-label">Vista temporal</label>
                        <select className="form-select" onChange={handleChangeFilter} name={"view"}
                                value={filter.view}>
                            <option key={CalendarView.MONTH} value={CalendarView.MONTH}>
                                Mes
                            </option>
                            <option key={CalendarView.WEEK} value={CalendarView.WEEK}>
                                Semana
                            </option>
                            <option key={CalendarView.THREE_DAYS} value={CalendarView.THREE_DAYS}>
                                Tres días
                            </option>
                            <option key={CalendarView.DAY} value={CalendarView.DAY}>
                                Dia
                            </option>
                            <option key={CalendarView.AGENDA} value={CalendarView.AGENDA}>
                                Diario
                            </option>
                        </select>
                    </div>
                </form>

                <div className={"col-8 px-5 d-flex justify-content-center"}>
                    <Calendar
                        calendarType={"iso8601"}
                        showDoubleView={screenSize.width >= 992}
                        onClickDay={goDate}
                        minDetail={"month"}
                        value={data.small_calendar_value}
                        showNeighboringMonth={false}
                        showFixedNumberOfWeeks={false}
                        next2Label={null}
                        prev2Label={null}
                    />
                </div>
            </div>

            <div className={"row mb-2 container-fluid"}>
                <div className={"col-12 p-2 mx-2"}>
                    <button className={"btn btn-success btn-sm"} onClick={goBack}>
                        {"<"}
                    </button>
                    <button className={"btn btn-success btn-sm mx-1"} onClick={goToday}>
                        Hoy
                    </button>
                    <button className={"btn btn-success btn-sm"} onClick={goForward}>
                        {">"}
                    </button>

                    <span className={"mx-5"}>{data.display_date}</span>

                    <button className={"btn btn-success btn-sm float-end"} onClick={onFullScreenClick}>
                        Pantalla completa
                    </button>
                </div>
            </div>
        </div>

        <div style={{height: 800}}>
            <Kalend
                kalendRef={kalendRef}
                colors={{light: {primaryColor: 'green'}}}
                language={'es'}
                hourHeight={120}
                autoScroll={true}
                showTimeLine={true}
                newEventText={"Nueva sesión"}
                disabledViews={[]}
                selectedView={filter.view}
                initialView={CalendarView.WEEK}
                initialDate={filter.initial_date ? new Date(filter.initial_date).toISOString() : new Date().toISOString()}
                events={events}
                onEventDragFinish={onEventDragFinish}
                onNewEventClick={onNewEventClick}
                onEventClick={onEventClick}
                onStateChange={onStateChange}
                draggingDisabledConditions={{type: "block"}}
                resizeDisabledConditions={{resize: "disabled"}}
            />
        </div>

        <RegisterSessionPopup
            childFunc={openTooltipRegister}
            updateComponent={updateComponent}
        />

        <EditSessionPopup
            childFunc={openTooltipEdit}
            updateComponent={updateComponent}
            openTooltipRegister={openTooltipRegister}
        />
    </div>
}

export default MainCalendarComponent;