import logo from "../../media/Logo/Logo_grande.png"

import React from "react";
import {Link} from "react-router-dom";

import useScreenSize from "../../auxiliar/customHooks/useScreenSize";
import PartnersComponent from "../../components/general/partners/PartnersComponent";


const AboutUsScreen = () => {
    const screenSize = useScreenSize()

    return <div className="container mt-5" style={{textAlign: "justify"}}>

        <div className="mt-4 p-4 rounded text-center">
            <p className={"h2"}>
                De fisios, para fisios
            </p>

            <div className="text-center container">
                <img src={logo} alt={'Logo'} height={screenSize.width > 600 ? "400px" : '200px'}/>
            </div>
        </div>

        <div>
            <h2>About us</h2>

            <div>
                <p>
                    El equipo de MiFisio Agenda está formado por fisioterapeutas e informáticos con ganas de
                    revolucionar un sector en el que las necesidades no están cubiertas de una forma óptima. Para ello,
                    hemos creado el único CRM exclusivo para clínicas de fisioterapia basándonos en las necesidades del
                    sector tras años de experiencia con el objetivo de poner a disposición de los fisioterapeutas las
                    herramientas óptimas.
                </p>

                <p>
                    La mayoría de programas de gestión clínica incluyen funcionalidades innecesarias y se olvidan
                    las necesidades básicas, resultando en elevados precios por programas genéricos y nada
                    especializados. MiFisio Agenda nace con la idea de solucionar esto, ofrecer un software específico,
                    sencillo y así evitar gastos innecesarios.
                </p>

                <p>
                    Además, somos conscientes de que las necesidades pueden variar dependiendo de cada cada clínica, por
                    lo que podemos habernos olvidado de incluir algunas funcionalidades que podrían ser útiles. Si este
                    es el caso y tienes alguna idea no dudes en enviarnos un correo a <a
                    href={"mailto:info@mifisioagenda.com"}>info@mifisioagenda.com</a>.
                </p>


                <p>
                    Si necesitas más información puedes consultar las condiciones de <Link
                    to="/pricing">suscripción</Link>, o si tienes dudas o sugerencias de mejoras puedes
                    contactar con nosotros mandando un correo a <a
                    href={"mailto:info@mifisioagenda.com"}>info@mifisioagenda.com</a>.
                </p>
            </div>
        </div>

        <PartnersComponent/>
    </div>
}

export default AboutUsScreen;