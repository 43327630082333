import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import useLocalStorage from "../../../auxiliar/customHooks/useLocalStorage";
import {cacheAllGroupSessionTypeData} from "../../../actions/groupSessionTypes";


const GroupSessionTypeSelectorComponent = ({
                                               handleChangeFilter,
                                               value,
                                               showLabel = true,
                                               showSelectOption = false,
                                               deletedGroupSessionType = undefined,
                                               disabled = false
                                           }) => {
    const activeClinic = useSelector(state => state.activeClinic)

    const dispatch = useDispatch()

    const [groupSessionTypes, setGroupSessionTypes] = useLocalStorage('group_session_types', undefined)

    useEffect(() => {
        if (groupSessionTypes === undefined)
            dispatch(cacheAllGroupSessionTypeData({
                clinic_id: activeClinic.id,
                order: "name",
                per_page: 1000,
                page: 1
            }, setGroupSessionTypes))
    }, [activeClinic, dispatch])

    return <>
        {showLabel && <label htmlFor={"group_session_type_id"} className="form-label">Tipo de clase *</label>}

        <select className="form-select" onChange={handleChangeFilter} name={"group_session_type_id"} value={value}
                disabled={disabled}>
            {showSelectOption && <option key={""} value="">Seleccione el tipo de clase</option>}

            {deletedGroupSessionType && <option
                key={deletedGroupSessionType.group_session_type_id}
                value={deletedGroupSessionType.group_session_type_id}
            >
                {deletedGroupSessionType.display_name}
            </option>}


            {groupSessionTypes && groupSessionTypes.data && groupSessionTypes.data.order && groupSessionTypes.data.order.map((key) => {
                if (groupSessionTypes.data.content && groupSessionTypes.data.content[key]) {
                    return <option key={key} value={key}>
                        {groupSessionTypes.data.content[key].name}
                    </option>
                }
            })}
        </select>
    </>
}

export default GroupSessionTypeSelectorComponent;