import React from "react";
import {useDispatch} from "react-redux";

import {dispatchRedirect} from "../../../../reducers/redirectReducer";
import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";


const ListVoucherComponent = ({
                                  voucherTypes,
                                  filter,
                                  setFilter,
                                  order,
                                  pagination,
                                  setSelectedVoucherId,
                                  selectedVoucherId
                              }) => {
    const dispatch = useDispatch()

    const onAddClick = () => dispatchRedirect(dispatch, "/services/voucher/register/")

    return <div className={`container mt-5 ${selectedVoucherId === undefined ? "mb-5" : ""}`}>
        <h2>Bonos Ofertados</h2>

        {(!voucherTypes || Object.keys(voucherTypes).length <= 0) &&
            <h4 className={"mb-3"}>No hay bonos disponibles</h4>}

        {voucherTypes && Object.keys(voucherTypes).length > 0 &&
            <div className={"table-responsive"}>
                <table className={"table align-middle my-3"}>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Tipo</th>
                        <th>Sesiones</th>
                        <th>Precio</th>
                        <th>Validez</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {order && order.map((voucherId) => {
                        if (voucherTypes && voucherTypes[voucherId] !== undefined) {
                            return <tr key={voucherId}>
                                <td>{voucherTypes[voucherId].name}</td>
                                <td>{voucherTypes[voucherId].type}</td>
                                <td>{voucherTypes[voucherId].total_sessions}</td>
                                <td>{voucherTypes[voucherId].price} €</td>
                                <td>{voucherTypes[voucherId].months_validity} meses</td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-dark btn-sm" onClick={() =>
                                        setSelectedVoucherId(voucherTypes[voucherId].voucher_type_id)
                                    }>
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        }
                    })}
                    </tbody>
                </table>
            </div>
        }

        <PaginatorComponent
            pagination={pagination}
            setFilter={setFilter}
            filter={filter}
            disabled={selectedVoucherId !== undefined}
        />

        <div className="col-12 container-fluid">
            <button className="btn btn-dark" onClick={onAddClick}>
                Nuevo bono
            </button>
        </div>
    </div>
}

export default ListVoucherComponent;