import axios from "axios";

export const getAllCalendarData = (params, setData) => {
    return () => {
        axios.get(`/api/managers/calendar/`, {params: params})
            .then(response => {
                const elementList = []

                if (response.data.content) {
                    for (const value of Object.values(response.data.content.sessions)) {
                        elementList.push({
                            id: value.session_id,
                            startAt: value.start_datetime,
                            endAt: value.finish_datetime,
                            summary: value.summary,
                            color: value.color,
                            color_dict: value.color_dict,
                            physio_name: value.employee_name,
                            type: value.session_type,
                            resize: "disabled",
                            event_type: value.type
                        })
                    }
                }

                setData(elementList)
            })
    }
}

export const getAllAnonymousCalendarData = (params, setData, setEmployees, setEmployeesOrder,
                                            setSessionTypes, setSessionTypesOrder, setFilter) => {
    return () => {
        axios.get(`/api/managers/calendar/anonymous/`, {params: params})
            .then(response => {
                const sessionsData = response.data.content.sessions_data
                const employeesData = response.data.content.employees_data
                const sessionTypesData = response.data.content.session_types_data
                const elementList = []
                let id = 0;

                if (response.data.content) {
                    for (const value of Object.values(sessionsData.sessions)) {
                        elementList.push({
                            id: id,
                            startAt: value.start_datetime,
                            endAt: value.finish_datetime,
                            summary: value.summary,
                            color: value.color,
                            resize: "disabled",
                            event_type: "unknown"
                        })
                        id += 1
                    }
                }

                setData(elementList)

                setEmployees(employeesData.content)
                setEmployeesOrder(employeesData.order)

                setSessionTypes(sessionTypesData.content)
                setSessionTypesOrder(sessionTypesData.order)

                setFilter({
                    ...params,
                    clinic_id: response.data.content.clinic_id
                })
            })
    }
}