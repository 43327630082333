import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {dispatchRedirect} from "../../../reducers/redirectReducer";
import {validateManager} from "../../../actions/manager";


const RegisterValidationScreen = () => {
    const dispatch = useDispatch();

    const queryParameters = new URLSearchParams(window.location.search)
    const code = queryParameters.get("code")
    const manager = queryParameters.get("manager")

    const [validState, setValidState] = useState("validating")

    useEffect(() => {
        dispatch(validateManager(manager, code,setValidState))
    }, [code])

    const validatingMessage = <h6 className="display-4">Se esta validando el correo</h6>
    const validMessage = <h6 className="display-4">Correo validado</h6>
    const invalidMessage = <h6 className="display-4">Ha habido algún problema con la validación, vuelva a intentarlo de nuevo</h6>
    const validStates = {"validating": validatingMessage,
        "valid": validMessage,
        "invalid": invalidMessage}

    const onReturnClick = (e) => {
        e.preventDefault()
        dispatchRedirect(dispatch, "/login")
    }
    return <div className={"container my-5"}>
        <div className="d-flex align-items-center justify-content-center m-5">
            <div className="text-center">
                {validStates[validState]}
            </div>
        </div>
        <div className="col-12 container-fluid">
            <button className="btn btn-dark" onClick={onReturnClick}>
                Volver
            </button>
        </div>
    </div>
}

export default RegisterValidationScreen