import React, {useState} from 'react'
import {Link} from "react-router-dom";

import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";


const NavbarLoggedOut = () => {
    const screenSize = useScreenSize()
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    if (screenSize.width < 992) {
        return <nav className="py-2 navbar navbar-expand-lg navbar-dark bg-dark">
            <Link className="navbar-brand ms-3" to="/login">
                <img src={require("../../../media/Logo/Icono.png")} height={20} alt={'Logo'}
                     className={"mb-1"}/> MiFisio
            </Link>
            <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#toggle" aria-controls="toggle"
                    aria-expanded={!isNavCollapsed}
                    onClick={handleNavCollapse}>
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="toggle">
                <ul className={`${isNavCollapsed ? '' : 'ps-4'} navbar-nav me-auto mb-2 mb-lg-0`}>
                    <Link className="nav-link" to="/login">Iniciar sesión</Link>
                    <Link className="nav-link" to="/register">Registro</Link>
                    <Link className="nav-link" to="/pricing">Información</Link>
                </ul>
            </div>
        </nav>
    }

    return <nav className="px-4 py-2 navbar navbar-expand-lg navbar-dark bg-dark">
        <Link className="navbar-brand ms-3" to="/login">
            <img src={require("../../../media/Logo/Icono.png")} height={20} alt={'Logo'}
                 className={"mb-1"}/> MiFisio
        </Link>
        <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse"
                data-target="#toggle" aria-controls="toggle"
                aria-expanded={!isNavCollapsed}
                onClick={handleNavCollapse}>
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="toggle">
            <ul className={`${isNavCollapsed ? '' : 'ps-4'} navbar-nav me-auto mb-2 mb-lg-0`}>
                <Link className="nav-link" to="/login">Iniciar sesión</Link>
                <Link className="nav-link" to="/register">Registro</Link>
                <Link className="nav-link" to="/pricing">Información</Link>
            </ul>
        </div>
    </nav>
}

export default NavbarLoggedOut;