import React, {useEffect, useState} from "react";

import ListTaskComponent from "../../../components/task/ListTaskComponent";
import EditTaskComponent from "../../../components/task/EditTaskComponent";
import TaskFiltersComponent from "../../../components/task/TaskFiltersComponent";
import {useDispatch, useSelector} from "react-redux";
import {getAllTaskData} from "../../../actions/task";


const MainTaskScreen = () => {
    const dispatch = useDispatch()
    const activeClinic = useSelector(state => state.activeClinic)

    const [tasks, setTasks] = useState({})
    const [order, setOrder] = useState([])

    const [selectedTaskId, setSelectedTaskId] = useState(undefined)

    const [updateBool, setUpdateBool] = useState(false)

    const [filter, setFilter] = useState({
        clinic_id: activeClinic.id,
        order: "date_asc",
        per_page: 20,
        page: 1,
        starting_date: "",
        finishing_date: "",
        temporality: "",
        advanced_temporality: false,
        duration: "",
        period: "days",
        birthday: false,
        unfinished: false,
        autogenerated: true
    })

    const [pagination, setPagination] = useState({})

    useEffect(() => {
        dispatch(getAllTaskData(filter, setTasks, setOrder, setPagination))
    }, [updateBool, filter, dispatch, setTasks])

    const update = () => {
        setSelectedTaskId(undefined)
        setUpdateBool(!updateBool)
    }

    return <div className={'container my-5'}>
        <h2>Tareas</h2>

        <TaskFiltersComponent
            filter={filter}
            setFilter={setFilter}
        />

        <ListTaskComponent
            tasks={tasks}
            filter={filter}
            setFilter={setFilter}
            order={order}
            pagination={pagination}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            update={update}
        />

        {selectedTaskId && <EditTaskComponent
            setSelectedTaskId={setSelectedTaskId}
            task={tasks[selectedTaskId]}
            update={update}
        />}
    </div>
}

export default MainTaskScreen;