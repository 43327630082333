import React from "react";
import logo from "../../media/Logo/Logo_mediano.png";

const TermsScreen = () => {
    return <div id="container" style={{textAlign: "justify"}}>
        <div className="text-center container my-5">
            <img src={logo} alt={'Logo'}/>
            <h1>Términos de uso</h1>
        </div>

        <div className={"container my-5"}>
            <h3>Aviso Legal</h3>

            <p>En cumplimiento de lo dispuesto en el artículo 10 de la Ley 34/2002 de Servicios de la Sociedad de la
                Información y el Comercio Electrónico (LSSI) se informa de lo siguiente:
                <ul>
                    <li>Titular de la página Web: MFISIO SCHEDULA SL en adelante MIFISIOAGENDA</li>
                    <li>C.I.F.: B10584274</li>
                    <li>Domicilio Social: Calle de Emilio Ferrari, 10, 28017 Madrid</li>
                    <li>E-mail: <a href={"mailto:soporte@mifisioagenda.com"}>soporte@mifisioagenda.com</a></li>
                </ul>
            </p>

            <p>
                MIFISIOAGENDA es una franquicia comercial gestionada por MFISIO SCHEDULA SL. Las páginas
                contenidas en esta Web han sido elaboradas de conformidad con la legislación española y se
                encuentran sometidas a su jurisdicción. El acceso a estas páginas Web implica la aceptación de todos los
                términos y condiciones que aparecen en el presente aviso legal.
            </p>
            <p>
                El contenido de esta página Web, www.mifisioagenda.com, incluido su diseño, blogs, menús, etc.
                Son propiedad de MIFISIOAGENDA, por lo que la copia, uso, reproducción, distribución o cualquier
                tipo de comunicación de la información contenida debe ser autorizada por escrito por MIFISIOAGENDA.
            </p>
            <p>
                MIFISIOAGENDA, se reserva todos los derechos para modificar, limitar o cancelar el acceso y
                contenidos de la Web en cualquier momento, no asumiendo responsabilidad alguna sobre su actualización.
                Así mismo se reserva la facultad de efectuar modificaciones en la configuración o presentación sin
                previo aviso.
            </p>

            <p>
                MIFISIOAGENDA les informa que, en cumplimiento de la Ley Orgánica 15/1999, de 13 de Diciembre,
                de Protección de Datos, sus datos personales serán incorporados en nuestros ficheros, únicamente con la
                finalidad de gestionar sus compras e informarles de nuestras novedades editoriales y actividades que se
                realicen en nuestra tienda. Si usted lo cree conveniente, puede ejercer sus derechos de acceso,
                rectificación, oposición y cancelación de sus datos personales por escrito al e-mail:
                <a href={"mailto:soporte@mifisioagenda.com"}>soporte@mifisioagenda.com</a>. Para más información lea el
                apartado Protección de Datos.
            </p>

            <p>
                El sitio web utiliza técnicas de seguridad de la información generalmente aceptada en la industria,
                tales como firewalls, procedimientos de control de acceso y mecanismos criptográficos, todo ello con el
                objeto de evitar el acceso no autorizado de datos. Para lograr estos fines, el usuario/cliente acepta
                que el prestador obtenga datos para efecto de la correspondiente autenticación de los controles de
                acceso.
            </p>

            <p>
                MIFISIOAGENDA no se hace responsable del contenido de la web que pueda ser proporcionado por
                los propios usuarios. Asimismo, MIFISIOAGENDA provee en la web las herramientas para que el
                contenido proporcionado por los usuarios que sea considerado como inapropiado pueda ser notificado.
                MIFISIOAGENDA se reserva el derecho de eliminar de la web aquel contenido proporcionado por
                los usuarios que sea considerado inapropiado.
            </p>

        </div>

        <div className={"container my-5"}>
            <h3>Política de privacidad</h3>

            <p>
                La empresa MFISIO SCHEDULA SL, de ahora en adelante llamada MIFISIOAGENDA les informa que, en
                cumplimiento de la Ley Orgánica 15/1999, de 13 de Diciembre, de Protección de Datos, sus datos
                personales serán incorporados en nuestros ficheros, únicamente con las siguientes finalidades:
                <ul>
                    <li><strong>Gestión de ventas</strong>: Con la finalidad de poder gestionar sus reservas y citas así
                        como los seguimientos de los tratamientos adquiridos por medio de la página web.
                    </li>
                    <li><strong>Realizar ofertas y promociones</strong>: Con la finalidad de mantenerle informado de las
                        ofertas puntuales así como de las promociones que en diferentes momentos puedan producirse.
                    </li>
                    <li><strong>Envío de información</strong>: Con la finalidad de mantenerle informado de las novedades
                        editoriales y actividades que se realicen en nuestra clínica o en las que MIFISIOAGENDA
                        colabore.
                    </li>
                </ul>
            </p>

            <p>
                MIFISIOAGENDA se compromete a:
                <ul>
                    <li>Que los datos que nos proporcione estén en nuestras bases de datos, a las que hemos aplicado
                        diferentes sistemas de seguridad para evitar un acceso no permitido.
                    </li>
                    <li>MIFISIOAGENDA se obliga, de acuerdo establece el artículo 10 de la Ley 15/1999 de la Ley de
                        Protección de Datos de Carácter Personal (LOPD), a guardar secreto respecto a los datos
                        personales tratados, aún después de finalizada la relación comercial entre ambas partes,
                        extendiéndose esta obligación a todo el personal de su organización que acceda a dichos datos.
                    </li>
                    <li>Si por cualquier causa, MIFISIOAGENDA, dejara de prestar sus servicios con carácter definitivo,
                        procederá a la destrucción de todos los datos almacenados, para mantener la seguridad de los
                        datos suministrados por el usuario.
                    </li>
                </ul>
            </p>


            <p>
                MIFISIOAGENDA, como responsable del Fichero de datos, tiene previsto la comunicación de sus datos
                personales a terceros en los supuestos que se detallan a continuación:
                <ul>
                    <li><strong>Por disposición legal</strong>: Sus datos serán comunicados a terceros en los supuestos
                        en los que una disposición legal así lo establezca (administraciones públicas, autoridades
                        administrativas o judiciales, etc.).
                    </li>
                    <li><strong>Gestión de pagos y cobros</strong>: Sus datos personales serán comunicados a terceros
                        cuando resulte necesario para formalizar y gestionar el pago de los productos o servicios
                        adquiridos (paypal, entidades bancarias o cajas de ahorros).
                    </li>
                    <li><strong>A Terceros Necesarios para la Prestación de Nuestros Servicios</strong>: se comunicarán
                        a terceros (administraciones públicas, registros oficiales u otros profesionales como notarios,
                        etc.) los datos de carácter personal que resulten necesarios para prestar los servicios
                        contratados.
                    </li>
                </ul>
            </p>

            <p>
                Si usted lo cree conveniente, puede ejercer sus derechos de acceso, rectificación, oposición y
                cancelación de sus datos personales por escrito al e-mail: <a
                href={"mailto:soporte@mifisioagenda.com"}>soporte@mifisioagenda.com</a>.
            </p>

            <p>
                MIFISIOAGENDA como titular del sitio web que Usted visita, expone en este apartado la Política de
                Privacidad en el uso, y sobre la información de carácter personal que el usuario puede facilitar cuando
                visite o navegue por la página web de nuestra titularidad.
            </p>

            <p>
                MIFISIOAGENDA se reserva el derecho de modificar la presente Política de Protección de Datos en
                cualquier momento, con el fin de adaptarla a novedades legislativas o cambios en sus actividades, siendo
                vigente la que en cada momento se encuentre publicada en nuestra web.
            </p>

            <h6>CALIDAD Y FINALIDAD</h6>
            <p>
                Al hacer “clic” en el botón “Enviar” (o equivalente) incorporado en nuestros formularios, el usuario
                declara que la información y los datos que en ellos ha facilitado son exactos y veraces. Para que la
                información facilitada esté siempre actualizada y no contenga errores, el Usuario deberá comunicar, a la
                mayor brevedad posible, las modificaciones de sus datos de carácter personal que se vayan produciendo,
                así como las rectificaciones de datos erróneos en caso de que detecte alguno. El Usuario garantiza que
                los datos aportados son verdaderos, exactos, completos y actualizados, siendo responsable de cualquier
                daño o perjuicio, directo o indirecto, que pudiera ocasionarse como consecuencia del incumplimiento de
                tal obligación. En función del formulario o sección a la que accedas, y según la información que nos
                facilite, esta se utilizará para las finalidades descritas a continuación, por lo que aceptas
                expresamente y de forma libre e inequívocamente el tratamiento de sus datos con acuerdo a las siguientes
                finalidades:

                <ul>
                    <li>
                        Las que particularmente se indiquen en cada una de las páginas o secciones donde aparezca el
                        formulario de registro o contacto electrónico.
                    </li>
                    <li>
                        Con carácter general, para atender sus solicitudes, comentarios, consultas o cualquier tipo de
                        petición que sea realizada por el usuario a través de cualquiera de las formas de contacto que
                        ponemos a disposición de nuestros usuarios.
                    </li>
                    <li>
                        Para informarle sobre consultas, peticiones, actividades, productos, novedades y/o servicios;
                        vía e-mail, fax, Whatsapp, Skype, teléfono proporcionado, sms, mms, y de igual forma para
                        enviarle comunicaciones comerciales o publicitarias a través de cualesquier otro medio
                        electrónico o físico, que posibilite realizar comunicaciones. Estas comunicaciones, siempre
                        serán relacionadas con nuestros productos, servicios, novedades o promociones, así como aquellos
                        productos o servicios que podamos considerar de su interés y que puedan ofrecer colaboradores,
                        empresas o “partners” con los que mantengamos acuerdos de promoción o colaboración comercial. De
                        ser así, garantizamos que estos terceros nunca tendrán acceso a sus datos personales, con las
                        excepciones reflejadas en el presente, siendo en todo caso estas comunicaciones realizadas por
                        parte de MIFISIOAGENDA.
                    </li>
                    <li> Elaborar perfiles de mercado con fines publicitarios o estadísticos.</li>
                </ul>
            </p>

            <p>
                En cumplimiento de la LSSI-CE 34/2002, de 11 de julio, de la Sociedad de la Información y del Comercio
                Electrónico, le informamos que podrá en cualquier momento revocar el consentimiento prestado para él
                envió de comunicaciones comerciales, tan solo enviando un correo electrónico a:
                <a href={"mailto:soporte@mifisioagenda.com"}>soporte@mifisioagenda.com</a> indicando: BAJA SUSCRIPCIÓN
                MIFISIOAGENDA.
            </p>


            <h6>ACCESO A DATOS POR CUENTA DE TERCEROS</h6>
            <p>
                MIFISIOAGENDA podrá contratar a terceros para que desempeñen funciones en su nombre en relación con los
                fines para los que se puedan recoger sus datos personales, como por ejemplo, analizar la información
                proporcionada, alojar sitios web o similares teniendo acceso a la información de carácter personal
                necesaria para el desempeño de sus funciones, si bien no podrán utilizar dicha información para ningún
                otro fin regulando nuestras relaciones con tales terceros de manera que se cumpla la normativa aplicable
                en materia de protección de datos de carácter personal.
            </p>

            <p>
                Si desea más información acerca del uso de cookies y las prácticas de recopilación de información y los
                procedimientos de aceptación o rechazo por parte del usuario consulte nuestra POLÍTICA DE COOKIES.
            </p>


            <h6>DATOS DE TERCEROS</h6>
            <p>
                En el supuesto de que nos facilite datos de carácter personal de terceras personas, en cumplimiento de
                lo dispuesto en el artículo 5.4. LOPD, declara haber informado a dichas personas con carácter previo,
                del contenido de los datos facilitados, de la procedencia de los mismos, de la existencia y finalidad
                del fichero donde se contienen sus datos, de los destinatarios de dicha información, de la posibilidad
                de ejercitar los derechos de acceso, rectificación, cancelación u oposición, así como de los datos
                identificativos de MIFISIOAGENDA. En este sentido, es de su exclusiva responsabilidad informar de tal
                circunstancia a los terceros cuyos datos nos va a ceder, no asumiendo MIFISIOAGENDA ninguna
                responsabilidad por el incumplimiento de este precepto por parte del usuario.
            </p>

            <h6>EJERCICIO DE DERECHOS</h6>
            <p>
                El titular de los datos podrá ejercer sus derechos de acceso, rectificación, cancelación y oposición
                dirigiéndose a MIFISIOAGENDA: <a
                href={"mailto:soporte@mifisioagenda.com"}>soporte@mifisioagenda.com</a> Dicha solicitud deberá contener
                los siguientes datos: nombre y apellidos, domicilio a efecto de notificaciones, fotocopia del DNI o
                Pasaporte.
            </p>

            <h6>MEDIDAS DE SEGURIDAD</h6>
            <p>
                MIFISIOAGENDA ha adoptado todas las medidas técnicas y de organización necesaria para garantizar la
                seguridad e integridad de los datos de carácter personal que trate, así como para evitar su pérdida,
                alteración y/o acceso por parte de terceros no autorizados. No obstante lo anterior, el usuario reconoce
                y acepta que las medidas de seguridad en Internet no son inexpugnables.
            </p>

        </div>


        <div className="container my-5">
            <h3>Política de cookies</h3>
            <p>Cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies
                permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de
                navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en
                que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>

            <p>El navegador del usuario memoriza cookies en el disco duro solamente durante la sesión actual ocupando un
                espacio de memoria mínimo y no perjudicando al ordenador. Las cookies no contienen ninguna clase de
                información personal específica, y la mayoría de estas se borran del disco duro al finalizar la sesión
                de navegador (las denominadas cookies de sesión).</p>

            <p>La mayoría de los navegadores aceptan como estándar a las cookies y, con independencia de las mismas,
                permiten o impiden en los ajustes de seguridad las cookies temporales o memorizadas.</p>

            <p>Sin su expreso consentimiento –mediante la activación de las cookies en su navegador– mifisioagenda.com
                no enlazará en las cookies los datos memorizados con sus datos personales proporcionados en el momento
                del registro o la compra.</p>

            <p>
                Tipos de cookies utiliza esta página web o puede llegar a utilizar en el futuro
                <ul>
                    <li><strong>Cookies técnicas</strong>: Son aquéllas que permiten al usuario la navegación a través
                        de una página web, plataforma o aplicación y la utilización de las diferentes opciones o
                        servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de
                        datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que
                        integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de
                        inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación,
                        almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de
                        redes sociales.
                    </li>
                    <li><strong>Cookies de personalización</strong>: Son aquellas que permiten al usuario acceder al
                        servicio con algunas características de carácter general predefinidas en función de una serie de
                        criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a
                        través del cual accede al servicio, la configuración regional desde donde accede al servicio,
                        etc.
                    </li>
                    <li><strong>Cookies de análisis</strong>: Son aquéllas que bien tratadas por nosotros o por
                        terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis
                        estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza
                        su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que
                        le ofrecemos.
                    </li>
                    <li><strong>Cookies publicitarias</strong>: Son aquéllas que, bien tratadas por nosotros o por
                        terceros, nos permiten gestionar de la forma más eficaz posible la oferta de los espacios
                        publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del
                        servicio solicitado o al uso que realice de nuestra página web. Para ello podemos analizar sus
                        hábitos de navegación en Internet y podemos mostrarle publicidad relacionada con su perfil de
                        navegación.
                    </li>
                    <li><strong>Cookies de publicidad comportamental</strong>: Son aquéllas que permiten la gestión, de
                        la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya
                        incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado.
                        Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la
                        observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil
                        específico para mostrar publicidad en función del mismo.
                    </li>
                    <li><strong>Cookies de terceros</strong>: La Web mifisioagenda.com puede utilizar servicios de
                        terceros que, por cuenta de Google, recopilaran información con fines estadísticos, de uso del
                        Site por parte del usuario y para la prestación de otros servicios relacionados con la actividad
                        del Website y otros servicios de Internet.
                    </li>
                </ul>
            </p>

            <p>El Usuario acepta expresamente, por la utilización de esta página web, el tratamiento de la información
                recabada en la forma y con los fines anteriormente mencionados. Y asimismo reconoce conocer la
                posibilidad de rechazar el tratamiento de tales datos o información rechazando el uso de Cookies
                mediante la selección de la configuración apropiada a tal fin en su navegador. Si bien esta opción de
                bloqueo de Cookies en su navegador puede no permitirle el uso pleno de todas las funcionalidades del
                Website.</p>

            <p>
                Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración
                de las opciones del navegador instalado en su ordenador:
                <ul>
                    <li><a href={"http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647"}>Google Chrome</a>
                    </li>
                    <li>
                        <a href={"http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"}>
                            Internet Explorer
                        </a>
                    </li>
                    <li>
                        <a href={"http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"}>
                            Mozilla Firefox
                        </a>
                    </li>
                    <li>
                        <a href={"https://support.apple.com/kb/ph21411?locale=es_ES"}>Safari</a>
                    </li>
                </ul>
            </p>

            Si tiene dudas sobre esta política de cookies, puede contactar con nosotros a través del correo electrónico
            <a href={"mailto:soporte@mifisioagenda.com"}>soporte@mifisioagenda.com</a>.
        </div>

    </div>
}

export default TermsScreen;