import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {format} from 'date-fns';

import SelectPatientForSessionComponent from "../../../patient/SelectPatientForSessionComponent";
import PhysiotherapistSelectorComponent from "../../../general/input/PhysiotherapistSelectorComponent";
import SessionTypeSelectorComponent from "../../../general/input/SessionTypeSelectorComponent";
import RecurrentParametersComponent from "./parameters/RecurrentParametersComponent";
import UniqueParametersComponent from "./parameters/UniqueParametersComponent";
import CheckBoxComponent from "../../../general/input/CheckBoxComponent";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateSession} from "../../../../auxiliar/formValidators/sessionValidators";
import {validateSmallPatientData} from "../../../../auxiliar/formValidators/patientValidators";
import {postSessionsData} from "../../../../actions/session";
import {getPatientData} from "../../../../actions/patient";
import {checkExistsField, parseJson} from "../../../../auxiliar/formValidators/auxValidators";


const RegisterSessionComponent = ({closeTooltip, eventData, setErrors}) => {
    const dispatch = useDispatch();
    const activeClinic = useSelector(state => state.activeClinic)

    const [data, setData] = useState({
        clinic_id: activeClinic.id,
        start_datetime: (format(new Date(eventData.startAt), "yyyy-MM-dd'T'HH:mm")).toString(),
        finish_datetime: (format(new Date(eventData.endAt), "yyyy-MM-dd'T'HH:mm")).toString(),
        comment: eventData.comment ? eventData.comment : "",
        employee_id: eventData.employee_id ? eventData.employee_id : "",
        patient_id: eventData.patient_id ? eventData.patient_id : "",
        session_type_id: eventData.session_type_id ? eventData.session_type_id : "",
        session_reason: eventData.session_reason ? eventData.session_reason : "",
        recurrent: false,
        first_date: undefined,
        last_date: undefined,
        start_hour: undefined,
        finish_hour: undefined,
        repeat_monday: false,
        repeat_tuesday: false,
        repeat_wednesday: false,
        repeat_thursday: false,
        repeat_friday: false,
        repeat_saturday: false,
        repeat_sunday: false
    })

    const [filter, setFilter] = useState({
        clinic_id: activeClinic.id,
        per_page: 5,
        order: "name",
        name: "",
        surnames: "",
        email: "",
        phone_number: ""
    })

    const [userErrors, setUserErrors] = useState({})

    const [registerDisabled, setRegisterDisabled] = useState(false)

    useEffect(() => {
        const sessionTypes = parseJson(window.localStorage.getItem('session_types'))

        if (sessionTypes && Object.keys(sessionTypes.data.content).length > 0) {
            let duration = 60 * 60000
            if (checkExistsField(sessionTypes.data.content, data.session_type_id))
                duration = sessionTypes.data.content[data.session_type_id].session_duration * 60000

            if (!data.recurrent && checkExistsField(data, 'start_datetime')) {
                let startingTime = new Date(data.start_datetime).getTime()
                if (isNaN(startingTime))
                    startingTime = new Date().getTime()

                setData({
                    ...data,
                    finish_datetime: (format(new Date(startingTime + duration), "yyyy-MM-dd'T'HH:mm")).toString()
                })
            } else if (data.recurrent && checkExistsField(data, 'start_hour')) {
                let startingTime = new Date(new Date().setHours(data.start_hour.slice(0, 2), data.start_hour.slice(3, 5))).getTime()
                if (isNaN(startingTime))
                    startingTime = new Date().getTime()

                setData({
                    ...data,
                    finish_hour: (format(new Date(startingTime + duration), "HH:mm")).toString()
                })
            }
        }
    }, [data.session_type_id, data.start_datetime, data.start_hour])

    // TODO: take the data without requesting. This happens when coming from user card
    useEffect(() => {
        if (checkExistsField(data, 'patient_id') && data.patient_id !== "new")
            dispatch(getPatientData(data.patient_id, setFilter))
    }, [])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validateSession(data, setErrors) && validateSmallPatientData(filter, setUserErrors))
            dispatch(postSessionsData(filter, data, closeTooltip, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    return <form className="row g-3">
        {data.recurrent ?
            <RecurrentParametersComponent
                data={data}
                setData={setData}/> :
            <UniqueParametersComponent
                data={data}
                setData={setData}
            />
        }
        <div className="col-6">
            <PhysiotherapistSelectorComponent
                handleChangeFilter={handleChange}
                value={data.employee_id}
                showAllOption={false}
                showSelectOption={true}
            />
        </div>
        <div className="col-6">
            <SessionTypeSelectorComponent
                handleChangeFilter={handleChange}
                value={data.session_type_id}
                showSelectOption={true}
            />
        </div>
        <div className="col-12">
            <label htmlFor={"comment"} className="form-label">Comentario ({data.comment.length}/200)</label><br/>
            <textarea
                className={"form-control"}
                onChange={handleChange}
                value={data.comment}
                name={"comment"}
                placeholder={"Comentario"}
            />
        </div>
        <div className="col-12">
            <label htmlFor={"session_reason"} className="form-label">Motivo de consulta
                ({data.session_reason.length}/200)</label><br/>
            <textarea
                className={"form-control"}
                onChange={handleChange}
                value={data.session_reason}
                name={"session_reason"}
                placeholder={"Motivo de consulta"}
            />
        </div>

        <div className="col-12 mb-3">
            <CheckBoxComponent
                value={data.recurrent}
                onChange={handleChange}
                name={"recurrent"}
                label={"Recurrente"}
            />
        </div>

        <SelectPatientForSessionComponent
            data={data}
            setData={setData}
            filter={filter}
            setFilter={setFilter}
            disabled={eventData.patient_id}
            userErrors={userErrors}
        />

        <div className="col-12 container-fluid">
            <button type="submit" className="btn btn-dark me-3" onClick={onRegisterClick}
                    disabled={registerDisabled}>
                Confirmar
            </button>

            <button type="submit" className="btn btn-dark float-end" onClick={closeTooltip}>
                Cancelar
            </button>
        </div>
    </form>
}

export default RegisterSessionComponent;