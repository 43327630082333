import axios from "axios";

import {dispatchRedirect} from "../reducers/redirectReducer";


export const getAllPatientData = (params, setData, setPagination, setOrder) => {
    return () => {
        axios.get(`/api/managers/patients/`, {params: params})
            .then(response => {
                setData(response.data.content)
                setPagination(response.data.pagination)
                setOrder(response.data.order)
            })
    }
}


export const getPatientData = (patientId, setData) => {
    return () => {
        axios.get(`/api/managers/patients/${patientId}`)
            .then(response => {
                setData({
                    ...response.data.content
                })
            })
    }
}

export const postPatientData = (data, setRegisterDisabled) => {
    return (dispatch) => {
        axios.post('/api/managers/patients/', data)
            .then(response => {
                dispatchRedirect(dispatch, `/patient/${response.data.content.patient_id}`)
                setRegisterDisabled(false)
            }).catch(() => {
                setRegisterDisabled(false)
            }
        )
    }
}


export const putPatientData = (patientId, data, setDisabled, setUpdateDisabled) => {
    let req_data = JSON.parse(JSON.stringify(data))

    return () => {
        axios.put(`/api/managers/patients/${patientId}`, req_data)
            .then(() => {
                setDisabled(true)
                setUpdateDisabled(false)
            }).catch(() => {
                setUpdateDisabled(false)
            }
        )
    }
}


export const deletePatient = (patientId, setUpdateDisabled) => {
    return (dispatch) => {
        axios.delete(`/api/managers/patients/${patientId}`)
            .then(() => {
                dispatchRedirect(dispatch, "/patient")
                setUpdateDisabled(false)
            }).catch(() => {
            setUpdateDisabled(false)
        })
    }
}


export const getPatientCard = (patientId, params, setPatientData, setFirstLoad, setExistingPatient,
                               setSessionsData, setSessionsPagination, setSessionsOrder,
                               setGroupSessionsData, setGroupSessionsPagination, setGroupSessionsOrder,
                               setCashRegistersData, setCashRegistersPagination, setCashRegisterOrder,
                               setAcquiredVouchersData, setAcquiredVouchersPagination, setAcquiredVouchersOrder,
                               setDocumentsData, setDocumentsPagination, setDocumentsOrder) => {
    return () => {
        axios.get(`/api/managers/patients/${patientId}/card`, {params: params})
            .then(response => {
                const data = response.data
                const sessions = data.sessions
                const groupSessions = data.group_sessions
                const acquiredVouchers = data.acquired_vouchers
                const cashRegisters = data.cash_registers
                const documents = data.documents

                setPatientData(data.patient)

                setSessionsData(sessions.content)
                setSessionsPagination(sessions.pagination)
                setSessionsOrder(sessions.order)

                setGroupSessionsData(groupSessions.content)
                setGroupSessionsPagination(groupSessions.pagination)
                setGroupSessionsOrder(groupSessions.order)

                setCashRegistersData(cashRegisters.content)
                setCashRegistersPagination(cashRegisters.pagination)
                setCashRegisterOrder(cashRegisters.order)

                setAcquiredVouchersData(acquiredVouchers.content)
                setAcquiredVouchersPagination(acquiredVouchers.pagination)
                setAcquiredVouchersOrder(acquiredVouchers.order)

                setDocumentsData(documents.content)
                setDocumentsPagination(documents.pagination)
                setDocumentsOrder(documents.order)

                setFirstLoad(false)
            }).catch(() => {
                setExistingPatient(false)
            }
        )
    }
}