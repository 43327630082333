import React from 'react'
import {useSelector} from "react-redux";
import {useMatch} from "react-router-dom";

import NavbarLoggedOut from "./NavbarLoggedOut";
import NavbarLoggedIn from "./NavbarLoggedIn";
import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";
import NavbarReservation from "./NavbarReservation";


const Navbar = () => {
    const auth = useSelector(state => state.auth)
    const screenSize = useScreenSize()
    const fullScreen = useMatch("/full-screen-agenda")
    const reservation = useMatch("/reservation/*")

    if (fullScreen && screenSize.width > 400)
        return null

    else if (reservation)
        return <NavbarReservation/>

    else if (["manager", "clinic"].includes(auth.user_type))
        return <NavbarLoggedIn/>

    return <NavbarLoggedOut/>
}

export default Navbar;