import React from "react";
import {dispatchRedirect} from "../../../../reducers/redirectReducer";
import {useDispatch} from "react-redux";


const ShowAcquiredVoucherComponent = ({acquiredVoucher}) => {
    const dispatch = useDispatch()

    if (!acquiredVoucher)
        return <div className="container my-5">
            <h2>Datos del bono no disponibles</h2>
            <p>Los datos del bono han sido eliminados</p>
        </div>

    return <div className="container my-5">
        <h2>Datos del bono</h2>
        <div className={"table-responsive"}>
            <table className={"table align-middle my-3"}>
                <thead>
                <tr>
                    <th>Tipo</th>
                    <th>Sesiones totales</th>
                    <th>Sesiones restantes</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                <tr key={acquiredVoucher.acquired_voucher_id}>
                    <td>{acquiredVoucher.name}</td>
                    <td>{acquiredVoucher.total_sessions}</td>
                    <td>{acquiredVoucher.remaining_sessions}</td>
                    <td className="text-center">
                        <button type="button" className="btn btn-dark btn-sm" onClick={(e) => {
                            e.preventDefault()
                            dispatchRedirect(dispatch, `/acquired-voucher/${acquiredVoucher.acquired_voucher_id}`)
                        }}>
                            Detalles
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
}

export default ShowAcquiredVoucherComponent;