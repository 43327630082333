export const checkExistsField = (data, name) => {
    return (data && name in data && data[name] !== undefined && data[name] !== null && data[name] !== "");
}

export const checkValidMail = (email) => {
    return !(email.trim().length < 3 || !email.match(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi));
}

export const checkValidPhoneNumber = (number) => {
    return !(number.trim().length > 15 || !number.match(/^[+]?\d+$/));
}

// It could be necessary to add some extra conditions, now we are only checking if it is convertible to date type
export const checkValidDatetime = (str_date) => {
    return isNaN(Date.parse(str_date));
}

export const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
}

export const  parseJson = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return false;
    }
}

