import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {format} from 'date-fns';

import InputComponent from "../../../general/input/InputComponent";
import {handleChangeAux} from "../../../../auxiliar/handleChange/handleChangeAux";
import {validateUnconfirmedSession} from "../../../../auxiliar/formValidators/unconfirmedSessionValidators";
import {postUnconfirmedSession} from "../../../../actions/unconfirmedSession";
import {checkExistsField} from "../../../../auxiliar/formValidators/auxValidators";


const RegisterUnconfirmedSessionComponent = ({
                                                 closeTooltip,
                                                 eventData,
                                                 setErrors,
                                                 clinicId,
                                                 employees,
                                                 employeesOrder,
                                                 sessionTypes,
                                                 sessionTypesOrder
                                             }) => {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        clinic_id: clinicId,
        patient_name: "",
        patient_surnames: "",
        patient_email: "",
        patient_phone_number: "",
        start_datetime: (format(new Date(eventData.startAt), "yyyy-MM-dd'T'HH:mm")).toString(),
        finish_datetime: (format(new Date(eventData.endAt), "yyyy-MM-dd'T'HH:mm")).toString(),
        employee_id: eventData.employee_id ? eventData.employee_id : "",
        session_type_id: eventData.session_type_id ? eventData.session_type_id : "",
        session_reason: eventData.session_reason ? eventData.session_reason : ""
    })

    const [registerDisabled, setRegisterDisabled] = useState(false)

    useEffect(() => {
        if (sessionTypes && Object.keys(sessionTypes).length > 0) {
            let duration = 60 * 60000
            if (checkExistsField(sessionTypes, data.session_type_id))
                duration = sessionTypes[data.session_type_id].duration * 60000

            let startingTime = new Date(data.start_datetime).getTime()
            if (isNaN(startingTime))
                startingTime = new Date().getTime()

            setData({
                ...data,
                finish_datetime: (format(new Date(startingTime + duration), "yyyy-MM-dd'T'HH:mm")).toString()
            })
        }
    }, [data.session_type_id, data.start_datetime, data.start_hour])

    const handleChange = (e) => handleChangeAux(e, data, setData)

    const onRegisterClick = (e) => {
        e.preventDefault()

        setRegisterDisabled(true)
        if (validateUnconfirmedSession(data, setErrors))
            dispatch(postUnconfirmedSession(data, closeTooltip, setRegisterDisabled))
        else
            setRegisterDisabled(false)
    }

    return <form className="row g-3">
        <div className="col-6">
            <InputComponent
                label={"Nombre *"}
                onChange={handleChange}
                value={data.patient_name}
                type={"text"}
                name={"patient_name"}
                placeholder={"Nombre"}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Apellidos *"}
                onChange={handleChange}
                value={data.patient_surnames}
                type={"text"}
                name={"patient_surnames"}
                placeholder={"Apellidos"}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Email *"}
                onChange={handleChange}
                value={data.patient_email}
                type={"text"}
                name={"patient_email"}
                placeholder={"Correo"}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Teléfono"}
                onChange={handleChange}
                value={data.patient_phone_number}
                type={"text"}
                name={"patient_phone_number"}
                placeholder={"Número de teléfono"}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Fecha de inicio"}
                onChange={handleChange}
                value={data.start_datetime}
                type={"datetime-local"}
                name={"start_datetime"}
                placeholder={"ejemplo@mail.com"}
            />
        </div>
        <div className="col-6">
            <InputComponent
                label={"Fecha de fin"}
                onChange={handleChange}
                value={data.finish_datetime}
                type={"datetime-local"}
                name={"finish_datetime"}
                disabled={true}
            />
        </div>
        <div className="col-6">
            <label htmlFor={"employee_id"} className="form-label">Fisioterapeuta</label>

            <select className="form-select" onChange={handleChange} name="employee_id" value={data.employee_id}>
                <option key={""} value="">Seleccione fisioterapeuta</option>
                {employees && employeesOrder && employeesOrder.map((key) => {
                    if (employees && employees[key])
                        return <option key={key} value={key}>
                            {employees[key].name}
                        </option>
                })}
            </select>
        </div>
        <div className="col-6">
            <label htmlFor="session_type_id" className="form-label">Tipo de sesión</label>

            <select className="form-select" onChange={handleChange} name={"session_type_id"}
                    value={data.session_type_id}>
                <option key={""} value="">Seleccione tipo de sesión</option>
                {sessionTypes && sessionTypesOrder && sessionTypesOrder.map((key) => {
                    if (sessionTypes && sessionTypes[key])
                        return <option key={key} value={key}>
                            {sessionTypes[key].name}
                        </option>
                })}
            </select>
        </div>
        <div className="col-12">
            <label htmlFor={"session_reason"} className="form-label">
                Motivo de consulta ({data.session_reason.length}/200)</label><br/>
            <textarea
                className={"form-control"}
                onChange={handleChange}
                value={data.session_reason}
                name={"session_reason"}
                placeholder={"Motivo de consulta"}
            />
        </div>

        <div className="col-12 container-fluid">
            <button type="submit" className="btn btn-dark me-3" onClick={onRegisterClick}
                    disabled={registerDisabled}>
                Confirmar
            </button>

            <button type="submit" className="btn btn-dark float-end" onClick={closeTooltip}>
                Cancelar
            </button>
        </div>
    </form>
}

export default RegisterUnconfirmedSessionComponent;