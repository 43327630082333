import {checkExistsField} from "./auxValidators";

export const validateVoucherData = (data, setErrors) => {
    const errors = {}

    if (!checkExistsField(data, "name"))
        errors['name'] = "El nombre es obligatorio"
    else if(data.name.trim().length < 3)
        errors['name'] = "El nombre es demasiado corto"

    if (!checkExistsField(data, "session_type_id") && !checkExistsField(data, "group_session_type_id"))
        errors['session_type_id'] = "El tipo de sesión canjeable debe ser seleccionado"

    if (!checkExistsField(data, "price"))
        errors['price'] = "El precio es obligatorio"
    else if (parseFloat(data.price) < 0)
        errors['price'] = "El precio debe ser positivo"

    if (!checkExistsField(data, "total_sessions"))
        errors['total_sessions'] = "El número de sesiones es obligatorio"
    else if (parseInt(data.total_sessions) <= 0)
        errors['total_sessions'] = "El número de sesiones debe ser un número entero positivo"

    if (!checkExistsField(data, "months_validity"))
        errors['months_validity'] = "El número de meses de validez es obligatorio"
    else if (parseInt(data.months_validity) <= 0)
        errors['months_validity'] = "El número de meses de validez debe ser un número entero positivo"

    setErrors(errors)
    return Object.keys(errors).length === 0;
}