import axios from "axios";


export const getAllCashRegistersData = (params, setData, setPagination, setOrder) => {
    return () => {
        axios.get(`/api/managers/cash_registers/`, {params: params})
            .then(response => {
                setPagination(response.data.pagination)
                setOrder(response.data.order)
                setData(response.data.content)
            })
    }
}

export const getCashRegisterData = (cashRegisterId, setData) => {
    return () => {
        axios.get(`/api/managers/cash_registers/${cashRegisterId}`)
            .then(response => {
                setData(response.data.content)
            })
    }
}

export const postCashRegisterData = (data, closeTooltip, update) => {
    return () => {
        axios.post('/api/managers/cash_registers/', data)
            .then(() => {
                closeTooltip()
                update()
            })
    }
}

export const getCashRegisterDetails = (cashRegisterId, params, setData) => {
    return () => {
        axios.get(`/api/managers/cash_registers/cash_register_details/${cashRegisterId}`, {params: params})
            .then(response => {
                setData(response.data.content)
            })
    }
}