import React, {useEffect, useRef, useState} from 'react';
import Popup from 'reactjs-popup';

import MessageComponent from "../../../general/auxiliar/message/MessageComponent";
import MessageSpan from "../../../general/auxiliar/message/MessageSpan";
import RegisterUnconfirmedSessionComponent from "./RegisterUnconfirmedSessionComponent";


const RegisterUnconfirmedSessionPopup = ({
                                             childFunc,
                                             updateComponent,
                                             clinicId,
                                             employees,
                                             employeesOrder,
                                             sessionTypes,
                                             sessionTypesOrder
                                         }) => {
    const ref = useRef();
    const openTooltip = () => ref.current.open();
    const closeTooltip = () => ref.current.close();

    const [eventData, setEventData] = useState({})

    const [errors, setErrors] = useState({})

    useEffect(() => {
        childFunc.current = (data) => {
            setEventData(data)
            openTooltip()
        }
    }, [])

    const onClose = () => setTimeout(() => {
        setErrors({})
        updateComponent()
    }, 100);

    return <Popup
        modal nested lockScroll closeOnDocumentClick={false}
        contentStyle={{margin: '50px auto', width: "95%"}}
        position="bottom center" ref={ref} onClose={onClose}
    >

        <div className={"p-3 mb-2 bg-light border rounded border-dark w-100"}
             style={{width: "100%", maxHeight: "100%", overflowY: "auto"}}>
            <MessageComponent/>

            <h2>Registro de una nueva sesión</h2>

            <p>
                Si eres un cliente habitual de la clínica te recomendamos que introduzcas los datos con los que puedan
                identificarte (email y teléfono habituales), para que la sesion se quede registrada en tu perfil y poder
                utilizar tus bonos ya adquiridos.
            </p>

            <MessageSpan messagesDict={errors}/>

            <RegisterUnconfirmedSessionComponent
                closeTooltip={closeTooltip}
                eventData={eventData}
                setErrors={setErrors}
                clinicId={clinicId}
                employees={employees}
                employeesOrder={employeesOrder}
                sessionTypes={sessionTypes}
                sessionTypesOrder={sessionTypesOrder}/>
        </div>
    </Popup>
};


export default RegisterUnconfirmedSessionPopup