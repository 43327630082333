import axios from "axios";

export const postPatientAuthorizedAcquiredVoucher = (data, onSuccessRequest) => {
    return () => {
        axios.post('/api/managers/patient_authorized_acquired_voucher/', data)
            .then(() => {
                onSuccessRequest(false)
            })
    }
}

export const deletePatientAuthorizedAcquiredVoucher = (patientId, acquiredVoucherId, onSuccessRequest) => {
    return () => {
        axios.delete(`/api/managers/patient_authorized_acquired_voucher/acquired_voucher/${acquiredVoucherId}/patient/${patientId}`)
            .then(() => {
                onSuccessRequest(false)
            })
    }
}